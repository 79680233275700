import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    authLayoutContainer: {
        height: '100vh',
        display: 'flex',
        overflow: 'hidden',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    }
}));

function AuthLayout({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.authLayoutContainer}>
            <Suspense fallback={<LinearProgress />}>
                {children}
            </Suspense>
        </div>
    );
}

AuthLayout.propTypes = {
    children: PropTypes.object
};

export default AuthLayout;
