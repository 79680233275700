/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
    Drawer,
    Divider,
    List,
    ListSubheader,
    Hidden,
    IconButton,
    Tooltip,
    colors
} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIosRounded';
import NavItem from 'src/components/NavItem';
import { useProductAccessContext, useProductAccessNavConfig } from 'src/auth/ProductAccess';
import navBarTheme from 'src/layouts/Main/navBarTheme';
import ScrollableContent from 'src/components/ScrollableContent';
import CustomerSelect from './CustomerSelect';
import BottomNavBar from './BottomNavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.blue
    },
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: theme.constants.contentHeight
    },
    navigation: {
        overflow: 'auto',
        padding: theme.spacing(0, 1, 1, 1),
        flexGrow: 1
    },
    profile: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    badge: {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    },
    badgeDot: {
        height: 9,
        minWidth: 9
    },
    onlineBadge: {
        backgroundColor: colors.green[600]
    },
    awayBadge: {
        backgroundColor: colors.orange[600]
    },
    busyBadge: {
        backgroundColor: colors.red[600]
    },
    offlineBadge: {
        backgroundColor: colors.grey[300]
    },
    avatar: {
        cursor: 'pointer',
        width: 40,
        height: 40
    },
    details: {
        marginLeft: theme.spacing(2)
    },
    moreButton: {
        marginLeft: 'auto',
        color: colors.blueGrey[200]
    },
    bottomNavBarContainer: {
        width: '100%',
        display: 'inline-block'
    },
    devider: {
        color: '#fff !important'
    },
    hideMenuDiv: {
        textAlign: 'right'
    },
    hideMenuIcon: {
        color: '#fff',
        '&:hover': {
            color: '#ccc'
        }
    },
    openDesktopMenuIcon: {
        width: '50px',
        height: '50px',
        borderRadius: '50px',
        color: '#fff',
        backgroundColor: '#254a8e',
        padding: '10px',
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        border: '2px solid #fff',
        zIndex: 9999,
        '&:hover': {
            color: '#ccc',
            backgroundColor: '#254a8e',
        }
    }
}));

function renderNavItems({
    // eslint-disable-next-line react/prop-types
    items, subheader, key, ...rest
}) {
    return (
        <List key={key}>
            {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
            {/* eslint-disable-next-line react/prop-types */}
            {items.reduce(
                // eslint-disable-next-line no-use-before-define
                (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc, pathname, item, depth = 0
}) {
    if (item.items) {
        let open = false;

        if (item.linkable) {
            open = item.items.some((subItem) => matchPath(pathname, {
                path: subItem.href,
                exact: false
            }));
        } else {
            open = matchPath(pathname, {
                path: item.href,
                exact: false
            });
        }

        acc.push(
            <NavItem
                disabled={Boolean(item.disabled) || item.items.every((childItem) => childItem?.disabled)}
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={item.href}
                label={item.label}
                open={Boolean(open) && !item.disabled}
                title={item.title}
                titleComponent={item.titleComponent}
                linkable={item.linkable}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    key: item.href
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                disabled={Boolean(item.disabled)}
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={item.href}
                label={item.label}
                title={item.title}
                titleComponent={item.titleComponent}
            />
        );
    }

    return acc;
}

function NavBar({
    openMobile,
    onMobileClose,
    className,
    desktopDrawerOpen,
    setDesktopDrawer,
    ...rest
}) {
    const classes = useStyles();
    const location = useLocation();
    const { loading } = useProductAccessContext();
    const navConfig = useProductAccessNavConfig();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    const handleDrawerOpen = () => {
        setDesktopDrawer(true);
    };

    const handleDrawerClose = () => {
        setDesktopDrawer(false);
    };

    const content = (

        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Divider className={classes.divider} />
            <CustomerSelect />
            {loading ?
                (
                    null
                ) :
                (
                    <>
                        <nav className={classes.navigation}>
                            <ScrollableContent>
                                {navConfig.map((item) => renderNavItems({
                                    items: item.items,
                                    subheader: item.subheader,
                                    pathname: location.pathname,
                                    key: item.subheader,
                                    linkable: item.linkable
                                }))}
                            </ScrollableContent>
                        </nav>
                        <Hidden mdDown>
                            <div className={classes.hideMenuDiv}>
                                <Tooltip title="Hide menu">
                                    <IconButton onClick={handleDrawerClose} className={classes.hideMenuIcon}>
                                        <ArrowLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Hidden>
                        <Divider className={classes.divider} />
                        <div className={classes.bottomNavBarContainer}>
                            <BottomNavBar />
                        </div>
                    </>
                )}
        </div>
    );

    return (
        <ThemeProvider theme={navBarTheme}>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.mobileDrawer
                    }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    ModalProps={{
                        // Needed for CustomerSelect to trigger and select the customer when in mobile view.
                        keepMounted: true
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.desktopDrawer
                    }}
                    open={desktopDrawerOpen}
                    variant="persistent"
                >
                    {content}
                </Drawer>
                {desktopDrawerOpen === false && (
                    <Tooltip title="Show menu">
                        <IconButton onClick={handleDrawerOpen} className={classes.openDesktopMenuIcon}>
                            <ArrowRightIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Hidden>
        </ThemeProvider>
    );
}

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
    desktopDrawerOpen: PropTypes.bool,
    setDesktopDrawer: PropTypes.func
};

export default NavBar;