import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CancelIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import { useWidgetContext, useWidgetDispatch, actions as widgetActions } from 'src/components/Widgets/WidgetContext';
import { layout } from 'src/widgets/defaultWidgetLayout';
import { useAssignedProducts } from 'src/auth/ProductAccess';
import { SMARTAGENTSERVICE, KAYAKWIDGET, URLSHORTENERSERVICE } from 'src/smartDialogProducts';

const useStyles = makeStyles(() => ({
    dialogContent: {
        margin: '0',
        padding: '0'
    }
}));

const WidgetsList = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useWidgetDispatch();
    const { success: successSnack, error: errorSnack } = useSnackbar();
    const { widgets } = useWidgetContext();
    const assignedProducts = useAssignedProducts();

    // Guide widget
    const guideWidgetDisabled = React.useMemo(() => {
        return Boolean(widgets?.find?.((w) => w?.component === 'GuideWidget'));
    }, [widgets]);

    const guideWidgetTooltip = React.useMemo(() => {
        if (widgets?.find?.((w) => w?.component === 'GuideWidget')) {
            return 'Widget already added';
        }
        return 'Add Widget';
    }, [widgets]);

    // Kayak lookup widget
    const kayakLookupWidgetDisabled = React.useMemo(() => {
        if (!assignedProducts[KAYAKWIDGET]) {
            return true;
        }
        return Boolean(widgets?.find?.((w) => w?.component === 'KayakLookupWidget'));
    }, [widgets, assignedProducts]);

    const kayakLookupWidgetTooltip = React.useMemo(() => {
        if (!assignedProducts[KAYAKWIDGET]) {
            return 'Widget disabled. Contact support to request access';
        }
        if (widgets?.find?.((w) => w?.component === 'KayakLookupWidget')) {
            return 'Widget already added';
        }
        return 'Add Widget';
    }, [assignedProducts, widgets]);

    // SmartAgent. widget
    const smartAgentWidgetDisabled = React.useMemo(() => {
        if (!assignedProducts[SMARTAGENTSERVICE]) {
            return true;
        }
        return Boolean(widgets?.find?.((w) => w?.component === 'SmartAgentWidget'));
    }, [widgets, assignedProducts]);

    const smartAgentWidgetTooltip = React.useMemo(() => {
        if (!assignedProducts[SMARTAGENTSERVICE]) {
            return 'Widget disabled. Contact support to request access';
        }
        if (widgets?.find?.((w) => w?.component === 'SmartAgentWidget')) {
            return 'Widget already added';
        }
        return 'Add Widget';
    }, [assignedProducts, widgets]);

    // Summary widget
    const summaryWidgetDisabled = React.useMemo(() => {
        return Boolean(widgets?.find?.((w) => w?.component === 'SummaryWidget'));
    }, [widgets]);

    const summaryWidgetTooltip = React.useMemo(() => {
        if (widgets?.find?.((w) => w?.component === 'SummaryWidget')) {
            return 'Widget already added';
        }
        return 'Add Widget';
    }, [widgets]);

    // Url Shortener widget
    const urlShortenerWidgetDisabled = React.useMemo(() => {
        if (!assignedProducts[URLSHORTENERSERVICE]) {
            return true;
        }
        return Boolean(widgets?.find?.((w) => w?.component === 'UrlShortenerWidget'));
    }, [widgets]);

    const urlShortenerWidgetTooltip = React.useMemo(() => {
        if (!assignedProducts[URLSHORTENERSERVICE]) {
            return 'Widget disabled. Contact support to request access';
        }
        if (widgets?.find?.((w) => w?.component === 'UrlShortenerWidget')) {
            return 'Widget already added';
        }
        return 'Add Widget';
    }, [widgets]);

    const { mutate: createWidget } = useHttpRequest(({ component }) => ({
        endpoint: '/widgets',
        method: 'POST',
        body: { component, settings: null, layout }
    }));

    const handleAddWidgetClick = async (widgetComponent) => {
        let name = '';

        if (widgetComponent === 'SmartAgentWidget') {
            name = 'SmartAgent';
        } else if (widgetComponent === 'GuideWidget') {
            name = 'Guide widget';
        } else if (widgetComponent === 'SummaryWidget') {
            name = 'Summary widget';
        } else if (widgetComponent === 'KayakLookupWidget') {
            name = 'Kayak lookup widget';
        } else if (widgetComponent === 'UrlShortenerWidget') {
            name = 'Url Shortener & Qr widget';
        }

        const { error } = await createWidget({ component: widgetComponent });
        if (error) {
            errorSnack(`Error adding ${name} widget`);
        } else {
            successSnack(`${name} widget added`);
            dispatch({ type: widgetActions.UPDATE_WIDGETS });
        }
    };

    const handleCancelClick = () => {
        handleClose();
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth aria-labelledby="maps-title">
            <DialogTitle id="maps-title">
                Widgets
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TableContainer style={{ flexGrow: 1 }}>
                    <Table xs={6} sm={3}>
                        <colgroup>
                            <col width="30%" />
                            <col width="50%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">Guide widget</TableCell>
                                <TableCell align="left">
                                    This widget shows some tips and tricks.
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={guideWidgetTooltip}>
                                        <span>
                                            <IconButton
                                                aria-label="add-widget"
                                                onClick={() => handleAddWidgetClick('GuideWidget')}
                                                className={classes.cancel}
                                                disabled={guideWidgetDisabled}
                                            >
                                                <WidgetsIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">KayakAgent.</TableCell>
                                <TableCell align="left">
                                    Run a lookup based on phone number or customer number.
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={kayakLookupWidgetTooltip}>
                                        <span>
                                            <IconButton
                                                aria-label="add-widget"
                                                onClick={() => handleAddWidgetClick('KayakLookupWidget')}
                                                className={classes.cancel}
                                                disabled={kayakLookupWidgetDisabled}
                                            >
                                                <WidgetsIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">SmartAgent.</TableCell>
                                <TableCell align="left">
                                    SmartAgent. is a number lookup service.
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={smartAgentWidgetTooltip}>
                                        <span>
                                            <IconButton
                                                aria-label="add-widget"
                                                onClick={() => handleAddWidgetClick('SmartAgentWidget')}
                                                className={classes.cancel}
                                                disabled={smartAgentWidgetDisabled}
                                            >
                                                <WidgetsIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Summary widget</TableCell>
                                <TableCell align="left">
                                    Summary of sent and received SMS and WhatsApp messages.
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={summaryWidgetTooltip}>
                                        <span>
                                            <IconButton
                                                aria-label="add-widget"
                                                onClick={() => handleAddWidgetClick('SummaryWidget')}
                                                className={classes.cancel}
                                                disabled={summaryWidgetDisabled}
                                            >
                                                <WidgetsIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Url Shortener & Qr widget</TableCell>
                                <TableCell align="left">
                                    Shorten long urls to make them fit into an sms message.
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={urlShortenerWidgetTooltip}>
                                        <span>
                                            <IconButton
                                                aria-label="add-widget"
                                                onClick={() => handleAddWidgetClick('UrlShortenerWidget')}
                                                className={classes.cancel}
                                                disabled={urlShortenerWidgetDisabled}
                                            >
                                                <WidgetsIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="cancel" onClick={handleCancelClick}>
                    <Tooltip title="Cancel">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

WidgetsList.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default WidgetsList;