import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/surveyActions';
import { sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    services: [],
    servicesLoading: true,
    refreshServices: false,

    selectedSurveyService: null,
    selectedSurveyServiceLoading: true,
    refreshSelectedSurveyService: false
};

const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SURVEYSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_SURVEYSERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                services: Array.isArray(action?.data) ? action.data.sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.REFERSH_SURVEYSERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case actionTypes.SET_SELECTEDSURVEYSERVICE: {
            return {
                ...state,
                selectedSurveyService: action.data,
                selectedSurveyServiceLoading: false,
                refreshSelectedSurveyService: false
            };
        }
        case actionTypes.SET_SELECTEDSURVEYSERVICELOADING: {
            return {
                ...state,
                selectedSurveyServiceLoading: true
            };
        }
        case actionTypes.REFRESH_SELECTEDSURVEYSERVICE: {
            return {
                ...state,
                refreshSelectedSurveyService: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default surveyReducer;