import palette from '../palette';

export default {
    root: {
        '&$selected': {
            backgroundColor: palette.background.default
        },
        '&$hover': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: palette.background.default
            }
        }
    }
};
