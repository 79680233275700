import React from 'react';
import PropTypes from 'prop-types';
import DefaultNotificationContent from 'src/components/Notifications/NotificationContent/DefaultNotificationContent';

const NotificationContentFactory = ({ classes, notification }) => {
    const [notificationComponent, setNotificationComponent] = React.useState(null);

    React.useEffect(() => {
        let mounted = true;

        const importComponent = async () => {
            const Component = await React
                .lazy(() => import(`src/components/Notifications/NotificationContent/${notification.eventType}`)
                    .catch(() => import('src/components/Notifications/NotificationContent/DefaultNotificationContent')));
            if (mounted) {
                setNotificationComponent(<Component classes={classes} notification={notification} />);
            }
        };

        if (notification && notification.eventType && mounted) {
            importComponent();
        }

        return () => { mounted = false; };
    }, [notification]);

    if (!notification.eventType) {
        return <ErrorBoundary><DefaultNotificationContent classes={classes} notification={notification} /></ErrorBoundary>;
    }

    return (
        <ErrorBoundary>
            <React.Suspense fallback={<div />}>
                {notificationComponent}
            </React.Suspense>
        </ErrorBoundary>
    );
};

NotificationContentFactory.propTypes = {
    classes: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any
};

export default NotificationContentFactory;