import { IconButton, Dialog, DialogContent, DialogTitle, Fab, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Draggable from 'react-draggable';
import CodeIcon from '@material-ui/icons/Code';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DevToolContent from 'src/components/DevTools/DevToolContent';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    dialog: {
        zIndex: 9999,
        position: 'absolute',
        top: 0,
        right: '50%',
        '&& .MuiBackdrop-root': {
            backgroundColor: 'transparent'
        }
    },
    dialogTitle: {
        padding: '2px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&& *': {
            color: theme.palette.white
        }
    },
    devToolsButton: ({ open }) => ({
        zIndex: 9999,
        cursor: open ? 'move' : 'pointer'
    }),
    modalBackdrop: {
        backgroundColor: 'transparent'
    },
    devToolsContent: {
        maxHeight: 400
    }
}));

const DEVTOOL_STATE = 'smartdialog_devtools';

const defaultState = { open: false, dialog: { x: 0, y: 0 }, fab: { x: 650, y: 15 } };

const getDevtoolState = () => {
    const val = localStorage.getItem(DEVTOOL_STATE);
    if (val) {
        return JSON.parse(val);
    }
    return defaultState;
};

const PaperComponent = (props) => {
    const [position, setPosition] = React.useState(getDevtoolState()?.dialog ?? { x: 0, y: 0 });

    const onStop = (e, { x, y }) => {
        setPosition({ x, y });
        const currentState = getDevtoolState();
        localStorage.setItem(DEVTOOL_STATE, JSON.stringify({ ...currentState, dialog: { x, y } }));
    };

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: position?.x ?? 0, y: position?.y ?? 0 }}
            onStop={onStop}
            bounds="parent"
        >
            <Paper {...props} />
        </Draggable>
    );
};

const DevToolButton = ({ open, onClick }) => {
    const classes = useStyles({ open });
    const [position, setPosition] = React.useState(getDevtoolState()?.fab ?? { x: defaultState.fab.x, y: defaultState.fab.y });

    const onStop = (e, { x, y }) => {
        setPosition({ x, y });
        const currentState = getDevtoolState();
        localStorage.setItem(DEVTOOL_STATE, JSON.stringify({ ...currentState, fab: { x, y } }));
    };

    const _onClick = (e) => {
        if (!open) {
            onClick(e);
        }
    };

    return (
        <Draggable
            disabled={!open}
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: position?.x ?? 0, y: position?.y ?? 0 }}
            onStop={onStop}
        >
            <Fab size="small" color="primary" className={classes.devToolsButton} variant="extended" onClick={_onClick}>
                <CodeIcon />
            </Fab>
        </Draggable>
    );
};

DevToolButton.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

const DevTools = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(getDevtoolState()?.open ?? false);

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            const currentState = getDevtoolState();
            localStorage.setItem(DEVTOOL_STATE, JSON.stringify({ ...currentState, open }));
        }
    }, [open]);

    if (process.env.NODE_ENV !== 'development') {
        return null;
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <DevToolButton open={open} onClick={() => setOpen(true)} />
            <Dialog
                className={classes.dialog}
                open={open}
                onClose={() => { }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle className={classes.dialogTitle} disableTypography style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Typography variant="h5">Devtools</Typography>
                    <IconButton size="small" onClick={handleClose}><CloseIcon /></IconButton>
                </DialogTitle>
                <DialogContent className={classes.devToolsContent}>
                    <DevToolContent />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DevTools;