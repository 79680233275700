import React from 'react';
import { Prompt } from 'react-router-dom';
import propTypes from 'prop-types';
import CustomPrompt from 'src/components/NavigationGuard/CustomPrompt';
import usePreventWindowUnload from 'src/components/NavigationGuard/usePreventWindowUnload';
import { useSelector, useDispatch } from 'react-redux';
import { DiscardChanges, PageHasChanges } from 'src/actions';

const NavigationGuard = ({ navigate, shouldBlockNavigation }) => {
    const dispatch = useDispatch();
    const { pageHasChanges, confirmationRequested, confirmationResolveFunction } = useSelector((state) => state.session);
    usePreventWindowUnload(pageHasChanges);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [lastLocation, setLastLocation] = React.useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

    const closeModal = () => {
        setModalVisible(false);
        if (confirmationResolveFunction) { confirmationResolveFunction(false); }
    };

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = async () => {
        setModalVisible(false);

        if (confirmationResolveFunction) {
            confirmationResolveFunction(true);
        } else {
            setConfirmedNavigation(true);
        }
        dispatch(DiscardChanges());
    };

    const _navigate = React.useCallback(navigate, []);
    React.useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            dispatch(PageHasChanges(false));
            setConfirmedNavigation(false);
            setLastLocation(null);

            // Navigate to the previous blocked location with your navigate function
            _navigate(lastLocation.pathname);
        }
    }, [dispatch, confirmedNavigation, lastLocation, _navigate]);

    React.useEffect(() => {
        if (confirmationRequested) {
            setModalVisible(true);
        }
    }, [confirmationRequested]);

    return (
        <>
            <Prompt
                when={pageHasChanges}
                message={handleBlockedNavigation}
            />
            <CustomPrompt
                open={modalVisible}
                onCancel={closeModal}
                onConfirm={handleConfirmNavigationClick}
            />
        </>
    );
};

NavigationGuard.propTypes = {
    navigate: propTypes.func.isRequired,
    shouldBlockNavigation: propTypes.func.isRequired
};

export default NavigationGuard;