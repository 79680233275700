import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Tooltip, Grid, Typography, Button, LinearProgress, colors } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import CancelIcon from '@material-ui/icons/CloseRounded';
import LookupIcon from '@material-ui/icons/ChevronRightRounded';
import MobileIcon from '@material-ui/icons/PhoneIphoneRounded';
import PhoneIcon from '@material-ui/icons/PhoneRounded';
import PropTypes from 'prop-types';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpRequest } from 'src/utils/httpClient';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setRecipients, clearSendMessageSettings } from 'src/actions';
import { SmartAgentPrice } from './SmartAgent';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.7em',
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        '&:first-of-type': {
            marginTop: theme.spacing(2)
        }
    },
    datarow: {
        marginTop: theme.spacing(1),
        fontSize: '1.2em'
    },
    container: {
        padding: theme.spacing(4),
        paddingBottom: '0'
    },
    contactInfoContainer: {
        position: 'sticky',
        top: theme.spacing(6)
    },
    button: {
        marginTop: theme.spacing(3),
        minWidth: '50%',
        color: '#fff',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        fontSize: '1.4em',
        fontWeight: 'bold',
        borderRadius: '10px'
    },
}));

const SearchResults = ({ open, setOpen, searchResults }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error: errorSnack } = useSnackbar();
    const { customerId } = useParams();
    const [contactDetails, setContactDetails] = React.useState({});

    const handleCancelClick = (event) => {
        event.stopPropagation();
        setContactDetails({});
        setOpen(false);
    };

    const handleSendMessageParams = (phonenumber) => {
        dispatch(clearSendMessageSettings());
        dispatch(setRecipients({ recipients: phonenumber ? [phonenumber] : [] }));

        history.push(`/${customerId}/sendmessages`);
    };

    const { mutate: _getContactDetailsRequest, loading: getContactDetailsLoading } = useHttpRequest((contactId) => ({
        method: 'GET',
        endpoint: `/smartagent/contacts/${contactId}`
    }));

    const getContactDetailsRequest = React.useCallback(_getContactDetailsRequest, []);

    const onLookupClick = async (id) => {
        const { payload, error, errorMessages } = await getContactDetailsRequest(id);

        if (!error) {
            setContactDetails(payload ?? {});
        } else {
            errorSnack(errorMessages);
        }
    };

    const columns = [
        {
            name: 'subsType',
            label: '',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <IconButton
                            aria-label="phone-type"
                        >
                            {value === 'MOB' ? (
                                <Tooltip title="Mobile phone">
                                    <MobileIcon />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Home or Work phone">
                                    <PhoneIcon />
                                </Tooltip>
                            )}
                        </IconButton>
                    );
                }
            }
        },
        {
            name: 'firstname',
            label: 'Firstname',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'lastname',
            label: 'Lastname',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'city',
            label: 'City',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: '',
            label: '',
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: () => {
                    return (
                        <IconButton
                            aria-label="contact-lookup"
                        >
                            <Tooltip title="Lookup contact">
                                <LookupIcon />
                            </Tooltip>
                        </IconButton>
                    );
                }
            }
        }
    ];

    const options = {
        elevation: 0,
        print: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        responsive: 'standard',
        filter: false,
        search: false,
        download: false,
        viewColumns: false,
        fixedHeader: true,
        textLabels: {
            body: {
                noMatch: 'Sorry, no contacts found',
            }
        },
        onRowClick: (row, { dataIndex }, event) => {
            event.stopPropagation();
            onLookupClick(searchResults?.[dataIndex]?.id ?? '');
        },
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth aria-labelledby="smart-agent-results-title">
            <DialogTitle id="maps-title">
                Search results
            </DialogTitle>
            <DialogContent style={{ padding: '0' }}>
                <Grid container item xs={12}>
                    <Grid item xs={7} className={classes.container}>
                        <MuiThemeProvider theme={(theme) => ({
                            ...theme,
                            overrides: {
                                ...theme.overrides,
                                MuiTableHead: {
                                    root: {
                                        display: 'none'
                                    }
                                },
                                MuiTableCell: {
                                    root: {
                                        padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`
                                    }
                                }
                            }
                        })}
                        >
                            <MUIDataTable data={searchResults ?? []} columns={columns} options={options} />
                        </MuiThemeProvider>
                    </Grid>
                    <Grid item xs={5} className={classes.container}>
                        {getContactDetailsLoading && <LinearProgress className={classes.progrssbar} />}
                        {Object.keys(contactDetails ?? {}).length === 0 ? (
                            <div className={classes.contactInfoContainer}>
                                {searchResults?.length > 0 ? (
                                    <h3>{`Click on a row in the contact list on the left to do a contact lookup. The fee is ${SmartAgentPrice} EUR/lookup.`}</h3>
                                ) : (
                                    <h3>No fee charged for the request.</h3>
                                )}
                            </div>
                        ) : (
                            <div className={classes.contactInfoContainer}>
                                <Typography className={classes.header}>Firstname</Typography>
                                <Typography className={classes.datarow}>{contactDetails?.firstname}</Typography>

                                <Typography className={classes.header}>Lastname</Typography>
                                <Typography className={classes.datarow}>{contactDetails?.lastname}</Typography>

                                <Typography className={classes.header}>Phone number</Typography>
                                <Typography className={classes.datarow}>{contactDetails?.number}</Typography>

                                <Typography className={classes.header}>Operator</Typography>
                                <Typography className={classes.datarow}>{contactDetails?.operatorName}</Typography>

                                <Typography className={classes.header}>Address</Typography>
                                <Typography className={classes.datarow}>{contactDetails?.streetAddress}</Typography>

                                <Typography className={classes.header}>City</Typography>
                                <Typography className={classes.datarow}>{`${contactDetails?.zip} ${contactDetails?.city}`}</Typography>

                                <Typography className={classes.header}>Owner</Typography>
                                <Typography className={classes.datarow}>{`${contactDetails?.ownerFirstname} ${contactDetails?.ownerLastname}`}</Typography>

                                <Button variant="contained" className={classes.button} onClick={() => handleSendMessageParams(contactDetails?.number)}>
                                    Send Message
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="cancel" onClick={(e) => handleCancelClick(e)}>
                    <Tooltip title="Cancel">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

SearchResults.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    searchResults: PropTypes.array.isRequired
};

export default SearchResults;