import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { SetDialogServiceMessagesLoaded, SetDialogServiceMessagesLoading, SetDialogThreadsLoaded, SetMoreDialogThreadsLoaded, SetDialogThreadsLoading, SetPendingDialogThreadsLoading, SetMorePendingDialogThreadsLoaded, SetPendingDialogThreadsLoaded, QueuedDialogMessageSendSuccess, QueuedDialogMessageSendError, SetPinnedThreadsLoading, SetPinnedThreadsLoaded, SetUnreadThreadCount, ClearThreadFilters } from 'src/actions/dialogActions';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { DIALOGSERVICE } from 'src/smartDialogProducts';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';

export const useLoadDialogThreads = () => {
    const dispatch = useDispatch();
    const [threadsFetchedForCustomerId, setThreadsFetchedForCustomerId] = React.useState(null);
    const { customerId: selectedCustomerId } = useParams();
    const { refreshDialogThreads, loadMoreThreads, threadPaging: { $top, $skip }, threadFilters: { service, status, search, ownerUserId } } = useSelector((state) => state.dialog);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    React.useEffect(() => {
        dispatch(SetDialogThreadsLoading(true));
    }, [dispatch, selectedCustomerId]);

    const $filter = React.useMemo(() => {
        const statusFilterArray = Array.isArray(status) ? status : [];

        const filters = [
            { expression: `SourceServiceIdOrServiceIdEquals == ${service}`, if: service !== '' },
            { expression: `status == ${statusFilterArray.join('|')}`, if: statusFilterArray.length > 0 },
            { expression: `ownerUserId == ${ownerUserId}`, if: ownerUserId !== '' }
        ]
            .filter((f) => f?.if).map((f) => f.expression);
        return filters.length > 0 ? filters.join(', ') : null;
    }, [service, status, ownerUserId]);

    const { mutate: fetchThreads } = useHttpRequest(({ search, $filter, $top, $skip }) => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads',
        params: {
            ...$filter ? { filters: $filter } : {},
            pageSize: $top,
            page: ($skip / $top) + 1,
            sorts: '-updated',
            searchText: search
        }
    }));

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload, error } = await fetchThreads({ search, $filter, $top, $skip });

            if (loadMoreThreads) {
                if (!error) {
                    dispatch(SetMoreDialogThreadsLoaded(payload?.totalCount, Array.isArray(payload?.value) ? payload.value : []));
                } else {
                    dispatch(SetMoreDialogThreadsLoaded(0, []));
                }
            } else if (error) {
                dispatch(SetDialogThreadsLoaded(0, []));
            } else {
                dispatch(SetDialogThreadsLoaded(payload?.totalCount, Array.isArray(payload?.value) ? payload.value : []));
            }
        };

        if (!customerHasDialogServiceAccess) {
            return () => { };
        }

        if (refreshDialogThreads || loadMoreThreads) {
            makeRequest();
            return () => { };
        }

        if (selectedCustomerId !== threadsFetchedForCustomerId) {
            setThreadsFetchedForCustomerId(selectedCustomerId);
            // ClearThreadFilters will refresh threads so no makeRequest() needed.
            dispatch(ClearThreadFilters());
        }
        return () => { };
    }, [dispatch, fetchThreads, customerHasDialogServiceAccess, selectedCustomerId, threadsFetchedForCustomerId, refreshDialogThreads, loadMoreThreads, $filter, $top, $skip, search]);

    const { mutate: fetchUnreadThreads } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads',
        params: {
            filters: 'status == Unread',
            pageSize: 1000,
            page: 1,
            select: 'id',
            sorts: '-updated'
        }
    }));

    /* Fetch Unread threads when threads are refreshed */
    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload, error } = await fetchUnreadThreads();

            if (!error && payload?.totalCount) {
                dispatch(SetUnreadThreadCount(payload.totalCount));
            }
        };

        if (customerHasDialogServiceAccess && (selectedCustomerId !== threadsFetchedForCustomerId || refreshDialogThreads)) {
            makeRequest();
        }
    }, [dispatch, fetchUnreadThreads, customerHasDialogServiceAccess, selectedCustomerId, refreshDialogThreads]);
};

export const useLoadPendingDialogThreads = () => {
    const dispatch = useDispatch();
    const [threadsFetchedOnMount, setThreadsFetchedOnMount] = React.useState(false);
    const { customerId: selectedCustomerId } = useParams();
    const { refreshPendingDialogThreads, loadMorePendingThreads, pendingThreadPaging: { $top, $skip }, pendingThreadFilters: { service, search, ownerUserId } } = useSelector((state) => state.dialog);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    React.useEffect(() => {
        setThreadsFetchedOnMount(false);
    }, [dispatch, selectedCustomerId]);

    React.useEffect(() => {
        dispatch(SetPendingDialogThreadsLoading(true));
    }, [dispatch]);

    const $filter = React.useMemo(() => {
        const filters = [
            { expression: `SourceServiceIdOrServiceIdEquals == ${service}`, if: service !== '' },
            { expression: `ownerUserId == ${ownerUserId}`, if: ownerUserId !== '' }
        ]
            .filter((f) => f?.if).map((f) => f.expression);
        return filters.length > 0 ? filters.join(', ') : null;
    }, [service, ownerUserId]);

    const { mutate } = useHttpRequest(({ search, $filter, $top, $skip }) => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/pendingthreads',
        params: {
            ...$filter ? { filters: $filter } : {},
            pageSize: $top,
            page: ($skip / $top) + 1,
            sorts: '-updated',
            searchText: search
        }
    }));
    const fetchThreads = React.useCallback(mutate, []);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload, error } = await fetchThreads({ search, $filter, $top, $skip });

            if (loadMorePendingThreads) {
                if (!error) {
                    dispatch(SetMorePendingDialogThreadsLoaded(payload?.totalCount, Array.isArray(payload?.value) ? payload.value : []));
                } else {
                    dispatch(SetMorePendingDialogThreadsLoaded(0, []));
                }
            } else if (error) {
                dispatch(SetPendingDialogThreadsLoaded(0, []));
            } else {
                dispatch(SetPendingDialogThreadsLoaded(payload?.totalCount, Array.isArray(payload?.value) ? payload.value : []));
            }
        };

        if (customerHasDialogServiceAccess && (!threadsFetchedOnMount || refreshPendingDialogThreads || loadMorePendingThreads)) {
            setThreadsFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchThreads, customerHasDialogServiceAccess, threadsFetchedOnMount, refreshPendingDialogThreads, loadMorePendingThreads, $filter, $top, $skip, search]);
};

/**
 * Hook that loads messages for dialog services and dispatches them.
 */
export const useLoadDialogServiceMessages = () => {
    const dispatch = useDispatch();
    const [serviceMessagesFetchedForCustomerId, setServiceMessagesFetchedForCustomerId] = React.useState(null);
    const { customerId: selectedCustomerId } = useParams();
    const { dialogServicesById, dialogServicesLoading, dialogMessageFilters: { search, service }, refreshDialogServiceMessages } = useSelector((state) => state.dialog);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const filteredServices = React.useMemo(() => {
        if (service && service !== '') {
            return `'${service}'`;
        }
        const dialogServices = Object.keys(dialogServicesById);
        if (dialogServices.length > 0) {
            return dialogServices.map((dialogServiceId) => `'${dialogServiceId}'`).join(', ');
        }
        return null;
    }, [service, dialogServicesById]);

    const { mutate } = useHttpRequest(({ filteredServices, search, from }) => ({
        method: 'GET',
        endpoint: '/messagepersistence/messagesodata',
        params: {
            $filter: [
                { expression: `Created ge ${from}`, if: true },
                { expression: `ServiceId in (${filteredServices})`, if: filteredServices },
                { expression: 'Direction eq \'Inbound\'', if: true },
                { expression: 'ServiceType ne \'Group\'', if: true },
                { expression: `SenderAddress eq '${search}'`, if: search !== '' }
            ].filter((f) => f.if).map((f) => f.expression).join(' and '),
            $expand: 'Attachments',
            $count: true,
            $top: 1000
        }
    }));

    const fetchMessages = React.useCallback(mutate, []);

    React.useEffect(() => {
        dispatch(SetDialogServiceMessagesLoading(true));
        return () => { dispatch(SetDialogServiceMessagesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchMessages({ filteredServices, from: moment().add(-1, 'month').toJSON(), search });
            dispatch(SetDialogServiceMessagesLoaded(error ? [] : payload));
        };

        if (
            customerHasDialogServiceAccess && !dialogServicesLoading && filteredServices &&
            (
                selectedCustomerId !== serviceMessagesFetchedForCustomerId || refreshDialogServiceMessages
            )
        ) {
            setServiceMessagesFetchedForCustomerId(selectedCustomerId);
            makeRequest();
        }
    }, [
        dispatch,
        fetchMessages,
        customerHasDialogServiceAccess,
        selectedCustomerId,
        serviceMessagesFetchedForCustomerId,
        refreshDialogServiceMessages,
        filteredServices,
        dialogServicesLoading,
        search
    ]);
};

export const useDialogMessageProcessor = () => {
    const dispatch = useDispatch();
    const [processedMessage, setProcessedMessage] = React.useState(null);
    const { queuedDialogMessages } = useSelector((state) => state.dialog);
    const { claims: { name: senderName } } = useSelector((state) => state.auth);

    const { mutate: getThreadService } = useHttpRequest((serviceId) => ({
        method: 'GET',
        endpoint: `/messaging/services/dialog/${serviceId}`
    }));

    const { mutate: sendMessage } = useHttpRequest(({
        content,
        thread: {
            id: threadId,
            serviceId,
            sourceServiceId,
            inputSourceAddress: sender,
            recipientAddress: address,
            latestMessage = { protocol: 'SMS' }
        },
        messagingAccountId,
        attachmentUri,
        forceExpiration = false
    }) => ({
        method: 'POST',
        endpoint: '/messaging/services/dialog/senddiscussionmessage',
        params: {
            forceExpiration
        },
        headers: {
            ...messagingAccountId ? { messagingAccountId } : {}
        },
        body: {
            content,
            threadId,
            serviceId,
            sourceServiceId,
            sender,
            senderName,
            protocol: latestMessage.protocol,
            recipients: [
                {
                    address,
                    personalization: {}
                }
            ],
            attachmentUri
        }
    }));

    React.useEffect(() => {
        if (processedMessage === null && queuedDialogMessages?.length > 0) {
            setProcessedMessage(queuedDialogMessages[0]);
        }
    }, [processedMessage, queuedDialogMessages]);

    React.useEffect(() => {
        const doWork = async () => {
            const { thread, content, attachmentUri } = processedMessage;
            const { payload: getThreadServicePayload, error: getThreadServiceError, errorMessages } = await getThreadService(thread?.serviceId);
            const messagingAccountId = getThreadServicePayload?.messagingAccountId;

            if (!getThreadServiceError && messagingAccountId) {
                const { payload: sendMessagePayload, error: sendMessageError, errorMessages } = await sendMessage({ content, thread, messagingAccountId, attachmentUri });
                if (!sendMessageError) {
                    dispatch(QueuedDialogMessageSendSuccess(processedMessage, sendMessagePayload));
                } else {
                    // Error in sendmessage
                    dispatch(QueuedDialogMessageSendError(processedMessage, sendMessagePayload, errorMessages));
                }
            } else {
                // Error in getThreadService
                dispatch(QueuedDialogMessageSendError(processedMessage, getThreadServicePayload, errorMessages));
            }

            setProcessedMessage(null);
        };
        if (processedMessage) {
            doWork();
        }
    }, [dispatch, processedMessage, getThreadService, sendMessage]);

    return null;
};

export const useLoadPinnedDialogThreads = () => {
    const dispatch = useDispatch();
    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const { mutate: fetchPinnedThreads, loading } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads/pinned'
    }));

    React.useEffect(() => {
        dispatch(SetPinnedThreadsLoading(loading));
    }, [loading, dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload, error } = await fetchPinnedThreads();
            dispatch(SetPinnedThreadsLoaded(!error && Array.isArray(payload) ? payload : []));
        };

        if (customerHasDialogServiceAccess) {
            makeRequest();
        }
    }, [customerHasDialogServiceAccess, fetchPinnedThreads, dispatch]);

    return null;
};

export const useSelectedDiscussionServiceInputSourceAddresses = (discussionServiceId) => {
    const { customerInputSourcesByInputSourceId } = useSelector((state) => state.twowaymessaging);

    const { payload: keywordPayload } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/dialog/inputsources/servicekeywords/${discussionServiceId}`
    }, Boolean(discussionServiceId));

    return React.useMemo(() => {
        if (!Array.isArray(keywordPayload?.availableKeywords) || keywordPayload?.serviceId !== discussionServiceId) {
            return [];
        }
        return keywordPayload.availableKeywords.reduce((addresses, keyword) => {
            if (!customerInputSourcesByInputSourceId?.[keyword?.inputSourceId]?.address) {
                return addresses;
            }
            return [...addresses, customerInputSourcesByInputSourceId[keyword.inputSourceId].address];
        }, []).sort();
    }, [customerInputSourcesByInputSourceId, keywordPayload, discussionServiceId]);
};