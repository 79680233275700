export const layout = {
    xl: {
        x: 0,
        y: 0
    },
    lg: {
        x: 0,
        y: 0
    },
    md: {
        x: 0,
        y: 0
    },
    xs: {
        x: 0,
        y: 0
    },
    xxs: {
        x: 0,
        y: 0
    }
};