import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerRightsLoaded } from 'src/actions/authActions';
import { useProductsByProductType } from 'src/auth/ProductAccess';
import { useHttpRequest } from 'src/utils/httpClient';

export const useCustomerRights = () => {
    const [loading, setLoading] = React.useState(true);
    const [customerRights, setCustomerRights] = React.useState([]);
    const { claims } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const customerIds = claims?.customerIds;
    const customerNames = claims?.customerNames;
    const customerRoles = claims?.customerRoles;
    const subscriptionPlans = claims?.subscriptionPlans;
    const isSysAdmin = claims?.isSysAdmin;

    const { mutate: _getAllCustomers } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/customermanagement/customer'
    }));
    const getAllCustomers = React.useCallback(_getAllCustomers, []);

    React.useEffect(() => {
        const fetchCustomers = async () => {
            const { error, payload } = await getAllCustomers();
            if (!error && Array.isArray(payload?.value)) {
                const _customerRights = payload.value.map((customer) => ({
                    customerId: customer.id,
                    name: customer?.displayName,
                    role: 'SysAdmin',
                    subscriptionPlan: customer.subscriptionPlan,
                    marketplaceCustomer: customer?.marketplaceCustomer,
                    trialCustomer: customer?.trialCustomer
                })).sort((a, b) => {
                    const nameA = a?.name?.toUpperCase?.(); // ignore upper and lowercase
                    const nameB = b?.name?.toUpperCase?.(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }) ?? [];

                setCustomerRights(_customerRights);
                dispatch(customerRightsLoaded(_customerRights, isSysAdmin));
            } else {
                setCustomerRights([]);
                dispatch(customerRightsLoaded([], isSysAdmin));
            }

            setLoading(false);
        };

        if (isSysAdmin) {
            fetchCustomers();
        } else if (customerIds) {
            const _customerRights = customerIds?.reduce((prev, current, currentIndex) => ([
                ...prev,
                {
                    customerId: current,
                    name: customerNames?.[currentIndex],
                    role: customerRoles?.[currentIndex],
                    subscriptionPlan: subscriptionPlans?.[currentIndex]
                }
            ]), []) ?? [];
            setCustomerRights(_customerRights);
            dispatch(customerRightsLoaded(_customerRights, isSysAdmin));
            setLoading(false);
        }
    }, [isSysAdmin, customerIds, customerNames, customerRoles, subscriptionPlans, getAllCustomers, dispatch]);

    return { isSysAdmin, customerRights, loading };
};

export const useCheckIfSysAdmin = () => {
    const { claims } = useSelector((state) => state.auth);
    return Boolean(claims?.isSysAdmin);
};

export const useCheckPermissions = (resourceId, productType) => {
    const { userAdminAccessGroups, selectedCustomerRole } = useSelector((state) => state.auth);
    const customerProducts = useProductsByProductType();

    const targetCustomerProduct = React.useMemo(() => customerProducts?.[productType] ?? null, [customerProducts, productType]);

    const adminCustomerProductAccessFromAccessGroup = React.useMemo(() => (
        userAdminAccessGroups?.find((ag) => {
            const matchingCustomerProductInAccessGroup = ag?.customerProducts?.find?.((cp) => cp?.productId === targetCustomerProduct?.id);
            const matchingCustomerProductResources = ag?.accessGroupResources?.filter?.((r) => r?.customerProductId === matchingCustomerProductInAccessGroup?.id) ?? [];

            return matchingCustomerProductInAccessGroup && matchingCustomerProductResources.length === 0;
        })), [userAdminAccessGroups, targetCustomerProduct]);

    const adminResourceAccessFromAccessGroup = React.useMemo(() => (
        userAdminAccessGroups?.find((ag) => ag?.accessGroupResources?.find?.((r) => r?.resourceId === resourceId))
    ), [userAdminAccessGroups, resourceId]);

    if (selectedCustomerRole === 'SysAdmin' || selectedCustomerRole === 'Admin' || adminCustomerProductAccessFromAccessGroup || adminResourceAccessFromAccessGroup) {
        return true;
    }

    return false;
};