export const CODESERVICE = 'CodeService';
export const CONTACTSERVICE = 'ContactService';
export const CUSTOMERMANAGEMENTSERVICE = 'CustomerManagementService';
export const MESSAGEPERSISTENCESERVICE = 'MessagePersistenceService';
export const ONEWAYMESSAGINGSERVICE = 'OneWayMessagingService';
export const TWOWAYMESSAGINGSERVICE = 'TwoWayMessagingService';
export const GROUPSERVICE = 'GroupService';
export const MESSAGINGSERVICE = 'MessagingService';
export const REPORTINGSERVICE = 'ReportingService';
export const DIALOGSERVICE = 'DialogService';
export const CARRIERBILLINGSERVICE = 'CarrierBillingService';
export const SURVEYSERVICE = 'SurveyService';
export const APISUBSCRIPTIONMANAGEMENT = 'ApiSubscriptionManagement';
export const PATMANAGEMENT = 'PatManagement';
export const ADBANSERVICE = 'AdBanService';
export const USERACCESS = 'UserAccess';
export const SMARTAGENTSERVICE = 'SmartAgentService';
export const KAYAKLOOKUPSERVICE = 'KayakLookupService';
export const KAYAKWIDGET = 'KayakWidget';
export const SENDGRIDEMAILSERVICE = 'SendgridEmailService';
export const URLSHORTENERSERVICE = 'UrlShortener';
export const DIALOGSERVICEACCESSOWNTHREADS = 'DialogServiceAccessOwnThreads';
export const DIALOGSERVICEACCESSALLTHREADS = 'DialogServiceAccessAllThreads';