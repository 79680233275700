import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpGetRequest } from 'src/utils/httpClient';
import * as ACTIONS from 'src/actions/statisticsActions';

export const useLoadMessageTraffic = (fromDate) => {
    const dispatch = useDispatch();
    const { refreshMessageTraffic } = useSelector((state) => state.statistics);
    const [fetchedOnMount, setFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messagepersistence/statistics/message-traffic',
        params: {
            from: fromDate
        }
    }, false);

    const fetchMessageTraffic = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch({ type: ACTIONS.SET_MESSAGE_TRAFFIC_LOADING });
        return () => { dispatch({ type: ACTIONS.SET_MESSAGE_TRAFFIC_LOADING }); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchMessageTraffic();
            dispatch({ type: ACTIONS.SET_MESSAGE_TRAFFIC, data: (error ? [] : payload) });
        };

        if (!fetchedOnMount || refreshMessageTraffic) {
            setFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchMessageTraffic, fetchedOnMount, refreshMessageTraffic]);
};