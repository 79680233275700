import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&& > p': {
            margin: theme.spacing(2, 1)
        }
    },
    table: {
        '&& .MuiTableBody-root > .MuiTableRow-root .MuiTableCell-root:first-child': {
            fontWeight: 600
        }
    },
    nested: {
        '&& > .MuiTableCell-root': {
            padding: 0
        },
        '&& .MuiTableBody-root > .MuiTableRow-root .MuiTableCell-root:first-child': {
            paddingLeft: theme.spacing(6),
            fontWeight: 600
        },
        '&& .MuiTableBody-root > .MuiTableRow-root:last-child .MuiTableCell-root': {
            borderBottom: 0
        }
    }
}));

export default useStyles;