import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { useLocation, useRouteMatch } from 'react-router';

import OneWayInfoData from 'src/components/InfoPopup/OneWayInfo';
import TwoWayInfoData from 'src/components/InfoPopup/TwoWayInfo';
import TwoWayNewInfo from 'src/components/InfoPopup/TwoWayNewInfo';
import TwoWayEditInfo from 'src/components/InfoPopup/TwoWayEditInfo';
import MessagingAccountInfoData from 'src/components/InfoPopup/MessagingAccountInfo';
import TwoWayMessagingActionsInfo from 'src/components/InfoPopup/TwoWayMessagingActionsInfo';
import TwoWayMessagingSourcesInfo from 'src/components/InfoPopup/TwoWayMessagingSourcesInfo';
import GroupMessagingInfo from 'src/components/InfoPopup/GroupMessaging';
import GroupMessagingNew from 'src/components/InfoPopup/GroupMessagingNew';
import GroupMessagingInfoAdvanced from 'src/components/InfoPopup/GroupMessagingAdvanced';
import CodeServiceNewInfo from 'src/components/InfoPopup/CodeServiceNew';
import CodeServiceEditInfo from 'src/components/InfoPopup/CodeServiceEdit';
import CodeServiceInfo from 'src/components/InfoPopup/CodeService';
import CarrierBillingServiceInfo from 'src/components/InfoPopup/CarrierBilling';
import AdBanServiceBasicInfo from 'src/components/InfoPopup/AdBanServiceBasicSettings';
import AdBanServiceAdvancedInfo from 'src/components/InfoPopup/AdBanServiceAdvancedSettings';
import AdBanServiceInfo from 'src/components/InfoPopup/AdBanService';
import UserInfoContent from 'src/components/InfoPopup/UserAccess/UserInfoContent';
import AccessGroupInfoContent from 'src/components/InfoPopup/UserAccess/AccessGroupInfoContent';
import RestrictedResourcesInfoContent from 'src/components/InfoPopup/UserAccess/RestrictedResourcesInfoContent';
import SurveyServiceInfo from 'src/components/InfoPopup/SurveyService';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#254a8e'
    },
    dialog: {
        width: '90% !important'
    },
    root: {
        '& .MuiPaper-root': {
            width: '90% !important'
        }
    },
    intro: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    msgWrapper: {
        whiteSpace: 'pre-wrap'
    }
}));

const options = {
    elevation: 0,
    responsive: 'standard',
    print: false,
    search: false,
    sort: false,
    filter: false,
    download: false,
    viewColumns: false,
    pagination: false,
    selectableRowsHeader: false,
    selectableRows: 'none'
};

const getColumnDefinitions = (fields) => {
    const firstRow = fields?.[0] ?? { field: '', description: '' };
    const rowProperties = Object.keys(firstRow).map((prop) => prop);

    const _columns = [
        {
            name: rowProperties?.[0],
            label: `${rowProperties?.[0]?.charAt(0)?.toUpperCase() ?? rowProperties?.[0]}${rowProperties?.[0]?.slice(1) ?? ''}`,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography
                            component="span"
                            noWrap
                            style={{ fontWeight: 'bold' }}
                        >
                            {value}
                        </Typography>
                    );
                }
            }
        }
    ];

    for (let i = 1; i < rowProperties.length; i++) {
        _columns.push({
            name: rowProperties?.[i],
            label: `${rowProperties?.[i]?.charAt(0)?.toUpperCase() ?? rowProperties?.[i]}${rowProperties?.[i]?.slice(1) ?? ''}`
        });
    }

    return _columns;
};

const useInfoData = () => {
    const isOneWayInfo = useRouteMatch('/:customerId/services/onewaymessaging');
    const isTwoWayInfo = useRouteMatch('/:customerId/services/twowaymessaging/info/:serviceId/info');
    const isTwoWayNewInfo = useRouteMatch('/:customerId/services/twowaymessaging/new/serviceinfo');
    const isTwoWayEditInfo = useRouteMatch('/:customerId/services/twowaymessaging/edit/:serviceId/serviceinfo');
    const isMessagingAccountInfo = useRouteMatch('/:customerId/management/messagingaccounts');
    const isTwoWayMessagingActionsInfo = useRouteMatch('/:customerId/services/twowaymessaging/edit/:serviceId/actions');
    const isTwoWayMessagingSourcesInfo = useRouteMatch('/:customerId/services/twowaymessaging/edit/:serviceId/sourcesprices');
    const isGroupMessagingActionsInfo = useRouteMatch('/:customerId/services/groupmessaging/:serviceId/info');
    const isGroupMessagingNewBasicSettingsInfo = useRouteMatch('/:customerId/services/groupmessaging/new/basicsettings');
    const isGroupMessagingEditBasicSettingsInfo = useRouteMatch('/:customerId/services/groupmessaging/edit/:serviceId/basicsettings');
    const isGroupMessagingEditAdvancedSettingsInfo = useRouteMatch('/:customerId/services/groupmessaging/edit/:serviceId/advancedsettings');
    const isCodeServiceNewInfo = useRouteMatch('/:customerId/services/codeservice/new');
    const isCodeServiceEditInfo = useRouteMatch('/:customerId/services/codeservice/edit/:serviceId');
    const isCodeServiceInfo = useRouteMatch('/:customerId/services/codeservice/:serviceId/info');
    const isCarrieBillingServiceInfo = useRouteMatch('/:customerId/services/carrierbilling/:serviceId/info');
    const isAdBanServiceNewBSInfo = useRouteMatch('/:customerId/management/optoutservices/new/basicsettings');
    const isAdBanServiceEditBSInfo = useRouteMatch('/:customerId/management/optoutservices/edit/:serviceId/basicsettings');
    const isAdBanServiceEditASInfo = useRouteMatch('/:customerId/management/optoutservices/edit/:serviceId/advancedsettings');
    const isAdBanServiceInfo = useRouteMatch('/:customerId/management/optoutservices/:serviceId/info');
    const isUserAccessUserInfo = useRouteMatch('/:customerId/management/useraccess/users');
    const isUserAccessAccessGroupInfo = useRouteMatch('/:customerId/management/useraccess/accessgroups');
    const isUserAccessRestrictedResourcesInfo = useRouteMatch('/:customerId/management/useraccess/restrictedresources');
    const isSurveyServiceInfo = useRouteMatch('/:customerId/services/surveyservice/:serviceId/info');

    if (isOneWayInfo) {
        return { header: 'OneWay Service Info', fields: OneWayInfoData, columns: getColumnDefinitions(OneWayInfoData) };
    }

    if (isTwoWayInfo && isTwoWayInfo.isExact) {
        return { header: 'TwoWay Service Info', fields: TwoWayInfoData, columns: getColumnDefinitions(TwoWayInfoData) };
    }

    if (isTwoWayNewInfo) {
        return { header: 'TwoWay Service Info', fields: TwoWayNewInfo, columns: getColumnDefinitions(TwoWayNewInfo) };
    }

    if (isTwoWayEditInfo) {
        return { header: 'TwoWay Service Info', fields: TwoWayEditInfo, columns: getColumnDefinitions(TwoWayEditInfo) };
    }

    if (isMessagingAccountInfo) {
        return { header: 'Messaging Account Instructions', fields: MessagingAccountInfoData, columns: getColumnDefinitions(MessagingAccountInfoData) };
    }

    if (isTwoWayMessagingActionsInfo) {
        return { header: 'Action Placeholders', fields: TwoWayMessagingActionsInfo, columns: getColumnDefinitions(TwoWayMessagingActionsInfo) };
    }

    if (isTwoWayMessagingSourcesInfo) {
        return { header: 'Sources & Prices Info', fields: TwoWayMessagingSourcesInfo, columns: getColumnDefinitions(TwoWayMessagingSourcesInfo) };
    }

    if (isGroupMessagingActionsInfo) {
        return { header: 'Group Messaging Info', fields: GroupMessagingInfo, columns: getColumnDefinitions(GroupMessagingInfo) };
    }

    if (isGroupMessagingNewBasicSettingsInfo) {
        return { header: 'Group Messaging Info', fields: GroupMessagingNew, columns: getColumnDefinitions(GroupMessagingNew) };
    }

    if (isGroupMessagingEditBasicSettingsInfo) {
        return { header: 'Group Messaging Info', fields: GroupMessagingNew, columns: getColumnDefinitions(GroupMessagingNew) };
    }

    if (isGroupMessagingEditAdvancedSettingsInfo) {
        return { header: 'Group Messaging Info', fields: GroupMessagingInfoAdvanced, columns: getColumnDefinitions(GroupMessagingInfoAdvanced) };
    }

    if (isCodeServiceNewInfo) {
        return { header: 'Code Service Info', fields: CodeServiceNewInfo, columns: getColumnDefinitions(CodeServiceNewInfo) };
    }

    if (isCodeServiceEditInfo) {
        return { header: 'Code Service Info', fields: CodeServiceEditInfo, columns: getColumnDefinitions(CodeServiceEditInfo) };
    }

    if (isCodeServiceInfo) {
        return { header: 'Code Service Info', fields: CodeServiceInfo, columns: getColumnDefinitions(CodeServiceInfo) };
    }

    if (isCarrieBillingServiceInfo) {
        return { header: 'Carrier Billing Service Info', fields: CarrierBillingServiceInfo, columns: getColumnDefinitions(CarrierBillingServiceInfo) };
    }

    if (isAdBanServiceNewBSInfo || isAdBanServiceEditBSInfo) {
        return { header: 'Opt-out Service Info', fields: AdBanServiceBasicInfo, columns: getColumnDefinitions(AdBanServiceBasicInfo) };
    }

    if (isAdBanServiceEditASInfo) {
        return { header: 'Opt-out Service Info', fields: AdBanServiceAdvancedInfo, columns: getColumnDefinitions(AdBanServiceAdvancedInfo) };
    }

    if (isAdBanServiceInfo) {
        return { header: 'Opt-out Service Info', fields: AdBanServiceInfo, columns: getColumnDefinitions(AdBanServiceInfo) };
    }

    if (isUserAccessUserInfo) {
        return { header: 'User Info', Content: UserInfoContent };
    }

    if (isUserAccessAccessGroupInfo) {
        return { header: 'Access Group Info', Content: AccessGroupInfoContent };
    }

    if (isUserAccessRestrictedResourcesInfo) {
        return { header: 'Restricted Resources Info', Content: RestrictedResourcesInfoContent };
    }

    if (isSurveyServiceInfo) {
        return { header: 'Survey Service Info', fields: SurveyServiceInfo, columns: getColumnDefinitions(SurveyServiceInfo) };
    }

    return null;
};

const InfoPopup = ({ onClose, open }) => {
    const infoData = useInfoData();
    const classes = useStyles();

    if (infoData === null) {
        return null;
    }

    const { header, fields, columns, Content } = infoData;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="md"
            className={classes.root}
        >
            <DialogTitle disableTypography className={classes.header}>
                <Typography variant="h4">
                    {header}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {Content && <Content />}
                {!Content && (
                    <MuiThemeProvider theme={(theme) => ({
                        ...theme,
                        overrides: {
                            ...theme.overrides,
                            MuiTableHead: {
                                root: {
                                    color: '#000 !important'
                                }
                            },
                            MUIDataTableHeadCell: {
                                root: {
                                    backgroundColor: '#fff',
                                    borderBottom: '2px solid #254a8e',
                                    fontWeight: 'bold'
                                }
                            },
                            MUIDataTableBodyCell: {
                                root: {
                                    whiteSpace: 'pre-wrap'
                                }
                            }
                        }
                    })}
                    >
                        <MUIDataTable
                            data={fields}
                            columns={columns || []}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
            </DialogContent>
        </Dialog>
    );
};

InfoPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

const InfoPopupContext = React.createContext();

const InfoPopupProvider = ({ children }) => {
    const location = useLocation();
    const [infoPopupOpen, setInfoPopupOpen] = React.useState(false);
    const showInfo = React.useCallback(() => setInfoPopupOpen(true), []);
    const handleClose = React.useCallback(() => setInfoPopupOpen(false), []);

    React.useEffect(() => { setInfoPopupOpen(false); }, [location.pathname]);

    return (
        <InfoPopupContext.Provider
            value={{
                showInfo
            }}
        >
            <>
                <InfoPopup open={infoPopupOpen} onClose={handleClose} />
                {children}
            </>
        </InfoPopupContext.Provider>
    );
};

InfoPopupProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const useInfoPopup = () => {
    const context = React.useContext(InfoPopupContext);

    if (context === undefined) {
        throw new Error('useInfoPopup must be used within a InfoPopupProvider');
    }
    return context;
};

export { useInfoPopup, InfoPopupProvider };