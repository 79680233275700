import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavigateTo } from 'src/actions';

const RouterProvider = () => {
    const { navigateUrl } = useSelector((state) => state.router);
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        if (navigateUrl) {
            dispatch(NavigateTo(null));
            history.push(navigateUrl);
        }
    }, [history, navigateUrl]);

    return null;
};

export default RouterProvider;