import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const OutlinedContainer = ({ className, children, label, focused }) => {
    return (
        <TextField
            className={className}
            variant="outlined"
            fullWidth
            label={label}
            multiline
            focused={Boolean(focused)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: InputComponent
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ children }}
        />
    );
};

OutlinedContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    focused: PropTypes.bool
};

export default OutlinedContainer;