import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Card, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import MouseIcon from '@material-ui/icons/MouseRounded';
import ResetIcon from '@material-ui/icons/RotateLeftRounded';
import PropTypes from 'prop-types';
import GoogleMap from 'src/components/Code/GoogleMap';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { REFRESH_AMOUNT, INTERVAL_MILLISECONDS } from 'src/widgets/widgetUtils';
import useInterval from 'src/utils/useInterval';

const getStorageKey = (serviceId) => `SmartDialog_${window.location.host}_${serviceId}_CodesGoogleMapsSettings`;

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        flex: 1
    },
    cardGrid: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    typeGrid: {
        width: '100%',
        flexBasis: '50px',
        backgroundColor: theme.palette.background.gray,
        color: theme.palette.white,
        '& *': {
            color: theme.palette.white
        }
    },
    gridItem: {
        margin: theme.spacing(0),
        padding: theme.spacing(2, 2),
        zIndex: 10,
        backgroundColor: theme.palette.background.gray
    },
    mapContainer: {
        position: 'absolute',
        top: theme.spacing(6),
        height: `calc(100% - ${theme.spacing(6)}px)`,
        width: '100%'
    },
    buttonContainer: {
        position: 'absolute',
        top: '0',
        right: '0'
    },
    button: {
        color: '#fff'
    }
}));

const defaultProps = {
    center: {
        lat: 62.80599712285822,
        lng: 25.045265800687517
    },
    zoom: 6
};

const MapWidget = ({ serviceId, serviceName }) => {
    const classes = useStyles();
    const [codes, setCodes] = React.useState([]);
    const [allowZoomOnScroll, setAllowZoomOnScroll] = React.useState(false);
    const [refreshWidget, setRefreshWidget] = React.useState(false);
    const [refreshes, setRefreshes] = React.useState(0);

    useInterval(
        async () => {
            setRefreshWidget(true);
            setRefreshes((retries) => retries + 1);
        },
        (
            refreshes < REFRESH_AMOUNT ? INTERVAL_MILLISECONDS : 0
        )
    );

    const { payload, loading: codesLoading, query: requestCodes } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/code/codes?service-id=${serviceId}`
    });

    React.useEffect(() => {
        if (payload) {
            setCodes(Array.isArray(payload) ? payload : []);
        }
    }, [payload]);

    React.useEffect(() => {
        if (refreshWidget) {
            requestCodes();
            setRefreshWidget(false);
        }
    }, [refreshWidget]);

    const storeSettingsToLocalStorage = () => {
        const zoomLevel = defaultProps.zoom;
        const latitude = defaultProps.center.lat;
        const longitude = defaultProps.center.lng;

        localStorage.setItem(getStorageKey(serviceId), JSON.stringify({ zoomLevel, latitude, longitude }));
    };

    if (codesLoading) {
        return <LinearProgress />;
    }

    const handleResetZoomOnClick = () => {
        storeSettingsToLocalStorage();
        setRefreshWidget(true);
    };

    const handleZoomOnScrollClick = () => {
        if (allowZoomOnScroll) {
            setAllowZoomOnScroll(false);
        } else {
            setAllowZoomOnScroll(true);
        }
    };

    return (
        <Suspense fallback={<LinearProgress />}>
            <Card className={classes.card}>
                <div className={classes.cardGrid}>
                    <div className={classes.typeGrid}>
                        <Grid container item xs={12} alignItems="center">
                            <Grid container item xs={12} justifyContent="flex-start" className={classes.gridItem}>
                                <Typography variant="h5">{`Redeemed codes: ${serviceName}`}</Typography>
                                <div className={classes.buttonContainer}>
                                    <IconButton aria-label="reset" onClick={handleResetZoomOnClick} className={classes.button}>
                                        <Tooltip title="Reset center and zoom">
                                            <ResetIcon />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton aria-label="allow-scroll" onClick={handleZoomOnScrollClick} className={classes.button}>
                                        <Tooltip title={allowZoomOnScroll ? 'Disable zoom on scroll' : 'Allow zoom on scroll'}>
                                            <MouseIcon />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div className={classes.mapContainer}>
                                    <GoogleMap codes={codes} allowZoomOnScroll={allowZoomOnScroll} serviceId={serviceId} defaultProps={defaultProps} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Card>
        </Suspense>
    );
};

MapWidget.propTypes = {
    serviceId: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired
};

export default MapWidget;