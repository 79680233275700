import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OpenNotificationSettings } from 'src/actions';
import { useSnackbar } from 'src/components/Snackbar';

const useExportSnack = () => {
    const { info } = useSnackbar();
    const dispatch = useDispatch();
    const { sendNotification, sendSms, sendEmail, sendEvent } = useSelector((state) => state.notifications);

    const showLinkToNotificationSettings = React.useMemo(() => {
        if (!sendNotification) {
            return true;
        }

        return !sendSms && !sendEmail && !sendEvent;
    }, [sendNotification, sendSms, sendEmail, sendEvent]);

    const openNotificationSettingsClick = React.useCallback((event) => {
        event.preventDefault();
        dispatch(OpenNotificationSettings());
    }, [dispatch]);

    return React.useCallback((notificationText) => {
        const _notificationText = typeof notificationText === 'string' ? notificationText : 'Message export process started. You will receive a notification when it is complete.';
        if (showLinkToNotificationSettings) {
            info(
                <div>
                    {_notificationText}
                    <br />
                    <a href="" onClick={openNotificationSettingsClick}><b>Click here to enable notifications in user settings.</b></a>
                </div>,
                { persist: true }
            );
        } else {
            info(_notificationText);
        }
    }, [info, openNotificationSettingsClick, showLinkToNotificationSettings]);
};

export default useExportSnack;