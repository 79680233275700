import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import useStyles from './useStyles';

const UserInfoContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="body1">List of company users which have access to the company's services in SmartDialog. New users can be created only by Arena Interactive Customer support. Username and password for login are allowed only for user’s personal access and they are not allowed to be shared for others.</Typography>
            <Table className={classes.table}>
                <colgroup>
                    <col width="30%" />
                    <col width="70%" />
                </colgroup>
                <TableBody>
                    <TableRow>
                        <TableCell>Displayname:</TableCell>
                        <TableCell>Name of the user (shown in the user profile)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Role:</TableCell>
                        <TableCell>"User / Admin", the main access level for the user in this company (shown in the user profile)</TableCell>
                    </TableRow>
                    <TableRow className={classes.nested}>
                        <TableCell colSpan={2}>
                            <Table>
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>User:</TableCell>
                                        <TableCell>Can see all own services and the message templates (Templates) and opt-outs (Opt-Out Numbers) in Management section.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Admin:</TableCell>
                                        <TableCell>Can see and manage all services. Admin can also see the Management and the Message Log sections.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Mobile phone:</TableCell>
                        <TableCell>Phone number of the user (shown in the user profile).</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email:</TableCell>
                        <TableCell>Email address of the user (shown in the user profile, used when the user is signing in to SmartDialog).</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Access Groups:</TableCell>
                        <TableCell>Name of the access group where the user belongs to. If there are more than one access group assigned to this user, then the amount of user access groups is shown here. With mouse over, there will be access group names shown as a list.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="body1">
                <b>User Info:</b>
                &nbsp;
                By selecting one User from the list, then the User Info page opens. There you can see more specific information from the selected user. You can also see information about access groups that this user belongs to and you can assign new access groups to the user.
            </Typography>
            <Typography variant="body1">
                <b>NOTE:</b>
                &nbsp;
                If a new user is created for the company, then the user will be shown in the Users list, but the user is not yet assigned to any user access groups. So, when a new SmartDialog user is created, it should be checked if there are any access groups where this new user should be added to.
            </Typography>
        </div>
    );
};

export default UserInfoContent;