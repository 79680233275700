export const SET_SURVEYSERVICES = 'SET_SURVEYSERVICES';
export const SET_SURVEYSERVICES_LOADING = 'SET_SURVEYSERVICES_LOADING';
export const REFERSH_SURVEYSERVICES = 'REFERSH_SURVEYSERVICES';

export const SET_SELECTEDSURVEYSERVICE = 'SET_SELECTEDSURVEYSERVICE';
export const SET_SELECTEDSURVEYSERVICELOADING = 'SET_SELECTEDSURVEYSERVICELOADING';
export const REFRESH_SELECTEDSURVEYSERVICE = 'REFRESH_SELECTEDSURVEYSERVICE';

export const setSurveyServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_SURVEYSERVICES_LOADING,
    data
});

export const setSurveyServices = (data) => (dispatch) => dispatch({
    type: SET_SURVEYSERVICES,
    data
});

export const refreshSurveyServices = () => (dispatch) => dispatch({
    type: REFERSH_SURVEYSERVICES
});

export const setSelectedSurveyService = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDSURVEYSERVICE,
    data
});

export const setSelectedSurveyServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDSURVEYSERVICELOADING,
    data
});

export const refreshSelectedSurveyService = () => (dispatch) => dispatch({
    type: REFRESH_SELECTEDSURVEYSERVICE
});