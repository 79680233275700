import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, IconButton, LinearProgress, Input, Tooltip, Link, colors } from '@material-ui/core';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpRequest } from 'src/utils/httpClient';
import InputAdornment from '@material-ui/core/InputAdornment';
import QrCodePopup from 'src/components/UrlShortener/QrCodePopup';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import QRCode from 'qrcode.react';
import ClearIcon from '@material-ui/icons/Clear';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        flex: 1,
        backgroundColor: '#254a8e'
    },
    logoImg: {
        maxWidth: '50%'
    },
    mainContainer: {
        marginTop: theme.spacing(3)
    },
    subSelect: {
        width: '100%',
        padding: theme.spacing(1),
        border: 0,
        color: '#333',
        backgroundColor: '#fff',
        borderRadius: '10px'
    },
    button: {
        width: '80%',
        color: '#fff',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        fontSize: '1.4em',
        fontWeight: 'bold',
        borderRadius: '10px',
        float: 'right'
    },
    progrssbar: {
        bottom: '5%',
        position: 'absolute',
        left: '5%',
        width: '90%'
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    },
    shortLinkContainer: {
        marginTop: '0',
        display: 'block',
    },
    shortLinkGrey: {
        color: '#999',
        fontSize: '1.6em',
        fontWeight: 'bold',
        paddingTop: '6px',
        display: 'inline-block'
    },
    shortLink: {
        color: '#fff',
        fontSize: '1.6em',
        fontWeight: 'bold'
    },
    copyButton: {
        color: '#fff',
        marginTop: '-10px',
        display: 'inline-block'
    },
    qr: {
        float: 'right'
    },
    largerQrImg: {
        maxWidth: '70%',
        marginLeft: theme.spacing(2)
    }
}));

function UrlShortenerWidget({ isPopup, handleSetPostfixValue }) {
    const classes = useStyles();
    const { error: errorSnack } = useSnackbar();
    const [qrPopupOpen, setQrPopupOpen] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [shortLink, setShortLink] = React.useState(null);
    const { handleCopy } = useCopyToClipboard();
    const { claims } = useSelector((state) => state.auth);

    const onChange = (e) => {
        setUrl(e.target.value);
    };

    const handleQrClose = () => {
        setQrPopupOpen(false);
    };

    const { mutate: _createLinkRequest, loading: createLinkLoading } = useHttpRequest((body) => ({
        method: 'POST',
        endpoint: '/urlshortener',
        body
    }));

    const createLinkRequest = React.useCallback(_createLinkRequest, []);

    const handleShortenClick = async () => {
        if (url === '') {
            errorSnack('Enter a url to shorten.');
        } else if (!url.startsWith('http') && !url.startsWith('www')) {
            errorSnack('Make sure it starts with http(s)://');
        } else {
            let tmpUrl = url;
            if (url?.startsWith('www')) {
                tmpUrl = `http://${url}`;
                setUrl(`http://${url}`);
            }

            const body = {
                url: tmpUrl,
                expireDate: moment(new Date(2078, 0, 1, 12, 0, 0)).format(),
                userName: claims?.name ?? ''
            };

            const { payload, error, errorMessages } = await createLinkRequest(body);

            if (!error) {
                setShortLink({ fpePostFix: payload?.fpePostFix ?? '' });
                if (isPopup) {
                    handleSetPostfixValue(payload?.fpePostFix ?? '');
                }
            } else {
                errorSnack(errorMessages);
            }
        }
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleEmptyClick = () => {
        setUrl('');
        setShortLink(null);
    };

    const endAdornment = () => {
        if (url) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleEmptyClick}
                        onMouseDown={handleMouseDown}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
        return '';
    };

    const handleCopyClick = () => {
        handleCopy(`https://siirry.fi/${shortLink?.fpePostFix ?? ''}`);
    };

    return (
        <Card className={classes.card} dir="ltr">
            <CardContent>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <img
                            alt="Logo"
                            src="/images/logos/urlshortener_logo.png"
                            className={classes.logoImg}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} container direction="row" className={classes.mainContainer}>
                        <Grid item xs={8} lg={8} className={classes.flexContainer}>
                            <Input
                                name="url"
                                onChange={onChange}
                                value={url ?? ''}
                                className={classes.subSelect}
                                placeholder="Link to shorten"
                                endAdornment={endAdornment()}
                                disableUnderline
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <Button variant="contained" className={classes.button} onClick={handleShortenClick}>
                                SHORTEN
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12} container direction="row" className={classes.mainContainer}>
                        <Grid item xs={8} lg={8} className={classes.flexContainer}>
                            <div className={classes.shortLinkContainer}>
                                {shortLink?.fpePostFix ? (
                                    <Link href={`https://siirry.fi/${shortLink?.fpePostFix ?? ''}`} target="_blank" className={classes.shortLink}>{`https://siirry.fi/${shortLink?.fpePostFix ?? ''}`}</Link>
                                ) : (
                                    <span className={classes.shortLinkGrey}>https://siirry.fi/v2/2C0OL</span>
                                )}
                                {shortLink?.fpePostFix ? (
                                    <>
                                        <Tooltip title="Copy short link to clipboard">
                                            <IconButton
                                                onClick={handleCopyClick}
                                                edge="end"
                                                className={classes.copyButton}
                                            >
                                                <CopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <QRCode value={`https://qr.siirry.fi/${shortLink?.fpePostFix ?? ''}`} size={64} includeMargin className={classes.qr} onClick={() => setQrPopupOpen(true)} />
                                    </>
                                ) : (
                                    <QRCode value="https://qr.siirry.fi/v2/2C0OL" size={64} includeMargin className={classes.qr} />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            {shortLink?.fpePostFix && (
                                <img
                                    alt="Click for lager qr code"
                                    src="/images/larger-qr.png"
                                    className={classes.largerQrImg}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <QrCodePopup open={qrPopupOpen} setOpen={handleQrClose} postfix={shortLink?.fpePostFix ?? ''} />
                {(createLinkLoading) && <LinearProgress className={classes.progrssbar} />}
            </CardContent>
        </Card>
    );
}

UrlShortenerWidget.propTypes = {
    isPopup: PropTypes.bool,
    handleSetPostfixValue: PropTypes.func
};

export default UrlShortenerWidget;