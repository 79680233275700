import { fetchMock } from 'src/utils/mock';

fetchMock.get(`begin:${process.env.REACT_APP_HOST}/widgets`, [
    {
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        component: 'MessageStatisticsDoughnutWidget',
        settings: {
            url: ''
        },
        layout: {
            lg: { x: 0, y: 0, w: 2, h: 2 },
            xs: { x: 0, y: 0, w: 2, h: 2 },
            xxs: { x: 0, y: 0, w: 2, h: 2 }
        }
    },
    {
        id: '277d671c-2062-4544-b07e-fbf0a18ff64e',
        component: 'MessageStatisticsDoughnutWidget',
        settings: {},
        layout: {
            lg: { x: 0, y: 2, w: 2, h: 2 },
            xs: { x: 0, y: 2, w: 2, h: 2 },
            xxs: { x: 0, y: 2, w: 2, h: 2 }
        }
    }
]);