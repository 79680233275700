import { Fade, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    loadingOverlayRoot: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        position: 'relative',
        flexDirection: 'column'
    },
    linearProgressContainer: {
        flex: 0
    },
    loadingOverlayLinearProgress: {
        backgroundColor: theme.palette.background.gray
    },
    loadingContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column'
    },
    loadingBackdrop: {
        flex: 1,
        opacity: 0.2,
        backgroundColor: theme.palette.background.gray,
    }
}));

const LoadingOverlay = ({ children, loading }) => {
    const classes = useStyles();

    return (
        <div className={classes.loadingOverlayRoot}>
            <Fade
                in={loading}
                style={{
                    transitionDelay: '1000ms',
                }}
                unmountOnExit
            >
                <div className={classes.loadingContainer}>
                    <div className={classes.linearProgressContainer}>
                        <LinearProgress classes={{ barColorPrimary: classes.loadingOverlayLinearProgress }} />
                    </div>
                    <div className={classes.loadingBackdrop} />
                </div>
            </Fade>
            {children}
        </div>
    );
};

LoadingOverlay.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool
};

export default LoadingOverlay;