import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, DialogActions, DialogContent, Fade, FormHelperText, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/styles';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { useDispatch } from 'react-redux';
import { SETTINGS_LOADED } from 'src/actions/notificationActions';

const useStyles = makeStyles((theme) => ({
    desktopNotificationsDeniedText: {
        color: theme.palette.error.main
    }
}));

const NotificationSettings = ({ setOpen }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { error: errorSnack, success: successSnack } = useSnackbar();
    const [refreshSettings, setRefreshSettings] = React.useState(true);
    const [isEdit, setIsEdit] = React.useState(false);
    const [hasChanges, setHasChanges] = React.useState(false);
    const [settings, setSettings] = React.useState({
        sendNotification: false,
        sendSms: false,
        sendEmail: false,
        sendEvent: false
    });
    const [desktopNotificationStatus, setDesktopNotificationStatus] = React.useState(null);

    const { mutate: fetchSettings, loading: loadingSettings } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/notifications/settings'
    }));

    const { mutate: saveSettings, loading: saveSettingsLoading } = useHttpRequest(({ isEdit, sendNotification, sendSms, sendEmail, sendEvent }) => ({
        method: isEdit ? 'PUT' : 'POST',
        endpoint: '/notifications/settings',
        body: {
            sendNotification, sendSms, sendEmail, sendEvent
        }
    }));

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload, error } = await fetchSettings();
            if (!error) {
                setIsEdit(Boolean(payload?.userId));
                dispatch({ type: SETTINGS_LOADED, data: payload });
                setSettings({
                    sendNotification: Boolean(payload?.sendNotification),
                    sendSms: Boolean(payload?.sendSms),
                    sendEmail: Boolean(payload?.sendEmail),
                    sendEvent: Boolean(payload?.sendEvent),
                });
            }
        };
        if (refreshSettings) {
            makeRequest();
        }
    }, [fetchSettings, refreshSettings]);

    React.useEffect(() => {
        const requestPermissions = async () => {
            const resp = await Notification.requestPermission();
            setDesktopNotificationStatus(resp);
        };
        // "SmartDialog Web Notification" enabled
        if (settings.sendEvent && 'Notification' in window) {
            requestPermissions();
        }
    }, [settings.sendEvent]);

    const handleOnChange = (e) => {
        setHasChanges(true);
        setSettings({
            ...settings,
            [e.target.name]: e.target.checked
        });
    };

    const handleSaveClick = async () => {
        const { error, errorMessages } = await saveSettings({ isEdit, ...settings });
        if (!error) {
            dispatch({ type: SETTINGS_LOADED, data: settings });
            successSnack('Notification settings saved.');
            setHasChanges(false);
        } else {
            errorSnack(errorMessages);
        }
    };

    const handleCancelClick = () => {
        setRefreshSettings(!refreshSettings);
        setOpen(false);
    };

    return (
        <>
            {loadingSettings && <DelayedLinearProgress />}
            <Collapse in={!loadingSettings}>
                <DialogContent className={classes.dialogContent}>
                    <TableContainer style={{ flexGrow: 1 }}>
                        <Table xs={6} sm={3} className={classes.table}>
                            <colgroup>
                                <col width="80%" />
                                <col width="20%" />
                            </colgroup>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography variant="h5">Enable notifications:</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Switch color="primary" checked={settings.sendNotification} name="sendNotification" onChange={handleOnChange} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Collapse in={settings.sendNotification}>
                        <TableContainer style={{ flexGrow: 1 }}>
                            <Table xs={6} sm={3} className={classes.table}>
                                <colgroup>
                                    <col width="80%" />
                                    <col width="20%" />
                                </colgroup>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography variant="h5">SmartDialog Web Notification:</Typography>
                                            {desktopNotificationStatus === 'denied' && <FormHelperText className={classes.desktopNotificationsDeniedText}>Desktop notifications have been denied and need to be manually enabled. You will still get notifications inside SmartDialog.</FormHelperText>}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Switch color="primary" checked={settings.sendEvent} name="sendEvent" onChange={handleOnChange} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography variant="h5">Send Email Notification:</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Switch color="primary" checked={settings.sendEmail} name="sendEmail" onChange={handleOnChange} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <Typography variant="h5">Send SMS Notification:</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Switch color="primary" checked={settings.sendSms} name="sendSms" onChange={handleOnChange} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </DialogContent>
                <DialogActions>
                    <IconButton aria-label="cancel" onClick={handleCancelClick}>
                        <Tooltip title="Cancel">
                            <CancelIcon />
                        </Tooltip>
                    </IconButton>
                    <Fade in={hasChanges} unmountOnExit>
                        <IconButton
                            aria-label="Save"
                            disabled={saveSettingsLoading}
                            onClick={handleSaveClick}
                        >
                            <Tooltip title="Save">
                                <SaveIcon />
                            </Tooltip>
                        </IconButton>
                    </Fade>
                </DialogActions>
            </Collapse>
        </>
    );
};

NotificationSettings.propTypes = {
    setOpen: PropTypes.func.isRequired
};

export default NotificationSettings;