import thunkMiddleware from 'redux-thunk';
import { saveToLocalStorage, loadFromLocalStorage } from './localStorageSyncMiddleware';
import dialogMiddlewares from './dialogMiddleware';
import signalRMiddleware from './signalRMiddleware';

const middlewares = [
    thunkMiddleware,
    ...dialogMiddlewares,
    signalRMiddleware,
    saveToLocalStorage,
    loadFromLocalStorage
];

export default middlewares;