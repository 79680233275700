export const SET_MESSAGE_TEMPLATES = 'SET_MESSAGE_TEMPLATES';
export const SET_MESSAGE_TEMPLATES_LOADING = 'SET_MESSAGE_TEMPLATES_LOADING';

export const REFRESH_TEMPLATES = 'REFRESH_TEMPLATES';

export const setMessageTemplatesLoading = () => (dispatch) => dispatch({
    type: SET_MESSAGE_TEMPLATES_LOADING,
    data: true
});

export const setMessageTemplates = (data) => (dispatch) => dispatch({
    type: SET_MESSAGE_TEMPLATES,
    data
});

export const refreshMessageTemplates = () => (dispatch) => dispatch({
    type: REFRESH_TEMPLATES
});
