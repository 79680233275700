import { LinearProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import uuid from 'uuid';

const useStyles = makeStyles(() => ({
    root: ({ visible }) => ({
        ...visible ? { visibility: 'visible' } : { visibility: 'hidden' }
    }),
    progressBar: ({ absolute }) => ({
        ...absolute ? { position: 'absolute', width: '100%', zIndex: 9999 } : {}
    })
}));

const DelayedLinearProgress = ({ absolute, timeoutMs = 3000, loading = true }) => {
    const [visible, setVisible] = React.useState(false);
    const [key, setKey] = React.useState(uuid());
    const timeoutRef = React.useRef();
    const classes = useStyles({ absolute, visible });

    React.useEffect(() => {
        clearTimeout(timeoutRef.current);
        setVisible(false);

        if (loading) {
            timeoutRef.current = setTimeout(() => {
                setVisible(true);
                setKey(uuid());
            }, timeoutMs);
        }
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [loading]);

    return (
        <div className={classes.root}>
            <LinearProgress key={key} className={classes.progressBar} />
        </div>
    );
};

DelayedLinearProgress.propTypes = {
    absolute: PropTypes.bool,
    loading: PropTypes.bool,
    timeoutMs: PropTypes.number
};

export default DelayedLinearProgress;