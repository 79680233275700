import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Tooltip, Grid, colors } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import CancelIcon from '@material-ui/icons/CloseRounded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.7em',
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        '&:first-of-type': {
            marginTop: theme.spacing(2)
        }
    },
    datarow: {
        marginTop: theme.spacing(1),
        fontSize: '1.2em'
    },
    container: {
        padding: theme.spacing(4),
        paddingBottom: '0'
    },
    contactInfoContainer: {
        position: 'sticky',
        top: theme.spacing(6)
    },
    button: {
        marginTop: theme.spacing(3),
        minWidth: '50%',
        color: '#fff',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        },
        fontSize: '1.4em',
        fontWeight: 'bold',
        borderRadius: '10px'
    },
    bold: {
        fontWeight: 'bold'
    },
    tableContainer: {
        paddingBottom: '5px',
        borderBottom: '1px solid #ededed !important'
    },
    table: {
        '& tr': {
            '& td': {
                padding: '4px',
                border: '0 !important'
            }
        }
    }
}));

const SearchResults = ({ open, setOpen, customerData, subscriptionData }) => {
    const classes = useStyles();

    const handleCancelClick = () => {
        setOpen(false);
    };

    const dateFormatter = (rowData) => {
        const date = moment.utc(rowData).local();
        return date.isValid() ? date.format('D.M.YYYY') : '';
    };

    const customerColumns = [
        {
            name: 'customerNumber',
            label: 'Customer number',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'firstname',
            label: 'Firstname',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'lastname',
            label: 'Lastname',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'zipcode',
            label: 'Zip code',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'postname',
            label: 'City',
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    const subscriptionColumns = [
        {
            name: 'paperCode',
            label: 'Papercode',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'paperName',
            label: 'Paper name',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'subsNo',
            label: 'Subscription number',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'subsCusName',
            label: 'Customer name',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'subsCusNo',
            label: 'Customer number',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'subscriptionType',
            label: 'Subscription status',
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    const subscriptionOptions = {
        elevation: 0,
        print: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        responsive: 'standard',
        filter: false,
        search: false,
        download: false,
        viewColumns: false,
        fixedHeader: true,
        textLabels: {
            body: {
                noMatch: 'Sorry, no subscriptions found',
            }
        },
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length + 1}>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="details table" className={classes.table}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Start date: </span>
                                            {dateFormatter(subscriptionData?.[rowMeta.dataIndex]?.subsStartDate)}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>End date: </span>
                                            {dateFormatter(subscriptionData?.[rowMeta.dataIndex]?.subsEndDate)}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Sales date: </span>
                                            {dateFormatter(subscriptionData?.[rowMeta.dataIndex]?.salesDate)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Payers' Customer Number: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.payCusNo}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Payers' Customer Name: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.payCusName}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Sales Number: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.salesNo}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Paper: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.paper}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Subsstate: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.subsState}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Subskind: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.subsKind}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Package Id: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.packageId}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Package name: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.packageName}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Package: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.package}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Product name: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.productName}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Invoice mode: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.invMode}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Receive type: </span>
                                            {subscriptionData?.[rowMeta.dataIndex]?.receiveType}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
            );
        }
    };

    const customerOptions = {
        elevation: 0,
        print: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        responsive: 'standard',
        filter: false,
        search: false,
        download: false,
        viewColumns: false,
        fixedHeader: true,
        textLabels: {
            body: {
                noMatch: 'Sorry, no customers found',
            }
        },
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <TableRow>
                    <TableCell colSpan={rowData.length + 1}>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="details table" className={classes.table}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Home phone: </span>
                                            {customerData?.[rowMeta.dataIndex]?.h_phone}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Work phone: </span>
                                            {customerData?.[rowMeta.dataIndex]?.w_phone}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Other phone: </span>
                                            {customerData?.[rowMeta.dataIndex]?.o_phone}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">
                                            <span className={classes.bold}>E-mail: </span>
                                            {customerData?.[rowMeta.dataIndex]?.emailaddress}
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className={classes.bold}>Address: </span>
                                            {`${customerData?.[rowMeta.dataIndex]?.streetname ?? ''} ${customerData?.[rowMeta.dataIndex]?.houseno ?? ''} ${customerData?.[rowMeta.dataIndex]?.staircase ?? ''} ${customerData?.[rowMeta.dataIndex]?.apartment ?? ''} ${customerData?.[rowMeta.dataIndex]?.zipcode ?? ''} ${customerData?.[rowMeta.dataIndex]?.postname ?? ''} ${customerData?.[rowMeta.dataIndex]?.countrycode ?? ''}`}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                </TableRow>
            );
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth aria-labelledby="kayak-lookup-results-title">
            <DialogTitle id="kayak-lookup-title">
                Lookup results
            </DialogTitle>
            <DialogContent style={{ padding: '0' }}>
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.container}>
                        <MuiThemeProvider theme={(theme) => ({
                            ...theme,
                            overrides: {
                                ...theme.overrides,
                                MuiTableHead: {
                                    root: {
                                        backgroundColor: '#fff !important',
                                    }
                                },
                                MuiButton: {
                                    root: {
                                        fontWeight: 'bold !important',
                                    }
                                },
                                MuiTableCell: {
                                    root: {
                                        backgroundColor: 'transparent !important',
                                        margin: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`
                                    }
                                }
                            }
                        })}
                        >
                            <h2>Customers</h2>
                            <MUIDataTable data={customerData ?? []} columns={customerColumns} options={customerOptions} />
                            <h2>Subscriptions</h2>
                            <MUIDataTable data={subscriptionData ?? []} columns={subscriptionColumns} options={subscriptionOptions} />
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="cancel" onClick={handleCancelClick}>
                    <Tooltip title="Cancel">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

SearchResults.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    customerData: PropTypes.array.isRequired,
    subscriptionData: PropTypes.array.isRequired
};

export default SearchResults;