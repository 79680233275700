const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Description',
        description: 'A description of the service.'
    },
    {
        field: 'Type',
        description: 'Choose between RKioski (Ärrä-koodi) and Generic codes'
    },
    {
        field: 'Out of codes message',
        description: 'This message will be sent to the end-user when there is no more unused codes.'
    },
    {
        field: 'Code generators: Priority',
        description: 'Decides in which order generators will be used when generating codes on the fly. Lower value means higher priority.'
    },
    {
        field: 'Code generators: Prefix',
        description: 'Will be used as a code prefix.'
    },
    {
        field: 'Code generators: Product',
        description: 'Product name can be put in a message with a placeholder.'
    },
    {
        field: 'Code generators: Valid days',
        description: 'How many days a code is valid.'
    },
    {
        field: 'Code generators: Algorithm',
        description: 'Alphanumeric: 16 character long code, with prefix included\n\nShort: 5 character long code, with prefix included\n\nGuid: Prefix + 36 character long guid'
    },
    {
        field: 'Code generators: Codes remaining',
        description: 'How many codes that can still be generated.'
    },
    {
        field: 'Limitations: Limitation type',
        description: 'Recipient max codes: Set max code amount for a recipient during service lifetime. Daily max codes: Set a daily max code amount for a recipient. Codes Remaining lower alert: An alert will be sent when the remaining code amount is less than the specified value.'
    },
    {
        field: 'Limitations: Value',
        description: 'The max amount of codes for a recipient.'
    },
    {
        field: 'Limitations: Error message',
        description: 'This message will be sent when the value is reached.'
    }
];

export default infoData;