import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/customerActions';

const initialState = {
    usersLoading: true,
    refreshUsers: false,
    customerUsers: {},
    contactPropertyNamesLoading: false,
    contactPropertyNames: []
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CUSTOMER_SET_USERS: {
            return {
                ...state,
                usersLoading: false,
                customerUsers: action?.data?.reduce((users, user) => ({ ...users, [user.externalUserId]: user }), {})
            };
        }
        case ACTIONS.CUSTOMER_SET_USERS_LOADING: {
            return {
                ...state,
                usersLoading: Boolean(action?.data)
            };
        }
        case ACTIONS.CUSTOMER_REFRESH_USERS: {
            return {
                ...state,
                refreshUsers: true,
                usersLoading: true
            };
        }
        case ACTIONS.CUSTOMER_SET_CONTACT_PROPERTIES_LOADING: {
            return {
                ...state,
                contactPropertyNamesLoading: Boolean(action?.data)
            };
        }
        case ACTIONS.CUSTOMER_SET_CONTACT_PROPERTIES: {
            return {
                ...state,
                contactPropertyNames: Array.isArray(action?.data) ? action.data : []
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default:
            return {
                ...state
            };
    }
};

export default customerReducer;