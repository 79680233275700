/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import SmsIcon from '@material-ui/icons/SmsRounded';
import DialogIcon from '@material-ui/icons/ForumRounded';
import SendMessagesIcon from '@material-ui/icons/SendRounded';
import MessageLogIcon from '@material-ui/icons/ViewListRounded';
import BarChartIcon from '@material-ui/icons/BarChartRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import ScheduledMessagesIcon from '@material-ui/icons/ScheduleRounded';
import { useParams } from 'react-router';
import * as products from 'src/smartDialogProducts';
import DialogNavbarTitle from 'src/components/Navbar/DialogNavbarTitle';

const routes = [
    {
        subheader: '',
        items: [
            {
                title: 'Dashboard',
                href: '/dashboard',
                icon: DashboardIcon,
                linkable: false
            },
            {
                titleComponent: DialogNavbarTitle,
                href: '/dialogs/started',
                icon: DialogIcon,
                linkable: false,
                restrictedToProductTypes: [products.DIALOGSERVICE]
            },
            {
                title: 'Send Messages',
                href: '/sendmessages',
                icon: SendMessagesIcon,
                linkable: true,
                restrictedToProductTypes: [products.DIALOGSERVICE]
            },
            {
                title: 'Services',
                href: '/services',
                icon: SmsIcon,
                linkable: false,
                restrictedToProductTypes: [products.MESSAGINGSERVICE, products.CARRIERBILLINGSERVICE],
                items: [
                    {
                        title: '1-Way Messaging Services',
                        href: '/services/onewaymessaging',
                        linkable: true,
                        restrictedToProductTypes: [products.ONEWAYMESSAGINGSERVICE]
                    },
                    {
                        title: '2-Way Messaging Services',
                        href: '/services/twowaymessaging',
                        linkable: true,
                        restrictedToProductTypes: [products.TWOWAYMESSAGINGSERVICE]
                    },
                    {
                        title: 'Group Messaging Services',
                        href: '/services/groupmessaging',
                        linkable: true,
                        restrictedToProductTypes: [products.GROUPSERVICE]
                    },
                    {
                        title: 'Dialog Services',
                        href: '/services/dialog',
                        linkable: true,
                        restrictedToProductTypes: [products.DIALOGSERVICE]
                    },
                    {
                        title: 'Code Services',
                        href: '/services/codeservice',
                        linkable: true,
                        restrictedToProductTypes: [products.CODESERVICE]
                    },
                    {
                        title: 'Carrier Billing Services',
                        href: '/services/carrierbilling',
                        linkable: true,
                        restrictedToProductTypes: [products.CARRIERBILLINGSERVICE]
                    },
                    {
                        title: 'Survey Services',
                        href: '/services/surveyservice',
                        linkable: true,
                        restrictedToProductTypes: [products.SURVEYSERVICE]
                    }
                ]
            },
            {
                title: 'Management',
                href: '/management',
                icon: SettingsIcon,
                linkable: false,
                restrictedToProductTypes: [products.MESSAGINGSERVICE],
                items: [
                    {
                        title: 'Messaging Accounts',
                        href: '/management/messagingaccounts',
                        linkable: false,
                        restrictedToProductTypes: [products.MESSAGINGSERVICE],
                        restrictedToRoles: ['Admin']
                    },
                    {
                        title: 'Subscription Keys',
                        href: '/management/subscriptionkeys',
                        linkable: false,
                        restrictedToProductTypes: [products.APISUBSCRIPTIONMANAGEMENT],
                        restrictedToRoles: ['Admin']
                    },
                    {
                        title: 'Personal Access Tokens',
                        href: '/management/pat',
                        linkable: false,
                        restrictedToProductTypes: [products.PATMANAGEMENT],
                        restrictedToRoles: ['Admin']
                    },
                    {
                        title: 'Messaging Templates',
                        href: '/management/messagingtemplates',
                        linkable: false,
                        restrictedToProductTypes: [products.DIALOGSERVICE]
                    },
                    {
                        title: 'Opt-out Numbers',
                        href: '/management/optouts',
                        linkable: false,
                        restrictedToProductTypes: [products.ADBANSERVICE]
                    },
                    {
                        title: 'Opt-out Services',
                        href: '/management/optoutservices',
                        linkable: false,
                        restrictedToProductTypes: [products.ADBANSERVICE],
                        restrictedToRoles: ['Admin']
                    },
                    {
                        title: 'User Access',
                        href: '/management/useraccess',
                        linkable: false,
                        restrictedToProductTypes: [products.USERACCESS],
                        restrictedToRoles: ['Admin']
                    },
                    {
                        title: 'Url Shortener & Qr',
                        href: '/management/urlshortener',
                        linkable: true,
                        restrictedToProductTypes: [products.URLSHORTENERSERVICE]
                    }
                ]
            },
            {
                title: 'Message Log',
                href: '/messagelog',
                icon: MessageLogIcon,
                linkable: true,
                restrictedToProductTypes: [products.MESSAGEPERSISTENCESERVICE]
            },
            {
                title: 'Scheduled Messages',
                href: '/scheduledmessages',
                icon: ScheduledMessagesIcon,
                linkable: true,
                restrictedToProductTypes: [products.MESSAGEPERSISTENCESERVICE]
            },
            {
                title: 'Reports',
                href: '/reports',
                icon: BarChartIcon,
                linkable: false,
                restrictedToProductTypes: [products.REPORTINGSERVICE],
                restrictedToRoles: ['Admin']
            }
        ]
    }
];

const useNavConfig = () => {
    const { customerId } = useParams();

    const mapRouteItems = (items) => {
        return items.map((item) => {
            if (item.items) {
                if (item.href) {
                    return {
                        ...item,
                        href: `/${customerId}${item.href}`,
                        items: [
                            ...mapRouteItems(item.items)
                        ]
                    };
                }
                return {
                    ...item,
                    items: [
                        ...mapRouteItems(item.items)
                    ]
                };
            }
            if (item.href) {
                return { ...item, href: `/${customerId}${item.href}` };
            }
            return { ...item };
        });
    };

    return mapRouteItems(routes);
};

export default useNavConfig;