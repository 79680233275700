import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const FoldersDialog = ({ open, onClose, folders }) => {
    const [selectedFolderId, setSelectedFolderId] = React.useState('_selectfolder_');

    const onChange = (e) => {
        setSelectedFolderId(e.target.value);
    };

    const handleCancelClick = () => {
        onClose(null);
    };

    const handleMoveClick = () => {
        onClose(selectedFolderId);
    };

    return (
        <Dialog
            aria-labelledby="Move Messages Dialog"
            open={open}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle disableTypography>
                <Typography variant="h4">
                    Move messages to folder
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col width="40%" />
                            <col width="60%" />
                        </colgroup>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">Destination folder</TableCell>
                                <TableCell align="left">
                                    <Select
                                        value={selectedFolderId}
                                        name="folderid"
                                        onChange={onChange}
                                        fullWidth
                                    >
                                        <MenuItem value="_selectfolder_"><em>Select folder</em></MenuItem>
                                        {folders?.map?.((f) => {
                                            return (
                                                <MenuItem key={f?.id} value={f?.id}>{f?.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button aria-label="cancel" onClick={handleCancelClick} variant="contained">
                    Cancel
                </Button>
                <Button disabled={selectedFolderId === '_selectfolder_'} aria-label="move messages" onClick={handleMoveClick} variant="contained" color="primary">
                    Move messages
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FoldersDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    folders: PropTypes.array
};

export default FoldersDialog;