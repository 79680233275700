import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/alertActions';
import { sortByDateProperty } from 'src/utils/arrayFunctions';

const initialState = {
    infoMessagesLoading: true,
    unreadAlerts: [],
    alerts: [],
    unreadNews: [],
    news: [],
    shouldRefreshInfoMessages: false
};

const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.INFOMESSAGES_LOADING: {
            return {
                ...state,
                infoMessagesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.INFOMESSAGES_LOADED: {
            if (!Array.isArray(action?.data?.items)) {
                return state;
            }

            const sortedInfomessages = action.data.items.sort(sortByDateProperty('published', 'asc'));
            const _alerts = sortedInfomessages.filter((infoMessage) => infoMessage?.type === 'Alert') ?? [];
            const _news = sortedInfomessages.filter((infoMessage) => infoMessage?.type === 'News') ?? [];

            return {
                ...state,
                infoMessagesLoading: false,
                alerts: _alerts,
                unreadAlerts: _alerts.filter((alert) => alert?.read === false) ?? [],
                news: _news,
                unreadNews: _news.filter((news) => news?.read === false) ?? [],
                shouldRefreshInfoMessages: false
            };
        }
        case ACTIONS.REFRESH_INFOMESSAGES: {
            return {
                ...state,
                infoMessagesLoading: true,
                shouldRefreshInfoMessages: !state.infoMessagesLoading
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default alertsReducer;
