/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTwoWayChargesBarData } from 'src/components/TwoWayMessaging/TwoWayServiceStatistics/hooks';
import moment from 'moment';
import ChargesBarChart from './Charts/ChargesBarChart';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1),
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: 20
    },
    chip: {
        margin: theme.spacing(1)
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

function TwoWayChargeStatisticsBar({ data, chips, groupBy, isWidget }) {
    const classes = useStyles();

    const uniqueKeywords = [];
    React.useMemo(() => {
        data?.forEach?.((d) => {
            Object.keys?.(d?.revenuePerKeyword)?.forEach?.((k) => {
                if (uniqueKeywords.indexOf(k) === -1 && k !== 'TotalCharges') {
                    uniqueKeywords.push(k);
                }
            });
        });
    }, [data, uniqueKeywords]);

    const barData = useTwoWayChargesBarData(data, groupBy, uniqueKeywords);

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
                <Chip className={classes.chip} label="Currency: EUR" />
                {isWidget && (
                    <Chip className={classes.chip} label={`Widget updated: ${dateFormatter(moment())}`} />
                )}
            </div>
            <div className={classes.chartContainer}>
                <ChargesBarChart
                    data={barData}
                    labels={uniqueKeywords}
                    groupBy={groupBy}
                />
            </div>
        </Card>
    );
}

TwoWayChargeStatisticsBar.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    groupBy: PropTypes.string.isRequired,
    isWidget: PropTypes.bool
};

export default TwoWayChargeStatisticsBar;