import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';
import uuid from 'uuid';

export const SELECT_THREAD = 'SELECT_THREAD';
export const SELECT_PENDING_THREAD = 'SELECT_PENDING_THREAD';
export const SELECT_DIALOG_MESSAGE = 'SELECT_DIALOG_MESSAGE';
export const SET_THREADS_LOADED = 'SET_THREADS_LOADED';
export const SET_PINNED_THREADS_LOADED = 'SET_PINNED_THREADS_LOADED';
export const SET_PENDING_THREADS_LOADED = 'SET_PENDING_THREADS_LOADED';
export const SET_MORE_THREADS_LOADED = 'SET_MORE_THREADS_LOADED';
export const SET_MORE_PENDING_THREADS_LOADED = 'SET_MORE_PENDING_THREADS_LOADED';
export const SET_SINGLE_THREAD_LOADED = 'SET_SINGLE_THREAD_LOADED';
export const SET_SINGLE_PENDING_THREAD_LOADED = 'SET_SINGLE_PENDING_THREAD_LOADED';
export const SET_THREADS_LOADING = 'SET_THREADS_LOADING';
export const SET_PINNED_THREADS_LOADING = 'SET_PINNED_THREADS_LOADING';
export const SET_PENDING_THREADS_LOADING = 'SET_PENDING_THREADS_LOADING';
export const REFRESH_THREADS = 'REFRESH_THREADS';
export const REFRESH_PENDING_THREADS = 'REFRESH_PENDING_THREADS';
export const LOAD_MORE_THREADS = 'LOAD_MORE_THREADS';
export const LOAD_MORE_PENDING_THREADS = 'LOAD_MORE_PENDING_THREADS';
export const REFRESH_THREAD_MESSAGES = 'REFRESH_THREAD_MESSAGES';
export const REFRESH_DIALOG_SERVICE_MESSAGES = 'REFRESH_DIALOG_SERVICE_MESSAGES';

export const SET_DIALOG_SERVICES_LOADED = 'SET_DIALOG_SERVICES_LOADED';
export const SET_DIALOG_SERVICES_LOADING = 'SET_DIALOG_SERVICES_LOADING';

export const SET_DISCUSSION_SERVICES_LOADED = 'SET_DISCUSSION_SERVICES_LOADED';
export const SET_DISCUSSION_SERVICES_LOADING = 'SET_DISCUSSION_SERVICES_LOADING';

export const SET_DIALOG_SERVICE_MESSAGES_LOADED = 'SET_DIALOG_SERVICE_MESSAGES_LOADED';
export const SET_DIALOG_SERVICE_MESSAGES_LOADING = 'SET_DIALOG_SERVICE_MESSAGES_LOADING';

export const SET_THREAD_MESSAGES_LOADING = 'SET_THREAD_MESSAGES_LOADING';
export const SET_THREAD_MESSAGES_LOADED = 'SET_THREAD_MESSAGES_LOADED';
export const CLEAR_THREADS = 'CLEAR_THREADS';
export const CLEAR_THREAD_FILTERS = 'CLEAR_THREAD_FILTERS';
export const CLEAR_PENDING_THREAD_FILTERS = 'CLEAR_PENDING_THREAD_FILTERS';
export const CLEAR_THREAD_MESSAGES = 'CLEAR_THREAD_MESSAGES';
export const CLEAR_DIALOG_MESSAGE_FILTERS = 'CLEAR_DIALOG_MESSAGE_FILTERS';

export const THREAD_CREATED = 'THREAD_CREATED';
export const UPDATE_THREAD = 'UPDATE_THREAD';
export const UPDATE_PENDING_THREAD = 'UPDATE_PENDING_THREAD';
export const UPDATE_SELECTED_THREAD = 'UPDATE_SELECTED_THREAD';
export const UPDATE_SELECTED_PENDING_THREAD = 'UPDATE_SELECTED_PENDING_THREAD';
export const UPDATE_THREAD_MESSAGE = 'UPDATE_THREAD_MESSAGE';
export const UPDATE_DIALOG_SENT_MESSAGES = 'UPDATE_DIALOG_SENT_MESSAGES';
export const CREATE_TEMPORARY_THREAD_FOR_SENTMESSAGE = 'CREATE_TEMPORARY_THREAD_FOR_SENTMESSAGE';
export const THREAD_REASSIGNED = 'THREAD_REASSIGNED';

export const SET_THREAD_FILTERS = 'SET_THREAD_FILTERS';
export const SET_PENDING_THREAD_FILTERS = 'SET_PENDING_THREAD_FILTERS';
export const SET_DIALOG_MESSAGE_FILTERS = 'SET_DIALOG_MESSAGE_FILTERS';
export const SET_THREAD_FILTERS_EXPANDED = 'SET_THREAD_FILTERS_EXPANDED';

export const TOGGLE_THREAD_GROUP = 'TOGGLE_THREAD_GROUP';

export const DIALOG_START_MESSAGE_SENT = 'DIALOG_START_MESSAGE_SENT';
export const DIALOG_START_MESSAGE_RESPONSE_RECEIVED = 'DIALOG_START_MESSAGE_RESPONSE_RECEIVED';

export const DIALOG_START_MESSAGE_CLEAR = 'DIALOG_START_MESSAGE_CLEAR';
export const DIALOG_MESSAGE_QUEUED_SUCCESS_RESPONSE_RECEIVED = 'DIALOG_MESSAGE_QUEUED_SUCCESS_RESPONSE_RECEIVED';
export const DIALOG_MESSAGE_QUEUED_ERROR_RESPONSE_RECEIVED = 'DIALOG_MESSAGE_QUEUED_ERROR_RESPONSE_RECEIVED';

export const UPDATE_CONVERSATION_MESSAGE = 'UPDATE_CONVERSATION_MESSAGE';
export const DIALOG_MESSAGE_QUEUED = 'DIALOG_MESSAGE_QUEUED';

export const SET_THREAD_PINNED = 'SET_THREAD_PINNED';
export const SET_THREAD_UNPINNED = 'SET_THREAD_UNPINNED';

export const SET_DIALOG_SENDMESSAGE_SETTINGS = 'SET_DIALOG_SENDMESSAGE_SETTINGS';
export const DIALOG_SEND_NEW_MESSAGE_RESPONSE_RECEIVED = 'DIALOG_SEND_NEW_MESSAGE_RESPONSE_RECEIVED';
export const CLEAR_SEND_NEW_MESSAGE_RESPONSE = 'CLEAR_SEND_NEW_MESSAGE_RESPONSE';
export const CLEAR_SEND_NEW_MESSAGE_SETTINGS = 'CLEAR_SEND_NEW_MESSAGE_SETTINGS';

export const DISCUSSION_THREAD_CREATED_EVENT_RECEIVED = 'DISCUSSION_THREAD_CREATED_EVENT_RECEIVED';
export const TEMPLATE_SELECTED = 'TEMPLATE_SELECTED';

export const SET_DIALOG_INBOX_HAS_FOCUS = 'SET_DIALOG_INBOX_HAS_FOCUS';
export const SET_UNREAD_THREAD_COUNT = 'SET_UNREAD_THREAD_COUNT';

export const SelectThread = (threadId) => (dispatch) => dispatch({
    type: SELECT_THREAD,
    data: threadId
});

export const SelectPendingThread = (threadId) => (dispatch) => dispatch({
    type: SELECT_PENDING_THREAD,
    data: threadId
});

export const SelectDialogMessage = (messageId) => (dispatch) => dispatch({
    type: SELECT_DIALOG_MESSAGE,
    data: messageId
});

export const SetDialogThreadsLoaded = (totalCount, data) => (dispatch) => dispatch({
    type: SET_THREADS_LOADED,
    data,
    totalCount
});

export const SetPendingDialogThreadsLoaded = (totalCount, data) => (dispatch) => dispatch({
    type: SET_PENDING_THREADS_LOADED,
    data,
    totalCount
});

export const SetMoreDialogThreadsLoaded = (totalCount, data) => (dispatch) => dispatch({
    type: SET_MORE_THREADS_LOADED,
    data,
    totalCount
});

export const SetMorePendingDialogThreadsLoaded = (totalCount, data) => (dispatch) => dispatch({
    type: SET_MORE_PENDING_THREADS_LOADED,
    data,
    totalCount
});

export const SetSingleDialogThreadLoaded = (data) => (dispatch) => dispatch({
    type: SET_SINGLE_THREAD_LOADED,
    data
});

export const SetSinglePendingDialogThreadLoaded = (data) => (dispatch) => dispatch({
    type: SET_SINGLE_PENDING_THREAD_LOADED,
    data
});

export const SetDialogThreadsLoading = (data) => (dispatch) => dispatch({
    type: SET_THREADS_LOADING,
    data
});

export const SetPendingDialogThreadsLoading = (data) => (dispatch) => dispatch({
    type: SET_PENDING_THREADS_LOADING,
    data
});

export const SetDiscussionServicesLoaded = (data) => (dispatch) => dispatch({
    type: SET_DISCUSSION_SERVICES_LOADED,
    data
});

export const SetDiscussionServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_DISCUSSION_SERVICES_LOADING,
    data
});

export const SetDialogServicesLoaded = (data) => (dispatch) => dispatch({
    type: SET_DIALOG_SERVICES_LOADED,
    data
});

export const SetDialogServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_DIALOG_SERVICES_LOADING,
    data
});

export const SetDialogServiceMessagesLoaded = (data) => (dispatch) => dispatch({
    type: SET_DIALOG_SERVICE_MESSAGES_LOADED,
    data
});

export const SetDialogServiceMessagesLoading = (data) => (dispatch) => dispatch({
    type: SET_DIALOG_SERVICE_MESSAGES_LOADING,
    data
});

export const SetThreadMessagesLoading = (data) => (dispatch) => dispatch({
    type: SET_THREAD_MESSAGES_LOADING,
    data
});

export const SetThreadMessagesLoaded = (data) => (dispatch) => dispatch({
    type: SET_THREAD_MESSAGES_LOADED,
    data
});

export const RefreshPendingThreads = () => (dispatch) => dispatch({
    type: REFRESH_PENDING_THREADS
});

export const RefreshThreadMessages = () => (dispatch) => dispatch({
    type: REFRESH_THREAD_MESSAGES
});

export const RefreshDialogServiceMessages = () => (dispatch) => dispatch({
    type: REFRESH_DIALOG_SERVICE_MESSAGES
});

export const ClearThreadMessages = () => (dispatch) => dispatch({
    type: CLEAR_THREAD_MESSAGES
});

export const RefreshThreads = () => (dispatch) => dispatch({
    type: REFRESH_THREADS
});

export const ClearThreads = () => (dispatch) => dispatch({
    type: CLEAR_THREADS
});

export const SetThreadFilters = (data) => (dispatch) => {
    dispatch({ type: SET_THREAD_FILTERS, data });
    dispatch({ type: REFRESH_THREADS });
};

export const SetPendingThreadFilters = (data) => (dispatch) => {
    dispatch({ type: SET_PENDING_THREAD_FILTERS, data });
    dispatch({ type: REFRESH_PENDING_THREADS });
};

export const SetDialogMessageFilters = (data) => (dispatch) => {
    dispatch({ type: SET_DIALOG_MESSAGE_FILTERS, data });
    dispatch({ type: REFRESH_DIALOG_SERVICE_MESSAGES });
};

export const ClearThreadFilters = () => async (dispatch) => {
    await dispatch({ type: CLEAR_THREAD_FILTERS });
    await dispatch({ type: REFRESH_THREADS });
};

export const ClearPendingThreadFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_PENDING_THREAD_FILTERS });
    dispatch({ type: REFRESH_PENDING_THREADS });
};

export const ClearInboundMessageFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_DIALOG_MESSAGE_FILTERS });
    dispatch({ type: REFRESH_DIALOG_SERVICE_MESSAGES });
};

export const SetThreadFiltersExpanded = (data) => (dispatch) => dispatch({
    type: SET_THREAD_FILTERS_EXPANDED,
    data
});

export const ToggleGroup = (groupHeader) => (dispatch) => dispatch({
    type: TOGGLE_THREAD_GROUP,
    data: groupHeader
});

export const SubscribeDiscussionThreadCreated = () => (dispatch) => dispatch(signalREventSubscribe('IDiscussionThreadCreated', (eventData) => dispatch({
    // Refreshing of threads handled in DialogMiddleware
    type: DISCUSSION_THREAD_CREATED_EVENT_RECEIVED,
    data: eventData.data
})));

export const SubscribeDiscussionThreadStatusChanges = () => (dispatch) => dispatch(signalREventSubscribe('IDiscussionThreadStatusChanged', (eventData) => dispatch({
    type: UPDATE_THREAD,
    data: eventData.data
})));

export const SubscribeDiscussionThreadReassigned = () => (dispatch) => dispatch(signalREventSubscribe('IDiscussionThreadReassigned', (eventData) => dispatch({
    type: THREAD_REASSIGNED,
    data: eventData.data
})));

export const UnsubscribeDiscussionThreadCreated = () => (dispatch) => dispatch(signalREventUnsubscribe('IDiscussionThreadCreated'));
export const UnsubscribeDiscussionThreadStatusChanges = () => (dispatch) => dispatch(signalREventUnsubscribe('IDiscussionThreadStatusChanged'));
export const UnsubscribeDiscussionThreadReassigned = () => (dispatch) => dispatch(signalREventUnsubscribe('IDiscussionThreadReassigned'));

export const DialogStartMessageSent = (data) => (dispatch) => {
    dispatch({ type: CLEAR_THREAD_FILTERS });
    dispatch({ type: DIALOG_START_MESSAGE_SENT, data });
};

export const DialogStartMessageResponseRecieved = ({ error, ...rest }) => (dispatch) => {
    dispatch({ type: DIALOG_START_MESSAGE_RESPONSE_RECEIVED, data: { error, ...rest } });
};

export const DialogSendNewMessageResponseRecieved = ({ error, ...rest }) => (dispatch) => {
    dispatch({ type: DIALOG_SEND_NEW_MESSAGE_RESPONSE_RECEIVED, data: { error, ...rest } });
};

export const DialogClearSendNewMessageResponse = () => (dispatch) => {
    dispatch({ type: CLEAR_SEND_NEW_MESSAGE_RESPONSE });
};

export const DialogStartMessageClear = () => (dispatch) => dispatch({ type: DIALOG_START_MESSAGE_CLEAR });

export const DialogSendNewMessageSettingsClear = () => (dispatch) => dispatch({ type: CLEAR_SEND_NEW_MESSAGE_SETTINGS });

export const LoadMoreThreads = () => (dispatch) => dispatch({
    type: LOAD_MORE_THREADS
});

export const LoadMorePendingThreads = () => (dispatch) => dispatch({
    type: LOAD_MORE_PENDING_THREADS
});

export const UpdateThread = (data) => (dispatch) => dispatch({
    type: UPDATE_THREAD,
    data
});

export const UpdatePendingThread = (data) => (dispatch) => dispatch({
    type: UPDATE_PENDING_THREAD,
    data
});

export const UpdateSelectedThread = (data) => (dispatch) => dispatch({
    type: UPDATE_SELECTED_THREAD,
    data
});

export const UpdateSelectedPendingThread = (data) => (dispatch) => dispatch({
    type: UPDATE_SELECTED_PENDING_THREAD,
    data
});

export const UpdateConversationMessage = (threadId, message) => (dispatch) => dispatch({
    type: UPDATE_CONVERSATION_MESSAGE,
    data: { threadId, message }
});

export const SubscribeInboundDialogMessageReceived = () => (dispatch) => dispatch(signalREventSubscribe('IInboundDialogMessageReceived', () => dispatch({
    type: REFRESH_DIALOG_SERVICE_MESSAGES
})));

export const UnsubscribeInboundDialogMessageReceived = () => (dispatch) => dispatch(signalREventUnsubscribe('IInboundDialogMessageReceived'));

export const DialogMessageQueued = (thread, content, attachmentUri) => (dispatch) => dispatch({
    type: DIALOG_MESSAGE_QUEUED,
    data: {
        internalQueueId: uuid(),
        thread,
        content,
        attachmentUri
    }
});

export const QueuedDialogMessageSendSuccess = (processedMessage, sendResponse) => (dispatch) => dispatch({
    type: DIALOG_MESSAGE_QUEUED_SUCCESS_RESPONSE_RECEIVED,
    data: { processedMessage, sendResponse }
});

export const QueuedDialogMessageSendError = (processedMessage, sendResponse, errorMessages) => (dispatch) => dispatch({
    type: DIALOG_MESSAGE_QUEUED_ERROR_RESPONSE_RECEIVED,
    data: { processedMessage, sendResponse, errorMessages }
});

export const SetPinnedThreadsLoading = (data) => (dispatch) => dispatch({
    type: SET_PINNED_THREADS_LOADING,
    data: Boolean(data)
});

export const SetPinnedThreadsLoaded = (data) => (dispatch) => dispatch({
    type: SET_PINNED_THREADS_LOADED,
    data
});

export const SetThreadPinned = (data) => (dispatch) => dispatch({
    type: SET_THREAD_PINNED,
    data
});

export const SetThreadUnPinned = (data) => (dispatch) => dispatch({
    type: SET_THREAD_UNPINNED,
    data
});

export const SetUnreadThreadCount = (count) => (dispatch) => dispatch({
    type: SET_UNREAD_THREAD_COUNT,
    data: count
});