import React from 'react';
import Page from 'src/components/Page';
import 'react-grid-layout/css/styles.css';
import WidgetContainer from 'src/components/Widgets/WidgetContainer';

const DashboardDefault = () => (
    <Page
        title="Dashboard"
    >
        <WidgetContainer />
    </Page>
);

export default DashboardDefault;