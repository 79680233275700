import { Chip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    chip: {
        '& span:first-child': {
            paddingLeft: 12,
            paddingRight: 12,
            fontWeight: 600
        }
    }
}));

const DialogNavbarTitle = () => {
    const classes = useStyles();

    const { unreadThreadCount } = useSelector((state) => state.dialog);
    if (unreadThreadCount > 0) {
        return (
            <div className={classes.container}>
                Dialogs
                <Chip
                    className={classes.chip}
                    size="small"
                    color="secondary"
                    label={unreadThreadCount}
                    onClick={() => { }}
                />
            </div>
        );
    }
    return 'Dialogs';
};

export default DialogNavbarTitle;