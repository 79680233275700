const infoData = [
    {
        field: 'Select mode',
        description: 'Use Basic mode to create a list of recipients to send messages to. Use Full mode to create a Group service for instance joining and leaving via SMS.'
    },
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Description',
        description: 'A description of the service.'
    },
    {
        field: 'Service state',
        description: 'The state of the service, (Active or Passive).'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Default sender',
        description: 'If no sender is provided in the sending request, this sender will be used. Number (Long- or shortcode) or Alphanumeric string (3-11 chars, no special characters)'
    },
    {
        field: 'Start date',
        description: 'Service valid from this date.'
    },
    {
        field: 'End date',
        description: 'Service valid thru this date.'
    },
    {
        field: 'Dlr url',
        description: 'The URL to send delivery status callbacks.'
    },
    {
        field: 'Dialog service',
        description: 'Use this service in Dialog. It meas that Discussion threads can be started with the members of a group message.'
    },
    {
        field: 'Code service',
        description: 'Select a Code service to be able to incorporate Redeemable codes in messages.'
    },
    {
        field: 'Grace period',
        description: 'The number of days, in which the recipient does not receive Group messages, after joining.'
    },
    {
        field: 'Messaging limitations',
        description: 'Limits how often a message can be sent to the group.'
    },
    {
        field: 'Membership period',
        description: 'Defines how long the membership lasts.'
    },
    {
        field: 'Renewal mode',
        description: 'Defines what happens when the membership period has ended.'
    },
    {
        field: 'Contact amount limit',
        description: 'Limits the total number of contacts a group can have.'
    },
    {
        field: 'Message retention days',
        description: 'Defines how long messages will be stored. Minimum value possible is 90 days. If not set, default value will be set to max value, which is 365 days.'
    },
    {
        field: 'Hidden content',
        description: 'When set, will hide the message content for all messages. To disable Hidden content please contact support.'
    },
    {
        field: 'Push billing',
        description: 'Fill in the form to give the Push payment information. Select Input source, Price and Vat for the Push billing service. The billing mode can be selected either as internal or via API (by giving the MO-URL). Set charging limit (between 1-150 €) and select whether you want to charge when joining to the Push billing group. Our customer support will finalize the setup and send a notification when the service is up and running. Until that the service will be in passive state and unable to edit.'
    }
];

export default infoData;