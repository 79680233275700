import RajdhaniMedium from 'src/fonts/Rajdhani/Rajdhani-Medium.ttf';

export default {
    '@global': {
        '*::-webkit-scrollbar': {
            width: '7px',
            height: '7px'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#aaa',
            borderRadius: 5
        },

        '@font-face': {
            fontFamily: "'Rajdhani'",
            src: `local("Rajdhani"), url(${RajdhaniMedium}) format("truetype")`
        },
        '*': {
            boxSizing: 'border-box',
            margin: '0',
            padding: '0'
        },
        html: {
            webkitFontSmoothing: 'antialiased',
            mozOsxFontSmoothing: 'grayscale'
        },
        body: {
            backgroundColor: '#f4f6f8'
        },
        a: {
            textDecoration: 'none'
        },
        ':not(pre) > code': {
            fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
            backgroundColor: '#eeeeee',
            padding: '2px 4px',
            direction: 'ltr',
            whiteSpace: 'pre',
            wordSpacing: 'normal',
            wordBreak: 'normal',
            lineHeight: '1.5',
            fontSize: '14px'
        },
        iframe: {
            display: 'none'
        },
        "code[class*='language-'], pre[class*='language-']": {
            color: '#c5c8c6',
            fontFamily: "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
            direction: 'ltr',
            textAlign: 'left',
            whiteSpace: 'pre',
            wordSpacing: 'normal',
            wordBreak: 'normal',
            lineHeight: '1.5',
            fontSize: '14px',
            mozTabSize: '4',
            oTabSize: '4',
            tabSize: '4',
            webkitHyphens: 'none',
            mozHyphens: 'none',
            msHyphens: 'none',
            hyphens: 'none'
        },
        "pre[class*='language-']": {
            padding: '1em',
            margin: '0.5em 0',
            overflow: 'auto'
        },
        ":not(pre) > code[class*='language-'], pre[class*='language-']": {
            background: '#2d323e'
        },
        ":not(pre) > code[class*='language-']": {
            padding: '0.1em',
            borderRadius: '0.3em'
        },
        '.token.comment, .token.prolog, .token.doctype, .token.cdata': {
            color: '#7c7c7c'
        },
        '.token.punctuation': {
            color: '#c5c8c6'
        },
        '.namespace': {
            opacity: '0.7'
        },
        '.token.property, .token.keyword, .token.tag': {
            color: '#96cbfe'
        },
        '.token.class-name': {
            color: '#ffffb6'
        },
        '.token.boolean, .token.constant': {
            color: '#99cc99'
        },
        '.token.symbol, .token.deleted': {
            color: '#f92672'
        },
        '.token.number': {
            color: '#ff73fd'
        },
        '.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted': {
            color: '#a8ff60'
        },
        '.token.variable': {
            color: '#c6c5fe'
        },
        '.token.operator': {
            color: '#ededed'
        },
        '.token.entity': {
            color: '#ffffb6',
            cursor: 'help'
        },
        '.token.url': {
            color: '#96cbfe'
        },
        '.language-css .token.string, .style .token.string': {
            color: '#87c38a'
        },
        '.token.atrule, .token.attr-value': {
            color: '#f9ee98'
        },
        '.token.function': {
            color: '#dad085'
        },
        '.token.regex': {
            color: '#e9c062'
        },
        '.token.important': {
            color: '#fd971f'
        },
        '.token.important, .token.bold': {
            fontWeight: 'bold'
        },
        '.token.italic': {
            fontStyle: 'italic'
        }
    }
};