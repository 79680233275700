import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';

export const SET_NOTIFICATIONSDRAWER_OPEN = 'SET_NOTIFICATIONSDRAWER_OPEN';
export const SHOW_READ_NOTIFICATIONS = 'SHOW_READ_NOTIFICATIONS';
export const READ_NOTIFICATIONS_LOADING = 'READ_NOTIFICATIONS_LOADING';
export const READ_NOTIFICATIONS_LOADED = 'READ_NOTIFICATIONS_LOADED';
export const UNREAD_NOTIFICATIONS_LOADING = 'UNREAD_NOTIFICATIONS_LOADING';
export const UNREAD_NOTIFICATIONS_LOADED = 'UNREAD_NOTIFICATIONS_LOADED';
export const REFRESH_UNREAD_NOTIFICATIONS = 'REFRESH_UNREAD_NOTIFICATIONS';
export const REFRESH_READ_NOTIFICATIONS = 'REFRESH_READ_NOTIFICATIONS';
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const NEW_UNREAD_NOTIFICATION = 'NEW_UNREAD_NOTIFICATION';

export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ';
export const MARK_NOTIFICATIONS_AS_READ_COMPLETED = 'MARK_NOTIFICATIONS_AS_READ_COMPLETED';

export const PROCESS_SNACKBAR_NOTIFICATION = 'PROCESS_SNACKBAR_NOTIFICATION';
export const SETTINGS_LOADED = 'SETTINGS_LOADED';

export const subscribeCustomerNotifications = () => (dispatch) => dispatch(signalREventSubscribe('ISmartDialogNotificationEvent', ({ id, data }) => (
    dispatch({ type: NEW_UNREAD_NOTIFICATION, data: { id, ...data } })
)));

export const unsubscribeCustomerNotifications = () => (dispatch) => dispatch(signalREventUnsubscribe('ISmartDialogNotificationEvent'));

export const showDesktopNotification = (notificationHeader, notificationBody, onClick) => async (dispatch, getState) => {
    const { notifications: { sendNotification, sendEvent } } = getState();

    if (!sendNotification || !sendEvent) {
        return null;
    }

    const notificationPermission = await Notification.requestPermission();
    if (notificationPermission !== 'granted') {
        return null;
    }

    const options = {
        body: notificationBody,
        icon: `${window.location.origin}/images/logos/smartdialog_logo_vertical_v2.png`,
        silent: true
    };

    const notification = new Notification(notificationHeader, options);

    notification.onclick = () => {
        window.focus();
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return notification;
};