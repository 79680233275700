export const SET_WIDGETS = 'SET_WIDGETS';
export const SET_WIDGETS_LOADING = 'SET_WIDGETS_LOADING';
export const REFERSH_WIDGETS = 'REFERSH_WIDGETS';

export const setWidgets = (data) => (dispatch) => dispatch({
    type: SET_WIDGETS,
    data
});

export const setWidgetsLoading = (data) => (dispatch) => dispatch({
    type: SET_WIDGETS_LOADING,
    data
});

export const refreshWidgets = () => (dispatch) => dispatch({
    type: REFERSH_WIDGETS
});