import { save, load } from 'redux-localstorage-simple';
import { STATE_LOADED_FROM_LOCALSTORAGE, AuthenticationActions, RESET_REDUCER_STATE } from 'src/actions';
import { localStorageStatesToSync } from 'src/store';

const namespaceSeparator = '::';
const localStoragePrefix = 'smartdialog::redux';

const getNamespace = (externalUserId) => `${localStoragePrefix}${namespaceSeparator}${externalUserId}`;

export const saveToLocalStorage = ({ dispatch, getState }) => (next) => (action) => {
    const state = getState();
    const externalUserId = state?.auth?.claims?.sub;
    const selectedCustomerId = state?.auth?.selectedCustomerId;

    if (selectedCustomerId && externalUserId && localStorageStatesToSync.length > 0) {
        const middleware = save({
            states: localStorageStatesToSync,
            namespace: getNamespace(externalUserId, selectedCustomerId),
            namespaceSeparator,
            debounce: 1000
        });
        return middleware({ dispatch, getState })(next)(action);
    }
    return next(action);
};

const populateWithDefaultData = (defaultData, data) => {
    const isLiteralObject = (a) => {
        return (!!a) && (a.constructor === Object);
    };
    const resp = Object.keys(data).reduce((prev, propertyName) => {
        const value = data[propertyName];

        if (isLiteralObject(value)) {
            return {
                ...prev,
                [propertyName]: {
                    ...prev[propertyName],
                    ...populateWithDefaultData(prev[propertyName], value)
                }
            };
        }
        return {
            ...prev,
            [propertyName]: value
        };
    }, defaultData);

    return resp;
};

export const loadFromLocalStorage = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === AuthenticationActions.AccessTokenRefreshed && localStorageStatesToSync.length > 0) {
        const userActionResult = next(action);
        const state = getState();
        const externalUserId = state?.auth?.claims?.sub;

        const localStoragePersistedState = load({
            states: localStorageStatesToSync,
            namespace: getNamespace(externalUserId),
            namespaceSeparator,
            disableWarnings: true
        });

        if (Object.keys(localStoragePersistedState).length > 0) {
            const defaultState = getState();
            const data = populateWithDefaultData(defaultState, localStoragePersistedState);
            dispatch({ type: STATE_LOADED_FROM_LOCALSTORAGE, data });
        }
        return userActionResult;
    }

    if (action.type === AuthenticationActions.CustomerSelected && localStorageStatesToSync.length > 0) {
        const state = getState();
        const selectedCustomerId = state?.auth?.selectedCustomerId;

        if (selectedCustomerId && selectedCustomerId !== action?.data) {
            // Customer changed -> reset persisted state
            dispatch({ type: RESET_REDUCER_STATE });
        }
    }

    return next(action);
};