import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const DefaultNotificationContent = ({ classes, notification }) => (
    <div className={classes.contentRowItem}>
        <Typography>{notification?.content ?? ''}</Typography>
    </div>
);

DefaultNotificationContent.propTypes = {
    classes: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired
};

export default DefaultNotificationContent;