export const SET_ADBANSERVICES_LOADING = 'SET_ADBANSERVICES_LOADING';
export const SET_ADBANSERVICES = 'SET_ADBANSERVICES';
export const SET_ADBANSERVICE = 'SET_ADBANSERVICE';
export const SET_SELECTEDADBANSERVICE = 'SET_SELECTEDADBANSERVICE';
export const SET_SELECTEDADBANSERVICELOADING = 'SET_SELECTEDADBANSERVICELOADING';
export const REFRESH_SELECTEDADBANSERVICE = 'REFRESH_SELECTEDADBANSERVICE';
export const REFERSH_ADBANSERVICES = 'REFERSH_ADBANSERVICES';
export const ADBANSERVICEUPDATED = 'ADBANSERVICEUPDATED';
export const ADBANSERVICEDELETED = 'ADBANSERVICEDELETED';

export const setAdBanServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_ADBANSERVICES_LOADING,
    data
});

export const setAdBanServices = (data) => (dispatch) => dispatch({
    type: SET_ADBANSERVICES,
    data
});

export const setAdBanService = (data) => (dispatch) => dispatch({
    type: SET_ADBANSERVICE,
    data
});

export const setSelectedAdBanService = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDADBANSERVICE,
    data
});

export const setSelectedAdBanServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDADBANSERVICELOADING,
    data
});

export const refreshSelectedAdBanService = (data) => (dispatch) => dispatch({
    type: REFRESH_SELECTEDADBANSERVICE,
    data
});

export const refreshAdBanServices = () => (dispatch) => dispatch({
    type: REFERSH_ADBANSERVICES
});

export const adBanServiceUpdated = (data) => (dispatch) => dispatch({
    type: ADBANSERVICEUPDATED,
    data
});

export const adBanServiceDeleted = (serviceId) => (dispatch) => dispatch({
    type: ADBANSERVICEDELETED,
    data: serviceId
});