import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import 'src/components/GuideWidget/styles.css';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        padding: '2.5%',
        maxWidth: '100%',
        flexGrow: 1,
        backgroundColor: '#fff',
        maxHeight: '100%'
    },
    header: {
        paddingTop: '20px',
        paddingBottom: '10px'
    },
    messagesDelivery: {
        display: 'inline-block',
        fontSize: '15px',
        paddingBottom: '10px'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: 20
    }
}));

const stats = [
    { lastMonth: { inboundSms: 0, outboundSms: 0, outboundSmsDelivered: 0, inboundWA: 0, outboundWA: 0, outboundWADelivered: 0 } },
    { thisMonth: { inboundSms: 0, outboundSms: 0, outboundSmsDelivered: 0, inboundWA: 0, outboundWA: 0, outboundWADelivered: 0 } },
    { total: { outbound: 0, outboundDelivered: 0, deliveryPercentage: 0.0 } },
    { updated: null }
];

const dateFormatter = (value) => {
    const date = moment.utc(value).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

function SummaryWidget() {
    const classes = useStyles();

    const [statistics, setStatistics] = React.useState({});
    const { services: oneWayServices, servicesLoading: oneWayServicesLoading } = useSelector((state) => state.onewaymessaging);
    const { services: twoWayServices, servicesLoading: twoWayServicesLoading } = useSelector((state) => state.twowaymessaging);
    const { services: groupServices, servicesLoading: groupServicesLoading } = useSelector((state) => state.groupmessaging);
    const { services: codeServices, servicesLoading: codeServicesLoading } = useSelector((state) => state.codeservice);
    const { services: carrierBillingServices, servicesLoading: carrierBillingServicesLoading } = useSelector((state) => state.carrierbilling);
    const { messageTraffic, messageTrafficLoading } = useSelector((state) => state.statistics);

    React.useEffect(() => {
        const resp = messageTraffic?.reduce?.((prev, current) => {
            // payload only contains message statistics from last month to date
            const _key = moment(current?.date).isSame(new Date(), 'month') ? 'thisMonth' : 'lastMonth';

            // SMS messages
            const smsInboundAmount = current?.statistics?.SMS?.inbound ?? 0;
            const smsOutboundAmount = current?.statistics?.SMS?.outbound ?? 0;
            const smsOutboundDeliveredAmount = current?.statistics?.SMS?.delivered ?? 0;

            // WhatsApp messages
            const waInboundAmount = current?.statistics?.Whatsapp?.inbound ?? 0;
            const waOutboundAmount = current?.statistics?.Whatsapp?.outbound ?? 0;
            const waOutboundDeliveredAmount = current?.statistics?.Whatsapp?.delivered ?? 0;

            // total
            const totalOutbound = smsOutboundAmount + waOutboundAmount;
            const totalOutboundDelivered = smsOutboundDeliveredAmount + waOutboundDeliveredAmount;

            const prevDate = prev?.updated ? moment(new Date(prev?.updated)) : moment(new Date(1, 1, 1));
            const currentDate = moment(new Date(current?.date));

            return {
                ...prev,
                [_key]: {
                    inboundSms: (prev?.[_key]?.inboundSms ?? 0) + smsInboundAmount,
                    outboundSms: (prev?.[_key]?.outboundSms ?? 0) + smsOutboundAmount,
                    outboundSmsDelivered: (prev?.[_key]?.outboundSmsDelivered ?? 0) + smsOutboundDeliveredAmount,
                    inboundWA: (prev?.[_key]?.inboundWA ?? 0) + waInboundAmount,
                    outboundWA: (prev?.[_key]?.outboundWA ?? 0) + waOutboundAmount,
                    outboundWADelivered: (prev?.[_key]?.outboundWADelivered ?? 0) + waOutboundDeliveredAmount
                },
                total: {
                    outbound: (prev?.total?.outbound ?? 0) + totalOutbound,
                    outboundDelivered: (prev?.total?.outboundDelivered ?? 0) + totalOutboundDelivered,
                    deliveryPercentage: ((((prev?.total?.outboundDelivered ?? 0) + totalOutboundDelivered) / ((prev?.total?.outbound ?? 0) + totalOutbound)) * 100)
                },
                updated: currentDate > prevDate ? currentDate : prevDate
            };
        }, stats) ?? stats;

        setStatistics(resp);
    }, [messageTraffic]);

    const options = {
        responsive: true,
        animation: {
            duration: 500,
            easing: 'linear'
        },
        scales: {
            yAxes: [{
                ticks: {
                    suggestedMin: 0,
                    precision: 0
                }
            }]
        }
    };

    const labels = ['Last month', 'This month'];

    const data = {
        labels,
        datasets: [
            {
                label: 'SMS sent',
                data: [statistics?.lastMonth?.outboundSms ?? 0, statistics?.thisMonth?.outboundSms ?? 0],
                backgroundColor: [
                    'rgba(25, 132, 197, 1)',
                    'rgba(25, 132, 197, 1)'
                ],
                borderRadius: 50
            },
            {
                label: 'WA sent',
                data: [statistics?.lastMonth?.outboundWA ?? 0, statistics?.thisMonth?.outboundWA ?? 0],
                backgroundColor: [
                    'rgba(89, 158, 148, 1)',
                    'rgba(89, 158, 148, 1)'
                ],
            },
            {
                label: 'SMS received',
                data: [statistics?.lastMonth?.inboundSms ?? 0, statistics?.thisMonth?.inboundSms ?? 0],
                backgroundColor: [
                    'rgba(34, 167, 240, 1)',
                    'rgba(34, 167, 240, 1)'
                ],
            },
            {
                label: 'WA received',
                data: [statistics?.lastMonth?.inboundWA ?? 0, statistics?.thisMonth?.inboundWA ?? 0],
                backgroundColor: [
                    'rgba(108, 212, 197, 1)',
                    'rgba(108, 212, 197, 1)'
                ],
            }
        ]
    };

    const loading = oneWayServicesLoading || twoWayServicesLoading || groupServicesLoading || codeServicesLoading || carrierBillingServicesLoading;

    return (
        <div className={classes.root}>
            <MuiThemeProvider theme={(theme) => ({
                ...theme,
                overrides: {
                    ...theme.overrides,
                    MuiTableCell: {
                        root: {
                            padding: '5px',
                            fontSize: '15px'
                        }
                    }
                }
            })}
            >
                {messageTrafficLoading ? <DelayedLinearProgress /> : (
                    <Alert severity="info">
                        {`Summary widget updated ${dateFormatter(statistics?.updated)}.`}
                    </Alert>
                )}

                {loading && <DelayedLinearProgress />}

                <h2 className={classes.header}>Active services</h2>
                <TableContainer style={{ flexGrow: 1 }}>
                    <Table xs={6} sm={3}>
                        <colgroup>
                            <col width="30%" />
                            <col width="20%" />
                            <col width="30%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">1-way Messaging Services:</TableCell>
                                <TableCell align="left">
                                    {oneWayServices?.filter((item) => item?.serviceState === 'Active' && moment(item?.startDate).isBefore(moment()) && (item?.endDate ? moment(item?.endDate).isAfter(moment()) : true))?.length ?? 0}
                                </TableCell>
                                <TableCell align="left">Carrier Billing Services:</TableCell>
                                <TableCell align="left">
                                    {carrierBillingServices?.length}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">2-way Messaging Services:</TableCell>
                                <TableCell align="left">
                                    {twoWayServices?.filter((item) => item?.serviceState === 'Active' && moment(item?.startDate).isBefore(moment()) && (item?.endDate ? moment(item?.endDate).isAfter(moment()) : true))?.length ?? 0}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Group Services:</TableCell>
                                <TableCell align="left">
                                    {groupServices?.filter((item) => item?.serviceState === 'Active' && moment(item?.startDate).isBefore(moment()) && (item?.endDate ? moment(item?.endDate).isAfter(moment()) : true))?.length ?? 0}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">Code Services:</TableCell>
                                <TableCell align="left">
                                    {codeServices?.length ?? 0}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <h2 className={classes.header}>Messages</h2>
                <span className={classes.messagesDelivery}>
                    Message delivery during this and last month:
                    <b>
                        {` ${statistics?.total?.deliveryPercentage?.toFixed(2) ?? 0}%`}
                    </b>
                </span>

                <TableContainer style={{ flexGrow: 1 }}>
                    <Table xs={6} sm={3}>
                        <colgroup>
                            <col width="30%" />
                            <col width="20%" />
                            <col width="30%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">SMS sent last month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.lastMonth?.outboundSms ?? 0}
                                </TableCell>
                                <TableCell align="left">WA sent last month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.lastMonth?.outboundWA ?? 0}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">SMS sent this month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.thisMonth?.outboundSms ?? 0}
                                </TableCell>
                                <TableCell align="left">WA sent this month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.thisMonth?.outboundWA ?? 0}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">SMS received last month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.lastMonth?.inboundSms ?? 0}
                                </TableCell>
                                <TableCell align="left">WA received last month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.lastMonth?.inboundWA ?? 0}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="left">SMS received this month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.thisMonth?.inboundSms ?? 0}
                                </TableCell>
                                <TableCell align="left">WA received this month:</TableCell>
                                <TableCell align="left">
                                    {statistics?.thisMonth?.inboundWA ?? 0}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className={classes.chartContainer}>
                    <Bar options={options} data={data} />
                </div>
            </MuiThemeProvider>
        </div>
    );
}

export default SummaryWidget;