export const sendStatusData = [
    {
        status: 'Scheluded',
        description: 'Scheluded.'
    },
    {
        status: 'Pending',
        description: 'Message is being processed and sent to the outbound queues.'
    },
    {
        status: 'Finished',
        description: 'Sending process completed.'
    }
];

export const deliveryStatusData = [
    {
        status: 'Acknowledged',
        description: 'Message confirmed to be on its way.'
    },
    {
        status: 'Created',
        description: 'Message is created.'
    },
    {
        status: 'Delivered',
        description: 'The message has been delivered and the customer`s phone has acknowledged the message as received.'
    },
    {
        status: 'Delivery Failed',
        description: 'Message delivery has failed.'
    },
    {
        status: 'Queued',
        description: 'The message is in the queue.'
    },
    {
        status: 'Resend',
        description: 'The message has been resent.'
    },
    {
        status: 'Scheluded',
        description: 'The message is scheduled.'
    },
    {
        status: 'Sent',
        description: 'The message is delivered to the operator.'
    },
    {
        status: 'Send Failed',
        description: 'Message sending failed. Customer has blocking service, or the customer has a Globotel subscription etc. Operator did not accept the message.'
    },
    {
        status: 'Send Cancelled',
        description: 'Message delivery is cancelled, message sending blocked to the recipient, either by operator or SmartDialog Ad Ban.'
    }
];

export const deliveryReasonData = [
    {
        reason: 'Billing Error',
        description: 'Error related to billing, occurs in prepaid subscriptions.'
    },
    {
        reason: 'Expired',
        description: 'Message delivery has expired. The customer mobile subscription has not been online.'
    },
    {
        reason: 'Limit Exceeded',
        description: 'Number limit. Number of message resends exceeded.'
    },
    {
        reason: 'Network Error',
        description: 'Network / connection error.'
    },
    {
        reason: 'No Error',
        description: 'No error. Message is delivered.'
    },
    {
        reason: 'Phone Error',
        description: 'An error related to the recipient`s phone device.'
    },
    {
        reason: 'Syntax Error',
        description: 'Syntax error, the format of the message is incorrect, or the sender is incorrect.'
    },
    {
        reason: 'System Error',
        description: 'System error with operator or in SmartDialog.'
    },
    {
        reason: 'Subsriber Error',
        description: 'An error related to the phone number or subscription, e.g. incorrect phone number or closed subscription. The phone number should always be specified in 358 format.'
    },
    {
        reason: 'Unknown reason',
        description: 'Unknow error, contact Arena Interactive support.'
    },
    {
        reason: 'Unknown',
        description: 'Unknow error, contact Arena Interactive support.'
    }
];

export const sendStatusDataFI = [
    {
        status: 'Scheluded',
        description: 'Ajastettu.'
    },
    {
        status: 'Pending',
        description: 'Lähetyksen käsittely menossa, viestejä lähetetään jonoihin.'
    },
    {
        status: 'Finished',
        description: 'Lähetys on valmis.'
    }
];

export const deliveryStatusDataFI = [
    {
        status: 'Acknowledged',
        description: 'Viesti vahvistettu olevan matkalla.'
    },
    {
        status: 'Created',
        description: 'Viesti on luotu.'
    },
    {
        status: 'Delivered',
        description: 'Viesti on toimitettu perille ja asiakkaan päätelaite on kuitannut viestin vastaanotetuksi.'
    },
    {
        status: 'Delivery Failed',
        description: 'Viestin toimitus epäonnistui.'
    },
    {
        status: 'Queued',
        description: 'Viesti on jonossa.'
    },
    {
        status: 'Resend',
        description: 'Viesti on uudelleen lähetetty.'
    },
    {
        status: 'Scheluded',
        description: 'Viesti on ajastettu.'
    },
    {
        status: 'Sent',
        description: 'Viesti on toimitettu operaattorille.'
    },
    {
        status: 'Send Failed',
        description: 'Viestin lähetys epäonnistunut, puhelinnumerossa on esim. palvelun esto tai asiakkaalla on Globotel -liittymä.'
    },
    {
        status: 'Send Cancelled',
        description: 'Viestin toimitus peruttu, viestilähetys estetty liittymään.'
    }
];

export const deliveryReasonDataFI = [
    {
        reason: 'Billing Error',
        description: 'Laskutukseen liittyvä virhe, esiintyy prepaid liittymissä.'
    },
    {
        reason: 'Expired',
        description: 'Viestin toimitus vanhentunut. Liittymä ei ole ollut verkkoyhteydessä.'
    },
    {
        reason: 'Limit Exceeded',
        description: 'Lukumäärärajoitus, esim. viestin uudelleen lähetyksen määrä ylittynyt.'
    },
    {
        reason: 'Network Error',
        description: 'Verkko/yhteysvirhe.'
    },
    {
        reason: 'No Error',
        description: 'Ei virhettä. Viesti toimitettu perille.'
    },
    {
        reason: 'Phone Error',
        description: 'Vastaanottajan puhelinlaitteeseen liittyvä virhe.'
    },
    {
        reason: 'Syntax Error',
        description: 'Syntaksivirhe, viestin muoto virheellinen, tai lähettäjätieto on virheellinen.'
    },
    {
        reason: 'System Error',
        description: 'Järjestelmävirhe operaattorilla tai SmartDialogissa.'
    },
    {
        reason: 'Subsriber Error',
        description: 'Puhelinnumeroon tai liittymään liittyvä virhe, esim. virheellinen puhelinnumero tai suljettu liittymä. Puhelinnumero tulisi aina määritellä 358-muodossa.'
    },
    {
        reason: 'Unknown reason',
        description: 'Tuntematon virhe, ole yhteydessä Arenan supporttiin.'
    },
    {
        reason: 'Unknown',
        description: 'Tuntematon virhe, ole yhteydessä Arenan supporttiin.'
    }
];