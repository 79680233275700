import { RESET_REDUCER_STATE, SET_NAVIGATE_URL } from 'src/actions';

const initialState = {
    navigateUrl: null
};

const routerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAVIGATE_URL: {
            return {
                ...state,
                navigateUrl: action?.data ?? null
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default routerReducer;