import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import MUIDataTable from 'mui-datatables';
import ScheduleIcon from '@material-ui/icons/ScheduleRounded';
import { Box, DialogContent } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/en-gb';
import DialogTitleWithNavigation from 'src/components/MessageEvents/DialogTitleWithNavigation';

export const FilterDialogFooter = () => <Box minWidth={300} />;

const useStyles = makeStyles((theme) => ({
    header: {
        width: '100%',
        padding: theme.spacing(2)
    },
    chip: {
        marginRight: theme.spacing(1),
        border: '1px solid #e2e6e9'
    }
}));

const OneWayPopupDialog = ({ onClose, open, recipients, content, createdDate, navigateToNextEvent, navigateToPreviousEvent }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const dateFormatter = (rowData) => {
        const date = moment.utc(rowData).local();
        return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
    };

    const timestampFormatter = (rowData) => {
        const date = moment.utc(rowData).local();
        return date.isValid() ? date.format('D.M.YYYY HH:mm:ss') : '';
    };

    const columns = [
        {
            name: 'address',
            label: 'Recipient',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: 'partCount',
            label: 'Amount of messages',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'status',
            label: 'Delivery status',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'reason',
            label: 'Delivery reason',
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: 'timestamp',
            label: 'Timestamp',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    timestampFormatter(value)
                )
            }
        }
    ];

    const options = {
        elevation: 0,
        print: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        downloadOptions: {
            filename: 'recipients.csv',
            separator: ';',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true
            }
        },
        customFilterDialogFooter: () => <FilterDialogFooter />,
        responsive: 'standard'
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="recipients popup" open={open} maxWidth={content?.length > 255 ? 'md' : 'lg'} fullWidth>
            <DialogTitleWithNavigation
                title="Recipients"
                onBackClick={navigateToPreviousEvent}
                onNextClick={navigateToNextEvent}
            />
            <DialogContent>
                <div className={classes.header}>
                    <Typography gutterBottom variant="body1">
                        {content}
                    </Typography>
                    <br />
                    <Chip icon={<ScheduleIcon />} className={classes.chip} label={`Created: ${dateFormatter(createdDate)}`} />
                </div>
                <Divider />
                <MuiThemeProvider theme={(theme) => ({
                    ...theme,
                    overrides: {
                        ...theme.overrides,
                        MUIDataTableFilter: {
                            resetLink: {
                                display: 'none'
                            }
                        },
                        MUIDataTableFilterList: {
                            chip: {
                                marginBottom: '10px',
                                border: '1px solid #e2e6e9'
                            }
                        },
                        MUIDataTable: {
                            responsiveBase: {
                                maxHeight: '50vh !important',
                                display: 'block'
                            }
                        }
                    }
                })}
                >
                    <MUIDataTable
                        data={recipients}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </DialogContent>
        </Dialog>
    );
};

OneWayPopupDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    recipients: PropTypes.array,
    content: PropTypes.string,
    createdDate: PropTypes.string,
    navigateToNextEvent: PropTypes.func,
    navigateToPreviousEvent: PropTypes.func
};

export default OneWayPopupDialog;