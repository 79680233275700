const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Description',
        description: 'A description of the service.'
    },
    {
        field: 'Type',
        description: 'Choose between RKioski (Ärrä-koodi) and Generic codes'
    },
    {
        field: 'Out of codes message',
        description: 'This message will be sent to the end-user when there is no more unused codes.'
    }
];

export default infoData;