import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory } from 'react-router-dom';
import PollStackedBarChart from 'src/components/Charts/PollStackedBarChart';
import { theme } from 'src/theme';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1),
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: 20
    },
    chip: {
        margin: theme.spacing(1)
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

function TwoWayPollStatistics({ data, chips, showBy, channel, hasIvr, isWidget, serviceId }) {
    const classes = useStyles();
    const history = useHistory();
    const { customerId } = useParams();
    const datasets = [];

    if (channel === 'all' || channel === 'messages') {
        datasets.push({
            label: 'Messages',
            maxBarThickness: 30,
            backgroundColor: theme.palette.primary.light,
            ...(showBy === 'amount' ? { data: data?.map((d) => d.messages) } : {}),
            ...(showBy === 'percentage' ? { data: channel === 'all' ? data?.map((d) => d.messagesTotalPercentage) : data?.map((d) => d.messagesPercentage) } : {})
        });
    }

    if ((channel === 'all' && hasIvr) || channel === 'ivr') {
        datasets.push({
            label: 'IVR',
            maxBarThickness: 30,
            backgroundColor: theme.palette.primary.main,
            ...(showBy === 'amount' ? { data: data?.map((d) => d.ivr) } : {}),
            ...(showBy === 'percentage' ? { data: channel === 'all' ? data?.map((d) => d.ivrTotalPercentage) : data?.map((d) => d.ivrPercentage) } : {})
        });
    }

    const barChartData = {
        labels: data?.map((d) => d.candidate),
        datasets
    };

    const handleOpenClick = () => {
        history.push(`/${customerId}/services/twowaymessaging/info/${serviceId}/events`);
    };

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c) => {
                    if (isWidget && c.includes('Service name')) {
                        return (
                            <Chip key={c} className={classes.chip} label={c} onClick={handleOpenClick} />
                        );
                    }
                    return (
                        <Chip key={c} className={classes.chip} label={c} />
                    );
                })}
                <Chip className={classes.chip} label={`Widget updated: ${dateFormatter(moment())}`} />
            </div>
            <div className={classes.chartContainer}>
                <PollStackedBarChart
                    data={barChartData}
                    showBy={showBy}
                    channel={channel}
                    hasIvr={hasIvr}
                />
            </div>
        </Card>
    );
}

TwoWayPollStatistics.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    showBy: PropTypes.string,
    channel: PropTypes.string,
    hasIvr: PropTypes.bool,
    isWidget: PropTypes.bool,
    serviceId: PropTypes.string
};

export default TwoWayPollStatistics;