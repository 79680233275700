import React from 'react';
import PropTypes from 'prop-types';
import ImageIcon from '@material-ui/icons/ImageRounded';

const FallbackImage = ({ src, alt, fallbackImage, ...rest }) => {
    const [error, setError] = React.useState(false);
    const onError = () => {
        setError(true);
    };

    if (error) {
        return fallbackImage;
    }
    return <img src={src} alt={alt} {...rest} onError={onError} />;
};

FallbackImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    fallbackImage: PropTypes.node
};

FallbackImage.defaultProps = {
    alt: '',
    fallbackImage: <ImageIcon />
};

export default FallbackImage;