/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useOutboundMessageDoughnutData } from 'src/components/OneWayMessaging/OneWayServiceStatistics/hooks';
import DoughnutChart from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChart';
import DoughnutChartStats from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChartStats';
import InfoChip from 'src/components/InfoChip';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        overflow: 'hidden',
        padding: theme.spacing(1, 2, 2, 2)
    },
    statsContainer: {
        textAlign: 'center',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

function OneWayStatisticsDoughnut({
    data, chips, showBy, isWidget
}) {
    const classes = useStyles();
    const chartData = useOutboundMessageDoughnutData(data, showBy);

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                <InfoChip className={classes.chip} title="Outbound Messages Info" content="This report is showing the statistics of message delivery status. More detailed information is presented in Message Events." />
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
                {isWidget && (
                    <Chip className={classes.chip} label={`Widget updated: ${dateFormatter(moment())}`} />
                )}
            </div>
            <div className={classes.chartContainer}>
                <DoughnutChart
                    data={chartData}
                    showBy={showBy}
                />
            </div>
            <div className={classes.statsContainer}>
                <DoughnutChartStats
                    data={chartData}
                    showBy={showBy}
                />
            </div>
        </Card>
    );
}

OneWayStatisticsDoughnut.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    showBy: PropTypes.string,
    isWidget: PropTypes.bool
};

export default OneWayStatisticsDoughnut;