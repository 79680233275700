import React from 'react';
import copy from 'copy-to-clipboard';

export default function useCopyToClipboard(resetInterval = null) {
    const [isCopied, setCopied] = React.useState(false);

    const handleCopy = React.useCallback((text) => {
        if (typeof text === 'string' || typeof text === 'number') {
            copy(text.toString());
            setCopied(true);
        } else {
            // Only string and number supported
            setCopied(false);
        }
    }, []);

    const resetCopy = React.useCallback(() => setCopied(false), []);

    React.useEffect(() => {
        let timeout;
        if (isCopied && resetInterval && typeof resetInterval === 'number') {
            timeout = setTimeout(() => setCopied(false), resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied, resetInterval]);

    return { isCopied, handleCopy, resetCopy };
}