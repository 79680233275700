import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/styles';
import React from 'react';
import NewWindow from 'react-new-window';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { create } from 'jss';
import { CssBaseline } from '@material-ui/core';
import { theme } from 'src/theme';
import PropTypes from 'prop-types';

const PopupWindow = ({ children, ...props }) => {
    const [state, setState] = React.useState();
    const [jssContainerRef, setJssContainerRef] = React.useState(null);

    React.useEffect(() => {
        if (jssContainerRef) {
            setState({
                ready: true,
                jss: create({
                    ...jssPreset(),
                    insertionPoint: jssContainerRef
                }),
                sheetsManager: new Map()
            });
        }
    }, [jssContainerRef]);

    return (
        <NewWindow
            {...props}
            closeOnUnmount
            copyStyles={false}
        >
            <div style={{ height: '100%', display: 'flex' }}>
                <div id="jss-container" ref={(ref) => setJssContainerRef(ref)} />
                {state?.ready && (
                    <StylesProvider
                        jss={state.jss}
                        sheetsManager={state.sheetsManager}
                    >
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <ErrorBoundary>
                                {children}
                            </ErrorBoundary>
                        </ThemeProvider>
                    </StylesProvider>
                )}
            </div>
        </NewWindow>
    );
};

PopupWindow.propTypes = {
    children: PropTypes.any
};

export default PopupWindow;