export const SET_ONEWAYSERVICES = 'SET_ONEWAYSERVICES';
export const SET_ONEWAYSERVICE = 'SET_ONEWAYSERVICE';

export const SET_ONEWAYSERVICES_LOADING = 'SET_ONEWAYSERVICES_LOADING';

export const ONEWAYSERVICEUPDATED = 'ONEWAYSERVICEUPDATED';
export const ONEWAYSERVICEDELETED = 'ONEWAYSERVICEDELETED';

export const REFERSH_ONEWAYSERVICES = 'REFERSH_ONEWAYSERVICES';

export const setOneWayServices = (data) => (dispatch) => dispatch({
    type: SET_ONEWAYSERVICES,
    data
});

export const setOneWayService = (data) => (dispatch) => dispatch({
    type: SET_ONEWAYSERVICE,
    data
});

export const setOneWayServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_ONEWAYSERVICES_LOADING,
    data
});

export const refreshOneWayServices = () => (dispatch) => dispatch({
    type: REFERSH_ONEWAYSERVICES
});

export const oneWayServiceUpdated = (data) => (dispatch) => dispatch({
    type: ONEWAYSERVICEUPDATED,
    data
});

export const oneWayServiceDeleted = (serviceId) => (dispatch) => dispatch({
    type: ONEWAYSERVICEDELETED,
    data: serviceId
});