export const SET_PROFILE_DRAWER_OPEN = 'SET_PROFILE_DRAWER_OPEN';
export const SET_PROFILE_DRAWER_SETTINGS_OPEN = 'SET_PROFILE_DRAWER_SETTINGS_OPEN';
export const SET_PROFILE_DRAWER_SETTINGS_TAB = 'SET_PROFILE_DRAWER_SETTINGS_TAB';

export const ServiceWorkerUpdateAvailable = () => (dispatch) => dispatch({ type: 'SW_UPDATE_AVAILABLE' });

export const ServiceWorkerUpdated = () => (dispatch) => {
    dispatch({ type: 'SW_UPDATED' });
    return Promise.resolve();
};

export const OpenProfileDrawer = (data) => (dispatch) => dispatch({
    type: SET_PROFILE_DRAWER_OPEN,
    data
});

export const OpenProfileDrawerSettings = (data) => (dispatch) => dispatch({
    type: SET_PROFILE_DRAWER_SETTINGS_OPEN,
    data
});

export const OpenProfileDrawerSettingsTab = (data) => (dispatch) => dispatch({
    type: SET_PROFILE_DRAWER_SETTINGS_TAB,
    data
});

export const OpenNotificationSettings = () => (dispatch) => {
    dispatch(OpenProfileDrawerSettings(true));
    dispatch(OpenProfileDrawerSettingsTab('Notifications'));
};