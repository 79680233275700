const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Service state',
        description: 'The state of the service, (Active or Passive).'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Message type',
        description: 'The message protocol used when sending messages.'
    },
    {
        field: 'Custom id name',
        description: 'If a contact should receive specific message content based on a "custom property" in their contact information, specify the property name here. Optionally used by "message templates".'
    },
    {
        field: 'Start date',
        description: 'Service valid from this date.'
    },
    {
        field: 'End date',
        description: 'Service valid thru this date.'
    },
    {
        field: 'Modified date',
        description: 'The time the service was last modified.'
    },
    {
        field: 'Sending interval',
        description: 'The interval used when sending messages, Daily / Weekly.'
    },
    {
        field: 'Interval settings',
        description: 'Weekly interval weekdays that is configured.'
    },
    {
        field: 'Sending time',
        description: 'The time of day when the messages will be sent. If a customer wants all messages to be sent at, for instance, 11:50:00.'
    },
    {
        field: 'Sending frequency',
        description: 'The sending frequency. Determines how many of the received contacts should receive a survey message. If set to 1, every contact gets a message. If set to 3, every third contact receives a message.'
    },
    {
        field: 'Daily send message limit',
        description: 'Daily message max limit for a service.'
    },
    {
        field: 'Recipient limit',
        description: 'Recipient message limit. Should be specified along with "Recipient limit days". For instance 1 message / 30 days.'
    },
    {
        field: 'Recipient limit days',
        description: 'Amount of day that a recipient should receive X amount of messages. Should be specified along with "Recipient limit". For instance 1 message / 30 days.'
    },
    {
        field: 'Response time',
        description: 'The amount of hours a user has time to respond to a message.'
    },
    {
        field: 'Contacts´ Import Settings: Format',
        description: 'The format of the input data that is fetched, so we know how to read it.'
    },
    {
        field: 'Contacts´ Import Settings: Interval',
        description: 'The processing interval specifying when the input data should be processed. Daily, Weekly, Monthly or Polling.'
    },
    {
        field: 'Contacts´ Import Settings: Interval settings',
        description: 'Weekly contains weekdays. Monthly contains the days in a month. Polling contains one value, the polling minutes. (Empty if Daily)'
    },
    {
        field: 'Contacts´ Import Settings: Processing time',
        description: 'The time of day when the input data should start processing. For instance, 19:50:00.'
    },
    {
        field: 'Contacts´ Import Settings: Request protocol',
        description: 'The request protocol that will be used when fetching the input data.'
    },
    {
        field: 'Output settings: Format',
        description: 'The format of the output data that is sent.'
    },
    {
        field: 'Output settings: Interval',
        description: 'The processing interval specifying when the output data should be processed. Daily, Weekly, Monthly or PreviousMonths.'
    },
    {
        field: 'Output settings: Interval settings',
        description: 'Weekly contains weekdays. Monthly contains the days in a month. PreviousMonths contains one value, the amount of previous months from where data should be fetched, supported values 1-3 (one to three months of data). (Empty if Daily.)'
    },
    {
        field: 'Output settings: Processing time',
        description: 'The time of day when the output data should start processing. For instance, 21:30:00.'
    },
    {
        field: 'Output settings: Request protocol',
        description: 'The request protocol that will be used when sending the output data.'
    },
    {
        field: 'Output settings: Generate empty output data',
        description: 'If the customer wants empty output data to be generated. Empty output data is not created by default.'
    },
    {
        field: 'Messaging templates: Default',
        description: 'If set to true, will be used as the initial survey message template.'
    },
    {
        field: 'Messaging templates: Sender',
        description: 'The address (number/email) that will be used as the sender of the message.'
    },
    {
        field: 'Messaging templates: Message content',
        description: 'The message content.'
    }
];

export default infoData;