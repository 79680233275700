import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/styles';
import {
    Paper, Typography, Link, Button, colors
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 420,
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: theme.spacing(1),
        margin: theme.spacing(3),
        outline: 'none',
        zIndex: 2000,
        backgroundColor: theme.palette.background.blue,
    },
    media: {
        padding: theme.spacing(1, 2),
        height: 180,
        textAlign: 'center',
        '& > img': {
            height: '100%',
            width: 'auto'
        }
    },
    header: {
        fontSize: '1.5em',
        color: '#fff',
        marginBottom: theme.spacing(1.5)
    },
    text: {
        color: '#fff'
    },
    link: {
        color: '#fff'
    },
    content: {
        padding: theme.spacing(1, 2)
    },
    actions: {
        padding: theme.spacing(2)
    },
    agreeButton: {
        width: '100%',
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    overlayRoot: {
        zIndex: 1999,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(153, 153, 153, .2)'
    }
}));

const cookieName = 'smartdialog_cookie_consent';

function CookiesNotification() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        Cookies.set(cookieName, 'true', { expires: new Date(2050, 1, 1) });
        setOpen(false);
    };

    useEffect(() => {
        const consent = Cookies.get(cookieName);

        if (!consent) {
            setOpen(true);
        }
    }, []);

    if (!open) {
        return null;
    }

    return (
        <div className={classes.overlayRoot}>
            <Paper
                className={classes.root}
                elevation={3}
            >
                <div className={classes.content}>
                    <Typography variant="body1" className={classes.header}>Cookies</Typography>
                    <Typography variant="body1" className={classes.text}>
                        We use cookies and similar technologies to enhance your browsing experience. Read our
                        {' '}
                        <Link
                            className={classes.link}
                            component="a"
                            href="https://www.arenainteractive.fi/privacypolicy"
                            target="_blank"
                        >
                            Privacy Policy
                        </Link>
                        .
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        className={classes.agreeButton}
                        color="primary"
                        onClick={handleClose}
                        variant="contained"
                    >
                        I understand
                    </Button>
                </div>
            </Paper>
        </div>
    );
}

export default CookiesNotification;