import React from 'react';
import { ListItem, ListItemIcon, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NotificationContentFactory from 'src/components/Notifications/NotificationContentFactory';

const useStyles = makeStyles((theme) => ({
    notificationListItemRoot: ({ onClick }) => ({
        cursor: onClick ? 'pointer' : 'unset',
        '& > li': {
            flex: 1
        },
        '&& .MuiListItemSecondaryAction-root': {
            display: 'none'
        },
        marginBottom: 5
    }),
    notificationListItem: {
        padding: 0,
        minHeight: 60,
        display: 'flex',
        overflow: 'hidden'
    },
    notificationListItemIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 50,
        height: '100%'
    },
    notificationHeader: ({ read }) => ({
        fontWeight: read ? 100 : 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    content: {
        flex: 1,
        minWidth: 0,
        height: '100%',
        padding: 5,
        paddingRight: theme.spacing(1)
    },
    firstContentRow: {
        height: '50%',
        alignItems: 'center',
        display: 'flex'
    },
    secondContentRow: {
        height: '50%',
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.spacing(2),
        '& > div > *:not(:last-child)': {
            marginRight: 5
        }
    },
    contentRowItem: ({ isDialog }) => ({
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 0,
        minHeight: 0,
        ...isDialog ? {} : {
            '& > p': {
                display: '-webkit-box',
                '-webkit-line-clamp': 4,
                '-webkit-box-orient': 'vertical'
            }
        }
    }),
    contentRowItemPublished: ({ read, isDialog }) => ({
        minWidth: 100,
        textAlign: 'center',
        '&& p': {
            fontWeight: read ? 100 : 600,
            display: isDialog ? 'none' : 'unset'
        }
    }),
    unreadIndicator: {
        position: 'absolute',
        width: 10,
        height: '100%',
        backgroundColor: ({ read, level }) => {
            if (read) {
                return 'inherit';
            }
            switch (level) {
                case 'Information':
                    return theme.palette.primary.main;
                case 'Warning':
                    return theme.palette.warning.main;
                case 'Error':
                    return theme.palette.error.main;
                default:
                    return theme.palette.primary.main;
            }
        }
    },
    notificationLinkButton: {
        alignSelf: 'flex-end'
    }
}));

const NotificationListItem = React.forwardRef(({
    className,
    icon,
    iconStyle,
    notification = {},
    onClick,
    isDialog
}, ref) => {
    const {
        header = '{notification}',
        created = new Date().toJSON(),
        read = false,
        level = 'Information'
    } = notification;

    const classes = useStyles({ read, level, isDialog, onClick });

    return (
        <Paper ref={ref} className={clsx(classes.notificationListItemRoot, className)} onClick={onClick} elevation={4}>
            <ListItem
                className={classes.notificationListItem}
                disableGutters
                onClick={() => { }}
            >
                <div className={classes.unreadIndicator} />
                <div className={classes.content}>
                    <div className={classes.firstContentRow}>
                        <ListItemIcon style={iconStyle} className={classes.notificationListItemIcon}>
                            {icon}
                        </ListItemIcon>
                        <div className={classes.contentRowItem}>
                            <Typography className={classes.notificationHeader}>{header ?? ''}</Typography>
                        </div>
                        <div className={classes.contentRowItemPublished}><Typography>{moment(created).fromNow()}</Typography></div>
                    </div>
                    <div className={classes.secondContentRow}>
                        <NotificationContentFactory classes={classes} notification={notification} />
                    </div>
                </div>
            </ListItem>
        </Paper>
    );
});

NotificationListItem.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.node.isRequired,
    iconStyle: PropTypes.object.isRequired,
    notification: PropTypes.shape({
        header: PropTypes.string,
        content: PropTypes.string,
        created: PropTypes.string,
        read: PropTypes.bool
    }).isRequired,
    onClick: PropTypes.func,
    isDialog: PropTypes.bool
};

export default NotificationListItem;