import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Avatar,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    ClickAwayListener,
    InputAdornment,
    Card,
    colors,
    Typography
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'src/components/Snackbar';
import { useUploadAttachment } from 'src/actions/actionHooks';
import { DialogMessageQueued, UpdateConversationMessage } from 'src/actions';
import { useParams } from 'react-router';
import MessageTemplateSelection, { generateTextAndCursorPositionFromTemplate, MessageTemplateTypes } from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection';
import MessageTemplateSelectionButtons from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection/MessageTemplateSelectionButtons';
import { useThreadChatWindowContext } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import { DeleteRounded } from '@material-ui/icons';
import MessageAttachment from 'src/components/Dialogs/ChatWindow/MessageAttachment';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import AttachFileButton from 'src/components/Dialogs/ChatWindow/AttachFileButton';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        zIndex: 1000
    },
    inputContainer: {
        flexGrow: 1,
        marginLeft: theme.spacing(2)
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(0.5, 2)
    },
    input: {
        width: '100%'
    },
    divider: {
        width: 1,
        height: 24
    },
    fileInput: {
        display: 'none'
    },
    sendButton: {
        alignSelf: 'flex-start'
    },
    attachmentCard: {
        margin: theme.spacing(1, 2),
        backgroundColor: colors.grey[100],
        padding: theme.spacing(1, 3)
    },
    attachmentCardContent: {
        display: 'flex',
        alignItems: 'center'
    },
    attachmentTitle: {
        fontWeight: 600,
        marginBottom: theme.spacing(1)
    },
    attachment: {
        flex: 1
    }
}));

const enabledTemplateTypes = [MessageTemplateTypes.QuickReply];

const ConversationForm = ({ className, ...rest }) => {
    const { error: errorSnack } = useSnackbar();
    const { threadId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { userInitials } = useSelector((state) => state.auth);
    const { selectedTemplateResponse } = useSelector((state) => state.dialog);
    const { conversationMessages, threadsById } = useThreadChatWindowContext();
    const [sendStarted, setSendStarted] = useState(false);
    const [value, setValue] = useState('');
    const [shouldFocusOnInput, setShouldFocusOnInput] = useState(false);
    const [messageTemplateTypeButtonsVisible, setMessageTemplateTypeButtonsVisible] = useState(false);
    const [messageTemplateTypeSelected, setMessageTemplateTypeSelected] = useState(null);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [selectedAttachmentFile, setSelectedAttachmentFile] = React.useState(null);

    const selectedThread = React.useMemo(() => threadsById?.[threadId], [threadId, threadsById]);
    const inputRef = React.useRef();

    const attachmentsEnabled = React.useMemo(() => (
        selectedThread?.status !== 'Expired' &&
        selectedThread?.latestMessage?.protocol === 'Whatsapp'
    ), [selectedThread]);

    const { uploadAttachment, attachment, loading } = useUploadAttachment(selectedAttachmentFile);

    React.useEffect(() => {
        if (selectedTemplateResponse?.template) {
            setValue((currentValue) => {
                const { newValue, newCursorPosition } = generateTextAndCursorPositionFromTemplate(selectedTemplateResponse?.template, currentValue, cursorPosition);
                setShouldFocusOnInput(newCursorPosition ?? 0);
                return newValue;
            });
        }
    }, [selectedTemplateResponse, cursorPosition]);

    // Reset when navigating to another thread
    React.useEffect(() => {
        setValue(conversationMessages?.[threadId] ?? '');
    }, [threadId, conversationMessages]);

    // Reset attachment when navigating to another thread
    React.useEffect(() => {
        setSelectedAttachmentFile(null);
    }, [threadId]);

    React.useEffect(() => {
        if (inputRef?.current && inputRef?.current?.focus && shouldFocusOnInput) {
            inputRef.current.selectionStart = shouldFocusOnInput;
            inputRef.current.selectionEnd = shouldFocusOnInput;
            inputRef.current.focus();
            setShouldFocusOnInput(false);
        }
    }, [inputRef, shouldFocusOnInput]);

    const handleSendClick = async () => {
        setSendStarted(true);
        let attachmentUri = null;

        if (attachment) {
            const { error, errorMessages, payload } = await uploadAttachment();
            if (!error) {
                attachmentUri = payload?.url ?? null;
                setSelectedAttachmentFile(null);
            } else {
                errorSnack(errorMessages);
                setSendStarted(false);
                return;
            }
        }

        dispatch(DialogMessageQueued(selectedThread, value, attachmentUri));
        dispatch(UpdateConversationMessage(threadId, ''));
        setSendStarted(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const onTemplateTypeSelected = (templateType) => {
        // Store cursorposition before opening template selection
        setCursorPosition(inputRef?.current?.selectionStart ?? 0);
        setMessageTemplateTypeSelected(templateType);
    };

    const handleMessageTemplateSelectionClose = () => {
        setMessageTemplateTypeSelected(null);
    };

    const handleClickAway = () => {
        setMessageTemplateTypeButtonsVisible(false);
    };

    const handleRemoveAttachment = () => {
        setSelectedAttachmentFile(null);
    };

    if (messageTemplateTypeSelected) {
        return <MessageTemplateSelection type={messageTemplateTypeSelected} onClose={handleMessageTemplateSelectionClose} />;
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <Avatar
                    alt="Sender Initials"
                >
                    {userInitials}
                </Avatar>
                <div className={classes.inputContainer}>
                    <Paper
                        className={classes.paper}
                        elevation={1}
                    >
                        {attachment && attachment.url && (
                            <Card className={classes.attachmentCard}>
                                <Typography variant="h5" className={classes.attachmentTitle}>{attachment?.file?.name}</Typography>
                                <div className={classes.attachmentCardContent}>
                                    <div className={classes.attachment}><MessageAttachment disabled uri={attachment.url} imageLoaded={() => { }} /></div>
                                    <IconButton onClick={handleRemoveAttachment}>
                                        <DeleteRounded />
                                    </IconButton>
                                </div>
                                {loading && <DelayedLinearProgress />}
                            </Card>
                        )}
                        <TextField
                            inputRef={inputRef}
                            disabled={selectedThread?.status === 'Expired'}
                            className={classes.input}
                            onChange={handleChange}
                            onFocus={() => setMessageTemplateTypeButtonsVisible(true)}
                            onBlur={(e) => dispatch(UpdateConversationMessage(threadId, e.target.value))}
                            placeholder={selectedThread?.status === 'Expired' ? 'Can not respond to an Expired thread' : 'Leave a message'}
                            value={value}
                            multiline
                            minRows={1}
                            maxRows={10}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: attachmentsEnabled && attachment === null && (
                                    <InputAdornment position="end">
                                        <AttachFileButton onChange={(file) => setSelectedAttachmentFile(file)} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Paper>
                    {messageTemplateTypeButtonsVisible && <MessageTemplateSelectionButtons enabledTemplateTypes={enabledTemplateTypes} onTemplateTypeSelected={onTemplateTypeSelected} />}
                </div>
                <Tooltip title="Send" className={classes.sendButton} PopperProps={{ disablePortal: true }}>
                    <div>
                        <IconButton
                            color={value.length > 0 ? 'primary' : 'default'}
                            onClick={handleSendClick}
                            disabled={sendStarted || selectedThread?.status === 'Expired' || value.length === 0}
                        >
                            <SendIcon />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

ConversationForm.propTypes = {
    className: PropTypes.string
};

export default ConversationForm;
