export const SET_CODESERVICES_LOADING = 'SET_CODESERVICES_LOADING';
export const SET_CODESERVICES = 'SET_CODESERVICES';
export const SET_CODESERVICE = 'SET_CODESERVICE';
export const REFERSH_CODESERVICES = 'REFERSH_CODESERVICES';
export const SET_SELECTEDCODESERVICE = 'SET_SELECTEDCODESERVICE';
export const SET_SELECTEDCODESERVICELOADING = 'SET_SELECTEDCODESERVICELOADING';
export const REFRESH_SELECTEDCODESERVICE = 'REFRESH_SELECTEDCODESERVICE';

export const CODESERVICEUPDATED = 'CODESERVICEUPDATED';
export const CODESERVICEDELETED = 'CODESERVICEDELETED';

export const SET_CODESERVICECODES_LOADING = 'SET_CODESERVICECODES_LOADING';
export const SET_CODESERVICECODES = 'SET_CODESERVICECODES';
export const REFERSH_CODESERVICECODES = 'REFERSH_CODESERVICECODES';

export const setCodeServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_CODESERVICES_LOADING,
    data
});

export const setCodeServices = (data) => (dispatch) => dispatch({
    type: SET_CODESERVICES,
    data
});

export const setCodeService = (data) => (dispatch) => dispatch({
    type: SET_CODESERVICE,
    data
});

export const refreshCodeServices = () => (dispatch) => dispatch({
    type: REFERSH_CODESERVICES
});

export const setSelectedCodeService = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDCODESERVICE,
    data
});

export const setSelectedCodeServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDCODESERVICELOADING,
    data
});

export const refreshSelectedCodeService = () => (dispatch) => dispatch({
    type: REFRESH_SELECTEDCODESERVICE
});

export const codeServiceUpdated = (data) => (dispatch) => dispatch({
    type: CODESERVICEUPDATED,
    data
});

export const codeServiceDeleted = (serviceId) => (dispatch) => dispatch({
    type: CODESERVICEDELETED,
    data: serviceId
});

export const setCodeServiceCodesLoading = (data) => (dispatch) => dispatch({
    type: SET_CODESERVICECODES_LOADING,
    data
});

export const setCodeServiceCodes = (data) => (dispatch) => dispatch({
    type: SET_CODESERVICECODES,
    data
});

export const refreshCodeServiceCodes = () => (dispatch) => dispatch({
    type: REFERSH_CODESERVICECODES
});