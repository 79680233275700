import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/codeActions';
import { sortByDateProperty, sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    servicesLoading: true,
    services: [],
    refreshServices: false,
    refreshCodes: false,
    codesLoading: true,
    codes: [],
    selectedCodeService: null,
    selectedCodeServiceLoading: true,
    refreshSelectedCodeService: false
};

const codeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CODESERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case actionTypes.SET_CODESERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_CODESERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                services: Array.isArray(action?.data) ? action.data.sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.REFERSH_CODESERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case actionTypes.SET_CODESERVICECODES_LOADING: {
            return {
                ...state,
                codesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_CODESERVICECODES: {
            return {
                ...state,
                refreshCodes: false,
                codesLoading: false,
                codes: Array.isArray(action?.data) ? action.data.reduce((codes, code) => {
                    if (!Array.isArray(code.events)) {
                        return [
                            ...codes,
                            code
                        ];
                    }

                    // Sort code events by timestamp
                    return [
                        ...codes,
                        {
                            ...code,
                            events: code.events.sort(sortByDateProperty('timestamp', 'desc'))
                        }
                    ];
                }, []).sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.REFERSH_CODESERVICECODES: {
            return {
                ...state,
                refreshCodes: true
            };
        }
        case actionTypes.SET_SELECTEDCODESERVICE: {
            return {
                ...state,
                selectedCodeService: action.data,
                selectedCodeServiceLoading: false,
                refreshSelectedCodeService: false
            };
        }
        case actionTypes.SET_SELECTEDCODESERVICELOADING: {
            return {
                ...state,
                selectedCodeServiceLoading: true
            };
        }
        case actionTypes.REFRESH_SELECTEDCODESERVICE: {
            return {
                ...state,
                refreshSelectedCodeService: true
            };
        }

        case actionTypes.CODESERVICEUPDATED: {
            return {
                ...state,
                services: state.services.reduce((services, service) => {
                    if (service?.id === action?.data?.id) {
                        return [...services, action.data];
                    }
                    return [...services, service];
                }, [])
            };
        }
        case actionTypes.CODESERVICEDELETED: {
            return {
                ...state,
                services: state.services.filter((service) => service?.id !== action?.data)
            };
        }

        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default codeReducer;