import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { resetServicePipelineReducer, SET_ACTION_PIPELINE, SET_MAINKEYWORD_ID, SET_SERVICE_TYPE } from 'src/actions/servicePipelineActions';
import { sortByProperty } from 'src/utils/arrayFunctions';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import { actionTypeOutputDefinitions } from 'src/components/ServicePipelineActions/constants';
import { useLoadGroupServices } from 'src/actions/actionHooks/services';

export const calculatePipelineActionOutputs = (pipelineActionsById, pipelineActionIdsUsedAsOutput) => {
    let _pipelineActions = Object.keys(pipelineActionsById).map((id) => pipelineActionsById[id]).sort(sortByProperty('executionOrder')).reduce((actions, currentAction, index) => {
        const showOutputArrow = currentAction?.discardOutput === false && Boolean(pipelineActionIdsUsedAsOutput?.[currentAction?.id]?.length > 0);
        if (index === 0) {
            const availableOutputActionId = currentAction?.discardOutput === true ? null : currentAction?.id;
            const availableOutputType = currentAction?.actionType ?? null;

            return [
                ...actions,
                {
                    ...currentAction,
                    executionOrder: index,
                    showInputArrow: false,
                    showOutputArrow,
                    showPassthroughArrow: false,
                    availableOutputActionId,
                    availableOutputType
                }
            ];
        }
        const previousAction = actions[index - 1];

        let availableOutputActionId = previousAction?.availableOutputActionId;
        let availableOutputType = previousAction?.availableOutputType;
        let availableOutputGroupServiceId = previousAction?.availableOutputGroupServiceId;

        if (!previousAction?.discardOutput) {
            // Replace output with previous actions output
            availableOutputActionId = availableOutputType ? previousAction?.id : null;
            availableOutputType = availableOutputType ? previousAction?.actionType : null;
            availableOutputGroupServiceId = availableOutputType ? previousAction?.options?.groupServiceId : null;
        }

        const showInputArrow = currentAction?.conditions?.length > 0;

        return [
            ...actions,
            {
                ...currentAction,
                executionOrder: index,
                showInputArrow,
                showOutputArrow,
                showPassthroughArrow: false,
                availableOutputActionId,
                availableOutputType,
                availableOutputGroupServiceId
            }];
    }, []);

    _pipelineActions = _pipelineActions.reduce((actions, currentAction, index) => {
        if (index > 0 && currentAction.showInputArrow) {
            const previousAction = actions?.[index - 1];
            if (previousAction.showOutputArrow) {
                return [...actions, currentAction];
            }

            const previousActions = [...actions];
            let i = previousActions.length;
            while (i > 0 && previousActions[i - 1]?.id !== currentAction.availableOutputActionId) {
                previousActions[i - 1].showPassthroughArrow = true;
                i--;
            }

            return [
                ...previousActions,
                currentAction
            ];
        }
        return [...actions, currentAction];
    }, []);

    return {
        orderedPipelineActionIds: _pipelineActions.map((action) => action?.id),
        pipelineActionsById: _pipelineActions.reduce((actions, action) => ({ ...actions, [action?.id]: action }), {})
    };
};

export const useLoadServiceDefaultActionPipeline = ({ serviceType }) => {
    if (!serviceType || !['twoway', 'group'].includes(serviceType)) {
        throw new Error('serviceType not set or invalid in useLoadServiceDefaultActionPipeline');
    }

    const [actionPipelineLoading, setActionPipelineLoading] = React.useState(true);
    const { serviceId } = useParams();
    const dispatch = useDispatch();
    const { triggerPipelineRefresh } = useSelector((state) => state.servicePipeline);
    const { servicesLoading: groupServicesLoading } = useSelector((state) => state.groupmessaging);

    useLoadGroupServices();

    React.useEffect(() => {
        dispatch({ type: SET_SERVICE_TYPE, data: serviceType });
    }, [dispatch]);

    React.useEffect(() => () => {
        dispatch(resetServicePipelineReducer());
    }, [dispatch]);

    const { payload: keywordPayload, loading: serviceKeywordsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/${serviceType}/inputsources/servicekeywords/${serviceId}`
    });

    const { mutate: getActionPipeline } = useHttpRequest((keywordId) => ({
        method: 'GET',
        endpoint: `/messaging/services/${serviceType}/pipelines/keyword/${keywordId}`
    }));

    const mainKeywordId = React.useMemo(() => keywordPayload?.availableKeywords?.find?.((ak) => ak?.text?.toLowerCase?.() === serviceId?.toLowerCase?.())?.id, [keywordPayload]);
    const mainKeywordNotFound = React.useMemo(() => !mainKeywordId && serviceKeywordsLoading === false, [mainKeywordId, serviceKeywordsLoading]);

    React.useEffect(() => {
        if (mainKeywordNotFound) {
            setActionPipelineLoading(false);
        }
    }, [mainKeywordNotFound]);

    React.useEffect(() => {
        let mounted = true;

        const fetchActionPipeline = async (mainKeywordId) => {
            if (mounted) { setActionPipelineLoading(true); }

            const { payload, error } = await getActionPipeline(mainKeywordId);
            if (!error) {
                dispatch({ type: SET_ACTION_PIPELINE, data: payload });
            }

            if (mounted) { setActionPipelineLoading(false); }
        };

        if (mainKeywordId) {
            dispatch({ type: SET_MAINKEYWORD_ID, data: mainKeywordId });
            fetchActionPipeline(mainKeywordId);
        }

        return () => { mounted = false; };
    }, [mainKeywordId, dispatch, getActionPipeline, triggerPipelineRefresh, serviceId]);

    return {
        loading: actionPipelineLoading || groupServicesLoading,
        mainKeywordNotFound
    };
};

export const useActionAvailableOutputDefinition = ({ action }) => {
    const { actionId } = useParams();
    const [outputDefinitionLoading, setOutputDefinitionLoading] = React.useState(true);
    const [availableOutputActionId, setAvailableOutputActionId] = React.useState(null);
    const [availableOutputDefinition, setAvailableOutputDefinition] = React.useState([]);
    const { customContactPropertiesByGroupServiceId } = useSelector((state) => state.servicePipeline);
    const { servicesLoading: groupServicesLoading } = useSelector((state) => state.groupmessaging);

    const outputDefinition = React.useMemo(() => {
        if (actionId === action?.availableOutputActionId) {
            return null;
        }
        return actionTypeOutputDefinitions?.[action?.availableOutputType] ?? null;
    }, [actionId, action]);

    const availableCustomContactProperties = React.useMemo(() => (Array.isArray(customContactPropertiesByGroupServiceId?.[action?.availableOutputGroupServiceId]) ? customContactPropertiesByGroupServiceId[action.availableOutputGroupServiceId] : []), [customContactPropertiesByGroupServiceId, action]);

    React.useEffect(() => {
        setOutputDefinitionLoading(true);
        setAvailableOutputActionId(null);
        setAvailableOutputDefinition([]);
    }, [outputDefinition]);

    React.useEffect(() => {
        if (Array.isArray(outputDefinition)) {
            setAvailableOutputDefinition(outputDefinition);
            setOutputDefinitionLoading(false);
        } else if (typeof outputDefinition === 'function') {
            if (!groupServicesLoading) {
                const definition = outputDefinition({ availableCustomContactProperties });
                setAvailableOutputDefinition(definition);
                setOutputDefinitionLoading(false);
            }
        } else {
            setAvailableOutputDefinition([]);
            setOutputDefinitionLoading(false);
        }
    }, [outputDefinition, availableCustomContactProperties, groupServicesLoading]);

    React.useEffect(() => {
        if (actionId !== action?.availableOutputActionId && action?.availableOutputActionId) {
            setAvailableOutputActionId(action.availableOutputActionId);
        } else {
            setAvailableOutputActionId(null);
        }
    }, [action, actionId]);

    return {
        outputDefinitionLoading,
        availableOutputDefinition,
        availableOutputActionId
    };
};