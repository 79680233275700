import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactPlayer from 'react-player';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { AttachmentRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: '100%',
        maxHeight: 200
    },
    otherAttachmentCardContent: {
        padding: 10
    },
    otherAttachmentIcon: {
        fontSize: 50
    },
    messageContentMediaVideoWrapper: {
        '&& video': {
            maxHeight: 200
        }
    }
}));

// eslint-disable-next-line
const ReactPlayerWrapper = React.forwardRef(({ children }, ref) => {
    const classes = useStyles();

    return <div className={classes.messageContentMediaVideoWrapper}>{children}</div>;
});

const MessageAttachment = ({ uri, imageLoaded }) => {
    const classes = useStyles();
    const [attachmentContentType, setAttachmentContentType] = React.useState(null);

    React.useEffect(() => {
        let abortController;
        const fetchAttachment = async () => {
            // Fetch attachment to get content-type
            try {
                abortController = new AbortController();
                const resp = await fetch(uri, { signal: abortController.signal });

                const headers = {};

                const it = resp?.headers?.entries();
                let result = it?.next();

                while (!result.done) {
                    const headerName = result.value[0];
                    const headerValue = result.value[1];
                    headers[headerName] = headerValue;
                    result = it.next();
                }

                setAttachmentContentType(headers?.['content-type'] ?? null);
            } catch (error) {
                setAttachmentContentType(null);
            }
        };

        fetchAttachment();

        return () => {
            if (abortController && typeof abortController.abort === 'function') {
                abortController.abort();
            }
        };
    }, [uri]);

    const handleOtherAttachmentClick = () => {
        window.open(uri, '_blank');
    };

    switch (attachmentContentType) {
        case 'image/jpeg':
        case 'image/png':
            return <img className={classes.image} src={uri} alt="Attachment" onLoad={imageLoaded} onError={imageLoaded} />;
        case 'video/mp4':
            return (
                <ReactPlayer
                    url={uri}
                    controls
                    loop
                    muted
                    playing
                    wrapper={ReactPlayerWrapper}
                />
            );
        default:
            return (
                <Card>
                    <CardActionArea onClick={handleOtherAttachmentClick}>
                        <CardContent className={classes.otherAttachmentCardContent}>
                            <AttachmentRounded className={classes.otherAttachmentIcon} />
                        </CardContent>
                    </CardActionArea>
                </Card>
            );
    }
};

MessageAttachment.propTypes = {
    uri: PropTypes.string.isRequired,
    imageLoaded: PropTypes.func.isRequired
};

export default MessageAttachment;