const parseErrorMessages = (errorObj) => {
    let errors = [];

    if (typeof (errorObj) === 'string') errors.push(errorObj);

    else if (Array.isArray(errorObj)) {
        errorObj.forEach((err) => {
            errors = [
                ...errors,
                ...parseErrorMessages(err)
            ];
        });
    } else if (typeof (errorObj) === 'object') {
        if (typeof (errorObj?.errorMessage) === 'string') {
            errors.push(errorObj.errorMessage);
        } else if (typeof (errorObj?.message) === 'string') {
            errors.push(errorObj.message);
        }
    }

    return errors.filter((error) => error);
};

const parseValidationFailures = (errorObj) => {
    return errorObj?.validationFailures?.filter?.((vf) => (vf.errorMessage)) ?? [];
};

const parseErrorObject = ({ payload, status }) => {
    const validationFailures = parseValidationFailures(payload);
    const formattedValidationFailures = validationFailures?.map((vf) => vf?.errorMessage ?? '')?.filter((vf) => vf && vf !== '') ?? [];

    const errorMessages = parseErrorMessages(payload);

    if (formattedValidationFailures.length > 0) {
        return [...errorMessages, ...formattedValidationFailures];
    }

    if (status === 403 && errorMessages.length === 0) {
        return 'This action requires Authorization';
    }

    return errorMessages;
};

export default parseErrorObject;