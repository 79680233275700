import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogStartMessageSent } from 'src/actions/dialogActions';
import { setMessageTemplatesLoading, setMessageTemplates } from 'src/actions/messageTemplateActions';
import { setSendGridTemplates, setSendGridVerifiedSenders } from 'src/actions/sendGridActions';
import { setWidgets, setWidgetsLoading } from 'src/actions/widgetActions';
import { setMessagingAccounts, setMessagingAccountsLoading, setDefaultMessagingAccount, setDefaultMessagingAccountLoading } from 'src/actions/messagingAccountActions';
import { setCustomerInputSources, setCustomerInputSourcesLoading } from 'src/actions/twoWayMessagingActions';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import { INFOMESSAGES_LOADING, REFRESH_INFOMESSAGES } from 'src/actions/alertActions';
import { setTagsLoading, setTags } from 'src/actions/tagActions';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { TWOWAYMESSAGINGSERVICE } from 'src/smartDialogProducts';

export const useLoadMessagingAccounts = () => {
    const dispatch = useDispatch();

    // Only set if loading is true, SetDiscussionServicesLoaded will set it to false
    React.useEffect(() => {
        dispatch(setMessagingAccountsLoading(true));
    }, [dispatch]);

    const { payload: accounts, loading: accountsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/accounts'
    });

    React.useEffect(() => {
        if (!accountsLoading) {
            dispatch(setMessagingAccounts(accounts));
        }
    }, [accounts, accountsLoading, dispatch]);
};

export const useLoadMessageTemplates = () => {
    const dispatch = useDispatch();
    const { refreshTemplates } = useSelector((state) => state.messageTemplates);

    const { payload: messageTemplatesPayload, error, loading: messageTemplatesLoading, query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/templates',
        params: {
            pageSize: 10000
        }
    });

    React.useEffect(() => {
        dispatch(setMessageTemplatesLoading());
    }, [dispatch]);

    const runRefreshTemplates = React.useCallback(query, []);

    React.useEffect(() => {
        if (!messageTemplatesLoading) {
            if (!error && Array.isArray(messageTemplatesPayload?.items)) {
                dispatch(setMessageTemplates(messageTemplatesPayload.items));
            } else {
                dispatch(setMessageTemplates([]));
            }
        }
    }, [messageTemplatesPayload, messageTemplatesLoading, error, dispatch]);

    React.useEffect(() => {
        if (refreshTemplates) { runRefreshTemplates(); }
    }, [refreshTemplates, runRefreshTemplates]);
};

export const useLoadCustomerInputSources = () => {
    const dispatch = useDispatch();

    const hasTwowayAccess = useCheckProductAssignment(TWOWAYMESSAGINGSERVICE);

    const { payload: inputSourceMappings, error, loading: inputSourceMappingsLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/twoway/inputsources'
    }, hasTwowayAccess);

    React.useEffect(() => {
        if (hasTwowayAccess) {
            dispatch(setCustomerInputSourcesLoading());
        }
    }, [dispatch, hasTwowayAccess]);

    React.useEffect(() => {
        if (!inputSourceMappingsLoading) {
            // Only set if loading is true, setMessageTemplates will set it to false
            dispatch(setCustomerInputSources(error ? [] : inputSourceMappings));
        }
    }, [inputSourceMappings, inputSourceMappingsLoading, error, dispatch]);
};

export const useLoadDefaultMessagingAccount = () => {
    const dispatch = useDispatch();
    const { error: errorSnack } = useSnackbar();

    React.useEffect(() => {
        dispatch(setDefaultMessagingAccountLoading());
    }, [dispatch]);

    const { mutate: _getAccounts } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/messaging/accounts'
    }));

    const getAccounts = React.useCallback(_getAccounts, []);

    const { mutate: _getAccount } = useHttpRequest((id) => ({
        method: 'GET',
        endpoint: `/messaging/accounts/${id}`
    }));

    const getAccount = React.useCallback(_getAccount, []);

    React.useEffect(() => {
        const fetch = async () => {
            const { payload: accountsPayload } = await getAccounts();

            if (Array.isArray(accountsPayload?.accounts)) {
                dispatch(setMessagingAccounts(accountsPayload));
            }

            const defaultAccId = accountsPayload?.accounts?.find((acc) => acc?.default)?.id;
            if (defaultAccId) {
                const { payload: defaultAccount } = await getAccount(defaultAccId);
                if (defaultAccount) {
                    dispatch(setDefaultMessagingAccount(defaultAccount));
                }
            } else {
                errorSnack('Error loading default messaging account');
                dispatch(setDefaultMessagingAccount(null));
            }
        };

        fetch();
    }, [getAccounts, getAccount, dispatch, errorSnack]);
};

export const useSendDialogStartMessage = (forceExpiration) => {
    const dispatch = useDispatch();
    const { claims: { name } } = useSelector((state) => state.auth);

    const { mutate: _sendMessage } = useHttpRequest(({ threadId, messagingAccountId, ...rest }) => ({
        method: 'POST',
        endpoint: '/messaging/services/dialog/senddiscussionmessage',
        params: {
            ...(forceExpiration ? { forceExpiration: true } : {})
        },
        headers: {
            ...messagingAccountId ? { messagingAccountId } : {}
        },
        body: {
            ...rest,
            ...threadId ? { threadId } : {}
        }
    }));

    const sendMessage = React.useCallback((data) => new Promise((resolve) => {
        const requestData = { ...data, senderName: name };

        if (requestData.threadId) {
            throw new Error('ThreadId was set in sendMessage request to useSendDialogStartMessage');
        }
        dispatch(DialogStartMessageSent(requestData));

        (async () => {
            const resp = await _sendMessage(requestData);
            resolve(resp);
        })();
    }), [dispatch, _sendMessage, name]);

    const { mutate: _sendWhatsappTemplateMessage } = useHttpRequest((body) => ({
        method: 'POST',
        endpoint: '/messaging/services/dialog/senddiscussionmessage/whatsapptemplate',
        params: {
            forceExpiration: true
        },
        body
    }));

    const sendWhatsappTemplateMessage = React.useCallback((data) => new Promise((resolve) => {
        const requestData = { ...data, senderName: name };

        if (requestData.threadId) {
            throw new Error('ThreadId was set in sendMessage request to useSendDialogStartMessage');
        }
        dispatch(DialogStartMessageSent(requestData));

        (async () => {
            const resp = await _sendWhatsappTemplateMessage(requestData);
            resolve(resp);
        })();
    }), [dispatch, _sendWhatsappTemplateMessage, name]);

    return { sendMessage, sendWhatsappTemplateMessage };
};

export const useUpdateInfomessageReadStatus = (infoMessageType, refreshInfomessages) => {
    if (infoMessageType !== 'News' && infoMessageType !== 'Alert') {
        throw new Error(`Invalid infoMessageType "${infoMessageType}" provided to useUpdateInfomessageReadStatus hook`);
    }

    const dispatch = useDispatch();
    const { claims: { sub: userId } } = useSelector((state) => state.auth);
    const { unreadAlerts, unreadNews } = useSelector((state) => state.alerts);

    const { mutate: updateLastReadStatus } = useHttpRequest((type) => ({
        method: 'PUT',
        endpoint: '/newsandalerts',
        body: {
            type,
            userId
        }
    }));

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error } = await updateLastReadStatus(infoMessageType);
            if (!error && refreshInfomessages !== false) {
                dispatch({ type: REFRESH_INFOMESSAGES });
            } else if (!error) {
                dispatch({ type: INFOMESSAGES_LOADING, data: false });
            }
        };

        if (unreadNews.length > 0 || unreadAlerts.length > 0) {
            makeRequest();
        }
    }, [dispatch, infoMessageType, refreshInfomessages, unreadNews.length, unreadAlerts.length, updateLastReadStatus]);
};

export const useLoadServiceTags = () => {
    const dispatch = useDispatch();
    const { refreshTags } = useSelector((state) => state.tagservice);
    const [tagsFetchedOnMount, setTagsFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/tags'
    }, false);

    const fetchTags = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setTagsLoading(true));
        return () => { dispatch(setTagsLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchTags();
            dispatch(setTags(error ? [] : payload));
        };

        if (!tagsFetchedOnMount || refreshTags) {
            setTagsFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchTags, tagsFetchedOnMount, refreshTags]);
};

export const useLoadSendGridTemplates = (runQuery) => {
    const dispatch = useDispatch();

    const { payload: sendGridTemplatesPayload, error, loading: sendGridTemplatesLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/sendgridtemplate/gettemplates'
    }, runQuery);

    React.useEffect(() => {
        if (!sendGridTemplatesLoading) {
            dispatch(setSendGridTemplates(Array.isArray(sendGridTemplatesPayload) ? sendGridTemplatesPayload : []));
        }
    }, [sendGridTemplatesPayload, sendGridTemplatesLoading, error, dispatch]);
};

export const useLoadSendGridVerifiedSenders = (runQuery) => {
    const dispatch = useDispatch();

    const { payload: sendGridVerifiedSendersPayload, error, loading: sendGridVerifiedSenderLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/sendgridtemplate/getverifiedsenders'
    }, runQuery);

    React.useEffect(() => {
        if (!sendGridVerifiedSenderLoading) {
            dispatch(setSendGridVerifiedSenders(Array.isArray(sendGridVerifiedSendersPayload) ? sendGridVerifiedSendersPayload : []));
        }
    }, [sendGridVerifiedSendersPayload, sendGridVerifiedSenderLoading, error, dispatch]);
};

export const useLoadWidgets = () => {
    const dispatch = useDispatch();
    const { refreshWidgets } = useSelector((state) => state.widget);
    const [widgetsFetchedOnMount, setWidgetsFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/widgets'
    }, false);

    const fetchWidgets = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setWidgetsLoading(true));
        return () => { dispatch(setWidgetsLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchWidgets();
            dispatch(setWidgets(error ? [] : payload));
        };

        if (!widgetsFetchedOnMount || refreshWidgets) {
            setWidgetsFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchWidgets, widgetsFetchedOnMount, refreshWidgets]);
};