import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';
import FoldersDialog from 'src/components/MessageEvents/FoldersDialog';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import FolderIcon from '@material-ui/icons/FolderRounded';
import PropTypes from 'prop-types';

const FoldersToolbar = ({ selectedMessageIds, selectedFolderId, serviceId, folders, refreshMessages }) => {
    const { error: errorSnack, success: successSnack } = useSnackbar();
    const [foldersMenuAnchor, setFoldersMenuAnchor] = React.useState(null);
    const [foldersDialogOpen, setFoldersDialogOpen] = React.useState(false);

    const { mutate: addMessagesFromFolder } = useHttpRequest(({ folderId, messagesToAdd }) => ({
        method: 'POST',
        endpoint: `/messaging/services/twoway/${serviceId}/folders/${folderId}/add`,
        body: messagesToAdd
    }));

    const { mutate: removeMessagesFromFolder } = useHttpRequest((messagesToRemove) => ({
        method: 'POST',
        endpoint: `/messaging/services/twoway/${serviceId}/folders/${selectedFolderId}/remove`,
        body: messagesToRemove
    }));

    const handleMoveMessagesToFolder = async (folderId) => {
        setFoldersDialogOpen(false);
        if (folderId !== null) {
            const { error, errorMessages } = await addMessagesFromFolder({ folderId, messagesToAdd: selectedMessageIds });

            if (!error) {
                refreshMessages();
                successSnack('Messages successfully added to folder.');
            } else {
                errorSnack(errorMessages);
            }
        }
    };

    const handleRemoveMessagesFromFolder = async () => {
        const { error, errorMessages } = await removeMessagesFromFolder(selectedMessageIds);

        if (!error) {
            refreshMessages();
            successSnack('Messages successfully removed.');
        } else {
            errorSnack(errorMessages);
        }
    };

    return (
        <>
            <Tooltip title="Folders">
                <IconButton aria-controls="folders-menu" aria-haspopup="true" onClick={(e) => setFoldersMenuAnchor(e.currentTarget)}>
                    <FolderIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="folders-menu"
                anchorEl={foldersMenuAnchor}
                keepMounted
                open={Boolean(foldersMenuAnchor)}
                onClose={() => setFoldersMenuAnchor(null)}
            >
                <MenuItem onClick={() => setFoldersDialogOpen(true)} disabled={selectedMessageIds?.length < 1}>Add messages to folder</MenuItem>
                <MenuItem onClick={handleRemoveMessagesFromFolder} disabled={selectedFolderId === '_allmessages_' || selectedFolderId === '_inbox_' || selectedMessageIds?.length < 1}>Remove messages from folder</MenuItem>
            </Menu>
            <FoldersDialog
                open={foldersDialogOpen}
                onClose={(fid) => handleMoveMessagesToFolder(fid)}
                folders={folders}
            />
        </>
    );
};

FoldersToolbar.propTypes = {
    selectedMessageIds: PropTypes.array,
    selectedFolderId: PropTypes.string,
    serviceId: PropTypes.string,
    folders: PropTypes.array,
    refreshMessages: PropTypes.func.isRequired
};

export default FoldersToolbar;