import { RESET_REDUCER_STATE } from 'src/actions';
import * as Actions from 'src/actions/soundActions';

const initialState = {
    shouldPlayNewUnreadMessageSound: false,
    shouldPlayNewDialogSound: false
};

const soundReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.PLAY_NEW_UNREAD_MESSAGE_SOUND: {
            return {
                ...state,
                shouldPlayNewUnreadMessageSound: true
            };
        }
        case Actions.PLAY_NEW_DIALOG_SOUND: {
            return {
                ...state,
                shouldPlayNewDialogSound: true
            };
        }
        case Actions.STOP_PLAYING_SOUNDS: {
            return {
                ...state,
                shouldPlayNewUnreadMessageSound: false,
                shouldPlayNewDialogSound: false
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default soundReducer;