import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const UserTitle = ({ match }) => {
    const { usersById } = useSelector((state) => state.userAccess);
    const userName = usersById?.[match?.params?.userId]?.profile?.displayName;

    return (
        <div>
            {userName || 'User'}
        </div>
    );
};

UserTitle.propTypes = {
    match: PropTypes.object
};

export default UserTitle;