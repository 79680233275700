import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationActions } from 'src/actions';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { USERACCESS } from 'src/smartDialogProducts';
import { useHttpRequest } from 'src/utils/httpClient';

const AccessGroupProvider = () => {
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const customerHasUserAccessProduct = useCheckProductAssignment(USERACCESS);

    const { mutate: fetchUserAccessgroups } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/accesscontrol/useraccessgroups'
    }));

    React.useEffect(() => {
        const fetchData = async () => {
            const { payload } = await fetchUserAccessgroups();
            dispatch({ type: AuthenticationActions.UserAccessGroupsLoaded, data: payload });
        };
        if (selectedCustomerId && customerHasUserAccessProduct) {
            fetchData();
        }
    }, [selectedCustomerId, fetchUserAccessgroups, dispatch, customerHasUserAccessProduct]);
    return null;
};

export default AccessGroupProvider;