import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import moment from 'moment';
import { ExportTypes } from 'src/components/MessageEvents/constants';
import PropTypes from 'prop-types';
import { groupByProperty, sortByProperty } from 'src/utils/arrayFunctions';
import { useLoadAllCustomerServices } from 'src/actions/actionHooks/services';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { SENDGRIDEMAILSERVICE } from 'src/smartDialogProducts';
import { useGetCustomerSendGridSubUser } from 'src/actions/actionHooks';

const getDefaultValues = (serviceId) => ({
    createdFrom: moment(),
    createdTo: moment(),
    serviceId: serviceId ?? '',
    type: '',
    direction: '',
    minFailedCount: 1,
    populateCustomerContactProperties: false,
    status: '',
    reason: ''
});

const minFromDate = moment().subtract(1, 'year').toDate();

const ExportDialog = ({ exportType, open, onClose, onExited, startExportDisabled, serviceId, folders }) => {
    const [data, setData] = React.useState(getDefaultValues(serviceId));

    const { servicesById, loading: servicesLoading } = useLoadAllCustomerServices(open && !serviceId);
    const sendGridServiceAssigned = useCheckProductAssignment(SENDGRIDEMAILSERVICE);
    const sendGridSubUser = useGetCustomerSendGridSubUser();

    const servicesGroupedByType = React.useMemo(() => (
        groupByProperty(Object.keys(servicesById).map((serviceId) => servicesById[serviceId]), (service) => service?.serviceExtension?.serviceType)
    ), [servicesById]);

    const title = React.useMemo(() => {
        switch (exportType) {
            case ExportTypes.Messages:
                return 'Export Messages';
            case ExportTypes.Recipients:
                return 'Export Message Recipients';
            case ExportTypes.FailedContacts:
                return 'Export Failed Contacts';
            default:
                return null;
        }
    }, [exportType]);

    React.useEffect(() => {
        if (open && !title) {
            onClose(null);
        }
    }, [open, title, onClose]);

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const _onExited = () => {
        setData(getDefaultValues(serviceId));
        onExited();
    };

    const handleCancelClick = () => {
        onClose(null);
    };

    const handleSaveClick = () => {
        onClose(data);
    };

    return (
        <Dialog
            aria-labelledby="Export Dialog"
            open={open}
            maxWidth="sm"
            fullWidth
            TransitionProps={{ onExited: _onExited }}
        >
            <DialogTitle disableTypography>
                <Typography variant="h4">
                    {title ?? ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col width="40%" />
                            <col width="60%" />
                        </colgroup>
                        <TableBody>
                            {exportType !== ExportTypes.Messages && (
                                <>
                                    <TableRow>
                                        <TableCell align="left">Created from</TableCell>
                                        <TableCell align="left">
                                            <DatePicker
                                                autoOk
                                                hideTabs
                                                disableFuture
                                                value={data.createdFrom}
                                                onChange={(date) => onChange({ target: { name: 'createdFrom', value: date?.startOf('day') } })}
                                                name="createdFrom"
                                                format="D.M.YYYY"
                                                minDate={minFromDate}
                                                fullWidth
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Created to</TableCell>
                                        <TableCell align="left">
                                            <DatePicker
                                                autoOk
                                                hideTabs
                                                disableFuture
                                                disabled={!data.createdFrom}
                                                value={data.createdTo}
                                                onChange={(date) => onChange({ target: { name: 'createdTo', value: date?.endOf('day') } })}
                                                name="createdTo"
                                                format="D.M.YYYY"
                                                minDate={data.createdFrom}
                                                maxDate={new Date()}
                                                fullWidth
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {exportType === ExportTypes.Recipients && (
                                        <>
                                            <TableRow>
                                                <TableCell align="left">Service</TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        native
                                                        value={data.serviceId}
                                                        name="serviceId"
                                                        onChange={onChange}
                                                        fullWidth
                                                        displayEmpty={servicesLoading}
                                                        disabled={servicesLoading || Boolean(serviceId)}
                                                    >
                                                        {servicesLoading && <option aria-label="Loading services" value="">Loading services...</option>}
                                                        {!servicesLoading && (
                                                            <>
                                                                <option aria-label="All" value="" />
                                                                {Object.keys(servicesGroupedByType).map((serviceType) => (
                                                                    <optgroup key={serviceType} label={serviceType}>
                                                                        {servicesGroupedByType[serviceType]?.sort(sortByProperty('name')).map((service) => (
                                                                            <option key={service.id} value={service.id}>{service.name}</option>
                                                                        ))}
                                                                    </optgroup>
                                                                ))}
                                                            </>
                                                        )}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Type</TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        value={data.type}
                                                        name="type"
                                                        onChange={onChange}
                                                        fullWidth
                                                    >
                                                        <MenuItem value=""><em>All</em></MenuItem>
                                                        <MenuItem value="SMS">SMS</MenuItem>
                                                        <MenuItem value="Whatsapp">Whatsapp</MenuItem>
                                                        <MenuItem value="Webhook">Webhook</MenuItem>
                                                        {(Boolean(sendGridServiceAssigned) && sendGridSubUser) && (
                                                            <MenuItem value="Email">Email</MenuItem>
                                                        )}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Direction</TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        value={data.direction}
                                                        name="direction"
                                                        onChange={onChange}
                                                        fullWidth
                                                    >
                                                        <MenuItem value=""><em>Both</em></MenuItem>
                                                        <MenuItem value="Inbound">Inbound</MenuItem>
                                                        <MenuItem value="Outbound">Outbound</MenuItem>
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Delivery Status</TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        value={data.status}
                                                        name="status"
                                                        onChange={onChange}
                                                        fullWidth
                                                    >
                                                        <MenuItem value=""><em>All</em></MenuItem>
                                                        <MenuItem value="Acknowledged">Acknowledged</MenuItem>
                                                        <MenuItem value="Created">Created</MenuItem>
                                                        <MenuItem value="Delivered">Delivered</MenuItem>
                                                        <MenuItem value="DeliveryFailed">Delivery Failed</MenuItem>
                                                        <MenuItem value="NullStatus">Null Status</MenuItem>
                                                        <MenuItem value="Queued">Queued</MenuItem>
                                                        <MenuItem value="Resend">Resend</MenuItem>
                                                        <MenuItem value="Scheduled">Scheduled</MenuItem>
                                                        <MenuItem value="Sent">Sent</MenuItem>
                                                        <MenuItem value="SendFailed">Send Failed</MenuItem>
                                                        <MenuItem value="SendCancelled">Send Cancelled</MenuItem>
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Delivery Reason</TableCell>
                                                <TableCell align="left">
                                                    <Select
                                                        value={data.reason}
                                                        name="reason"
                                                        onChange={onChange}
                                                        fullWidth
                                                    >
                                                        <MenuItem value=""><em>All</em></MenuItem>
                                                        <MenuItem value="BillingError">Billing Error</MenuItem>
                                                        <MenuItem value="Expired">Expired</MenuItem>
                                                        <MenuItem value="LimitExceeded">Limit Exceeded</MenuItem>
                                                        <MenuItem value="NetworkError">Network Error</MenuItem>
                                                        <MenuItem value="NoError">No Error</MenuItem>
                                                        <MenuItem value="PhoneError">Phone Error</MenuItem>
                                                        <MenuItem value="SyntaxError">Syntax Error</MenuItem>
                                                        <MenuItem value="SystemError">System Error</MenuItem>
                                                        <MenuItem value="SubscriberError">Subscriber Error</MenuItem>
                                                        <MenuItem value="NullReason">Unknown reason</MenuItem>
                                                        <MenuItem value="Unknown">Unknown</MenuItem>
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                    {exportType === ExportTypes.FailedContacts && (
                                        <>
                                            <TableRow>
                                                <TableCell align="left">Minimum Failed Messages</TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        name="minFailedCount"
                                                        value={data.minFailedCount}
                                                        onChange={onChange}
                                                        type="number"
                                                        fullWidth
                                                        inputProps={{
                                                            min: 1
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left">Populate Contact Properties </TableCell>
                                                <TableCell align="left">
                                                    <FormControlLabel
                                                        control={(
                                                            <Switch
                                                                checked={data.populateCustomerContactProperties}
                                                                onChange={(e) => onChange({ target: { name: 'populateCustomerContactProperties', value: e.target.checked } })}
                                                                name="populateCustomerContactProperties"
                                                                color="primary"
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </>
                            )}
                            {((exportType === ExportTypes.Messages || exportType === ExportTypes.Recipients) && folders?.length > 0) && (
                                <TableRow>
                                    <TableCell align="left">Destination folder</TableCell>
                                    <TableCell align="left">
                                        <Select
                                            value={data?.folderId ?? ''}
                                            name="folderId"
                                            onChange={onChange}
                                            fullWidth
                                        >
                                            <MenuItem value=""><em>Select folder</em></MenuItem>
                                            {folders?.map?.((f) => {
                                                return (
                                                    <MenuItem key={f?.id} value={f?.id}>{f?.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button aria-label="cancel" onClick={handleCancelClick} variant="contained">
                    Cancel
                </Button>
                <Button disabled={startExportDisabled} aria-label="save" onClick={handleSaveClick} variant="contained" color="primary">
                    Start Export
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ExportDialog.propTypes = {
    exportType: PropTypes.oneOf([ExportTypes.Messages, ExportTypes.Recipients, ExportTypes.FailedContacts]),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    startExportDisabled: PropTypes.bool.isRequired,
    serviceId: PropTypes.string,
    folders: PropTypes.array
};

export default ExportDialog;