const enums = {
    typeOfNumber: {
        0: 'Alphanumeric',
        1: 'National',
        2: 'International'
    },
    messageDirection: {
        0: 'Inbound',
        1: 'Outbound'
    },
    sendStatus: {
        0: 'NullStatus',
        1: 'Created',
        2: 'Scheduled',
        3: 'Sent',
        4: 'Acknowledged',
        5: 'Delivered',
        6: 'DeliveryFailed',
        7: 'SendFailed',
        8: 'SendCancelled',
        9: 'Queued',
        10: 'Resend'
    },
    deliveryReason: {
        0: 'NullReason',
        1: 'LimitExceeded',
        2: 'NoError',
        3: 'SyntaxError',
        4: 'SystemError',
        5: 'SubscriberError',
        6: 'PhoneError',
        7: 'NetworkError',
        8: 'Expired',
        9: 'BillingError',
        10: 'Unknown'
    },
    messageConnectionAttributeType: {
        0: 'Boolean',
        1: 'Integer',
        2: 'String'
    },
    ipRestrictionType: {
        0: 'Address',
        1: 'Range',
        2: 'Network'
    },
    accountCharset: {
        1252: 'Windows1252',
        65001: 'UTF8'
    },
    serviceState: {
        0: 'Active',
        1: 'Passive',
        2: 'Paused',
        3: 'Archived'
    },
    messagingServiceType: {
        0: 'OneWay',
        1: 'TwoWay',
        2: 'PushBilling'
    },
    accountPlatform: {
        0: 'Aimo',
        1: 'E0',
        2: 'E1',
        3: 'Pulse'
    },
    messageType: {
        0: 'MO',
        1: 'Dlr'
    },
    auditActionResult: {
        0: 'Failed',
        1: 'Success'
    },
    deliveryReportFilter: {
        0: 'Final',
        1: 'Intermediate'
    },
    changeType: {
        0: 'Create',
        1: 'Update',
        2: 'Delete'
    },
    pricingType: {
        0: 'Flatrate',
        1: 'BracketBased',
        2: 'QuantityBased'
    },
    reportingInterval: {
        0: 'Monthly',
        1: 'Bimonthly'
    },
    reportType: {
        0: 'AccountingReport',
        1: 'BillingReport'
    },
    yearQuarter: {
        0: 'Q1',
        1: 'Q2',
        2: 'Q3',
        3: 'Q4'
    },
    billingCompany: {
        0: 'ArenaInteractiveOy',
        1: 'ArenaMessagingOy'
    },
    currency: {
        0: 'EUR',
        1: 'SEK',
        2: 'NOK',
        3: 'DKK',
        4: 'ISK',
        5: 'GBP',
        6: 'RUB',
        7: 'USD'
    }
};

const enumsReducer = (state = enums) => {
    /* switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            return {
                loggedIn: true,
                accessToken: action.data.accessToken,
                user: {
                    ...action.data.user
                }
            };
        }

        case actionTypes.SESSION_LOGOUT: {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    } */
    return state;
};

export default enumsReducer;