import React from 'react';
import { FormControl, Input, InputLabel, makeStyles, MuiThemeProvider, Popper, Tooltip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { defaultRoute } from 'src/routes';
import { Autocomplete } from '@material-ui/lab';
import MarketplaceCustomerIcon from '@material-ui/icons/EuroRounded';
import TrialCustomerIcon from '@material-ui/icons/WatchLaterRounded';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    searchOptionroot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    selectCustomerHeader: {
        color: '#fff !important'
    },
    popperComponent: ({ popperWidth }) => ({
        minWidth: popperWidth,
        width: 'unset !important',
        maxWidth: 500,
        left: '13px !important',
        transform: 'translate3d(0px, 125px, 0px) !important'
    })
}));

const theme = (theme) => ({
    ...theme,
    overrides: {
        ...theme.overrides,
        MuiAutocomplete: {
            endAdornment: {
                '&& button': {
                    color: '#fff !important'
                }
            }
        }
    }
});

const SearchOption = ({ option = {} }) => {
    const classes = useStyles();
    const { marketplaceCustomer = false, trialCustomer = false } = option;

    return (
        <div className={classes.searchOptionroot}>
            {option?.name ?? ''}
            {trialCustomer && <Tooltip title="Trial Customer"><TrialCustomerIcon /></Tooltip>}
            {marketplaceCustomer && <Tooltip title="Marketplace Customer"><MarketplaceCustomerIcon /></Tooltip>}
        </div>
    );
};

SearchOption.propTypes = {
    option: PropTypes.object
};

const PopperComponent = ({ style, ...props }) => {
    const classes = useStyles({ popperWidth: props?.style?.width ?? 200 });
    return <Popper {...props} className={clsx(props?.className, classes.popperComponent)} />;
};

PopperComponent.propTypes = {
    style: PropTypes.shape({
        width: PropTypes.number.isRequired
    }).isRequired,
    className: PropTypes.string.isRequired
};

const SysAdminCustomerSelect = () => {
    const [open, setOpen] = React.useState(false);
    const [, setInputValue] = React.useState('');
    const classes = useStyles();
    const history = useHistory();
    const { customerId } = useParams();
    const { customerRights, customerRightsLoading } = useSelector((state) => state.auth);

    const selectedCustomer = React.useMemo(() => customerRights?.find?.((customer) => customer?.customerId === customerId), [customerId, customerRights]);

    const onInputChange = async (event, value, reason) => {
        if (reason === 'input') {
            setInputValue(value);
        }
    };

    const _onChange = (event, value) => {
        setInputValue('');
        history.push(`/${value?.customerId}${defaultRoute}`);
    };

    if (customerRightsLoading && !selectedCustomer) {
        return (
            <FormControl fullWidth>
                <InputLabel htmlFor="component-simple" className={classes.selectCustomerHeader}>Choose company</InputLabel>
                <Input id="component-simple" defaultValue="Loading companies..." />
            </FormControl>
        );
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Autocomplete
                PopperComponent={PopperComponent}
                disabled={Boolean(customerRightsLoading)}
                disableClearable
                size="small"
                fullWidth
                blurOnSelect
                id="customerSearch"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={_onChange}
                onInputChange={onInputChange}
                getOptionSelected={(option, value) => option?.customerId === value?.customerId}
                getOptionLabel={(option) => option?.name}
                options={customerRights}
                loading={customerRightsLoading}
                renderInput={(params) => (
                    <FormControl fullWidth>
                        <InputLabel htmlFor="component-simple" className={classes.selectCustomerHeader}>Choose company</InputLabel>
                        <Input id="component-simple" {...params.InputProps} inputProps={params.inputProps} />
                    </FormControl>
                )}
                renderOption={(option) => <SearchOption option={option} />}
                value={selectedCustomer}
            />
        </MuiThemeProvider>
    );
};

export default SysAdminCustomerSelect;