import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import 'src/components/GuideWidget/styles.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
    {
        label: 'Empty dashboard',
        imgPath: '/images/guidewidget/empty-dashboard.png',
    },
    {
        label: 'More widgets',
        imgPath: '/images/guidewidget/more-widgets.png',
    },
    {
        label: 'Reorganize dashboard',
        imgPath: '/images/guidewidget/reorganize-dashboard.png',
    },
    {
        label: 'Pin favourite service dashboard',
        imgPath: '/images/guidewidget/pin-favourite.png',
    }
];

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '100%',
        flexGrow: 1,
        backgroundColor: '#fff',
        maxHeight: '100%'
    },
    stepper: {
        backgroundColor: '#fff',
        position: 'absolute',
        width: '100%',
        bottom: 0
    },
    imgContainer: {
        display: 'flex',
        height: '100%',
    },
    img: {
        display: 'flex',
        overflow: 'hidden',
        maxWidth: '100%',
        maxHeight: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain'
    }
}));

function GuideWidget() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        if (activeStep === maxSteps - 1) {
            setActiveStep(0);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setActiveStep(maxSteps - 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={10000}
                style={{ height: '100%' }}
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.label} className={classes.imgContainer}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                className={classes.stepper}
                nextButton={(
                    <Button size="small" onClick={handleNext}>
                        Next
                        <KeyboardArrowRight />
                    </Button>
                )}
                backButton={(
                    <Button size="small" onClick={handleBack}>
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                )}
            />
        </div>
    );
}

export default GuideWidget;