export const ADD_ACTION = 'ADD_ACTION';
export const EDIT_ACTION = 'EDIT_ACTION';
export const SAVE_ACTION = 'SAVE_ACTION';
export const DELETE_ACTION = 'DELETE_ACTION';
export const UPDATE_ACTION = 'UPDATE_ACTION';

export const CANCEL_ADD_EDIT_ACTION = 'CANCEL_ADD_EDIT_ACTION';
export const CHOOSE_ACTION_TYPE = 'CHOOSE_ACTION_TYPE';

export const SET_MAINKEYWORD_ID = 'SET_MAINKEYWORD_ID';
export const SET_ACTION_PIPELINE = 'SET_ACTION_PIPELINE';

export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';

export const MARK_AS_OUTPUT_USER = 'MARK_AS_OUTPUT_USER';
export const UNMARK_AS_OUTPUT_USER = 'UNMARK_AS_OUTPUT_USER';

export const SET_PIPELINE_ACTIONS = 'SET_PIPELINE_ACTIONS';

export const RESET_SERVICE_PIPELINE = 'RESET_SERVICE_PIPELINE';

export const addAction = (actionType, insertIndex) => (dispatch) => dispatch({
    type: ADD_ACTION,
    data: {
        actionType,
        insertIndex
    }
});

export const saveAction = () => (dispatch) => dispatch({
    type: SAVE_ACTION
});

export const updateAction = (data) => (dispatch) => dispatch({
    type: UPDATE_ACTION,
    data
});

export const cancelEditAction = (actionId) => (dispatch) => dispatch({
    type: CANCEL_ADD_EDIT_ACTION,
    data: actionId
});

export const deleteAction = (actionId) => (dispatch) => dispatch({
    type: DELETE_ACTION,
    data: actionId
});

export const markAsOutputUser = (parentId, childId) => (dispatch) => dispatch({
    type: MARK_AS_OUTPUT_USER,
    data: { parentId, childId }
});

export const unmarkAsOutputUser = (parentId, childId) => (dispatch) => dispatch({
    type: UNMARK_AS_OUTPUT_USER,
    data: { parentId, childId }
});

export const resetServicePipelineReducer = () => (dispatch) => dispatch({
    type: RESET_SERVICE_PIPELINE
});