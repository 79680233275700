import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const CodeServiceTitle = ({ match }) => {
    const { services } = useSelector((state) => state.codeservice);
    const serviceName = services?.find((service) => service.id === match?.params?.serviceId)?.name;

    return (
        <div>
            {serviceName || 'Code Service'}
        </div>
    );
};

CodeServiceTitle.propTypes = {
    match: PropTypes.object
};

export default CodeServiceTitle;