import { fetchMock } from 'src/utils/mock';
import uuid from 'uuid';

export const mockedThreadId = '78412c18-aed4-430a-9a99-1b0686969714';

fetchMock.post(`begin:${process.env.REACT_APP_HOST}/messaging/services/dialog/senddiscussionmessage`, {
    messageId: uuid(),
    recipients: [
        {
            id: uuid(),
            address: '358501234567'
        }
    ],
    messagePartCount: 1,
    threadId: mockedThreadId
});