import { AuthenticationActions } from 'src/actions/authActions';
import { stopSignalRConnection } from 'src/actions/signalrActions';

const signalRMiddlware = ({ dispatch, getState }) => (next) => (action) => {
    // When customerselection changes, stop signalRConnection. New connection is required for the newly selected customer
    if (action.type === AuthenticationActions.CustomerSelected) {
        const selectedCustomerId = getState()?.auth?.selectedCustomerId;

        if (selectedCustomerId !== action?.data) {
            dispatch(stopSignalRConnection());
        }
        return next(action);
    }
    return next(action);
};

export default signalRMiddlware;