import { Card, CardContent, CardHeader, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { DeleteRounded } from '@material-ui/icons';
import { TemplateButtonTypes } from 'src/components/MessageTemplates/WhatsappTemplateButtons';
import uuid from 'uuid';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        paddingTop: 5,
        paddingBottom: 0,
        '&& .MuiCardHeader-action': {
            marginTop: 0,
            minHeight: 'unset'
        },
        '&& .MuiTypography-root': {
            fontSize: 17,
            fontWeight: 600
        }
    },
    cardContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex'
    },
    labelField: {
        flex: 3,
        marginRight: 5
    },
    dataField: {
        flex: 5
    }
}));

const WhatsappTemplateButtonCard = ({
    button: {
        type = TemplateButtonTypes.Call,
        label = '',
        data = '',
        _key = uuid()
    },
    disabled = false,
    onChange,
    onRemove
}) => {
    const classes = useStyles();

    const templateTypeLabel = React.useMemo(() => (
        Object.keys(TemplateButtonTypes)?.find?.((typeLabel) => TemplateButtonTypes[typeLabel] === type)
    ), [type]);

    const dataFieldLabel = React.useMemo(() => {
        switch (type) {
            case TemplateButtonTypes.Call:
                return 'Phone Number';
            case TemplateButtonTypes.QuickReply:
                return 'Quick Reply Text';
            case TemplateButtonTypes.Url:
                return 'Url';
            default:
                return '';
        }
    }, [type]);

    const handleOnChange = ({ target: { name, value } }) => {
        onChange({
            _key,
            type: type ?? TemplateButtonTypes.Call,
            label,
            data,
            [name]: value
        });
    };

    return (
        <Card className={classes.templateButtonContainer} elevation={3}>
            <CardHeader
                className={classes.cardHeader}
                action={!disabled && <IconButton size="small" onClick={onRemove}><DeleteRounded /></IconButton>}
                title={templateTypeLabel}
            />
            <CardContent className={classes.cardContent}>
                <TextField disabled={disabled} className={classes.labelField} variant="outlined" size="small" name="label" label="Button Label" value={label ?? ''} onChange={handleOnChange} />
                <TextField disabled={disabled} className={classes.dataField} variant="outlined" size="small" name="data" label={dataFieldLabel} value={data ?? ''} onChange={handleOnChange} />
            </CardContent>
        </Card>
    );
};

WhatsappTemplateButtonCard.propTypes = {
    button: PropTypes.shape({
        type: PropTypes.oneOf(['Call', 'QuickReply', 'Url']),
        label: PropTypes.string,
        data: PropTypes.string,
        _key: PropTypes.string
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default WhatsappTemplateButtonCard;