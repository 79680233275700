import { createTheme, TableCell } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import constants from './constants';

const baseTheme = {
    palette,
    typography,
    overrides,
    constants
};

const oldRender = TableCell.render;

// Fix mui-datatables logging warning for "isEmpty" not recognized as a prop
TableCell.render = (...args) => {
    const [props, ...otherArgs] = args;
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props;
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    }
    return oldRender.apply(this, args);
};

export const theme = createTheme(baseTheme);
