export const SET_TAGS_LOADING = 'SET_TAGS_LOADING';
export const SET_TAGS = 'SET_TAGS';
export const REFERSH_TAGS = 'REFERSH_TAGS';

export const setTagsLoading = (data) => (dispatch) => dispatch({
    type: SET_TAGS_LOADING,
    data
});

export const setTags = (data) => (dispatch) => dispatch({
    type: SET_TAGS,
    data
});

export const refreshTags = () => (dispatch) => dispatch({
    type: REFERSH_TAGS
});