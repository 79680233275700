import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import SaveIcon from '@material-ui/icons/SaveRounded';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sortByProperty } from 'src/utils/arrayFunctions';

const OwnerAssignmentDialog = ({ onClose, open, ownerUserId }) => {
    const [newOwnerUserId, setNewOwnerUserId] = React.useState('');
    const { customerUsers, usersLoading } = useSelector((state) => state.customer);

    const ownerOptions = React.useMemo(() => (
        Object.keys(customerUsers).map((id) => customerUsers[id]).sort(sortByProperty((user) => user?.profile?.displayName ?? ''))
    ), [customerUsers]);

    React.useEffect(() => { setNewOwnerUserId(ownerUserId); }, [ownerUserId]);

    const handleClose = () => {
        onClose(null);
    };

    const handleSaveOwner = () => {
        if (newOwnerUserId !== ownerUserId && customerUsers?.[newOwnerUserId]) {
            onClose(customerUsers[newOwnerUserId]);
        } else {
            onClose(null);
        }
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="Reassign Chat Popup" open={open} fullWidth maxWidth="xs">
            <DialogTitle disableTypography>
                <Typography variant="h4">
                    Reassign Chat
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="ownerAssignment-label">Select Owner</InputLabel>
                    <Select
                        labelId="ownerAssignment-label"
                        id="ownerAssignment"
                        value={usersLoading ? '' : newOwnerUserId}
                        onChange={(e) => setNewOwnerUserId(e.target.value)}
                        label="Owner"
                    >
                        {usersLoading && <MenuItem value=""><em>Loading owners...</em></MenuItem>}
                        {ownerOptions.map((user) => (
                            <MenuItem key={user?.externalUserId} value={user?.externalUserId ?? ''}>
                                {user?.profile?.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <IconButton onClick={handleClose}>
                    <Tooltip title="Cancel">
                        <CloseIcon />
                    </Tooltip>
                </IconButton>
                <IconButton onClick={handleSaveOwner}>
                    <Tooltip title="Save owner">
                        <SaveIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

OwnerAssignmentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    ownerUserId: PropTypes.string.isRequired
};

export default OwnerAssignmentDialog;