import configureStore from './configureStore';

/**
 * Will store state from redux to localStorage on a per User basis.
 * Will only store the states defined in localStorageStatesToSync.
 * Enter states in the from of <reducerName>.<optionalsubpath>.<optionalsubsubpath> etc
 * So for instance dialog.threadFilters
 */

export const localStorageStatesToSync = [
    'dialog.threadFilters',
    'dialog.conversationMessages'
];

const store = configureStore();
export default store;
