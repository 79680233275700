import React from 'react';

/*
    callback: function to be executed every interval
    timeout: milliseconds between each interval. Setting to 0 will clear the interval
    returns cancel function
*/
const useInterval = (callback, timeout = 0) => {
    const timeoutIdRef = React.useRef();

    const cancel = React.useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearInterval(timeoutId);
        }
    }, [timeoutIdRef]);

    React.useEffect(() => {
        if (timeout) {
            timeoutIdRef.current = setInterval(callback, timeout);
        } else {
            cancel();
        }
        return cancel;
    }, [callback, timeout, cancel]);

    return cancel;
};

export default useInterval;