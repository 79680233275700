import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpRequest } from 'src/utils/httpClient';
import * as ACTIONS from 'src/actions/customerActions';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { CONTACTSERVICE } from 'src/smartDialogProducts';

export const useLoadCustomerUsers = () => {
    const dispatch = useDispatch();
    const [customerUsersFetchedForCustomerId, setCustomerUsersFetchedForCustomerId] = React.useState(null);
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { refreshUsers } = useSelector((state) => state.customer);

    const { mutate: fetchUsers } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/customermanagement/users'
    }));

    // Set loading = true when customer selection changes
    React.useEffect(() => {
        dispatch({ type: ACTIONS.CUSTOMER_SET_USERS_LOADING, data: true });
        setCustomerUsersFetchedForCustomerId(null);
    }, [dispatch, selectedCustomerId]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchUsers();
            dispatch({ type: ACTIONS.CUSTOMER_SET_USERS, data: (error ? [] : payload) });
            dispatch({ type: ACTIONS.CUSTOMER_SET_USERS_LOADING, data: false });
        };

        if (selectedCustomerId && (customerUsersFetchedForCustomerId !== selectedCustomerId || refreshUsers)) {
            setCustomerUsersFetchedForCustomerId(selectedCustomerId);
            makeRequest();
        }
    }, [dispatch, fetchUsers, customerUsersFetchedForCustomerId, selectedCustomerId, refreshUsers]);
};

export const useGetCustomerDefaultSender = () => {
    const { selectedCustomerMetadataByKey } = useSelector((state) => state.auth);
    return selectedCustomerMetadataByKey?.defaultsender?.value ?? '';
};

export const useGetCustomerSendGridSubUser = () => {
    const { selectedCustomerMetadataByKey } = useSelector((state) => state.auth);
    return selectedCustomerMetadataByKey?.sendgridusername?.value ?? '';
};

export const useGetCustomerMessageLogDefaultTab = () => {
    const { selectedCustomerMetadataByKey } = useSelector((state) => state.auth);

    return React.useMemo(() => {
        const validTabs = ['all', 'batches'];
        const value = selectedCustomerMetadataByKey?.messagelogdefaulttab?.value?.toLowerCase?.();

        if (validTabs.indexOf(value) > -1) {
            return value;
        }
        return validTabs[0];
    }, [selectedCustomerMetadataByKey]);
};

export const useLoadCustomerContactProperties = () => {
    const dispatch = useDispatch();
    const [fetchedForCustomerId, setFetchedForCustomerId] = React.useState(null);
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const hasContactService = useCheckProductAssignment(CONTACTSERVICE);

    const { mutate } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/contacts/properties'
    }));

    // Set loading = true when customer selection changes
    React.useEffect(() => {
        dispatch({ type: ACTIONS.CUSTOMER_SET_CONTACT_PROPERTIES_LOADING, data: true });
        setFetchedForCustomerId(null);
    }, [dispatch, selectedCustomerId]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await mutate();
            dispatch({ type: ACTIONS.CUSTOMER_SET_CONTACT_PROPERTIES, data: Array.isArray(payload?.propertyNames) ? payload.propertyNames : [] });
            dispatch({ type: ACTIONS.CUSTOMER_SET_CONTACT_PROPERTIES_LOADING, data: false });
        };

        if (hasContactService && selectedCustomerId && (fetchedForCustomerId !== selectedCustomerId)) {
            setFetchedForCustomerId(selectedCustomerId);
            makeRequest();
        }
    }, [dispatch, mutate, hasContactService, selectedCustomerId, fetchedForCustomerId]);

    return null;
};