export const SET_GROUPSERVICES_LOADING = 'SET_GROUPSERVICES_LOADING';
export const SET_GROUPSERVICES = 'SET_GROUPSERVICES';
export const SET_GROUPSERVICE = 'SET_GROUPMSERVICE';
export const SET_SELECTEDGROUPSERVICE = 'SET_SELECTEDGROUPSERVICE';
export const SET_SELECTEDGROUPSERVICELOADING = 'SET_SELECTEDGROUPSERVICELOADING';
export const REFRESH_SELECTEDGROUPSERVICE = 'REFRESH_SELECTEDGROUPSERVICE';

export const REFERSH_GROUPSERVICES = 'REFERSH_GROUPSERVICES';

export const SET_SELECTEDGROUPCONTACT = 'SET_SELECTEDGROUPCONTACT';
export const REFERSH_GROUPCONTACTS = 'REFERSH_GROUPCONTACTS';
export const SET_GROUPCONTACTSLOADING = 'SET_GROUPCONTACTSLOADING';

export const GROUPSERVICEUPDATED = 'GROUPSERVICEUPDATED';
export const GROUPSERVICEDELETED = 'GROUPSERVICEDELETED';

export const SET_PUSHMESSAGES = 'SET_PUSHMESSAGES';
export const SET_PUSHMESSAGES_LOADING = 'SET_PUSHMESSAGES_LOADING';
export const REFERSH_PUSHMESSAGES = 'REFERSH_PUSHMESSAGES';
export const SET_SELECTED_PUSHMESSAGE = 'SET_SELECTED_PUSHMESSAGE';

export const setGroupServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_GROUPSERVICES_LOADING,
    data
});

export const setGroupServices = (data) => (dispatch) => dispatch({
    type: SET_GROUPSERVICES,
    data
});

export const setGroupService = (data) => (dispatch) => dispatch({
    type: SET_GROUPSERVICE,
    data
});

export const setSelectedGroupService = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDGROUPSERVICE,
    data
});

export const setSelectedGroupServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDGROUPSERVICELOADING,
    data
});

export const refreshSelectedGroupService = (data) => (dispatch) => dispatch({
    type: REFRESH_SELECTEDGROUPSERVICE,
    data
});

export const refreshGroupServices = () => (dispatch) => dispatch({
    type: REFERSH_GROUPSERVICES
});

export const setSelectedGroupContact = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDGROUPCONTACT,
    data
});

export const refreshGroupContacts = (data) => (dispatch) => dispatch({
    type: REFERSH_GROUPCONTACTS,
    data
});

export const loadingGroupContacts = () => (dispatch) => dispatch({
    type: SET_GROUPCONTACTSLOADING
});

export const groupServiceUpdated = (data) => (dispatch) => dispatch({
    type: GROUPSERVICEUPDATED,
    data
});

export const groupServiceDeleted = (serviceId) => (dispatch) => dispatch({
    type: GROUPSERVICEDELETED,
    data: serviceId
});

export const setPushMessages = (data) => (dispatch) => dispatch({
    type: SET_PUSHMESSAGES,
    data
});

export const setPushMessagesLoading = (data) => (dispatch) => dispatch({
    type: SET_PUSHMESSAGES_LOADING,
    data
});

export const refreshPushMessages = () => (dispatch) => dispatch({
    type: REFERSH_PUSHMESSAGES
});

export const setSelectedPushMessage = (data) => (dispatch) => dispatch({
    type: SET_SELECTED_PUSHMESSAGE,
    data
});