const infoData = [
    {
        field: 'Service tags',
        description: 'Categorize services with Tags  for future search and segmentation'
    },
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Service state',
        description: 'The state of the service, (Active or Passive).'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Default sender',
        description: 'If no sender is provided in the sending request, this sender will be used.'
    },
    {
        field: 'Start date',
        description: 'Service valid from this date.'
    },
    {
        field: 'End date',
        description: 'Service valid thru this date.'
    },
    {
        field: 'Dlr url',
        description: 'The URL to send delivery status callbacks.'
    },
    {
        field: 'Code service',
        description: 'Select Code service which you want to add to be used in this 1-way Messaging service, for sending codes to message recipients.'
    },
    {
        field: 'Client id',
        description: 'Identifies this service, and allows for many services to use the same messaging account.'
    },
    {
        field: 'Message retention days',
        description: 'Defines how long messages will be stored. Minimum value possible is 90 days. If not set, default value will be set to max value, which is 365 days.'
    },
    {
        field: 'Force ad messages',
        description: 'Indicates that this service only sends messages that are ad´s. This allows ad-blocking to work as that only blocks ad-messages. If a service sends both ad- and regular messages, this should be set to "false" and the "adMessage" parameter set for ad messages.'
    },
    {
        field: 'Hidden content',
        description: 'When set, will hide the message content for all messages. To disable Hidden content please contact support.'
    }
];

export default infoData;