import React from 'react';
import MapWidget from 'src/components/Code/MapWidget';
import PropTypes from 'prop-types';

const CodeRedeemedCodesMapWidget = ({ settings }) => {
    const { serviceId, serviceName } = settings;

    return (
        <MapWidget serviceId={serviceId} serviceName={serviceName} />
    );
};

CodeRedeemedCodesMapWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 4,
        h: 4
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 4,
        h: 4
    },
    xs: {
        w: 4,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

export default CodeRedeemedCodesMapWidget;