import { combineReducers } from 'redux';
import sessionReducer from 'src/reducers/sessionReducer';
import authReducer from 'src/reducers/authReducer';
import enumsReducer from 'src/reducers/enumsReducer';
import oneWayMessagingReducer from 'src/reducers/oneWayMessagingReducer';
import twoWayMessagingReducer from 'src/reducers/twoWayMessagingReducer';
import groupMessagingReducer from 'src/reducers/groupMessagingReducer';
import messagingAccountReducer from 'src/reducers/messagingAccountReducer';
import messageTemplateReducer from 'src/reducers/messageTemplateReducer';
import sendMessageReducer from 'src/reducers/sendMessageReducer';
import dialogReducer from 'src/reducers/dialogReducer';
import signalrReducer from 'src/reducers/signalrReducer';
import alertsReducer from 'src/reducers/alertsReducer';
import codeReducer from 'src/reducers/codeReducer';
import carrierBillingReducer from 'src/reducers/carrierBillingReducer';
import surveyReducer from 'src/reducers/surveyReducer';
import tagReducer from 'src/reducers/tagReducer';
import adBanReducer from 'src/reducers/adBanReducer';
import userAccessReducer from 'src/reducers/userAccessReducer';
import customerReducer from 'src/reducers/customerReducer';
import notificationReducer from 'src/reducers/notificationReducer';
import statisticsReducer from 'src/reducers/statisticsReducer';
import sendGridReducer from 'src/reducers/sendGridReducer';
import appReducer from 'src/reducers/appReducer';
import widgetReducer from 'src/reducers/widgetReducer';
import servicePipelineReducer from 'src/reducers/servicePipelineReducer';
import folderReducer from 'src/reducers/folderReducer';
import routerReducer from 'src/reducers/routerReducer';
import soundReducer from 'src/reducers/soundReducer';

import { STATE_LOADED_FROM_LOCALSTORAGE } from 'src/actions';

const _rootReducer = combineReducers({
    auth: authReducer,
    onewaymessaging: oneWayMessagingReducer,
    twowaymessaging: twoWayMessagingReducer,
    servicePipeline: servicePipelineReducer,
    groupmessaging: groupMessagingReducer,
    messagingAccounts: messagingAccountReducer,
    messageTemplates: messageTemplateReducer,
    session: sessionReducer,
    enums: enumsReducer,
    sendMessage: sendMessageReducer,
    dialog: dialogReducer,
    signalR: signalrReducer,
    alerts: alertsReducer,
    codeservice: codeReducer,
    carrierbilling: carrierBillingReducer,
    surveyservice: surveyReducer,
    tagservice: tagReducer,
    adbanservice: adBanReducer,
    userAccess: userAccessReducer,
    customer: customerReducer,
    notifications: notificationReducer,
    statistics: statisticsReducer,
    sendGrid: sendGridReducer,
    app: appReducer,
    widget: widgetReducer,
    folders: folderReducer,
    router: routerReducer,
    sound: soundReducer
});

const rootReducer = (state, action) => {
    if (!action || !action.type) {
        throw new Error('Invalid action or action type missing from dispatched action');
    }

    if (action.type === STATE_LOADED_FROM_LOCALSTORAGE) {
        return _rootReducer({
            ...state,
            ...action.data
        }, action);
    }
    return _rootReducer(state, action);
};

export default rootReducer;