import { IconButton } from '@material-ui/core';
import { AttachFileRounded } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { useGetPopupWindow } from 'src/components/ExternalPopupWindows/ExternalPopupWindowProvider';

const acceptedMimeTypes = [
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264',
    'image/bmp',
    'image/tiff',
    'text/vcard',
    'text/x-vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
    'application/pdf',
    'application/vcard',
    'application/vnd.apple.pkpass',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'audio/aac',
    'audio/ogg; codecs=opus'
];

const uploadFile = (window, accept = 'audio/*,video/*,image/*') => {
    let lock = false;
    return new Promise((resolve) => {
        // create input file
        const el = window.document.createElement('input');
        el.id = +new Date();
        el.style.display = 'none';
        el.setAttribute('type', 'file');
        el.setAttribute('accept', accept);
        window.document.body.appendChild(el);

        el.addEventListener('change', () => {
            lock = true;
            const file = el.files[0];

            resolve(file);
            // remove dom
            window.document.body.removeChild(window.document.getElementById(el.id));
        }, { once: true });

        // file blur
        window.addEventListener('focus', () => {
            setTimeout(() => {
                if (!lock && window.document.getElementById(el.id)) {
                    resolve(null);
                    // remove dom
                    window.document.body.removeChild(window.document.getElementById(el.id));
                }
            }, 300);
        }, { once: true });

        // open file select box
        el.click();
    });
};

const AttachFileButton = ({ onChange }) => {
    const popupWindow = useGetPopupWindow();

    const onClick = async () => {
        const currentWindow = popupWindow ?? window;
        const file = await uploadFile(currentWindow, acceptedMimeTypes.join(','));
        onChange(file);
    };

    return (
        <IconButton
            size="small"
            aria-label="Attach file"
            onClick={onClick}
            onMouseDown={onClick}
            edge="end"
        >
            <AttachFileRounded />
        </IconButton>
    );
};

AttachFileButton.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default AttachFileButton;