import JwtDecode from 'jwt-decode';
import { AuthenticationActions } from 'src/actions/authActions';

const getSelectedCustomerClaims = (selectedCustomerId, customerRights) => {
    const selectedCustomerRights = customerRights?.find((val) => val?.customerId === selectedCustomerId);
    return {
        selectedCustomerName: selectedCustomerRights?.name ?? null,
        selectedCustomerRole: selectedCustomerRights?.role ?? null,
        selectedCustomerSubscriptionPlan: selectedCustomerRights?.subscriptionPlan?.split(/([A-Z]?[^A-Z]*)/g)?.slice(0, -1)?.join(' ')?.trim() ?? null
    };
};

const getUserInitials = (claims) => {
    const _initials = [
        (claims?.given_name?.substring(0, 1) ?? null),
        (claims?.family_name?.substring(0, 1) ?? null)
    ];

    if (_initials.every((val) => val)) {
        return _initials;
    }

    return claims?.email?.substring(0, 1)?.toUpperCase() ?? 'SD';
};

const initialState = {
    user: null,
    userInitials: null,
    isLoadingUser: false,
    accessToken: null,
    claims: null,
    customerRights: [],
    customerRightsLoading: true,
    isSysAdmin: false,

    userAccessGroups: [],
    userAdminAccessGroups: [],

    selectedCustomerId: null,
    selectedCustomerName: null,
    selectedCustomerRole: null,
    selectedCustomerSubscriptionPlan: null,
    selectedCustomerAllowPriorityMessages: false,
    selectedCustomerMessageRetentionDays: null,
    selectedCustomerMaxPrice: 0,
    selectedCustomerVats: null,
    selectedCustomerAlpha2Code: null,
    selectedCustomerMetadataByKey: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthenticationActions.AccessTokenRefreshed:
            return {
                ...state,
                accessToken: action?.data ?? null,
                userInitials: getUserInitials(action?.data ? JwtDecode(action?.data) : null),
                claims: action?.data ? JwtDecode(action?.data) : null
            };
        case AuthenticationActions.CustomerSelected: {
            return {
                ...state,
                selectedCustomerId: action?.data ?? null,
                ...getSelectedCustomerClaims(action?.data, state?.customerRights)
            };
        }
        case AuthenticationActions.CustomerRightsLoaded: {
            return {
                ...state,
                ...(state.selectedCustomerId ? getSelectedCustomerClaims(state.selectedCustomerId, action?.data?.customerRights ?? []) : {}),
                customerRights: action?.data?.customerRights ?? [],
                customerRightsLoading: false,
                isSysAdmin: Boolean(action?.data?.isSysAdmin)
            };
        }
        case AuthenticationActions.ProfileUpdated: {
            return {
                ...state,
                claims: {
                    ...state?.claims ?? {},
                    given_name: action?.data?.givenName ?? '',
                    name: action?.data?.displayName ?? '',
                    family_name: action?.data?.surname ?? '',
                    city: action?.data?.city ?? '',
                    postalCode: action?.data?.postalCode ?? '',
                    streetAddress: action?.data?.streetAddress ?? '',
                    mobilePhone: action?.data?.mobilePhone ?? '',
                    mail: action?.data?.mail ?? ''
                }
            };
        }
        case AuthenticationActions.SelectedCustomerFetched: {
            return {
                ...state,
                // Add more properties if needed in the future
                selectedCustomerId: action?.data?.id,
                selectedCustomerAllowPriorityMessages: action?.data?.allowPriorityMessages ?? false,
                selectedCustomerMessageRetentionDays: action?.data?.messageRetentionDays ?? null,
                selectedCustomerMaxPrice: action?.data?.maxPrice ?? 0,
                selectedCustomerVats: action?.data?.vats ?? null,
                selectedCustomerAlpha2Code: action?.data?.country?.alpha2Code ?? null,
                selectedCustomerMetadataByKey: action?.data?.metadata?.reduce?.((allMetadata, metadata) => ({ ...allMetadata, [metadata?.key?.toLowerCase()]: metadata }), {}) ?? {}
            };
        }
        case AuthenticationActions.UserAccessGroupsLoaded: {
            const _accessGroups = Array.isArray(action.data) ? action.data : [];

            return {
                ...state,
                userAccessGroups: _accessGroups,
                userAdminAccessGroups: _accessGroups.filter((ag) => ag?.role === 'Admin')
            };
        }
        default:
            return state;
    }
};

export default reducer;