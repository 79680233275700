import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    chartContainer: {
        minHeight: 250,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative'
    }
}));

function BarChart({ data: dataProp, showBy, groupBy }) {
    const classes = useStyles();
    const theme = useTheme();
    const barThickness = 25;
    const maxBarThickness = 22;
    const stacked = false;
    const cornerRadius = 3;
    let yMax;
    if (showBy === 'percentage') {
        yMax = 100;
    }

    const data = {
        datasets: [
            {
                label: 'Sent',
                backgroundColor: theme.palette.status.sent.main,
                data: dataProp.Sent,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            },
            {
                label: 'Redeemed',
                backgroundColor: theme.palette.status.redeemed.main,
                data: dataProp.Redeemed,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            },
            {
                label: 'Voided',
                backgroundColor: theme.palette.status.voided.main,
                data: dataProp.Voided,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            },
            {
                label: 'Recycled',
                backgroundColor: theme.palette.status.recycled.main,
                data: dataProp.Recycled,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            }
        ]
    };

    const options = {
        isoWeekday: true,
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius,
        animation: {
            duration: 500,
            easing: 'linear'
        },
        legend: {
            display: true
        },
        layout: {
            padding: 0
        },
        scales: {
            xAxes: [
                {
                    offset: true,
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: groupBy,
                        displayFormats: {
                            minute: 'mm',
                            hour: 'HH',
                            day: 'DD.MM.YYYY',
                            week: '[w]-w',
                            month: 'MMM-YY',
                            quarter: '[Q]Q-YY',
                            year: 'YYYY',
                        }
                    },
                    bounds: 'ticks',
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        source: 'auto',
                        padding: 20,
                        fontColor: theme.palette.text.secondary
                    },
                    stacked
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    },
                    ticks: {
                        padding: 20,
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                        max: yMax,
                        maxTicksLimit: 5
                    },
                    stacked
                }
            ]
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.common.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: () => { },
                label: (tooltipItem) => {
                    const label = `${data.datasets[tooltipItem.datasetIndex].label}: ${showBy === 'percentage' ? tooltipItem.yLabel?.toFixed(1) : tooltipItem.yLabel}`;
                    return label;
                }
            }
        }
    };

    return (
        <div className={classes.chartContainer}>
            <Bar
                data={data}
                options={options}
            />
        </div>
    );
}

BarChart.propTypes = {
    data: PropTypes.object.isRequired,
    showBy: PropTypes.string,
    groupBy: PropTypes.string
};

export default BarChart;