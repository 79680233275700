import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar as useNotificationSnackbar } from 'notistack';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import { getNotificationIcon } from 'src/components/Notifications/NotificationsDrawer';
import { useTheme } from '@material-ui/styles';
import NotificationListItem from 'src/components/Notifications/NotificationListItem';

const useStyles = makeStyles(() => ({
    alert: {
        minWidth: 200,
        maxWidth: 400
    },
    close: {
        right: '0',
        top: '0'
    },
    root: {
        marginTop: 56
    },
    notificationListItem: {
        maxWidth: 500
    },
    alertSnackList: {
        marginLeft: 15
    }
}));

const SnackbarContext = React.createContext();

const SNACKTYPES = {
    ALERT: 'alert',
    NOTIFICATION: 'notification'
};

const SnackbarContextProvider = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useNotificationSnackbar();

    const closeAllSnackbars = React.useCallback(() => closeSnackbar(), []);

    const showSnack = React.useCallback((
        type,
        {
            snackText,
            snackTitle,
            snackSeverity,
            persist = false,
            autoHideDuration = 5000,
            notification
        }
    ) => enqueueSnackbar({ type, snackText, snackTitle, snackSeverity, notification, persist }, { persist, autoHideDuration }), [enqueueSnackbar]);

    const success = React.useCallback((snackText, options = {}) => showSnack(SNACKTYPES.ALERT, {
        ...options,
        snackText,
        snackTitle: 'Success',
        snackSeverity: 'success'
    }), [showSnack]);

    const error = React.useCallback((snackText, options = {}) => showSnack(SNACKTYPES.ALERT, {
        ...options,
        snackText: snackText && snackText !== '' && snackText?.length > 0 ? snackText : 'Oops an error occurred',
        snackTitle: 'Error',
        snackSeverity: 'error',
        autoHideDuration: 30000
    }), [showSnack]);

    const info = React.useCallback((snackText, options = {}) => showSnack(SNACKTYPES.ALERT, {
        ...options,
        snackText,
        snackTitle: 'Info',
        snackSeverity: 'info'
    }), [showSnack]);

    const warning = React.useCallback((snackText, options = {}) => showSnack(SNACKTYPES.ALERT, {
        ...options,
        snackText,
        snackTitle: 'Warning',
        snackSeverity: 'warning'
    }), [showSnack]);

    const notification = React.useCallback((notification, options = {}) => showSnack(SNACKTYPES.NOTIFICATION, { ...options, notification }), [showSnack]);

    return (
        <SnackbarContext.Provider
            value={{
                success,
                error,
                info,
                warning,
                notification,
                closeAllSnackbars
            }}
        >
            {children}
        </SnackbarContext.Provider>
    );
};

SnackbarContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const useSnackbar = () => {
    const context = React.useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export { useSnackbar };

const AlertSnackContent = ({ snackText }) => {
    const classes = useStyles();

    if (typeof snackText === 'string') {
        return snackText;
    }

    if (Array.isArray(snackText) && snackText.length > 0 && snackText.every((text) => typeof text === 'string')) {
        return (
            <>
                {snackText[0] ?? ''}
                <br />
                <ul className={classes.alertSnackList}>
                    {snackText.slice(1, snackText.length).map((text) => (
                        <li key={text}>{text}</li>
                    ))}
                </ul>
            </>
        );
    }

    if (React.isValidElement(snackText)) {
        return snackText;
    }

    return null;
};

AlertSnackContent.propTypes = {
    snackText: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired
};

const SnackbarProvider = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();

    const notistackRef = React.createRef();

    const handleSnackClose = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <NotistackSnackbarProvider
            ref={notistackRef}
            classes={{
                containerRoot: classes.root
            }}
            maxSnack={5}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            content={(key, { type, snackText, snackTitle, snackSeverity, notification }) => {
                switch (type) {
                    case SNACKTYPES.ALERT:
                        return (
                            <Alert
                                id={key}
                                severity={snackSeverity}
                                className={classes.alert}
                                action={(
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleSnackClose(key)}
                                        className={classes.close}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            >
                                <AlertTitle>{snackTitle}</AlertTitle>
                                <AlertSnackContent snackText={snackText} />
                            </Alert>
                        );
                    case SNACKTYPES.NOTIFICATION: {
                        const { iconStyle, icon } = getNotificationIcon(notification, theme);
                        return (
                            <NotificationListItem
                                id={key}
                                className={classes.notificationListItem}
                                icon={icon}
                                iconStyle={iconStyle}
                                notification={notification}
                                onClick={() => { }}
                                isDialog
                            />
                        );
                    }
                    default:
                        return null;
                }
            }}
        >
            {children}
        </NotistackSnackbarProvider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.any
};

export default (props) => <SnackbarProvider><SnackbarContextProvider {...props} /></SnackbarProvider>;