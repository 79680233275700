import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AdBanServiceTitle = ({ match }) => {
    const { services } = useSelector((state) => state.adbanservice);
    const serviceName = services?.find((service) => service.id === match?.params?.serviceId)?.name;

    return (
        <div>
            {serviceName || 'Opt-out Service'}
        </div>
    );
};

AdBanServiceTitle.propTypes = {
    match: PropTypes.object
};

export default AdBanServiceTitle;