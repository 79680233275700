import {
    HttpClient
} from '@microsoft/signalr';

export default class SignalRHttpClient extends HttpClient {
    headers = {};

    constructor(headers = {}) {
        super();

        this.headers = {
            ...this.headers,
            ...headers
        };
    }

    async post(url, httpOptions) {
        const headers = {
            ...httpOptions.headers,
            ...this.headers
        };

        const resp = await fetch(url, {
            method: 'POST',
            headers
        });

        return {
            statusCode: resp.status,
            statusText: resp.statusText,
            content: (await resp.text())
        };
    }
}