import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import propTypes from 'prop-types';

const CustomPrompt = ({ open, onConfirm, onCancel }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You have unsaved changes. Do you wish to discard the changes?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    No
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CustomPrompt.propTypes = {
    open: propTypes.bool.isRequired,
    onConfirm: propTypes.func.isRequired,
    onCancel: propTypes.func.isRequired
};

export default CustomPrompt;