const VatDecimalCount = 1;
const PpmCoefficient = 10000;

export const toVatPpm = (inputValue) => {
    let value = inputValue;

    if (typeof value === 'string') {
        value = value.replace(',', '.');
    }

    const numberValue = Number.parseFloat(value);
    if (Number.isNaN(numberValue)) {
        return 0;
    }

    if (numberValue < 0) {
        return 0;
    }

    if (numberValue >= (1 * PpmCoefficient) && numberValue <= (100 * PpmCoefficient)) {
        return numberValue;
    }

    return PpmCoefficient * Number.parseFloat(numberValue.toFixed(VatDecimalCount));
};

export const toVatPercentage = (value, appendSuffix = false) => {
    const numberValue = Number.parseFloat(value);
    if (Number.isNaN(numberValue)) {
        return '';
    }

    if (numberValue < 0) {
        return '';
    }

    if (numberValue < 101) {
        // Wrap with parseFloat to remove unnecessary decimal if 0
        return `${Number.parseFloat(numberValue.toFixed(VatDecimalCount))}${appendSuffix ? '%' : ''}`;
    }

    if (numberValue > (100 * PpmCoefficient)) {
        return '';
    }

    // Wrap with parseFloat to remove unnecessary decimal if 0
    return `${Number.parseFloat((numberValue / PpmCoefficient).toFixed(VatDecimalCount))}${appendSuffix ? '%' : ''}`;
};