import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/dialogActions';
import { updateObjectInDictionary } from 'src/utils/dictionaryFunctions';

const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Unread', value: 'Unread' },
    { label: 'Read', value: 'Read' },
    { label: 'Expired', value: 'Expired' }
];

const disabledDiscussionStartProtocols = [
    'Messenger',
    'Instagram',
    'WeChat'
];

const initialState = {
    inboxViewSelected: 'DialogThreads',
    startedDialogsByMessageId: {},
    selectedThreadId: null,
    selectedThread: null,
    selectedPendingThreadId: null,
    selectedPendingThread: null,
    selectedDialogMessageId: null,
    selectedDialogMessage: null,
    threadsLoading: true,
    pinnedThreadsLoading: true,
    pendingThreadsLoading: true,
    threadNotFound: false,
    threadsById: {},
    pinnedThreadIds: {},
    pendingThreadsById: {},
    threadTotalCount: 0,
    pendingThreadTotalCount: 0,
    threadCountFetched: 0,
    pendingThreadCountFetched: 0,
    unreadThreadCount: 0,
    collapsedGroups: {},
    dialogServicesById: {},
    dialogServicesLoading: true,
    discussionServicesById: {},
    discussionServicesByProtocolAndId: {},
    discussionServicesLoading: true,
    enabledDiscussionProtocols: [],
    dialogServiceMessagesLoading: true,
    dialogServiceMessages: [],
    dialogServiceMessageCount: 0,
    threadMessagesLoading: true,
    threadMessagesById: {},
    refreshThreadMessages: false,
    loadMoreThreads: false,
    loadMorePendingThreads: false,

    sentDialogMessagesByThreadId: {},
    queuedDialogMessages: [],

    threadFiltersExpanded: false,
    threadPaging: {
        $top: 50,
        $skip: 0
    },
    pendingThreadPaging: {
        $top: 50,
        $skip: 0
    },
    threadFilters: {
        status: [],
        groupBy: 'date',
        service: '',
        search: '',
        ownerUserId: ''
    },
    pendingThreadFilters: {
        groupBy: 'date',
        service: '',
        search: '',
        ownerUserId: ''
    },
    dialogMessageFilters: {
        groupBy: 'date',
        service: '',
        search: '',
        ownerUserId: ''
    },
    statusOptions,
    refreshDialogThreads: false,
    refreshPendingDialogThreads: false,
    refreshDialogServices: false,
    refreshDiscussionServices: false,
    refreshDialogServiceMessages: false,
    conversationMessages: {},
    sendDiscussionMessageSettings: {
        messageType: '',
        recipientAddress: '',
        discussionServiceId: null
    },
    sendNewMessageResponse: null,
    selectedTemplateResponse: null,
    dialogInboxHasFocus: false,
    fetchUnreadThreads: false
};

const dialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_THREAD: {
            // Check if thread with id exists before selecting
            return {
                ...state,
                selectedThreadId: action.data ?? null,
                selectedThread: state?.threadsById[action.data] ?? null
            };
        }
        case ACTIONS.SELECT_PENDING_THREAD: {
            // Check if thread with id exists before selecting
            return {
                ...state,
                selectedPendingThreadId: action.data ?? null,
                selectedPendingThread: state?.pendingThreadsById[action.data] ?? null
            };
        }
        case ACTIONS.SELECT_DIALOG_MESSAGE: {
            // Check if thread with id exists before selecting
            return {
                ...state,
                selectedDialogMessageId: action.data?.id ?? null,
                selectedDialogMessage: action.data ?? null
            };
        }
        case ACTIONS.SET_THREADS_LOADED: {
            const _threadsById = action.data?.reduce((acc, thread) => ({ ...acc, [thread.id]: { ...thread } }), {}) ?? {};
            return {
                ...state,
                threadsLoading: false,
                loadMoreThreads: false,
                threadTotalCount: action?.totalCount ?? 0,
                threadCountFetched: action?.data?.length ?? 0,
                refreshDialogThreads: false,

                startedDialogsByMessageId: {
                    ...state.startedDialogsByMessageId,
                    ...action.data?.reduce((acc, thread) => ({ ...acc, ...(thread.parentMessageId ? { [thread.parentMessageId]: true } : {}) }), {}) ?? {}
                },
                threadsById: _threadsById,

                ...(state.selectedThreadId ? {
                    selectedThread: action.data?.find((thread) => thread.id === state.selectedThreadId) ?? null
                } : {}),
                ...(state.startMessage && _threadsById[state.startMessage?.threadId] ? {
                    startMessage: {
                        ...state.startMessage,
                        // Only set threadsRefreshed to true if latestMessage is present, this means thread has been fully created. This triggers redirect
                        threadsRefreshed: Boolean(_threadsById[state.startMessage?.threadId]?.latestMessage)
                    }
                } : {})
            };
        }
        case ACTIONS.SET_PINNED_THREADS_LOADED: {
            return {
                ...state,
                pinnedThreadIds: action?.data?.reduce?.((pinnedThreadIds, pinnedThread) => ({ ...pinnedThreadIds, [pinnedThread?.id]: pinnedThread }), state.pinnedThreadIds) ?? state.pinnedThreadIds
            };
        }
        case ACTIONS.SET_PINNED_THREADS_LOADING: {
            return {
                ...state,
                pinnedThreadsLoading: action?.data
            };
        }
        case ACTIONS.SET_PENDING_THREADS_LOADED: {
            const _threadsById = action.data?.reduce((acc, thread) => ({ ...acc, [thread.id]: { ...thread } }), {}) ?? {};
            return {
                ...state,
                pendingThreadsLoading: false,
                loadMorePendingThreads: false,
                pendingThreadTotalCount: action?.totalCount ?? 0,
                pendingThreadCountFetched: action?.data?.length ?? 0,
                refreshPendingDialogThreads: false,

                pendingThreadsById: _threadsById,
                ...(state.sendNewMessageResponse && _threadsById[state.sendNewMessageResponse?.threadId] ? {
                    sendNewMessageResponse: {
                        ...state.sendNewMessageResponse,
                        // Only set threadsRefreshed to true if latestMessage is present, this means thread has been fully created. This triggers redirect
                        threadsRefreshed: Boolean(_threadsById[state.sendNewMessageResponse?.threadId]?.latestMessage)
                    }
                } : {})
            };
        }
        case ACTIONS.SET_MORE_THREADS_LOADED: {
            return {
                ...state,
                threadsLoading: false,
                loadMoreThreads: false,
                refreshDialogThreads: false,
                threadsById: action.data?.reduce((acc, thread) => ({ ...acc, [thread.id]: { ...thread } }), state.threadsById) ?? state.threadsById,
                threadTotalCount: action?.totalCount ?? state.threadTotalCount,
                threadCountFetched: state.threadCountFetched + (action?.data?.length ?? 0)
            };
        }
        case ACTIONS.SET_MORE_PENDING_THREADS_LOADED: {
            return {
                ...state,
                pendingThreadsLoading: false,
                loadMorePendingThreads: false,
                refreshPendingDialogThreads: false,
                pendingThreadsById: action.data?.reduce((acc, thread) => ({ ...acc, [thread.id]: { ...thread } }), state.pendingThreadsById) ?? {},
                pendingThreadTotalCount: action?.totalCount ?? state.pendingThreadTotalCount,
                pendingThreadCountFetched: state.pendingThreadCountFetched + (action?.data?.length ?? 0)
            };
        }
        case ACTIONS.SET_SINGLE_THREAD_LOADED: {
            return {
                ...state,
                threadsLoading: false,
                loadMoreThreads: false,
                refreshDialogThreads: false,
                threadsById: {
                    ...state.threadsById,
                    [action.data.id]: {
                        ...action.data
                    }
                }
            };
        }
        case ACTIONS.SET_SINGLE_PENDING_THREAD_LOADED: {
            return {
                ...state,
                pendingThreadsLoading: false,
                loadMorePendingThreads: false,
                refreshPendingDialogThreads: false,
                pendingThreadsById: {
                    ...state.pendingThreadsById,
                    [action.data.id]: {
                        ...action.data
                    }
                }
            };
        }
        case ACTIONS.SET_THREADS_LOADING: {
            return {
                ...state,
                threadsLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_PENDING_THREADS_LOADING: {
            return {
                ...state,
                pendingThreadsLoading: Boolean(action.data)
            };
        }
        case ACTIONS.REFRESH_THREADS: {
            return {
                ...state,
                threadsLoading: true,
                threadCountFetched: 0,
                threadTotalCount: 0,
                threadPaging: {
                    ...state.threadPaging,
                    $skip: 0
                },
                refreshDialogThreads: true
            };
        }
        case ACTIONS.REFRESH_PENDING_THREADS: {
            return {
                ...state,
                pendingThreadsLoading: true,
                pendingThreadCountFetched: 0,
                pendingThreadTotalCount: 0,
                pendingThreadPaging: {
                    ...state.pendingThreadPaging,
                    $skip: 0
                },
                refreshPendingDialogThreads: true
            };
        }
        case ACTIONS.LOAD_MORE_THREADS: {
            return {
                ...state,
                threadsLoading: true,
                loadMoreThreads: true,
                threadPaging: {
                    ...state.threadPaging,
                    $skip: state.threadPaging.$skip + state.threadPaging.$top
                }
            };
        }
        case ACTIONS.LOAD_MORE_PENDING_THREADS: {
            return {
                ...state,
                pendingThreadsLoading: true,
                loadMorePendingThreads: true,
                pendingThreadPaging: {
                    ...state.pendingThreadPaging,
                    $skip: state.pendingThreadPaging.$skip + state.pendingThreadPaging.$top
                }
            };
        }
        case ACTIONS.CLEAR_THREADS: {
            return {
                ...state,
                threadsById: {},
                selectedThread: null,
                threadMessagesById: {},
                threadCountFetched: 0,
                threadTotalCount: 0,
                threadPaging: {
                    ...state.threadPaging,
                    $skip: 0
                }
            };
        }
        case ACTIONS.CLEAR_THREAD_FILTERS: {
            return {
                ...state,
                threadFilters: initialState.threadFilters
            };
        }
        case ACTIONS.CLEAR_PENDING_THREAD_FILTERS: {
            return {
                ...state,
                pendingThreadFilters: initialState.pendingThreadFilters
            };
        }
        case ACTIONS.CLEAR_DIALOG_MESSAGE_FILTERS: {
            return {
                ...state,
                dialogMessageFilters: initialState.dialogMessageFilters
            };
        }
        case ACTIONS.REFRESH_THREAD_MESSAGES: {
            return {
                ...state,
                refreshThreadMessages: true
            };
        }
        case ACTIONS.SET_DIALOG_SERVICES_LOADING: {
            return {
                ...state,
                dialogServicesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_DIALOG_SERVICES_LOADED: {
            return {
                ...state,
                dialogServicesById: action.data?.reduce((acc, service) => ({
                    ...acc,
                    [service.id]: { ...service }
                }), {}) ?? {},
                dialogServicesLoading: false,
                refreshDialogServices: false
            };
        }
        case ACTIONS.SET_DISCUSSION_SERVICES_LOADING: {
            return {
                ...state,
                discussionServicesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_DISCUSSION_SERVICES_LOADED: {
            const discussionServicesById = action.data?.reduce((acc, service) => ({
                ...acc,
                [service.id]: { ...service }
            }), {}) ?? {};

            const discussionServicesByProtocolAndId = action.data?.reduce((acc, service) => {
                const protocols = service?.serviceConfiguration?.protocols?.map?.((item) => item?.protocol) ?? [];
                return protocols.reduce((acc, protocol) => ({ ...acc, [protocol]: Array.isArray(acc?.[protocol]) ? [...acc[protocol], service] : [service] }), acc);
            }, {}) ?? {};

            const enabledDiscussionProtocols = Object.keys(discussionServicesById).reduce((protocols, serviceId) => ([
                ...protocols,
                ...discussionServicesById?.[serviceId]?.serviceConfiguration?.protocols?.map?.((p) => p?.protocol) ?? []
            ]), []);

            return {
                ...state,
                discussionServicesById,
                discussionServicesByProtocolAndId,
                discussionServicesLoading: false,
                refreshDiscussionServices: false,
                enabledDiscussionProtocols: [...new Set(enabledDiscussionProtocols)]?.filter?.((p) => !disabledDiscussionStartProtocols.includes(p))?.sort()
            };
        }
        case ACTIONS.SET_DIALOG_SERVICE_MESSAGES_LOADING: {
            return {
                ...state,
                dialogServiceMessages: [],
                dialogServiceMessageCount: 0,
                dialogServiceMessagesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_DIALOG_SERVICE_MESSAGES_LOADED: {
            return {
                ...state,
                dialogServiceMessages: action.data?.value ?? [],
                dialogServiceMessageCount: action?.data?.['@odata.count'] ?? 0,
                dialogServiceMessagesLoading: false,
                refreshDialogServiceMessages: false
            };
        }
        case ACTIONS.SET_THREAD_MESSAGES_LOADING: {
            return {
                ...state,
                threadMessagesLoading: Boolean(action.data)
            };
        }
        case ACTIONS.SET_THREAD_MESSAGES_LOADED: {
            return {
                ...state,
                threadMessagesById: action.data?.reduce((messages, msg) => ({
                    ...messages,
                    [msg.id]: {
                        ...msg
                    }
                }), state.threadMessagesById) ?? {},
                threadMessagesLoading: false,
                refreshThreadMessages: false,
                sentDialogMessage: null
            };
        }
        case ACTIONS.CLEAR_THREAD_MESSAGES: {
            return {
                ...state,
                threadMessagesById: {},
                sentDialogMessage: null
            };
        }
        case ACTIONS.SET_THREAD_FILTERS: {
            return {
                ...state,
                threadsById: {},
                threadFilters: {
                    ...state.threadFilters,
                    ...action.data,
                    // Only allow grouping by date when showing filtering by a specific service
                    ...(action.data.service ? { groupBy: 'date' } : {})
                },
                collapsedGroups: {}
            };
        }
        case ACTIONS.SET_PENDING_THREAD_FILTERS: {
            return {
                ...state,
                pendingThreadFilters: {
                    ...state.pendingThreadFilters,
                    ...action.data,
                    // Only allow grouping by date when showing filtering by a specific service
                    ...(action.data.service ? { groupBy: 'date' } : {})
                },
                collapsedGroups: {}
            };
        }
        case ACTIONS.SET_DIALOG_MESSAGE_FILTERS: {
            return {
                ...state,
                dialogMessageFilters: {
                    ...state.dialogMessageFilters,
                    ...action.data,
                    // Only allow grouping by date when showing filtering by a specific service
                    ...(action.data.service ? { groupBy: 'date' } : {})
                },
                collapsedGroups: {}
            };
        }
        case ACTIONS.SET_THREAD_FILTERS_EXPANDED: {
            return {
                ...state,
                threadFiltersExpanded: Boolean(action.data)
            };
        }
        case ACTIONS.TOGGLE_THREAD_GROUP: {
            return {
                ...state,
                collapsedGroups: state.collapsedGroups[action.data] ?
                    Object.keys(state.collapsedGroups).filter((c) => c !== action.data).reduce((acc, current) => ({ ...acc, [current]: true }), {}) :
                    { ...state.collapsedGroups, [action.data]: true }
            };
        }
        case ACTIONS.UPDATE_THREAD: {
            const threadsById = updateObjectInDictionary(state.threadsById, action.data.id, (thread) => {
                const status = state.dialogInboxHasFocus && action.data.id === state.selectedThreadId && state.selectedThread?.latestMessage?.direction === 'Inbound' ? 'Read' : action.data.status;
                return {
                    ...thread,
                    ...action.data,
                    isTemporary: false,
                    status
                };
            });
            const pinnedThreadIds = updateObjectInDictionary(state.pinnedThreadIds, action.data.id, (thread) => {
                const status = state.dialogInboxHasFocus && action.data.id === state.selectedThreadId && state.selectedThread?.latestMessage?.direction === 'Inbound' ? 'Read' : action.data.status;
                return {
                    ...thread,
                    ...action.data,
                    isTemporary: false,
                    status
                };
            });

            return {
                ...state,
                threadsById,
                pinnedThreadIds,
                unreadThreadCount: Object.keys(threadsById).reduce((count, threadId) => (threadsById[threadId]?.status === 'Unread' ? count + 1 : count), 0)
            };
        }
        case ACTIONS.UPDATE_PENDING_THREAD: {
            return {
                ...state,
                pendingThreadsById: updateObjectInDictionary(state.pendingThreadsById, action.data.id, (thread) => ({
                    ...thread,
                    ...action.data
                }))
            };
        }
        case ACTIONS.UPDATE_SELECTED_THREAD: {
            return {
                ...state,
                selectedThread: {
                    ...state.selectedThread,
                    ...action.data,
                    status: action.data?.latestMessage?.direction === 'Inbound' ? 'Read' : (state?.selectedThread?.status ?? 'Unread')
                }
            };
        }
        case ACTIONS.UPDATE_SELECTED_PENDING_THREAD: {
            return {
                ...state,
                selectedPendingThread: {
                    ...state.selectedPendingThread,
                    ...action.data
                }
            };
        }
        case ACTIONS.UPDATE_THREAD_MESSAGE: {
            return {
                ...state,
                threadMessagesById: {
                    ...state.threadMessagesById,
                    ...(state.threadMessagesById[action?.data?.sendMessageRequestId] ? {
                        ...action.data
                    } : {})
                }
            };
        }
        case ACTIONS.DIALOG_START_MESSAGE_SENT: {
            return {
                ...state,
                startMessage: {
                    sendRequest: { ...action.data },
                    sentTimestamp: new Date(),
                    threadsRefreshed: false,
                    loading: true
                },
                sendNewMessageResponse: {
                    sendRequest: { ...action.data },
                    sentTimestamp: new Date(),
                    threadsRefreshed: false,
                    loading: true
                }
            };
        }
        case ACTIONS.DIALOG_START_MESSAGE_CLEAR: {
            return {
                ...state,
                startMessage: null
            };
        }
        case ACTIONS.DIALOG_START_MESSAGE_RESPONSE_RECEIVED: {
            return {
                ...state,
                startMessage: {
                    ...state.startMessage,
                    ...(action.data?.payload?.threadId ? { threadId: action.data.payload.threadId } : {}),
                    error: action.data?.error ?? false,
                    errorCode: action.data?.headers?.['discussion-available-sender-error'] ?? null,
                    errorMessage: action.data?.payload?.errorMessage ?? null,
                    loading: false,
                }
            };
        }
        case ACTIONS.DIALOG_MESSAGE_QUEUED: {
            const { thread: { id: threadId }, internalQueueId } = action.data;
            return {
                ...state,
                queuedDialogMessages: [
                    ...state.queuedDialogMessages,
                    {
                        ...action.data
                    }
                ],
                sentDialogMessagesByThreadId: {
                    ...state.sentDialogMessagesByThreadId,
                    [threadId]: {
                        ...state.sentDialogMessagesByThreadId?.[threadId] ?? {},
                        [internalQueueId]: {
                            ...action.data,
                            sentTimestamp: new Date().toJSON(),
                            loading: true
                        }
                    }
                }
            };
        }
        case ACTIONS.DIALOG_MESSAGE_QUEUED_SUCCESS_RESPONSE_RECEIVED: {
            const {
                processedMessage: {
                    attachmentUri,
                    content,
                    senderName,
                    internalQueueId,
                    thread: { id: threadId, latestMessage: { protocol }, inputSourceAddress: sender, status }
                },
                sendResponse: { messageId }
            } = action.data;

            const sentDialogMessagesByThreadId = {
                ...state.sentDialogMessagesByThreadId,
                // Copy sent message with correct messageId into sentDialogMessagesByThreadId
                // to prevent it disappering when loading new messages.
                // Duplicate messageIds are checked in ConversationMessages component
                [threadId]: {
                    ...state.sentDialogMessagesByThreadId?.[threadId] ?? {},
                    [messageId]: {
                        ...state.sentDialogMessagesByThreadId?.[threadId]?.[internalQueueId] ?? {}
                    }
                }
            };
            delete sentDialogMessagesByThreadId?.[threadId]?.[internalQueueId];

            const newThreadMessage = {
                attachmentUri,
                content,
                direction: 'Outbound',
                id: messageId,
                protocol,
                sender,
                senderName,
                threadId,
                sentTimestamp: new Date().toJSON()
            };

            return {
                ...state,
                queuedDialogMessages: state.queuedDialogMessages?.filter?.((queueMessage) => queueMessage?.internalQueueId !== internalQueueId) ?? [],
                sentDialogMessagesByThreadId,
                threadMessagesById: {
                    ...state.threadMessagesById,
                    [messageId]: newThreadMessage
                },
                ...status !== 'Pending' ? {
                    threadsById: {
                        ...state.threadsById,
                        [threadId]: {
                            ...state.threadsById[threadId],
                            latestMessage: newThreadMessage
                        }
                    }
                } : {
                    pendingThreadsById: {
                        ...state.pendingThreadsById,
                        [threadId]: {
                            ...state.pendingThreadsById[threadId],
                            latestMessage: newThreadMessage
                        }
                    }
                }
            };
        }
        case ACTIONS.DIALOG_MESSAGE_QUEUED_ERROR_RESPONSE_RECEIVED: {
            const {
                processedMessage: {
                    internalQueueId,
                    thread: { id: threadId }
                },
                errorMessages
            } = action.data;

            return {
                ...state,
                queuedDialogMessages: state.queuedDialogMessages?.filter?.((queueMessage) => queueMessage?.internalQueueId !== internalQueueId) ?? [],
                sentDialogMessagesByThreadId: updateObjectInDictionary(state.sentDialogMessagesByThreadId, threadId, (thread) => ({
                    ...thread,
                    [internalQueueId]: {
                        ...thread?.[internalQueueId] ?? {},
                        error: true,
                        errorCode: action.data?.payload?.errorCode ?? null,
                        errorMessage: action.data?.payload?.errorMessage ?? errorMessages ?? null,
                        loading: false
                    }
                }))
            };
        }
        case ACTIONS.REFRESH_DIALOG_SERVICE_MESSAGES: {
            return {
                ...state,
                refreshDialogServiceMessages: true
            };
        }
        case ACTIONS.UPDATE_CONVERSATION_MESSAGE: {
            return {
                ...state,
                conversationMessages: {
                    ...state.conversationMessages,
                    [action.data?.threadId]: action.data?.message
                }
            };
        }
        case ACTIONS.THREAD_REASSIGNED: {
            const { threadId, ownerUserId } = action?.data ?? {};

            if (threadId && ownerUserId && state.threadsById[threadId]) {
                return {
                    ...state,
                    threadsById: {
                        ...state.threadsById,
                        [threadId]: {
                            ...state.threadsById[threadId],
                            ownerUserId
                        }
                    }
                };
            }

            return {
                ...state,
            };
        }
        case ACTIONS.SET_THREAD_PINNED: {
            const existingThread = state.threadsById?.[action?.data];

            if (existingThread) {
                return {
                    ...state,
                    pinnedThreadIds: {
                        ...state.pinnedThreadIds,
                        [existingThread.id]: { ...existingThread }
                    }
                };
            }

            return {
                ...state,
            };
        }
        case ACTIONS.SET_THREAD_UNPINNED: {
            const _pinnedThreadIds = { ...state.pinnedThreadIds };
            const existingThread = _pinnedThreadIds?.[action?.data];

            if (existingThread) {
                delete _pinnedThreadIds[action.data];
                return {
                    ...state,
                    selectedThread: null,
                    selectedThreadId: null,
                    pinnedThreadIds: _pinnedThreadIds
                };
            }

            return {
                ...state
            };
        }
        case ACTIONS.SET_DIALOG_SENDMESSAGE_SETTINGS: {
            return {
                ...state,
                ...action?.data ? {
                    sendDiscussionMessageSettings: {
                        ...state.sendDiscussionMessageSettings,
                        ...action.data
                    }
                } : {
                    sendDiscussionMessageSettings: initialState.sendDiscussionMessageSettings
                }
            };
        }
        case ACTIONS.DIALOG_SEND_NEW_MESSAGE_RESPONSE_RECEIVED: {
            return {
                ...state,
                sendNewMessageResponse: {
                    ...state.sendNewMessageResponse,
                    ...(action.data?.payload?.threadId ? { threadId: action.data.payload.threadId } : {}),
                    error: action.data?.error ?? false,
                    errorCode: action.data?.headers?.['discussion-available-sender-error'] ?? null,
                    errorMessage: action.data?.payload?.errorMessage ?? null,
                    loading: false,
                }
            };
        }
        case ACTIONS.CLEAR_SEND_NEW_MESSAGE_RESPONSE: {
            return {
                ...state,
                sendNewMessageResponse: null
            };
        }
        case ACTIONS.CLEAR_SEND_NEW_MESSAGE_SETTINGS: {
            return {
                ...state,
                sendDiscussionMessageSettings: initialState.sendDiscussionMessageSettings
            };
        }
        case ACTIONS.TEMPLATE_SELECTED: {
            return {
                ...state,
                selectedTemplateResponse: action?.data ?? null
            };
        }
        case ACTIONS.SET_DIALOG_INBOX_HAS_FOCUS: {
            return {
                ...state,
                dialogInboxHasFocus: Boolean(action?.data)
            };
        }
        case ACTIONS.SET_UNREAD_THREAD_COUNT: {
            return {
                ...state,
                unreadThreadCount: action?.data
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default dialogReducer;
