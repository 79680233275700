import React from 'react';
import { useHttpGetRequest } from 'src/utils/httpClient';
import PropTypes from 'prop-types';
import WidgetSkeleton from 'src/components/Skeleton';
import { updateRequestSettings, REFRESH_AMOUNT, INTERVAL_MILLISECONDS } from 'src/widgets/widgetUtils';
import useInterval from 'src/utils/useInterval';
import moment from 'moment';

const WidgetDataContext = React.createContext();
export const WidgetDataConsumer = WidgetDataContext.Consumer;

const WidgetDataProvider = ({ children, settings: { interval, requestSettings } }) => {
    const [refreshWidget, setRefreshWidget] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [refreshes, setRefreshes] = React.useState(0);

    useInterval(
        async () => {
            const intervalTo = moment(interval?.to);
            const now = moment();

            if (interval?.definedInterval.startsWith('this') || (interval?.definedInterval === 'custom' && intervalTo > now)) {
                setRefreshWidget(true);
            }
            setRefreshes((retries) => retries + 1);
        },
        (
            refreshes < REFRESH_AMOUNT ? INTERVAL_MILLISECONDS : 0
        )
    );

    const { query: pollQuery, loading } = useHttpGetRequest(updateRequestSettings(interval, requestSettings), false);

    const fetchWidgetData = React.useCallback(pollQuery, []);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await fetchWidgetData();
            setData(payload);
        };

        if (!data || refreshWidget) {
            setRefreshWidget(false);
            makeRequest();
        }
    }, [refreshWidget, setRefreshWidget]);

    if (loading || !data) {
        return <WidgetSkeleton />;
    }

    return children(data);
};

export const useWidgetData = () => {
    const context = React.useContext(WidgetDataContext);
    if (context === undefined) {
        throw new Error('useWidgetData must be used within a WidgetDataProvider');
    }
    return context;
};

WidgetDataProvider.propTypes = {
    children: PropTypes.func,
    settings: PropTypes.object.isRequired
};

export default WidgetDataProvider;