import React from 'react';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpRequest } from 'src/utils/httpClient';
import { layout } from 'src/widgets/defaultWidgetLayout';

export const useCreateWidget = (component) => {
    const { success: successSnack, error: errorSnack } = useSnackbar();

    const { mutate } = useHttpRequest(({ component, settings }) => ({
        endpoint: '/widgets',
        method: 'POST',
        body: { component, settings, layout }
    }));

    const createWidget = React.useCallback(async (settings) => {
        const { error, errorMessages, ...rest } = await mutate({ component, settings });
        if (error) {
            errorSnack(errorMessages);
        } else {
            successSnack('Widget created');
        }

        return { error, ...rest };
    }, [mutate, component, successSnack, errorSnack]);

    return { createWidget };
};