import { fetchMock } from 'src/utils/mock';

fetchMock.put(`begin:${process.env.REACT_APP_HOST}/messaging/services/dialog/sendgroupmessage`, {
    id: '229df753906b4d07b9587fcad372f45d',
    messagePartCount: 1,
    recipientCount: 1,
    sendDateTimeEstimate: '2021-02-18T10:14:14.4461302Z',
    warnings: []
});

fetchMock.get(`begin:${process.env.REACT_APP_HOST}/messaging/services/dialog/sendmessage/status/`, {
    runtimeStatus: 'Running',
    input: {
        sender: '3584573966909',
        content: 'sdhsdhsdh',
        protocol: 'SMS',
        recipients: [],
        allowedSendTimeStart: '22:00:00',
        allowedSendTimeEnd: '21:59:59',
        allowedSendDays: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ],
        maxMessagesPerBatch: 1000,
        batchIntervalMinutes: 0,
        adMessage: false
    },
    output: [{ recipientBatch: [{ address: '358503408423', personalization: { firstname: 'Robin' } }], success: true, errorMessage: null, sendResponse: { messageId: '4a9fe902-3787-402e-76cf-08d8d3ec59e8', messagePartCount: 1, recipients: [{ id: 'a505dc63-ee54-4aa6-0d11-08d8d3ec5a3b', address: '358503408423' }] } }],
    createdTime: '2019-10-06T18:30:24Z',
    lastUpdatedTime: '2019-10-06T19:40:30Z'
});