const regex = /[^A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&amp;'()*+<>,\-./:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*/;

export default (str) => {
    let parts = 0;

    // check if string contains character(s) that is not in the GSM03.38 set
    const isGsm0338 = !regex.test(str);

    if ((isGsm0338 && str?.length > 0 && str?.length <= 160) || (!isGsm0338 && str?.length > 0 && str?.length <= 70)) {
        parts = 1;
    } else if (isGsm0338 && str?.length > 160) {
        parts = str.length / 153;
        parts = Math.ceil(parts);
    } else if (!isGsm0338 && str?.length > 70) {
        parts = str.length / 67;
        parts = Math.ceil(parts);
    }

    return parts;
};

const GSMCHARS1 = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡"
    + 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
const GSMCHARS2 = '\f^{}\\[~]|€';

export const gsmify = (str) => {
    if (str == null) {
        return '';
    }
    let res = '';
    for (let i = 0; i < str.length; i++) {
        const c = str.charAt(i);
        if (GSMCHARS1.lastIndexOf(c) !== -1 || GSMCHARS2.indexOf(c) !== -1) {
            res += c;
            // eslint-disable-next-line no-continue
            continue;
        }
        switch (c) {
            // --- 0 chars
            case '\b':
                break;
            // --- 1 chars
            case 'Á':
            case 'À':
            case 'Â':
                res += 'A';
                break;
            case 'â':
            case 'á':
            case 'ª':
                res += 'a';
                break;
            case 'Ç':
                res += 'C';
                break;
            case '¢':
                res += 'c';
                break;
            case 'Ê':
            case 'Ë':
            case 'È':
                res += 'E';
                break;
            case 'ë':
            case 'ê':
                res += 'e';
                break;
            case 'Ĥ':
                res += 'H';
                break;
            case 'ĥ':
                res += 'h';
                break;
            case 'Î':
            case 'Ï':
            case 'Í':
            case 'Ì':
                res += 'I';
                break;
            case 'î':
            case 'í':
            case 'ï':
                res += 'i';
                break;
            case 'Ĺ':
                res += 'L';
                break;
            case 'ĺ':
                res += 'l';
                break;
            case 'Ô':
            case 'Ò':
            case 'Ó':
                res += 'O';
                break;
            case 'ô':
            case 'ó':
                res += 'o';
                break;
            case 'Ŕ':
                res += 'R';
                break;
            case 'ŕ':
                res += 'r';
                break;
            case 'Û':
            case 'Ù':
            case 'Ú':
                res += 'U';
                break;
            case 'û':
            case 'ú':
                res += 'u';
                break;
            case 'Ŷ':
            case 'Ÿ':
            case 'Ỳ':
            case 'Ý':
                res += 'Y';
                break;
            case 'ÿ':
            case 'ý':
                res += 'y';
                break;
            case 'Ž':
                res += 'Z';
                break;
            case 'ž':
                res += 'z';
                break;
            case 'Ã':
                res += 'Ä';
                break;
            case 'ã':
                res += 'ä';
                break;
            case 'Œ':
            case 'Õ':
                res += 'Ö';
                break;
            case 'œ':
            case 'õ':
                res += 'ö';
                break;
            case '²':
                res += '2';
                break;
            case '³':
                res += '3';
                break;
            case '¦':
                res += '|';
                break;
            case '·':
            case '•':
            case '‐':
            case '‑':
            case '‒':
            case '–':
            case '—':
            case '―':
                res += '-';
                break;
            case '↑':
            case '⇑':
            case '⇧':
                res += '^';
                break;
            case '‘':
            case '’':
            case '`':
            case '´':
                res += "'";
                break;
            case '“':
            case '”':
                res += '"';
                break;
            case '\t':
            case '\u00A0':
                res += ' ';
                break;
            // --- 2 chars
            case '™':
                res += 'TM';
                break;
            case '←':
                res += '<-';
                break;
            case '→':
                res += '->';
                break;
            case '⇦':
            case '⇐':
                res += '<=';
                break;
            case '⇒':
            case '⇨':
                res += '=>';
                break;
            case '«':
                res += '<<';
                break;
            case '»':
                res += '>>';
                break;
            // --- 3 chars
            case '©':
                res += '(C)';
                break;
            case '®':
                res += '(R)';
                break;
            case '½':
                res += '1/2';
                break;
            case '¼':
                res += '1/4';
                break;
            case '¾':
                res += '3/4';
                break;
            case '↔':
                res += '<->';
                break;
            case '⇔':
                res += '<=>';
                break;
            case '…':
                res += '...';
                break;
            // --- the rest
            default:
                res += '?';
        }
    }
    return res;
};

export const getGSMLength = (str) => {
    // count rcode as 8
    let bodyText = str.replace(/\$\{rcode\}/g, '12345678');
    // ...and rcode expires as something representative (TODO: inaccurate)
    bodyText = bodyText.replace(/\$\{rcode_expires\}/g, '12.12.2012');
    // Strip template parameters
    bodyText = bodyText.replace(/\$\{.*?\}/g, '');
    let l = 0;
    const ll = bodyText.length;
    for (let i = 0; i < ll; i++) {
        const ch = bodyText.charAt(i);
        if (GSMCHARS1.lastIndexOf(ch) !== -1) {
            l += 1;
        } else if (GSMCHARS2.indexOf(ch) !== -1) {
            l += 2;
        } else {
            // Not GSM 03.38
            return -1;
        }
    }
    return l;
};

/**
 * Counts how many sms messages, strips template parameters off.
 *
 * @param bodyText
 * @return number of sms message count
 */
export const getMessageCount = (str) => {
    // count r-code as 8
    let bodyText = str.replace(/\$\{rcode\}/g, '12345678');
    // ...and rcode expires as something representative (TODO: inaccurate)
    bodyText = bodyText.replace(/\$\{rcode_expires\}/g, '12.12.2012');
    // Strip template parameters
    bodyText = bodyText.replace(/\$\{.*?\}/g, '');
    let parts = 1;
    const gsmLen = getGSMLength(bodyText);
    if (gsmLen > 160) {
        // Multiple GSM 03.38 parts, each max 153 chars
        parts = gsmLen / 153;
        parts = Math.ceil(parts);
    } else if (gsmLen < 0 && bodyText.length > 70) {
        // Multiple UCS-2 parts, each max 67 chars
        parts = bodyText.length / 67;
        parts = Math.ceil(parts);
    }
    return parts;
};

export const isGSMMessage = (str) => {
    // Strip template parameters
    const bodyText = str.replace(/\$\{.*?\}/g, '');
    const ll = bodyText.length;
    for (let i = 0; i < ll; i++) {
        const ch = bodyText.charAt(i);
        if (GSMCHARS1.lastIndexOf(ch) !== -1) {
            // eslint-disable-next-line no-continue
            continue;
        } else if (GSMCHARS2.indexOf(ch) !== -1) {
            // eslint-disable-next-line no-continue
            continue;
        } else {
            // Not GSM 03.38
            return false;
        }
    }
    return true;
};