import { actions } from 'src/actions/signalrActions';

const initialState = {
    connection: null,
    connected: false,
    customerId: null,
    reconnecting: false,
    error: false,
    subscriptionsByEventName: {},

    connectedOn: null,
    disconnectedOn: null
};

const signalRReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CONNECTING: {
            return {
                ...state,
                connection: action?.connection ?? null
            };
        }
        case actions.CONNECTED: {
            return {
                ...state,
                connected: true,
                connection: action.data?.connection ?? null,
                customerId: action.data?.customerId ?? null,
                connectedOn: new Date(),
                disconnectedOn: null,
                error: false
            };
        }
        case actions.RECONNECTING: {
            return {
                ...state,
                connected: false,
                reconnecting: true
            };
        }
        case actions.ERROR: {
            return {
                ...state,
                connected: false,
                error: true,
                reconnecting: false
            };
        }
        case actions.DISCONNECTED: {
            return {
                ...state,
                connected: false,
                connectedOn: null,
                disconnectedOn: new Date(),
                subscriptionsByEventName: {}
            };
        }
        case actions.CONNECTION_STOPPED: {
            return {
                ...state,
                connected: false,
                connection: null,
                customerId: null,
                subscriptionsByEventName: {}
            };
        }
        case actions.SUBSCRIBING_TO_EVENT: {
            if (!action?.data?.eventName || !action?.data?.eventHandler || !action?.data?.subscriptionId) {
                throw new Error(`${actions.SUBSCRIBING_TO_EVENT} action requires eventName, eventHandler and subscriptionId`);
            }
            return {
                ...state,
                subscriptionsByEventName: {
                    ...state.subscriptionsByEventName,
                    [action.data.eventName]: {
                        ...state.subscriptionsByEventName?.[action.data.eventName] ?? {},
                        [action?.data?.subscriptionId]: action.data.eventHandler
                    }
                }
            };
        }
        case actions.UNSUBSCRIBING_FROM_EVENT: {
            const _subscriptionsByEventName = { ...state.subscriptionsByEventName };

            const eventName = action?.data?.eventName;
            const subscriptionId = action?.data?.subscriptionId;

            if (!eventName) {
                throw new Error(`${actions.UNSUBSCRIBING_FROM_EVENT} action requires eventName`);
            }

            if (subscriptionId) {
                // Delete specific subscription
                delete _subscriptionsByEventName?.[eventName]?.[subscriptionId];
                // If it was the last subscription for the EventName, delete by key.
                if (_subscriptionsByEventName?.[eventName] && Object.keys(_subscriptionsByEventName[eventName]).length === 0) {
                    delete _subscriptionsByEventName[eventName];
                }
            } else {
                delete _subscriptionsByEventName?.[eventName];
            }

            return {
                ...state,
                subscriptionsByEventName: _subscriptionsByEventName
            };
        }
        default: {
            return state;
        }
    }
};

export default signalRReducer;
