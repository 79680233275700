import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetDialogServicesLoaded, SetDialogServicesLoading, SetDiscussionServicesLoaded, SetDiscussionServicesLoading } from 'src/actions/dialogActions';
import { setOneWayServices, setOneWayServicesLoading } from 'src/actions/oneWayMessagingActions';
import { setTwoWayServices, setTwoWayServicesLoading } from 'src/actions/twoWayMessagingActions';
import { setGroupServices, setGroupServicesLoading, setPushMessages, setPushMessagesLoading } from 'src/actions/groupMessagingActions';
import { setCodeServices, setCodeServicesLoading, setCodeServiceCodes, setCodeServiceCodesLoading, setSelectedCodeServiceLoading, setSelectedCodeService } from 'src/actions/codeActions';
import { setCarrierBillingServices, setCarrierBillingServicesLoading, setCarrierBillingTransactions, setCarrierBillingTransactionsLoading } from 'src/actions/carrierBillingActions';
import { setSurveyServices, setSurveyServicesLoading, setSelectedSurveyService, setSelectedSurveyServiceLoading } from 'src/actions/surveyActions';
import { setAdBanServices, setAdBanServicesLoading } from 'src/actions/adBanActions';
import { setFoldersByServiceId, setFoldersLoading } from 'src/actions/folderActions';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { CODESERVICE, DIALOGSERVICE, GROUPSERVICE, SURVEYSERVICE } from 'src/smartDialogProducts';
import { trackEvent } from 'src/utils/appInsights';

export const useLoadAllCustomerServices = (loadServices = false) => {
    const { payload, loading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services'
    }, loadServices);

    const servicesById = React.useMemo(() => {
        return payload?.reduce?.((allServices, service) => ({
            ...allServices,
            [service.id]: { ...service }
        }), {}) ?? {};
    }, [payload]);

    return { servicesById, loading };
};

/**
 * Hook that loads discussion services and dispatches them.
 */
export const useLoadDiscussionServices = () => {
    const dispatch = useDispatch();
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { refreshDiscussionServices } = useSelector((state) => state.dialog);
    const [servicesFetchedForCustomerId, setServicesFetchedForCustomerId] = React.useState(null);
    const [retry, setRetry] = React.useState(false);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const { mutate: fetchServices } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/discussionservices',
        params: {
            'include-service-configurations': true
        }
    }));

    React.useEffect(() => {
        // Only set if loading is true, SetDialogServicesLoaded will set it to false
        dispatch(SetDiscussionServicesLoading(customerHasDialogServiceAccess));
        return () => { dispatch(SetDiscussionServicesLoading(customerHasDialogServiceAccess)); };
    }, [dispatch, customerHasDialogServiceAccess]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, status, payload } = await fetchServices();

            if (error && status > 499 && !retry) {
                setRetry(true);
                trackEvent('Retry when fetching DiscussionServices');
            } else {
                dispatch(SetDiscussionServicesLoaded(Array.isArray(payload) ? payload : []));
            }
        };

        // Do nothing if no dialogservice assigned
        if (!customerHasDialogServiceAccess) {
            return () => { };
        }

        // Re-fetch discussionservices when customer changes
        if (selectedCustomerId !== servicesFetchedForCustomerId) {
            setRetry(false);
            setServicesFetchedForCustomerId(selectedCustomerId);
            makeRequest();

            return () => { };
        }

        // Refresh when refreshDiscussionServices or retry set to true
        if (refreshDiscussionServices || retry) {
            setRetry(false);
            makeRequest();

            return () => { };
        }

        return () => { };
    }, [dispatch, fetchServices, selectedCustomerId, customerHasDialogServiceAccess, servicesFetchedForCustomerId, refreshDiscussionServices, retry]);
};

/**
 * Hook that loads dialog services and dispatches them.
 */
export const useLoadDialogServices = () => {
    const dispatch = useDispatch();
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { refreshDialogServices } = useSelector((state) => state.dialog);
    const [servicesFetchedForCustomerId, setServicesFetchedForCustomerId] = React.useState(null);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/dialog'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        // Only set if loading is true, SetDialogServicesLoaded will set it to false
        dispatch(SetDialogServicesLoading(customerHasDialogServiceAccess));
        return () => { dispatch(SetDialogServicesLoading(customerHasDialogServiceAccess)); };
    }, [dispatch, customerHasDialogServiceAccess]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(SetDialogServicesLoaded(error ? [] : payload));
        };

        if (customerHasDialogServiceAccess && (selectedCustomerId !== servicesFetchedForCustomerId || refreshDialogServices)) {
            setServicesFetchedForCustomerId(selectedCustomerId);
            makeRequest();
        }
    }, [dispatch, fetchServices, selectedCustomerId, customerHasDialogServiceAccess, servicesFetchedForCustomerId, refreshDialogServices]);
};

export const useLoadOneWayServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.onewaymessaging);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/oneway'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setOneWayServicesLoading(true));
        return () => { dispatch(setOneWayServicesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setOneWayServices(error ? [] : payload));
        };

        if (!servicesFetchedOnMount || refreshServices) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices]);
};

export const useLoadTwoWayServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.twowaymessaging);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/twoway'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setTwoWayServicesLoading(true));
        return () => { dispatch(setTwoWayServicesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setTwoWayServices(error ? [] : payload));
        };

        if (!servicesFetchedOnMount || refreshServices) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices]);
};

export const useLoadGroupServices = () => {
    const dispatch = useDispatch();
    const { refreshServices, servicesFetched } = useSelector((state) => state.groupmessaging);

    const hasGroupServiceAccess = useCheckProductAssignment(GROUPSERVICE);

    const { query, loading } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/group',
        params: {
            'include-service-configurations': true
        }
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        // Only set if loading is true, SetDiscussionServicesLoaded will set it to false
        dispatch(setGroupServicesLoading(loading));
        return () => { dispatch(setGroupServicesLoading(loading)); };
    }, [dispatch, loading]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setGroupServices(error ? [] : payload));
        };

        if (hasGroupServiceAccess && (!servicesFetched || refreshServices)) {
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetched, refreshServices, hasGroupServiceAccess]);
};

export const useLoadPushMessages = (serviceId) => {
    const dispatch = useDispatch();
    const { refreshPushMessages } = useSelector((state) => state.groupmessaging);
    const [messagesFetchedOnMount, setMessagesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/group/pushbilling/message/${serviceId}`
    }, false);

    const fetchMessages = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setPushMessagesLoading(true));
        return () => { dispatch(setPushMessagesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchMessages();
            dispatch(setPushMessages(error ? [] : payload));
        };

        if (!messagesFetchedOnMount || refreshPushMessages) {
            setMessagesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchMessages, messagesFetchedOnMount, refreshPushMessages]);
};

export const useLoadCodeServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.codeservice);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const hasCodeServiceAccess = useCheckProductAssignment(CODESERVICE);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/code'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setCodeServicesLoading(hasCodeServiceAccess));
        return () => {
            if (hasCodeServiceAccess) {
                dispatch(setCodeServicesLoading(true));
            }
        };
    }, [dispatch, hasCodeServiceAccess]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setCodeServices(error ? [] : payload.filter((p) => p.status === 'Active')));
        };

        if (hasCodeServiceAccess && (!servicesFetchedOnMount || refreshServices)) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices, hasCodeServiceAccess]);
};

export const useLoadCodeService = (serviceId) => {
    const dispatch = useDispatch();
    const { refreshSelectedCodeService } = useSelector((state) => state.codeservice);
    const [serviceFetchedOnMount, setServiceFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/code/${serviceId}`
    }, false);

    const fetchService = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setSelectedCodeServiceLoading(true));
        return () => { dispatch(setSelectedCodeServiceLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchService();
            dispatch(setSelectedCodeService(error ? [] : payload));
        };

        if (!serviceFetchedOnMount || refreshSelectedCodeService) {
            setServiceFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchService, serviceFetchedOnMount, refreshSelectedCodeService]);
};

export const useLoadCodeServiceCodes = (serviceId) => {
    const dispatch = useDispatch();
    const { refreshCodes } = useSelector((state) => state.codeservice);
    const [codesFetchedOnMount, setCodesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/code/codes?service-id=${serviceId}`
    }, false);

    const fetchCodes = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setCodeServiceCodesLoading(true));
        return () => { dispatch(setCodeServiceCodesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchCodes();
            dispatch(setCodeServiceCodes(error ? [] : payload));
        };

        if (!codesFetchedOnMount || refreshCodes) {
            setCodesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchCodes, codesFetchedOnMount, refreshCodes]);
};

export const useLoadCarrierBillingServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.carrierbilling);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/carrierbilling'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setCarrierBillingServicesLoading(true));
        return () => { dispatch(setCarrierBillingServicesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setCarrierBillingServices(error ? [] : payload));
        };

        if (!servicesFetchedOnMount || refreshServices) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices]);
};

export const useLoadCarrierBillingTransactions = (serviceId) => {
    const dispatch = useDispatch();
    const { refreshTransactions } = useSelector((state) => state.carrierbilling);
    const [transactionsFetchedOnMount, setTransactionsFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/carrierbilling/servicetransactions/${serviceId}`
    }, false);

    const fetchTransactions = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setCarrierBillingTransactionsLoading(true));
        return () => { dispatch(setCarrierBillingTransactionsLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchTransactions();
            dispatch(setCarrierBillingTransactions(error ? [] : payload));
        };

        if (!transactionsFetchedOnMount || refreshTransactions) {
            setTransactionsFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchTransactions, transactionsFetchedOnMount, refreshTransactions]);
};

export const useLoadAdBanServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.adbanservice);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/adban'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setAdBanServicesLoading(true));
        return () => { dispatch(setAdBanServicesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setAdBanServices(error ? [] : payload));
        };

        if (!servicesFetchedOnMount || refreshServices) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices]);
};

export const useLoadSurveyServices = () => {
    const dispatch = useDispatch();
    const { refreshServices } = useSelector((state) => state.surveyservice);
    const [servicesFetchedOnMount, setServicesFetchedOnMount] = React.useState(false);

    const hasSurveyServiceAccess = useCheckProductAssignment(SURVEYSERVICE);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/survey'
    }, false);

    const fetchServices = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setSurveyServicesLoading(true));
        return () => { dispatch(setSurveyServicesLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchServices();
            dispatch(setSurveyServices(error ? [] : payload));
        };

        if (hasSurveyServiceAccess && (!servicesFetchedOnMount || refreshServices)) {
            setServicesFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchServices, servicesFetchedOnMount, refreshServices, hasSurveyServiceAccess]);
};

export const useLoadSurveyService = (serviceId) => {
    const dispatch = useDispatch();
    const { refreshSelectedSurveyService } = useSelector((state) => state.surveyservice);
    const [serviceFetchedOnMount, setServiceFetchedOnMount] = React.useState(false);

    const hasSurveyServiceAccess = useCheckProductAssignment(SURVEYSERVICE);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/survey/${serviceId}`
    }, false);

    const fetchService = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setSelectedSurveyServiceLoading(true));
        return () => { dispatch(setSelectedSurveyServiceLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchService();
            dispatch(setSelectedSurveyService(error ? [] : payload));
        };

        if (hasSurveyServiceAccess && (!serviceFetchedOnMount || refreshSelectedSurveyService)) {
            setServiceFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchService, serviceFetchedOnMount, refreshSelectedSurveyService, hasSurveyServiceAccess]);
};

export const useLoadFolders = () => {
    const dispatch = useDispatch();
    const { refreshFolders } = useSelector((state) => state.folders);
    const [foldersFetchedOnMount, setFoldersFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/twoway/foldersodata',
        params: {
            $top: 1000
        }
    }, false);

    const fetchFolders = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch(setFoldersLoading(true));
        return () => { dispatch(setFoldersLoading(true)); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await fetchFolders();
            dispatch(setFoldersByServiceId(payload?.value));
        };

        if (!foldersFetchedOnMount || refreshFolders) {
            setFoldersFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchFolders, foldersFetchedOnMount, refreshFolders]);
};