import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { LinearProgress } from '@material-ui/core';
import { useParams } from 'react-router';
import LoadingOverlay from 'src/components/LoadingOverlay';
import BarChart from 'src/components/Charts/BarChart';
import { theme } from 'src/theme';
import moment from 'moment';
import { sum } from 'src/utils/arrayFunctions';
import SaveAsWidgetOverlay from 'src/components/Widgets/SaveAsWidgetOverlay';
import { useSelector } from 'react-redux';

const barThickness = 25;
const maxBarThickness = 22;

const doughnutDataModel = (data, groupBy) => {
    const resp = data?.reduce((acc, current) => {
        const _key = moment(current?.key).startOf(groupBy).toJSON();

        const smsAmount = current?.messages?.SMS ?? 0;
        const whatsappAmount = current?.messages?.Whatsapp ?? 0;

        return {
            ...acc,
            [_key]: {
                SMS: (acc?.[_key]?.SMS ?? 0) + smsAmount,
                Whatsapp: (acc?.[_key]?.Whatsapp ?? 0) + whatsappAmount
            }
        };
    }, {});

    const getProperty = (propName) => {
        return Object.keys(resp ?? {}).map((key) => ({ x: key, y: resp?.[key]?.[propName] }));
    };

    const whatsappData = getProperty('Whatsapp');
    const whatsappTotal = sum(whatsappData)((data) => data?.y ?? 0);

    const smsData = getProperty('SMS');
    const smsTotal = sum(smsData)((data) => data?.y ?? 0);

    return {
        datasets: [
            {
                label: `WhatsApp - ${whatsappTotal}`,
                tooltipLabel: 'WhatsApp',
                backgroundColor: theme.palette.status.delivered.main,
                data: whatsappData,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            },
            {
                label: `SMS - ${smsTotal}`,
                tooltipLabel: 'SMS',
                backgroundColor: theme.palette.status.pending.main,
                data: smsData,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            }
        ]
    };
};

export const MessageStatsBarChart = ({ data, groupBy, chips, isWidget }) => {
    return <BarChart data={doughnutDataModel(data, groupBy)} groupBy={groupBy} chips={chips} isWidget={isWidget} />;
};

MessageStatsBarChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        messages: PropTypes.shape({
            whatsapp: PropTypes.number,
            sms: PropTypes.number
        })
    })).isRequired,
    groupBy: PropTypes.oneOf(['hour', 'day', 'week', 'month', 'quarter', 'year']).isRequired,
    chips: PropTypes.arrayOf(PropTypes.string),
    isWidget: PropTypes.bool
};

const DialogMessageStatisticsChart = ({ requestOptions: { from, to, groupBy, direction }, interval }) => {
    const { serviceId } = useParams();

    const { dialogServicesById } = useSelector((state) => state.dialog);
    const serviceName = dialogServicesById?.[serviceId]?.name ?? 'Dialog Service';

    const requestSettings = {
        method: 'GET',
        endpoint: '/messaging/services/dialog/statistics/protocols',
        params: {
            sourceServiceId: serviceId,
            from,
            to,
            groupBy,
            ...['Inbound', 'Outbound'].includes(direction) ? { direction } : {}
        }
    };
    const { payload, loading } = useHttpGetRequest(requestSettings);

    return (
        <Suspense fallback={<LinearProgress />}>
            <LoadingOverlay loading={loading}>
                <SaveAsWidgetOverlay
                    component="DialogMessageStatisticsWidget"
                    settings={{
                        title: 'Dialog Message Statistics',
                        requestSettings,
                        interval,
                        groupBy,
                        chips: [
                            `Service name: ${serviceName}`,
                            `Direction: ${direction}`
                        ]
                    }}
                >
                    <MessageStatsBarChart
                        data={Array.isArray(payload) ? payload : []}
                        groupBy={groupBy}
                    />
                </SaveAsWidgetOverlay>
            </LoadingOverlay>
        </Suspense>
    );
};

DialogMessageStatisticsChart.propTypes = {
    requestOptions: PropTypes.object.isRequired,
    interval: PropTypes.shape({
        definedInterval: PropTypes.string.isRequired,
        from: PropTypes.object.isRequired,
        to: PropTypes.object.isRequired
    })
};

export default DialogMessageStatisticsChart;