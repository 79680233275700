import * as actionTypes from 'src/actions';

const initialState = {
    messagingAccounts: [],
    messagingAccountsLoading: true,
    defaultMessagingAccount: null,
    defaultMessagingAccountLoading: true
};

const messagingAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MESSAGINGACCOUNTSLOADING: {
            return {
                ...state,
                messagingAccountsLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_MESSAGINGACCOUNTS: {
            return {
                ...state,
                messagingAccounts: action.data?.accounts ?? [],
                messagingAccountsLoading: false
            };
        }
        case actionTypes.SET_MESSAGINGACCOUNT: {
            return {
                ...state,
                messagingAccounts: [
                    ...state.messagingAccounts.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case actionTypes.SET_DEFAULTMESSAGINGACCOUNTLOADING: {
            return {
                ...state,
                defaultMessagingAccountLoading: true
            };
        }
        case actionTypes.SET_DEFAULTMESSAGINGACCOUNT: {
            return {
                ...state,
                defaultMessagingAccount: action.data,
                defaultMessagingAccountLoading: false
            };
        }
        case actionTypes.RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default messagingAccountReducer;
