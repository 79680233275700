import { DISCARD_CHANGES, RESET_REDUCER_STATE } from 'src/actions';
import { calculatePipelineActionOutputs } from 'src/actions/actionHooks/servicePipelines';
import * as actions from 'src/actions/servicePipelineActions';
import * as GroupServiceActions from 'src/actions/groupMessagingActions';

const initialState = {
    actionPipeline: null,
    unModifiedPipelineActionsById: {},
    orderedPipelineActionIds: [],
    pipelineActionsById: {},
    pipelineActionIdsUsedAsOutput: {},
    mainKeywordId: null,
    customContactPropertiesByGroupServiceId: {},

    serviceType: 'twoway',
    serviceTypeUrlPath: 'twowaymessaging',

    triggerPipelineRefresh: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ADD_ACTION: {
            const { actionType, insertIndex } = action.data;
            const _pipelineActionsById = {
                ...state.pipelineActionsById,
                new: {
                    id: 'new',
                    actionType,
                    executionOrder: insertIndex
                }
            };
            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(_pipelineActionsById, state.pipelineActionIdsUsedAsOutput);

            return {
                ...state,
                orderedPipelineActionIds,
                pipelineActionsById
            };
        }
        case actions.CANCEL_ADD_EDIT_ACTION: {
            const cancelledActionId = action?.data;
            const _pipelineActionsById = { ...state.pipelineActionsById };

            const _editedAction = state.pipelineActionsById?.[cancelledActionId];
            const _pipelineActionIdsUsedAsOutput = { ...state.pipelineActionIdsUsedAsOutput };

            if (!_editedAction) {
                return { ...state };
            }

            if (cancelledActionId === 'new') {
                const parentId = _editedAction.availableOutputActionId;
                delete _pipelineActionsById[cancelledActionId];

                const index = _pipelineActionIdsUsedAsOutput[parentId]?.indexOf(cancelledActionId);
                if (index > -1) {
                    _pipelineActionIdsUsedAsOutput[parentId]?.splice?.(index, 1);
                }
            } else if (state.unModifiedPipelineActionsById?.[cancelledActionId]) {
                _pipelineActionsById[cancelledActionId] = state.unModifiedPipelineActionsById[cancelledActionId];
            }

            // Clear conditions

            if (state.unModifiedPipelineActionsById[cancelledActionId]?.availableOutputActionId) {
                const parentId = state.unModifiedPipelineActionsById[cancelledActionId].availableOutputActionId;
                const childId = cancelledActionId;

                if (state.unModifiedPipelineActionsById[cancelledActionId]?.conditions?.length === 0) {
                    _pipelineActionIdsUsedAsOutput[parentId] = state.pipelineActionIdsUsedAsOutput[parentId]?.reduce?.((children, _childId) => {
                        if (_childId === childId) {
                            return children ?? null;
                        }
                        return children ? [...children, _childId] : [_childId];
                    }, null) ?? null;
                }
                if (state.unModifiedPipelineActionsById[cancelledActionId]?.conditions?.length > 0) {
                    _pipelineActionIdsUsedAsOutput[parentId] = [
                        ...state.pipelineActionIdsUsedAsOutput?.[parentId] ?? [],
                        childId
                    ];
                }
            }

            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(_pipelineActionsById, _pipelineActionIdsUsedAsOutput);

            return {
                ...state,
                orderedPipelineActionIds,
                pipelineActionsById,
                unModifiedPipelineActionsById: {},
                pipelineActionIdsUsedAsOutput: _pipelineActionIdsUsedAsOutput
            };
        }
        case actions.UPDATE_ACTION: {
            const updatedActionId = action?.data?.id;
            const updatedAction = state.pipelineActionsById?.[updatedActionId];

            if (!updatedAction) {
                return { ...state };
            }
            const _pipelineActionsById = { ...state.pipelineActionsById };
            _pipelineActionsById[updatedActionId] = { ...action.data };

            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(_pipelineActionsById, state.pipelineActionIdsUsedAsOutput);

            let _unModifiedPipelineActionsById = state.unModifiedPipelineActionsById;

            if (updatedActionId !== 'new' && !_unModifiedPipelineActionsById[updatedActionId]) {
                _unModifiedPipelineActionsById = {
                    ...state.unModifiedPipelineActionsById,
                    [updatedActionId]: { ...state.pipelineActionsById[updatedActionId] }
                };
            }

            return {
                ...state,
                orderedPipelineActionIds,
                pipelineActionsById,
                unModifiedPipelineActionsById: _unModifiedPipelineActionsById
            };
        }
        case actions.SET_MAINKEYWORD_ID: {
            return {
                ...state,
                mainKeywordId: action.data
            };
        }
        case actions.SET_ACTION_PIPELINE: {
            const _pipelineActionsById = action?.data?.actions?.reduce?.((actions, action) => ({ ...actions, [action?.id]: action }), {});
            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(_pipelineActionsById, state.pipelineActionIdsUsedAsOutput);
            return {
                ...state,
                actionPipeline: action.data,
                orderedPipelineActionIds,
                pipelineActionsById,
                unModifiedPipelineActionsById: pipelineActionsById
            };
        }
        case GroupServiceActions.SET_GROUPSERVICES: {
            return {
                ...state,
                customContactPropertiesByGroupServiceId: action?.data?.reduce?.((properties, groupService) => {
                    return {
                        ...properties,
                        [groupService?.id]: Array.isArray(groupService?.serviceConfiguration?.customContactProperties) ? groupService.serviceConfiguration.customContactProperties : []
                    };
                }, {}) ?? {}
            };
        }
        case actions.SET_PIPELINE_ACTIONS: {
            return {
                ...state,
                ...action.data
            };
        }
        case actions.SAVE_ACTION: {
            return {
                ...state,
                triggerPipelineRefresh: !state.triggerPipelineRefresh
            };
        }
        case actions.DELETE_ACTION: {
            return {
                ...state,
                triggerPipelineRefresh: !state.triggerPipelineRefresh,
                pipelineActionIdsUsedAsOutput: {}
            };
        }
        case actions.SET_SERVICE_TYPE: {
            return {
                ...state,
                serviceType: action.data,
                serviceTypeUrlPath: action.data === 'twoway' ? 'twowaymessaging' : 'groupmessaging'

            };
        }
        case actions.MARK_AS_OUTPUT_USER: {
            const parentId = action?.data?.parentId;
            const childId = action?.data?.childId;

            if (!parentId || !childId || state.pipelineActionIdsUsedAsOutput?.[parentId]?.find?.((id) => id === childId)) {
                return { ...state };
            }
            const _pipelineActionIdsUsedAsOutput = {
                ...state.pipelineActionIdsUsedAsOutput,
                [parentId]: [
                    ...state.pipelineActionIdsUsedAsOutput?.[parentId] ?? [],
                    childId
                ]
            };
            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(state.pipelineActionsById, _pipelineActionIdsUsedAsOutput);
            return {
                ...state,
                pipelineActionIdsUsedAsOutput: _pipelineActionIdsUsedAsOutput,
                orderedPipelineActionIds,
                pipelineActionsById
            };
        }
        case actions.UNMARK_AS_OUTPUT_USER: {
            const parentId = action?.data?.parentId;
            const childId = action?.data?.childId;

            if (!parentId || !childId || !state.pipelineActionIdsUsedAsOutput?.[parentId]?.find?.((id) => id === childId)) {
                return { ...state };
            }
            const _pipelineActionIdsUsedAsOutput = {
                ...state.pipelineActionIdsUsedAsOutput,
                [parentId]: state.pipelineActionIdsUsedAsOutput[parentId].reduce((children, _childId) => {
                    if (_childId === childId) {
                        return children ?? null;
                    }
                    return children ? [...children, _childId] : [_childId];
                }, null)
            };

            const { orderedPipelineActionIds, pipelineActionsById } = calculatePipelineActionOutputs(state.pipelineActionsById, _pipelineActionIdsUsedAsOutput);
            return {
                ...state,
                pipelineActionIdsUsedAsOutput: _pipelineActionIdsUsedAsOutput,
                orderedPipelineActionIds,
                pipelineActionsById
            };
        }
        case DISCARD_CHANGES: {
            return { ...state };
        }
        case actions.RESET_SERVICE_PIPELINE:
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return { ...state };
        }
    }
};

export default reducer;