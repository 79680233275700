export const SET_FOLDERS_BY_SERVICEID = 'SET_FOLDERS_BY_SERVICEID';
export const SET_FOLDERS_LOADING = 'SET_FOLDERS_LOADING';
export const REFERSH_FOLDERS = 'REFERSH_FOLDERS';

export const setFoldersByServiceId = (data) => (dispatch) => dispatch({
    type: SET_FOLDERS_BY_SERVICEID,
    data
});

export const setFoldersLoading = (data) => (dispatch) => dispatch({
    type: SET_FOLDERS_LOADING,
    data
});

export const refreshFolders = () => (dispatch) => dispatch({
    type: REFERSH_FOLDERS
});