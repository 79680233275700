import React from 'react';
import { Button, Card, CardContent, CardMedia, IconButton, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/styles';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import PropTypes from 'prop-types';
import { parseDisplayContent } from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection';
import { useDispatch, useSelector } from 'react-redux';
import MessageTemplateDialog from 'src/components/MessageTemplates/MessageTemplateDialog';
import { refreshMessageTemplates } from 'src/actions/messageTemplateActions';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 0,
        zIndex: 1000
    },
    headerRow: {
        backgroundColor: theme.palette.background.gray,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        '&& *': {
            color: theme.palette.white
        }
    },
    headerRowButtonContainer: {
        marginLeft: 'auto'
    },
    templateButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxHeight: 200,
        overflow: 'auto',
        zIndex: 9999,
        padding: theme.spacing(1)
    },
    templateButton: {
        '&&:not(last-child)': {
            marginBottom: theme.spacing(1)
        }
    }
}));

const QuickreplyTemplateSelection = ({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { quickReplyTemplates, messageTemplatesLoading } = useSelector((state) => state.messageTemplates);
    const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = React.useState(false);
    const [createdTemplateId, setCreatedTemplateId] = React.useState(null);
    const _onClose = React.useCallback(onClose, []);

    const createdTemplate = React.useMemo(() => quickReplyTemplates?.find?.((tmpl) => tmpl?.id === createdTemplateId), [quickReplyTemplates, createdTemplateId]);

    // Close and select the newly created template
    React.useEffect(() => {
        if (createdTemplate) {
            setCreateTemplateDialogOpen(false);
            setCreatedTemplateId(null);
            _onClose({ template: createdTemplate });
        }
    }, [createdTemplate, _onClose]);

    const handleMessageTemplateDialogClose = (templateId) => {
        if (templateId) {
            dispatch(refreshMessageTemplates());
            setCreatedTemplateId(templateId);
        }
    };

    const onCloseClick = (template) => () => {
        setCreateTemplateDialogOpen(false);
        onClose({ template });
    };

    return (
        <>
            <Card className={classes.card} elevation={0} variant="outlined">
                <CardMedia className={classes.headerRow}>
                    <Typography variant="h5">
                        Select Quick Reply
                    </Typography>
                    <Tooltip title="Create Quick Reply">
                        <IconButton size="small" variant="contained" color="primary" onClick={() => setCreateTemplateDialogOpen(true)}><AddIcon /></IconButton>
                    </Tooltip>
                    <div className={classes.headerRowButtonContainer}>
                        <Tooltip title="Close">
                            <IconButton size="small" variant="contained" color="primary" onClick={onCloseClick()}><CloseIcon /></IconButton>
                        </Tooltip>
                    </div>
                </CardMedia>
                <CardContent>
                    <div className={classes.templateButtonContainer}>
                        {messageTemplatesLoading && <div style={{ width: '100%' }}><DelayedLinearProgress /></div>}
                        {!messageTemplatesLoading && quickReplyTemplates?.length === 0 && (
                            <>
                                <Typography variant="body1">
                                    No Quick Replies available.
                                </Typography>
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => setCreateTemplateDialogOpen(true)}
                                >
                                    Create Quick Reply
                                </Button>
                            </>
                        )}
                        {quickReplyTemplates?.map?.((tmpl) => (
                            <Button
                                key={tmpl?.id}
                                className={classes.templateButton}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={onCloseClick(tmpl)}
                            >
                                {parseDisplayContent(tmpl?.content)}
                            </Button>
                        ))}
                    </div>
                </CardContent>
            </Card>
            <MessageTemplateDialog channelType="QuickReply" open={createTemplateDialogOpen} setOpen={setCreateTemplateDialogOpen} handleClose={handleMessageTemplateDialogClose} />
        </>
    );
};

QuickreplyTemplateSelection.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default QuickreplyTemplateSelection;