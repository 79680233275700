import { fetchMock } from 'src/utils/mock';

fetchMock.get(`begin:${process.env.REACT_APP_HOST}/messagepersistence/statistics/twoway`, [
    {
        key: '2020-06-15T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-08-10T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-08-17T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-08-24T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-08-31T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-09-07T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-09-21T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    },
    {
        key: '2020-10-05T00:00:00Z',
        messages: {
            whatsapp: 20,
            sms: 10
        }
    }
]);