import React from 'react';
import WidgetGrid from 'src/components/Widgets/WidgetGrid';
import { WidgetContextProvider, useWidgetDispatch, actions as widgetActions, useWidgetContext } from 'src/components/Widgets/WidgetContext';
import { useHttpGetRequest, useHttpRequest } from 'src/utils/httpClient';
import WidgetGridMenu from 'src/components/Widgets/WidgetGridMenu';
import ServicesProvider from 'src/components/ServicesProvider';
import MessageTrafficProvider from 'src/components/SummaryWidget/MessageTrafficProvider';
import { useSnackbar } from 'src/components/Snackbar';
import { useParams } from 'react-router-dom';
import { useAssignedProducts } from 'src/auth/ProductAccess';
import { SMARTAGENTSERVICE } from 'src/smartDialogProducts';

const WidgetContainer = () => {
    const { updateWidgets, widgetIdToRemove, changedLayoutApiModel, saveLayoutCalled } = useWidgetContext();
    const dispatch = useWidgetDispatch();
    const { customerId } = useParams();
    const { success: successSnack } = useSnackbar();
    const assignedProducts = useAssignedProducts();

    const { payload, loading, query: _refreshWidgets } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/widgets'
    }, false);

    const availableWidgets = React.useMemo(() => {
        if (!Array.isArray(payload)) {
            return [];
        }

        return payload.filter((item) => {
            if (item?.component !== 'SmartAgentWidget') {
                return true;
            }
            if (assignedProducts[SMARTAGENTSERVICE]) {
                return true;
            }
            return false;
        });
    }, [payload, assignedProducts]);

    const refreshWidgets = React.useCallback(_refreshWidgets, []);

    const { mutate: _removeWidget } = useHttpRequest((id) => ({
        endpoint: `/widgets/${id}`,
        method: 'DELETE'
    }));

    const removeWidget = React.useCallback(_removeWidget, []);

    const { mutate: _saveLayout } = useHttpRequest((body) => ({
        endpoint: '/widgets/layout',
        method: 'PUT',
        body
    }));

    const saveLayout = React.useCallback(_saveLayout, []);

    React.useEffect(() => {
        if (customerId) {
            refreshWidgets();
        }
    }, [customerId, updateWidgets, refreshWidgets]);

    React.useEffect(() => {
        dispatch({ type: widgetActions.SET_WIDGETS_LOADING, data: loading });
    }, [dispatch, loading]);

    React.useEffect(() => {
        dispatch({ type: widgetActions.SET_WIDGETS, data: availableWidgets });
    }, [availableWidgets, dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error } = await saveLayout(changedLayoutApiModel);
            if (!error) {
                successSnack('Widget layout saved');
                dispatch({ type: widgetActions.LAYOUT_SAVED });
            } else {
                dispatch({ type: widgetActions.LAYOUT_SAVE_ERROR });
            }
        };

        if (changedLayoutApiModel && saveLayoutCalled) {
            makeRequest();
        }
    }, [saveLayoutCalled, changedLayoutApiModel, saveLayout, dispatch, successSnack]);

    React.useEffect(() => {
        const remove = async () => {
            const { error } = await removeWidget(widgetIdToRemove);
            if (!error) {
                successSnack('Widget removed');
                dispatch({ type: widgetActions.WIDGET_REMOVED, data: widgetIdToRemove });
            }
        };

        if (widgetIdToRemove) {
            remove();
        }
    }, [widgetIdToRemove, dispatch, removeWidget, successSnack]);

    const summaryWidgetInUse = React.useMemo(() => {
        return Boolean(availableWidgets?.find?.((w) => w?.component === 'SummaryWidget'));
    }, [availableWidgets]);

    const TwoWaymessagingEventsWidgetInUse = React.useMemo(() => {
        return Boolean(availableWidgets?.find?.((w) => w?.component === 'TwoWayMessagingEventsWidget'));
    }, [availableWidgets]);

    const FavouriteServiceWidgetInUse = React.useMemo(() => {
        return Boolean(availableWidgets?.find?.((w) => w?.component === 'FavouriteServiceWidget'));
    }, [availableWidgets]);

    return (
        <>
            {(summaryWidgetInUse || TwoWaymessagingEventsWidgetInUse || FavouriteServiceWidgetInUse) && (
                <ServicesProvider />
            )}
            {summaryWidgetInUse && (
                <MessageTrafficProvider />
            )}
            <WidgetGridMenu />
            <WidgetGrid />
        </>
    );
};

export default () => <WidgetContextProvider><WidgetContainer /></WidgetContextProvider>;