import React from 'react';
import { withStyles } from '@material-ui/styles';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import DoneIcon from '@material-ui/icons/DoneOutlineRounded';
import { Typography, Grid, Collapse, Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { trackEvent, trackException } from 'src/utils/appInsights';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'uuid';

const styles = (theme) => ({
    container: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    errorIcon: {
        'font-size': 60
    },
    errorText: {
        marginBottom: 10
    },
    formControl: {
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        padding: 0,
        paddingBottom: 0
    },
    reportForm: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    reportSubmitted: {
        padding: theme.spacing(2),
        display: 'flex'
    }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            correlationId: uuid(),
            name: props?.claims?.name ?? '',
            email: props?.claims?.mail ?? '',
            message: '',
            reportFormVisible: false,
            reportSubmitted: false,
            hasError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleToggleReportForm = this.handleToggleReportForm.bind(this);
    }

    static getDerivedStateFromError(/* error */) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, /* errorInfo */) {
        // You can also log the error to an error reporting service
        const { correlationId } = this.state;
        trackException(error, { correlationId });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { name, email, message, correlationId } = this.state;
        const { claims, match } = this.props;
        trackEvent('UserSubmittedErrorReport', {
            correlationId,
            userId: claims?.sub,
            customerId: match?.params?.customerId ?? null,
            name,
            email,
            message,
            url: window.location.href
        });
        this.setState({ reportSubmitted: true });
    }

    handleToggleReportForm(e) {
        const { reportFormVisible } = this.state;
        this.setState({ reportFormVisible: !reportFormVisible });
        e.preventDefault();
    }

    // eslint-disable-next-line class-methods-use-this
    refreshPage() {
        window.location.reload();
    }

    render() {
        const { classes, children } = this.props;
        const { reportFormVisible, reportSubmitted, name, email, message, hasError } = this.state;

        if (hasError) {
            return (
                <Grid
                    className={classes.container}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Card>
                        <CardHeader
                            avatar={
                                <SentimentDissatisfiedIcon className={classes.errorIcon} />
                            }
                            title={(<Typography variant="h3">Oops something went wrong!</Typography>)}
                            subheader={(
                                <>
                                    <Typography variant="h5" className={classes.errorText}>Try refreshing the page or submit an error report</Typography>
                                    <Button variant="contained" color="primary" onClick={this.refreshPage} style={{ marginRight: 10 }}>Refresh Page</Button>
                                    {!reportFormVisible && <Button variant="contained" color="secondary" onClick={this.handleToggleReportForm}>Submit Report</Button>}
                                </>
                            )}
                        />
                        <CardContent
                            style={{ padding: 0 }}
                        >
                            <Collapse
                                in={reportFormVisible}
                            >
                                {reportSubmitted ? (
                                    <div className={classes.reportSubmitted}>
                                        <DoneIcon style={{ marginRight: 10 }} />
                                        <Typography variant="h5">Thank you for your submission. We will take a look as soon as possible.</Typography>
                                    </div>
                                ) : (

                                    <div className={classes.reportForm}>
                                        <TextField
                                            className={classes.formControl}
                                            name="name"
                                            id="name"
                                            variant="outlined"
                                            label="Name"
                                            value={name}
                                            onChange={this.handleChange}
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            name="email"
                                            id="email"
                                            variant="outlined"
                                            label="Email"
                                            value={email}
                                            onChange={this.handleChange}
                                            fullWidth
                                        />
                                        <TextField
                                            className={classes.formControl}
                                            multiline
                                            name="message"
                                            id="message"
                                            variant="outlined"
                                            label="Message"
                                            value={message}
                                            onChange={this.handleChange}
                                            fullWidth
                                            minRows={4}
                                        />
                                        <Button variant="contained" color="primary" onClick={this.handleSubmit}>Submit</Button>
                                    </div>
                                )}
                            </Collapse>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
    claims: PropTypes.object,
    classes: PropTypes.object,
    match: PropTypes.object
};

const mapStateToProps = (state) => {
    return { claims: state?.auth?.claims ?? {} };
};

const ErrorBoundaryWithProps = connect(mapStateToProps)(withRouter(withStyles(styles)(ErrorBoundary)));
export default ErrorBoundaryWithProps;

const ErrorThrowingComponent = () => {
    throw new Error('Error from ErrorThrowingComponent');
};

export function ErrorBoundaryTest() {
    return <ErrorBoundaryWithProps><ErrorThrowingComponent /></ErrorBoundaryWithProps>;
}