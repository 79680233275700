import { useLoadMessageTraffic } from 'src/actions/actionHooks';
import moment from 'moment';

const MessageTrafficProvider = () => {
    const fromDate = moment().subtract(1, 'months').startOf('month').valueOf();
    useLoadMessageTraffic(fromDate);

    return null;
};

export default MessageTrafficProvider;