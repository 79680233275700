export const SET_TWOWAYSERVICES = 'SET_TWOWAYSERVICES';
export const SET_TWOWAYSERVICE = 'SET_TWOWAYSERVICE';

export const SET_TWOWAYSERVICES_LOADING = 'SET_TWOWAYSERVICES_LOADING';

export const TWOWAYSERVICEUPDATED = 'TWOWAYSERVICEUPDATED';
export const TWOWAYSERVICEDELETED = 'TWOWAYSERVICEDELETED';

export const SET_CUSTOMER_INPUTSOURCES = 'SET_CUSTOMER_INPUTSOURCES';
export const SET_CUSTOMER_INPUTSOURCES_LOADING = 'SET_CUSTOMER_INPUTSOURCES_LOADING';

export const SET_NEW_TWOWAYSERVICE = 'SET_NEW_TWOWAYSERVICE';
export const SET_EDITED_TWOWAYSERVICE = 'SET_EDITED_TWOWAYSERVICE';
export const SET_TWOWAYSERVICE_LOADING = 'SET_TWOWAYSERVICE_LOADING';
export const SET_EDITEDTWOWAYSERVICE_INITIAL_HIDDENCONTENT = 'SET_EDITEDTWOWAYSERVICE_INITIAL_HIDDENCONTENT';

export const REFERSH_TWOWAYSERVICES = 'REFERSH_TWOWAYSERVICES';

export const CLEAR_NEW_TWOWAY_SERVICE = 'CLEAR_NEW_TWOWAY_SERVICE';

export const setTwoWayServices = (data) => (dispatch) => dispatch({
    type: SET_TWOWAYSERVICES,
    data
});

export const setTwoWayService = (data) => (dispatch) => dispatch({
    type: SET_TWOWAYSERVICE,
    data
});

export const setTwoWayServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_TWOWAYSERVICES_LOADING,
    data
});

export const setCustomerInputSourcesLoading = () => (dispatch) => dispatch({
    type: SET_CUSTOMER_INPUTSOURCES_LOADING
});

export const setCustomerInputSources = (data) => (dispatch) => dispatch({
    type: SET_CUSTOMER_INPUTSOURCES,
    data
});

export const refreshTwoWayServices = () => (dispatch) => dispatch({
    type: REFERSH_TWOWAYSERVICES
});

export const twoWayServiceUpdated = (data) => (dispatch) => dispatch({
    type: TWOWAYSERVICEUPDATED,
    data
});

export const twoWayServiceDeleted = (serviceId) => (dispatch) => dispatch({
    type: TWOWAYSERVICEDELETED,
    data: serviceId
});

export const setNewTwowayService = (data) => (dispatch) => dispatch({
    type: SET_NEW_TWOWAYSERVICE,
    data
});

export const setEditedTwowayService = (data) => (dispatch) => dispatch({
    type: SET_EDITED_TWOWAYSERVICE,
    data
});

export const setTwowayServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_TWOWAYSERVICE_LOADING,
    data
});

export const setEditedTwowayServiceInitialHiddenContent = (data) => (dispatch) => dispatch({
    type: SET_TWOWAYSERVICE_LOADING,
    data
});

export const clearNewTwoWayService = () => (dispatch) => dispatch({
    type: CLEAR_NEW_TWOWAY_SERVICE
});