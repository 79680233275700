import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/carrierBillingActions';
import { sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    servicesLoading: true,
    services: [],
    selectedCarrierBillingService: null,
    selectedCarrierBillingServiceLoading: true,
    transactions: [],
    transactionsLoading: false,
    refreshTransactions: false,
};

const carrierBillingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CARRIERBILLINGSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_CARRIERBILLINGSERVICES: {
            return {
                ...state,
                servicesLoading: false,
                services: Array.isArray(action?.data) ? action.data.sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.SET_SELECTEDCARRIERBILLINGSERVICE: {
            return {
                ...state,
                selectedCarrierBillingService: action.data,
                selectedCarrierBillingServiceLoading: false
            };
        }
        case actionTypes.SET_SELECTEDCARRIERBILLINGSERVICELOADING: {
            return {
                ...state,
                selectedCarrierBillingServiceLoading: true
            };
        }
        case actionTypes.SET_CARRIERBILLINGTRANSACTIONS: {
            return {
                ...state,
                transactions: action.data,
                transactionsLoading: false,
                refreshTransactions: false,
            };
        }
        case actionTypes.SET_CARRIERBILLINGTRANSACTIONS_LOADING: {
            return {
                ...state,
                transactionsLoading: true
            };
        }
        case actionTypes.REFERSH_CARRIERBILLINGTRANSACTIONS: {
            return {
                ...state,
                refreshTransactions: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default carrierBillingReducer;