import React from 'react';
import PropTypes from 'prop-types';
import { sizes } from 'src/components/Widgets/widgetSizes';

const WidgetContext = React.createContext();
const WidgetDispatchContext = React.createContext();

export const actions = {
    SET_WIDGETS: 'SET_WIDGETS',
    SET_WIDGETS_LOADING: 'SET_WIDGETS_LOADING',

    LAYOUT_SAVE: 'LAYOUT_SAVE',
    LAYOUT_CANCEL: 'LAYOUT_CANCEL',
    LAYOUT_EDIT: 'LAYOUT_EDIT',
    LAYOUT_CHANGED: 'LAYOUT_CHANGED',
    LAYOUT_SAVED: 'LAYOUT_SAVED',
    LAYOUT_SAVE_ERROR: 'LAYOUT_SAVE_ERROR',

    UPDATE_WIDGETS: 'UPDATE_WIDGETS',

    REMOVE_WIDGET: 'REMOVE_WIDGET',

    WIDGET_REMOVED: 'WIDGET_REMOVED'
};

export const defaultState = {
    editMode: false,
    widgetsLoading: true,
    updateWidgets: false,
    widgetIdToRemove: null,
    changedLayout: null,
    changedLayoutApiModel: null,

    saveLayoutCalled: false,

    layout: {},
    widgets: []
};

const mapLayout = (widgets) => {
    return widgets?.reduce((acc, current) => ({
        ...acc,
        xl: [
            ...acc.xl,
            { i: current?.id, x: current?.layout?.xl?.x ?? 0, y: current?.layout?.xl?.y ?? 0, w: sizes?.[current?.component]?.xl?.w, h: sizes?.[current?.component]?.xl?.h }
        ],
        lg: [
            ...acc.lg,
            { i: current?.id, x: current?.layout?.lg?.x ?? 0, y: current?.layout?.lg?.y ?? 0, w: sizes?.[current?.component]?.lg?.w, h: sizes?.[current?.component]?.lg?.h }
        ],
        md: [
            ...acc.md,
            { i: current?.id, x: current?.layout?.md?.x ?? 0, y: current?.layout?.md?.y ?? 0, w: sizes?.[current?.component]?.md?.w, h: sizes?.[current?.component]?.md?.h }
        ],
        xs: [
            ...acc.xs,
            { i: current?.id, x: current?.layout?.xs?.x ?? 0, y: current?.layout?.xs?.y ?? 0, w: sizes?.[current?.component]?.xs?.w, h: sizes?.[current?.component]?.xs?.h }
        ],
        xxs: [
            ...acc.xxs,
            { i: current?.id, x: current?.layout?.xxs?.x ?? 0, y: current?.layout?.xxs?.y ?? 0, w: sizes?.[current?.component]?.xxs?.w, h: sizes?.[current?.component]?.xxs?.h }
        ]
    }), { xl: [], lg: [], md: [], xs: [], xxs: [] }) ?? {};
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_WIDGETS: {
            return {
                ...state,
                widgets: action?.data ?? [],
                layout: mapLayout(action?.data)
            };
        }
        case actions.SET_WIDGETS_LOADING: {
            return {
                ...state,
                widgetsLoading: action.data
            };
        }
        case actions.UPDATE_WIDGETS: {
            return {
                ...state,
                updateWidgets: !state.updateWidgets
            };
        }
        case actions.REMOVE_WIDGET: {
            return {
                ...state,
                widgetIdToRemove: action.data
            };
        }
        case actions.WIDGET_REMOVED: {
            const widgets = state.widgets?.filter((w) => w?.id !== action?.data);

            return {
                ...state,
                widgetIdToRemove: null,
                widgets,
                layout: mapLayout(widgets)
            };
        }
        case actions.LAYOUT_CHANGED: {
            const layouts = action.data;
            const widgetLayout = {};

            Object.keys(layouts).forEach((breakpoint) => {
                layouts[breakpoint].forEach((breakpointWidget) => {
                    widgetLayout[breakpointWidget.i] = {
                        ...widgetLayout[breakpointWidget.i],
                        [breakpoint]: {
                            x: breakpointWidget?.x ?? 0,
                            y: breakpointWidget?.y ?? 0
                        }
                    };
                });
            });

            return {
                ...state,
                changedLayout: action.data,
                changedLayoutApiModel: Object.keys(widgetLayout).map((widgetId) => ({
                    id: widgetId,
                    layout: widgetLayout[widgetId]
                }))
            };
        }
        case actions.LAYOUT_SAVE: {
            return {
                ...state,
                saveLayoutCalled: true
            };
        }
        case actions.LAYOUT_CANCEL: {
            return {
                ...state,
                editMode: false,
                saveLayoutCalled: false,
                widgets: [],
                layout: {},
                updateWidgets: !state.updateWidgets
            };
        }
        case actions.LAYOUT_SAVED: {
            return {
                ...state,
                editMode: false,
                saveLayoutCalled: false,
                layout: state.changedLayout,
                changedLayout: null
            };
        }
        case actions.LAYOUT_SAVE_ERROR: {
            return {
                ...state,
                saveLayoutCalled: false
            };
        }
        case actions.LAYOUT_EDIT: {
            return {
                ...state,
                editMode: true
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

function WidgetContextProvider({ children }) {
    const [state, updateContext] = React.useReducer(reducer, defaultState);

    return (
        <WidgetContext.Provider value={state}>
            <WidgetDispatchContext.Provider value={updateContext}>
                {children}
            </WidgetDispatchContext.Provider>
        </WidgetContext.Provider>
    );
}

WidgetContextProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

function useWidgetContext() {
    const context = React.useContext(WidgetContext);
    if (context === undefined) {
        throw new Error('useWidgetContext must be used within a WidgetContextProvider');
    }
    return context;
}

function useWidgetDispatch() {
    const context = React.useContext(WidgetDispatchContext);
    if (context === undefined) {
        throw new Error('useWidgetDispatch must be used within a WidgetContextProvider');
    }
    return context;
}

export {
    WidgetContextProvider,
    useWidgetContext,
    useWidgetDispatch
};