import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpGetRequest } from 'src/utils/httpClient';
import * as ACTIONS from 'src/actions/userAccessActions';

export const useLoadUsers = () => {
    const dispatch = useDispatch();
    const { refreshUsers } = useSelector((state) => state.userAccess);
    const [fetchedOnMount, setFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/useraccess/users'
    }, false);

    const fetchUsers = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch({ type: ACTIONS.SET_USERS_LOADING });
        return () => { dispatch({ type: ACTIONS.SET_USERS_LOADING }); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchUsers();
            dispatch({ type: ACTIONS.SET_USERS, data: (error ? [] : payload) });
        };

        if (!fetchedOnMount || refreshUsers) {
            setFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchUsers, fetchedOnMount, refreshUsers]);
};

export const useLoadAccessGroups = () => {
    const dispatch = useDispatch();
    const { refreshAccessGroups } = useSelector((state) => state.userAccess);
    const [fetchedOnMount, setFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/useraccess/accessgroups'
    }, false);

    const fetchAccessGroups = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch({ type: ACTIONS.SET_ACCESSGROUPS_LOADING });
        return () => { dispatch({ type: ACTIONS.SET_ACCESSGROUPS_LOADING }); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchAccessGroups();
            dispatch({ type: ACTIONS.SET_ACCESSGROUPS, data: (error ? [] : payload) });
        };

        if (!fetchedOnMount || refreshAccessGroups) {
            setFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchAccessGroups, fetchedOnMount, refreshAccessGroups]);
};

export const useLoadCustomerProducts = () => {
    const dispatch = useDispatch();
    const { refreshCustomerProducts } = useSelector((state) => state.userAccess);
    const [fetchedOnMount, setFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/useraccess/customerproducts'
    }, false);

    const fetchCustomerProducts = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch({ type: ACTIONS.SET_CUSTOMERPRODUCTS_LOADING });
        return () => { dispatch({ type: ACTIONS.SET_CUSTOMERPRODUCTS_LOADING }); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchCustomerProducts();
            dispatch({ type: ACTIONS.SET_CUSTOMERPRODUCTS, data: (error ? [] : payload) });
        };

        if (!fetchedOnMount || refreshCustomerProducts) {
            setFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchCustomerProducts, fetchedOnMount, refreshCustomerProducts]);
};

export const useLoadCustomerResources = () => {
    const dispatch = useDispatch();
    const { refreshCustomerResources } = useSelector((state) => state.userAccess);
    const [fetchedOnMount, setFetchedOnMount] = React.useState(false);

    const { query } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/useraccess/customerresources'
    }, false);

    const fetchCustomerResources = React.useCallback(query, []);

    React.useEffect(() => {
        dispatch({ type: ACTIONS.SET_CUSTOMERRESOURCES_LOADING });
        return () => { dispatch({ type: ACTIONS.SET_CUSTOMERRESOURCES_LOADING }); };
    }, [dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await fetchCustomerResources();
            dispatch({ type: ACTIONS.SET_CUSTOMERRESOURCES, data: (error ? [] : payload) });
        };

        if (!fetchedOnMount || refreshCustomerResources) {
            setFetchedOnMount(true);
            makeRequest();
        }
    }, [dispatch, fetchCustomerResources, fetchedOnMount, refreshCustomerResources]);
};