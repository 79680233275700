export const SET_CARRIERBILLINGSERVICES_LOADING = 'SET_CARRIERBILLINGSERVICES_LOADING';
export const SET_CARRIERBILLINGSERVICES = 'SET_CARRIERBILLINGSERVICES';
export const SET_SELECTEDCARRIERBILLINGSERVICE = 'SET_SELECTEDCARRIERBILLINGSERVICE';
export const SET_SELECTEDCARRIERBILLINGSERVICELOADING = 'SET_SELECTEDCARRIERBILLINGSERVICELOADING';
export const SET_CARRIERBILLINGTRANSACTIONS_LOADING = 'SET_CARRIERBILLINGTRANSACTIONS_LOADING';
export const SET_CARRIERBILLINGTRANSACTIONS = 'SET_CARRIERBILLINSET_CARRIERBILLINGTRANSACTIONSGSERVICES';
export const REFERSH_CARRIERBILLINGTRANSACTIONS = 'REFERSH_CARRIERBILLINGTRANSACTIONS';

export const setCarrierBillingServicesLoading = (data) => (dispatch) => dispatch({
    type: SET_CARRIERBILLINGSERVICES_LOADING,
    data
});

export const setCarrierBillingServices = (data) => (dispatch) => dispatch({
    type: SET_CARRIERBILLINGSERVICES,
    data
});

export const setSelectedCarrierBillingService = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDCARRIERBILLINGSERVICE,
    data
});

export const setSelectedCarrierBillingServiceLoading = (data) => (dispatch) => dispatch({
    type: SET_SELECTEDCARRIERBILLINGSERVICELOADING,
    data
});

export const setCarrierBillingTransactions = (data) => (dispatch) => dispatch({
    type: SET_CARRIERBILLINGTRANSACTIONS,
    data
});

export const setCarrierBillingTransactionsLoading = (data) => (dispatch) => dispatch({
    type: SET_CARRIERBILLINGTRANSACTIONS_LOADING,
    data
});

export const refreshCarrierBillingTransactions = () => (dispatch) => dispatch({
    type: REFERSH_CARRIERBILLINGTRANSACTIONS
});