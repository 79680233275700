import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AccessGroupTitle = ({ match }) => {
    const { accessGroups } = useSelector((state) => state.userAccess);
    const accessGroupName = accessGroups?.find((accessGroup) => accessGroup.id === match?.params?.accessGroupId)?.name;

    return (
        <div>
            {accessGroupName || 'Access Group'}
        </div>
    );
};

AccessGroupTitle.propTypes = {
    match: PropTypes.object
};

export default AccessGroupTitle;