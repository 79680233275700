const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Description',
        description: 'A description of the service.'
    },
    {
        field: 'Created date',
        description: 'Date when the service was created.'
    },
    {
        field: 'Merchant id',
        description: 'Blingpay merchant id.'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Success url',
        description: 'Redirect url, when transaction is successful.'
    },
    {
        field: 'Error url',
        description: 'Redirect url, when transaction fails.'
    },
    {
        field: 'Service group',
        description: 'Description of service group.'
    },
    {
        field: 'VAT',
        description: 'Service VAT class.'
    },
    {
        field: 'Type',
        description: 'Product type can be either Digital or Physical.'
    },
    {
        field: 'Products: Id',
        description: 'Product Id.'
    },
    {
        field: 'Products: Marketing name',
        description: 'The name of the product.'
    },
    {
        field: 'Products: Description',
        description: 'A description of the product.'
    }
];

export default infoData;