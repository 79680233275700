export const CHANGES_UNSAVED = 'CHANGES_UNSAVED';
export const CHANGES_SAVED = 'CHANGES_SAVED';
export const CONFIRMATION_REQUESTED = 'CONFIRMATION_REQUESTED';
export const CONFIRMATION_SAVE_PROMISE = 'CONFIRMATION_SAVE_PROMISE';
export const CONFIRMATION_RESPONSE_RECEIVED = 'CONFIRMATION_RESPONSE_RECEIVED';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';

export const PageHasChanges = (hasChanges) => (dispatch) => {
    dispatch({
        type: hasChanges ? CHANGES_UNSAVED : CHANGES_SAVED
    });
    return Promise.resolve();
};

export const DiscardChanges = () => (dispatch) => dispatch({
    type: DISCARD_CHANGES
});