/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import BarChart from 'src/components/CarrierBilling/Statistics/BarChart';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        padding: theme.spacing(1),
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        padding: 20
    },
    chip: {
        margin: theme.spacing(1),
        border: '1px solid #e2e6e9'
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

const useOutboundMessageBarData = (statistics, statusFilter, productFilter, groupBy, visualization, interval) => {
    const [data, setData] = React.useState({});

    const filteredStats = React.useMemo(() => {
        return statistics?.filter((t) => moment(t?.updated)?.isBetween(moment(interval.from), moment(interval.to).endOf('date'))) ?? [];
    }, [interval.from, interval.to, statistics]);

    React.useEffect(() => {
        let mounted = true;

        const resp = filteredStats?.reduce((acc, current) => {
            // amount
            const createdAmount = current?.status === 'Created' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? 1 : 0;
            const committedAmount = current?.status === 'Committed' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? 1 : 0;
            const cancelledAmount = current?.status === 'Cancelled' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? 1 : 0;
            const reservedAmount = current?.status === 'Reserved' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? 1 : 0;
            const failedAmount = current?.status === 'Failed' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? 1 : 0;

            // amount in cents
            const createdCentAmount = current?.status === 'Created' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? current.sum : 0;
            const committedCentAmount = current?.status === 'Committed' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? current.sum : 0;
            const cancelledCentAmount = current?.status === 'Cancelled' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? current.sum : 0;
            const reservedCentAmount = current?.status === 'Reserved' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? current.sum : 0;
            const failedCentAmount = current?.status === 'Failed' && (productFilter !== 'all' ? current?.productId === productFilter : {}) ? current.sum : 0;

            let _key = moment(current?.updated).startOf(groupBy);
            if (groupBy === 'week') {
                _key = (moment(current?.updated).isoWeekday('Monday').startOf(groupBy)).isoWeekday('Monday');
            }

            return {
                ...acc,
                [_key]: {
                    Created: (acc?.[_key]?.Created ?? 0) + (visualization === 'euro' ? createdCentAmount : createdAmount),
                    Committed: (acc?.[_key]?.Committed ?? 0) + (visualization === 'euro' ? committedCentAmount : committedAmount),
                    Cancelled: (acc?.[_key]?.Cancelled ?? 0) + (visualization === 'euro' ? cancelledCentAmount : cancelledAmount),
                    Reserved: (acc?.[_key]?.Reserved ?? 0) + (visualization === 'euro' ? reservedCentAmount : reservedAmount),
                    Failed: (acc?.[_key]?.Failed ?? 0) + (visualization === 'euro' ? failedCentAmount : failedAmount),
                    Total: (acc?.[_key]?.Total ?? 0) + (visualization === 'euro' ?
                        createdCentAmount + committedCentAmount + cancelledCentAmount + reservedCentAmount + failedCentAmount :
                        createdAmount + committedAmount + cancelledAmount + reservedAmount + failedAmount)
                }
            };
        }, {});

        const getProperty = (propName) => {
            const formatData = (data, total) => {
                switch (visualization) {
                    case 'percentage': {
                        return (data / total) * 100;
                    }
                    case 'euro': {
                        return data / 100;
                    }
                    default: {
                        return data;
                    }
                }
            };

            return Object.keys(resp ?? {}).map((key) => ({ x: key, y: formatData(resp?.[key]?.[propName], resp?.[key]?.Total) }));
        };

        if (mounted) {
            switch (statusFilter) {
                case 'created':
                    setData({
                        Created: getProperty('Created')
                    });
                    break;
                case 'committed':
                    setData({
                        Committed: getProperty('Committed')
                    });
                    break;
                case 'cancelled':
                    setData({
                        Cancelled: getProperty('Cancelled')
                    });
                    break;
                case 'reserved':
                    setData({
                        Reserved: getProperty('Reserved')
                    });
                    break;
                case 'failed':
                    setData({
                        Failed: getProperty('Failed')
                    });
                    break;
                default:
                    setData({
                        Created: getProperty('Created'),
                        Committed: getProperty('Committed'),
                        Cancelled: getProperty('Cancelled'),
                        Reserved: getProperty('Reserved'),
                        Failed: getProperty('Failed')
                    });
            }
        }
        return () => { mounted = false; };
    }, [statusFilter, productFilter, groupBy, visualization, interval, filteredStats]);

    return data;
};

function CarrierBillingStatisticsBar({
    data, chips, groupBy, showBy, statusFilter, productFilter, interval, isWidget
}) {
    const classes = useStyles();
    const barData = useOutboundMessageBarData(data, statusFilter, productFilter, groupBy, showBy, interval);

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
                {isWidget && (
                    <Chip className={classes.chip} label={`Widget updated: ${dateFormatter(moment())}`} />
                )}
            </div>
            <div className={classes.chartContainer}>
                <BarChart
                    data={barData}
                    showBy={showBy}
                    groupBy={groupBy}
                />
            </div>
        </Card>
    );
}

CarrierBillingStatisticsBar.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    groupBy: PropTypes.string.isRequired,
    showBy: PropTypes.string,
    statusFilter: PropTypes.string.isRequired,
    productFilter: PropTypes.string.isRequired,
    interval: PropTypes.any,
    isWidget: PropTypes.bool
};

export default CarrierBillingStatisticsBar;