import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const ChannelTypeLogo = ({ channelType, ...rest }) => {
    switch (channelType) {
        case 'SMS':
            return (
                <Tooltip title="SMS" placement="top" arrow>
                    <img {...rest} src="/images/logos/SMS_Logo.png" alt="SMS Logo" />
                </Tooltip>
            );
        case 'RCS':
            return (
                <Tooltip title="RCS" placement="top" arrow>
                    <img {...rest} src="/images/logos/RCS_Logo.png" alt="RCS Logo" />
                </Tooltip>
            );
        case 'MMS':
            return (
                <Tooltip title="MMS" placement="top" arrow>
                    <img {...rest} src="/images/logos/MMS_Logo.png" alt="MMS Logo" />
                </Tooltip>
            );
        case 'Whatsapp':
            return (
                <Tooltip title="WhatsApp" placement="top" arrow>
                    <img {...rest} src="/images/logos/WhatsApp_Logo.png" alt="WhatsApp Logo" />
                </Tooltip>
            );
        case 'Messenger':
            return (
                <Tooltip title="Messenger" placement="top" arrow>
                    <img {...rest} src="/images/logos/Messenger_Logo.png" alt="Messenger Logo" />
                </Tooltip>
            );
        case 'Instagram':
            return (
                <Tooltip title="Instagram" placement="top" arrow>
                    <img {...rest} src="/images/logos/Instagram_Logo.png" alt="Instagram Logo" />
                </Tooltip>
            );
        case 'WeChat':
            return (
                <Tooltip title="WeChat" placement="top" arrow>
                    <img {...rest} src="/images/logos/WeChat_Logo.png" alt="WeChat Logo" />
                </Tooltip>
            );
        case 'Webhook':
            return (
                <Tooltip title="Webhook" placement="top" arrow>
                    <img {...rest} src="/images/logos/Webhook_Logo.png" alt="Webhook Logo" />
                </Tooltip>
            );
        case 'Email':
            return (
                <Tooltip title="Email" placement="top" arrow>
                    <img {...rest} src="/images/logos/Email_Logo.png" alt="Email Logo" />
                </Tooltip>
            );
        default:
            return null;
    }
};

ChannelTypeLogo.propTypes = {
    channelType: PropTypes.string
};

export default ChannelTypeLogo;