import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MARK_NOTIFICATIONS_AS_READ_COMPLETED, READ_NOTIFICATIONS_LOADED, READ_NOTIFICATIONS_LOADING, SETTINGS_LOADED, subscribeCustomerNotifications, UNREAD_NOTIFICATIONS_LOADED, UNREAD_NOTIFICATIONS_LOADING, unsubscribeCustomerNotifications } from 'src/actions/notificationActions';
import { useHttpRequest } from 'src/utils/httpClient';
import useNotificationProcessor from 'src/components/Notifications/useNotificationProcessor';

const NotificationsProvider = () => {
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { refreshUnreadNotifications, refreshReadNotifications, notificationsToMarkAsRead, top, unreadNotificationSkipCount, readNotificationSkipCount } = useSelector((state) => state.notifications);
    const dispatch = useDispatch();

    useNotificationProcessor();

    const { mutate: getUnreadNotifications } = useHttpRequest(({ includeSharedNotifications = true, top = 100, skip = 0 } = {}) => ({
        method: 'GET',
        endpoint: '/notifications',
        params: {
            includeSharedNotifications,
            sorts: '-created',
            page: skip / top + 1,
            pageSize: top,
            filters: 'read==false'
        }
    }));

    const { mutate: getReadNotifications } = useHttpRequest(({ includeSharedNotifications = true, top = 100, skip = 0 } = {}) => ({
        method: 'GET',
        endpoint: '/notifications',
        params: {
            includeSharedNotifications,
            sorts: '-created',
            page: skip / top + 1,
            pageSize: top,
            filters: 'read==true'
        }
    }));

    const { mutate: markNotificationsRead } = useHttpRequest((notificationIds) => ({
        method: 'POST',
        endpoint: '/notifications',
        body: notificationIds
    }));

    const { mutate: fetchSettings } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/notifications/settings'
    }));

    React.useEffect(() => {
        if (selectedCustomerId) {
            dispatch(subscribeCustomerNotifications());
        }
        return () => {
            dispatch(unsubscribeCustomerNotifications());
        };
    }, [dispatch, selectedCustomerId]);

    // Load and refresh unread notifications
    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await getUnreadNotifications({ top, skip: unreadNotificationSkipCount });

            if (!error) {
                dispatch({ type: UNREAD_NOTIFICATIONS_LOADED, data: payload });
            }
        };

        if (refreshUnreadNotifications) {
            dispatch({ type: UNREAD_NOTIFICATIONS_LOADING });
            makeRequest();
        }
    }, [dispatch, refreshUnreadNotifications, getUnreadNotifications, top, unreadNotificationSkipCount]);

    // Load and refresh read notifications
    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload } = await getReadNotifications({ top, skip: readNotificationSkipCount });

            if (!error) {
                dispatch({ type: READ_NOTIFICATIONS_LOADED, data: payload });
            }
        };

        if (refreshReadNotifications) {
            dispatch({ type: READ_NOTIFICATIONS_LOADING });
            makeRequest();
        }
    }, [dispatch, refreshReadNotifications, getReadNotifications, top, readNotificationSkipCount]);

    // Mark notifications as read
    React.useEffect(() => {
        const makeRequest = async () => {
            await markNotificationsRead(notificationsToMarkAsRead);
            dispatch({ type: MARK_NOTIFICATIONS_AS_READ_COMPLETED, data: notificationsToMarkAsRead });
        };
        if (notificationsToMarkAsRead?.length > 0) {
            makeRequest();
        }
    }, [dispatch, notificationsToMarkAsRead, markNotificationsRead]);

    // Get notification settings
    React.useEffect(() => {
        const makeRequest = async () => {
            const { payload } = await fetchSettings();
            dispatch({ type: SETTINGS_LOADED, data: payload });
        };
        makeRequest();
    }, [dispatch, fetchSettings, selectedCustomerId]);

    return null;
};

export default NotificationsProvider;