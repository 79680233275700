import * as ACTIONS from 'src/actions/dialogActions';
import moment from 'moment';
import { NavigateTo } from 'src/actions/routerActions';
import { showDesktopNotification } from 'src/actions/notificationActions';
import { PlayNewDialogSound, PlayNewUnreadMessageSound } from 'src/actions';

const threadUpdated = ({ dispatch, getState }) => (next) => (action) => {
    const { dialog: { threadsById, pendingThreadsById, selectedThread, threadMessagesLoading } } = getState();

    // UPDATE_THREAD dispatched by IDiscussionThreadStatusChanged signalr event, check for existing thread
    if (action.type === ACTIONS.UPDATE_THREAD) {
        const { threadId, status, latestMessage } = action.data;

        if (threadsById[threadId] && status && latestMessage) {
            // Check if selectedThread is the same as the one in event, refresh messages
            if (selectedThread?.id === threadId && !threadMessagesLoading) {
                dispatch({
                    type: ACTIONS.UPDATE_SELECTED_THREAD,
                    data: {
                        updated: moment().toJSON(),
                        status,
                        latestMessage
                    }
                });

                dispatch({ type: ACTIONS.REFRESH_THREAD_MESSAGES });
            }

            return next({
                ...action,
                data: {
                    id: threadId,
                    updated: moment().toJSON(),
                    status,
                    latestMessage
                }
            });
        }

        // Refresh pending thread list to remove thread which exists on thread tab
        if (pendingThreadsById[threadId]) {
            dispatch({ type: ACTIONS.REFRESH_PENDING_THREADS });
        }

        // Thread created by other user, thread list needs to be refreshed
        dispatch({ type: ACTIONS.REFRESH_THREADS });
    }

    return next(action);
};

const dialogMessageQueued = ({ getState }) => (next) => (action) => {
    if (action.type === ACTIONS.DIALOG_MESSAGE_QUEUED) {
        const { auth } = getState();

        return next({
            ...action,
            data: {
                ...action.data,
                senderName: auth?.claims?.name ?? 'SenderName'
            }
        });
    }
    return next(action);
};

const discussionThreadMessageReceived = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === ACTIONS.DISCUSSION_THREAD_CREATED_EVENT_RECEIVED) {
        if (action?.data?.status !== 'Pending') {
            dispatch({
                type: ACTIONS.REFRESH_THREADS
            });

            const result = next(action);
            const { auth: { selectedCustomerId }, dialog } = getState();

            const shouldShowDesktopNotification = !dialog?.dialogInboxHasFocus;
            const threadId = action?.data?.latestMessage?.threadId;
            const sender = action?.data?.latestMessage?.sender;
            const content = action?.data?.latestMessage?.content;

            if (threadId && sender && content) {
                if (shouldShowDesktopNotification) {
                    dispatch(showDesktopNotification(`New Dialog started by ${sender}`, `${content}`, () => {
                        dispatch(NavigateTo(`/${selectedCustomerId}/dialogs/started/${threadId}`));
                    }));
                }
                dispatch(PlayNewDialogSound());
            }

            return result;
        }

        if (action?.data?.status === 'Pending') {
            dispatch({
                type: ACTIONS.REFRESH_PENDING_THREADS
            });
        }
    }

    return next(action);
};

const discussionThreadUpdated = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === ACTIONS.UPDATE_THREAD) {
        const result = next(action);
        const { auth: { selectedCustomerId }, dialog: { dialogInboxHasFocus, selectedThreadId } } = getState();

        const status = action?.data?.status;
        const threadId = action?.data?.latestMessage?.threadId;
        const sender = action?.data?.latestMessage?.sender;
        const content = action?.data?.latestMessage?.content;

        const shouldShowDesktopNotification = !dialogInboxHasFocus;
        const shouldPlaySound = !dialogInboxHasFocus || selectedThreadId !== threadId;

        if (status === 'Unread' && threadId && sender && content) {
            if (shouldShowDesktopNotification) {
                dispatch(showDesktopNotification(`Message from ${sender}`, `${content}`, () => {
                    dispatch(NavigateTo(`/${selectedCustomerId}/dialogs/started/${threadId}`));
                }));
            }
            if (shouldPlaySound) {
                dispatch(PlayNewUnreadMessageSound());
            }
        }

        return result;
    }
    return next(action);
};

export default [
    threadUpdated,
    dialogMessageQueued,
    discussionThreadMessageReceived,
    discussionThreadUpdated
];