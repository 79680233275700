import { Tooltip } from '@material-ui/core';
import React from 'react';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import PropTypes from 'prop-types';

const CopyableText = ({ text, valueAsTooltip }) => {
    const { isCopied, handleCopy } = useCopyToClipboard(5000);

    const handleCopyClick = () => {
        handleCopy(text);
    };

    const tooltipTitle = () => {
        if (isCopied) {
            return 'Copied';
        }
        if (valueAsTooltip) {
            return text;
        }
        return 'Copy to Clipboard';
    };

    return (
        <Tooltip title={tooltipTitle()} placement="bottom-start" onClick={handleCopyClick}>
            <span>{text}</span>
        </Tooltip>
    );
};

CopyableText.propTypes = {
    text: PropTypes.string.isRequired,
    valueAsTooltip: PropTypes.bool
};

export default CopyableText;