import React from 'react';

const useTabHasFocus = (documentElement = window.document) => {
    const [documentVisible, setDocumentVisible] = React.useState(documentElement.visibilityState);
    const [tabHasFocus, setTabHasFocus] = React.useState(true);

    React.useEffect(() => {
        const handleVisibilityChange = () => setDocumentVisible(documentElement.visibilityState);
        const handleFocus = () => setTabHasFocus(true);
        const handleBlur = () => setTabHasFocus(false);

        documentElement.addEventListener('visibilitychange', handleVisibilityChange);
        documentElement.addEventListener('focus', handleFocus);
        documentElement.addEventListener('blur', handleBlur);

        return () => {
            documentElement.removeEventListener('visibilitychange', handleVisibilityChange);
            documentElement.removeEventListener('focus', handleFocus);
            documentElement.removeEventListener('blur', handleBlur);
        };
    }, [documentElement]);

    return documentVisible === 'visible' && tabHasFocus;
};

export default useTabHasFocus;