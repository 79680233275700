const infoData = [
    {
        field: 'Response messages',
        description: 'Set a response message for each action type.'
    },
    {
        field: 'Action keywords',
        description: 'Set an input source and a keyword.'
    }
];

export default infoData;