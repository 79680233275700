import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/statisticsActions';

const initialState = {
    messageTraffic: [],
    refreshMessageTraffic: false,
    messageTrafficLoading: false
};

const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MESSAGE_TRAFFIC: {
            return {
                ...state,
                refreshMessageTraffic: false,
                messageTrafficLoading: false,
                messageTraffic: [...action?.data ?? []]
            };
        }
        case actionTypes.SET_MESSAGE_TRAFFIC_LOADING: {
            return {
                ...state,
                messageTrafficLoading: Boolean(action.data)
            };
        }
        case actionTypes.REFRESH_MESSAGE_TRAFFIC: {
            return {
                ...state,
                refreshMessageTraffic: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default statisticsReducer;
