import React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import useStyles from './useStyles';

const AccessGroupInfoContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="body1">List of user access groups for this company. Here you can manage access group information and create new access groups.</Typography>
            <Table className={classes.table}>
                <colgroup>
                    <col width="30%" />
                    <col width="70%" />
                </colgroup>
                <TableBody>
                    <TableRow>
                        <TableCell>Name:</TableCell>
                        <TableCell>Name of the Access Group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Description:</TableCell>
                        <TableCell>The purpose / content of this access group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Role:</TableCell>
                        <TableCell>User / Admin, the role that is given to the users in this access group. This access group specific role overrides the users main level role in those services which belong to this access group. </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Default:</TableCell>
                        <TableCell>Default = "No". If you set the Deny = "Yes", then the users which belong to this access group are denied access to those products or services which belong to this access group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Users:</TableCell>
                        <TableCell>Name of the user or the number of users which belong to the selected access group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>User Assignment:</TableCell>
                        <TableCell>Add or remove users in access groups by using arrow buttons.</TableCell>
                    </TableRow>
                    <TableRow className={classes.nested}>
                        <TableCell colSpan={2}>
                            <Table>
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Available Users:</TableCell>
                                        <TableCell>The users who can be added to this access group.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Selected Users:</TableCell>
                                        <TableCell>The users who have already been added to this access group.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Products:</TableCell>
                        <TableCell>The products / UI sections which belong to the selected access group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Product Assignment:</TableCell>
                        <TableCell>Add or remove products in access groups by using arrow buttons. This section defines whether the selected product will be seen in the SmartDialog UI for the users who are added to this access group.</TableCell>
                    </TableRow>
                    <TableRow className={classes.nested}>
                        <TableCell colSpan={2}>
                            <Table>
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Available Products:</TableCell>
                                        <TableCell>The products which can be added to this access group.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Selected Products:</TableCell>
                                        <TableCell>The products which have already been added to this access group.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Resources:</TableCell>
                        <TableCell>The services which belong to the selected access group.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Service & Resource Assignment: </TableCell>
                        <TableCell>Add or remove services in access groups by using arrow buttons.</TableCell>
                    </TableRow>
                    <TableRow className={classes.nested}>
                        <TableCell colSpan={2}>
                            <Table>
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Available Services & Resources:</TableCell>
                                        <TableCell>The services which can be added to this access group.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Selected Services & Resources:</TableCell>
                                        <TableCell>The services which have already been added to this access group.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography variant="body1">
                <b>NOTE:</b>
                &nbsp;
                If you haven’t set any service to the Selected Services, then the access group will include all these services (by using the defined product assignment level). In this case, all the new services that will be created later, are also automatically added to this access group.
            </Typography>
        </div>
    );
};

export default AccessGroupInfoContent;