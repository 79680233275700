import React from 'react';
import PropTypes from 'prop-types';
import WhatsappTemplateSelection from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection/WhatsappTemplateSelection';
import QuickreplyTemplateSelection from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection/QuickreplyTemplateSelection';
import { useDispatch } from 'react-redux';
import { TEMPLATE_SELECTED } from 'src/actions';

export const MessageTemplateTypes = {
    QuickReply: 'QuickReply',
    Whatsapp: 'Whatsapp',
    SMS: 'SMS',
    InstantMessage: 'InstantMessage'
};

const templateContentLength = 80;
export const parseDisplayContent = (content) => {
    if (typeof content !== 'string') {
        return '';
    }
    if (content?.length > templateContentLength - 3) {
        return `${content.substring(0, templateContentLength - 3)}...`;
    }
    return content;
};

export const generateTextAndCursorPositionFromTemplate = (template, inputValue, cursorPosition, bodyParameters) => {
    switch (template?.type) {
        case MessageTemplateTypes.QuickReply: {
            const textBeforeCursorPosition = inputValue.substring(0, cursorPosition);
            const textAfterCursorPosition = inputValue.substring(cursorPosition, inputValue.length);

            const newValue = `${textBeforeCursorPosition}${template?.content ?? ''}${textAfterCursorPosition}`;
            const newCursorPosition = cursorPosition + (template?.content?.length ?? 0);

            return { newValue, newCursorPosition };
        }
        case MessageTemplateTypes.InstantMessage: {
            switch (template?.extensionParameters?.protocol) {
                case MessageTemplateTypes.Whatsapp: {
                    let newValue = template?.content ?? '';

                    if (Array.isArray(bodyParameters)) {
                        newValue = bodyParameters?.reduce?.((result, parameter, index) => result?.replace(`{{${index + 1}}}`, parameter), newValue);
                    }

                    const newCursorPosition = null;
                    return { newValue, newCursorPosition };
                }
                default:
            }
            break;
        }
        default:
    }

    return { newValue: inputValue, newCursorPosition: cursorPosition };
};

const MessageTemplateSelection = ({ type, onClose }) => {
    const dispatch = useDispatch();

    const _onClose = (data) => {
        dispatch({
            type: TEMPLATE_SELECTED,
            data
        });
        onClose();
    };

    switch (type) {
        case MessageTemplateTypes.Whatsapp:
            return <WhatsappTemplateSelection onClose={_onClose} />;
        case MessageTemplateTypes.QuickReply:
            return <QuickreplyTemplateSelection onClose={_onClose} />;
        default:
            return null;
    }
};

MessageTemplateSelection.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func
};
export default MessageTemplateSelection;