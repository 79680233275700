/* eslint-disable */
import React from 'react';
import { withStyles } from '@material-ui/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Typography, Grid } from '@material-ui/core';

const styles = (theme) => ({
    container: {
        width: '100%',
        height: '100%'
    },
    errorIcon: {
        'font-size': 40
    },
    errorText: {
        margin: '10px'
    }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            const { classes } = this.props;
            return (
                <Grid
                    className={classes.container}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ErrorIcon className={classes.errorIcon} />
                    <Typography variant="h3" className={classes.errorText}>Error loading widget</Typography>
                </Grid>
            );
        }

        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);