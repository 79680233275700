import React, { useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Chip,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
    Hidden
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ReAssignIcon from '@material-ui/icons/AssignmentIndRounded';
import PinChatToDashBoardIcon from '@material-ui/icons/BookmarkRounded';
import ArchiveIcon from '@material-ui/icons/ArchiveRounded';
import ViewHistoryIcon from '@material-ui/icons/HistoryRounded';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import ChannelTypeLogo from 'src/components/Dialogs/ChannelTypeLogo';
import { SetThreadFilters, UpdateSelectedThread, UpdateThread } from 'src/actions';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import ConfirmDialog from 'src/components/ConfirmDialog';
import OwnerAssignmentDialog from 'src/components/Dialogs/ChatWindow/OwnerAssignmentDialog';
import * as InboxItemTypes from 'src/views/Dialogs/inboxItemTypes';
import { useCreateChatWindowWidget } from 'src/components/Dialogs/hooks';
import { useThreadChatWindowContext } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import SmartAgentButton from 'src/components/SmartAgent/SmartAgentButton';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white
    },
    backButton: {
        marginRight: theme.spacing(2)
    },
    toolbarContent: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    toolbarCenterContent: {
        marginRight: theme.spacing(1),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    userText: {
        textAlign: 'center',
        maxWidth: '100%',
        '&&:not(:last-child)': {
            marginBottom: 5
        }
    },
    channelTypeIcon: {
        width: 35,
        margin: theme.spacing(0, 1)
    },
    statusChip: {
        margin: theme.spacing(0, 1),
        '&.status_New': {
            backgroundColor: theme.palette.status.dialog.new.main,
            color: theme.palette.white
        },
        '&.status_Active': {
            backgroundColor: theme.palette.status.dialog.active.main,
            color: theme.palette.white
        },
        '&.status_Unread': {
            backgroundColor: theme.palette.status.dialog.unread.main,
            color: theme.palette.white
        },
        '&.status_Read': {
            backgroundColor: theme.palette.status.dialog.read.main,
            color: theme.palette.white
        },
        '&.status_Closed': {
            backgroundColor: theme.palette.status.dialog.closed.main,
            color: theme.palette.white
        },
        '&.status_Expired': {
            backgroundColor: theme.palette.status.dialog.expired.main,
            color: theme.palette.white
        },
    }
}));

const StatusChip = ({ selectedThread }) => {
    const classes = useStyles();

    switch (selectedThread?.status) {
        case 'Active':
            return <Chip className={clsx(classes.statusChip, 'status_Active')} label="Active" />;
        case 'Unread':
            return <Chip className={clsx(classes.statusChip, 'status_Unread')} label="Unread" />;
        case 'Read':
            return <Chip className={clsx(classes.statusChip, 'status_Read')} label="Read" />;
        case 'Expired':
            return <Chip className={clsx(classes.statusChip, 'status_Expired')} label={`Expired: ${selectedThread?.expirationReason}`} />;
        default:
            return null;
    }
};

StatusChip.propTypes = {
    selectedThread: PropTypes.object
};

const ConversationToolbar = ({ conversation, className, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const moreRef = useRef(null);
    const { customerId, threadId } = useParams();
    const [openMenu, setOpenMenu] = useState(false);
    const [confirmExpireThread, setConfirmExpireThread] = useState(false);
    const [ownerAssignmentDialogOpen, setOwnerAssignmentDialogOpen] = useState(false);
    const { error: errorSnack } = useSnackbar();
    const { threadsById, dialogServicesById, discussionServicesById, isWidget } = useThreadChatWindowContext();
    const { customerUsers } = useSelector((state) => state.customer);

    const selectedThread = React.useMemo(() => threadsById?.[threadId], [threadId, threadsById]);
    const threadOwner = React.useMemo(() => customerUsers?.[selectedThread?.ownerUserId]?.profile?.displayName ?? '', [selectedThread, customerUsers]);
    const selectedService = React.useMemo(() => {
        if (!selectedThread) {
            return null;
        }
        return (
            dialogServicesById?.[selectedThread?.sourceServiceId] ??
            discussionServicesById?.[selectedThread?.sourceServiceId] ??
            discussionServicesById?.[selectedThread?.serviceId] ??
            null
        );
    }, [selectedThread, dialogServicesById, discussionServicesById]);

    const { mutate: updateThread } = useHttpRequest(({ serviceId, threadId, owner, ownerUserId, status }) => ({
        endpoint: `/messaging/services/dialog/threads/${serviceId}/${threadId}`,
        method: 'PATCH',
        body: {
            ...owner ? { owner } : {},
            ...ownerUserId ? { ownerUserId } : {},
            ...status ? { newStatus: status } : {}
        }
    }));

    const { createWidget } = useCreateChatWindowWidget();

    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleViewChathistory = () => {
        setOpenMenu(false);
        dispatch(SetThreadFilters({ search: selectedThread?.recipientAddress ?? '' }));
    };

    const handleCloseAndExpire = async () => {
        setOpenMenu(false);
        setConfirmExpireThread(true);
    };

    const handleExpireThread = async () => {
        const patchRequest = { serviceId: selectedThread?.serviceId, threadId: selectedThread?.id, status: 'Expired' };
        const { error, errorMessages } = await updateThread(patchRequest);
        if (error) {
            errorSnack(errorMessages);
        } else {
            const updatedThread = { id: selectedThread?.id, status: 'Expired', expirationReason: 'Closed', updated: new Date().toJSON() };
            dispatch(UpdateThread(updatedThread));
            dispatch(UpdateSelectedThread(updatedThread));
        }
    };

    const handleAssignThread = async (e) => {
        e.currentTarget.blur();
        setOpenMenu(false);
        setOwnerAssignmentDialogOpen(true);
    };

    const handleOwnerDialogClosed = async (user) => {
        if (user) {
            const owner = user?.profile?.displayName ?? '';
            const ownerUserId = user?.externalUserId ?? null;
            const patchRequest = { serviceId: selectedThread?.serviceId, threadId: selectedThread?.id, owner, ownerUserId };
            const { error, errorMessages } = await updateThread(patchRequest);
            if (error) {
                errorSnack(errorMessages);
            } else {
                const updatedThread = { id: selectedThread?.id, owner, updated: new Date().toJSON(), ownerUserId };
                dispatch(UpdateThread(updatedThread));
                dispatch(UpdateSelectedThread(updatedThread));
                setOwnerAssignmentDialogOpen(false);
            }
        } else {
            setOwnerAssignmentDialogOpen(false);
        }
    };

    const handlePinChatToDashboard = async () => {
        const settings = { selectedThread, title: 'Dialog Chat', hasLink: true };
        // Success and Errormessages emitted in createWidget function
        await createWidget(settings);
    };

    return (
        <>
            <Toolbar
                {...rest}
                className={clsx(classes.root, className)}
                disableGutters
            >
                <Hidden mdUp>
                    <Tooltip title="Back">
                        <IconButton
                            className={classes.backButton}
                            component={RouterLink}
                            edge="start"
                            to={`/${customerId}/dialogs/${InboxItemTypes.Started}`}
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Tooltip>
                </Hidden>
                <div className={classes.toolbarContent}>
                    <ChannelTypeLogo className={classes.channelTypeIcon} channelType={selectedThread?.latestMessage?.protocol} />
                    <div className={classes.toolbarCenterContent}>
                        <Typography variant="h4" className={classes.userText}>
                            {`${selectedThread?.recipientAddress} - ${selectedService?.name ?? ''}`}
                        </Typography>
                        <div>
                            <SmartAgentButton protocol={selectedThread?.latestMessage?.protocol ?? ''} recipientAddress={selectedThread?.recipientAddress ?? ''} isWidget={isWidget} showAsChip />
                            {threadOwner && <Tooltip title="Reassign chat"><Chip disabled={isWidget} size="small" icon={<ReAssignIcon />} label={threadOwner} onClick={handleAssignThread} /></Tooltip>}
                        </div>
                    </div>
                    <StatusChip selectedThread={selectedThread} />
                </div>
                {!isWidget && (
                    <>
                        <Tooltip title="More options">
                            <IconButton
                                onClick={handleMenuOpen}
                                ref={moreRef}
                            >
                                <MoreIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={moreRef.current}
                            keepMounted
                            elevation={1}
                            onClose={handleMenuClose}
                            open={openMenu}
                        >
                            {selectedThread?.status !== 'Expired' && (
                                <MenuItem onClick={handlePinChatToDashboard}>
                                    <ListItemIcon>
                                        <PinChatToDashBoardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Pin chat to Dashboard" />
                                </MenuItem>
                            )}
                            {selectedThread?.status !== 'Expired' && (
                                <MenuItem onClick={handleAssignThread}>
                                    <ListItemIcon>
                                        <ReAssignIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Reassign Chat" />
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleViewChathistory}>
                                <ListItemIcon>
                                    <ViewHistoryIcon />
                                </ListItemIcon>
                                <ListItemText primary={`View chat history with ${selectedThread?.recipientAddress}`} />
                            </MenuItem>
                            {selectedThread?.status !== 'Expired' && (
                                <MenuItem onClick={handleCloseAndExpire}>
                                    <ListItemIcon>
                                        <ArchiveIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Close and Expire Chat" />
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                )}
            </Toolbar>
            <ConfirmDialog title="Expire Chat?" open={confirmExpireThread} setOpen={() => setConfirmExpireThread(false)} onConfirm={handleExpireThread}>
                <Typography variant="body1">Are you sure you want to expire this chat?</Typography>
            </ConfirmDialog>
            <OwnerAssignmentDialog
                open={ownerAssignmentDialogOpen}
                onClose={handleOwnerDialogClosed}
                ownerUserId={selectedThread?.ownerUserId ?? ''}
            />
        </>
    );
};

ConversationToolbar.propTypes = {
    className: PropTypes.string,
    conversation: PropTypes.object.isRequired
};

export default ConversationToolbar;
