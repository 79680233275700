import * as ACTIONS from 'src/actions/widgetActions';

const initialState = {
    widgets: [],
    widgetsLoading: true,
    refreshWidgets: false
};

const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_WIDGETS: {
            return {
                ...state,
                widgetsLoading: false,
                widgets: [...action?.data ?? []]
            };
        }
        case ACTIONS.SET_WIDGETS_LOADING: {
            return {
                ...state,
                widgetsLoading: Boolean(action.data)
            };
        }
        case ACTIONS.REFERSH_WIDGETS: {
            return {
                ...state,
                refreshWidgets: true
            };
        }
        default: {
            return state;
        }
    }
};

export default widgetReducer;