const infoData = [
    {
        placeholder: 'Select Action',
        description: 'You can add one or more actions to be taken for the inbound messages. Actions are: Static Reply, Email Forward, Webhook, Group Lookup, SmartAgent Lookup, Kayak Subscription Lookup, Kayak Customer Lookup. Actions can have different Conditions, Flow Settings and Options, depending on the Action Type and selected order of Actions.'
    },
    {
        placeholder: 'Condition',
        description: 'If a previous action has an output, it´s possible to use the output in conditions. The action will only run if the conditions are met. Depending on the previous output action the conditions vary.'
    },
    {
        placeholder: 'Flow Settings',
        description: 'Save Output: Save output for use as condition in later actions.\n\nExit after this action: Stop execution of further actions, after the action has been run.\n\nWait for reply message delivery: Wait until reply message has been delivered before continuing with further actions.'
    },
    {
        placeholder: 'Action: Static Reply',
        description: 'Sends the provided text as a reply message to the sender. Give the action a Name, Description, and the message content to be sent. Placeholders can be added to the reply message depending on previous action result.'
    },
    {
        placeholder: 'Action: Email Forward',
        description: 'Forwards incoming content to one or more email addresses. Give this action a Name, Description, Recipient email addresses, Email subject, Sender email address and the email message content. Use placeholders to pick specific parts of the incoming message into the Email subject or body. For instance, a subject can look like this: "Message from $(M)". There are placeholders to define e.g. the entire message content, the sender of the message or the recipient of the message.'
    },
    {
        placeholder: 'Action: Webhook',
        description: 'Forwards the incoming content to a Webhook URL. Give this action a Name, Description, endpoint URL address to which the call is made, and query parameters if the interface requires them. Different options are provided to support the formats of previous systems.\n\nUse OAuth2 Authentication: Sends a POST request to a given OAuth Endpoint URL using given form parameters and headers.\n\nGet reply from external API response: Sends SMS message with the content. Specify from which element the response is read when calling the webhook. For instance, the "body" in a text/html response. Leave this empty for text/plain.\n\nLegacy Mode: Create Engine Legacy compatible request. You can choose another http method instead of POST.'
    },
    {
        placeholder: 'Action: Group Lookup',
        description: 'Looks up a contact from the selected Group Service. If the contact exists, properties from the contact can be used in subsequent actions.'
    },
    {
        placeholder: 'Action: SmartAgent Lookup',
        description: 'Makes a phone number search from external phone number service. Result can be used in other actions like Static reply message or Email forward by adding placeholders like eg. firstname and lastname.'
    },
    {
        placeholder: 'Action: Kayak Subscription Lookup',
        description: 'Makes a Kayak subscription lookup. The result can be used as a condition in a later action. Select susbcription state and kind (eg. active standing subscription) and papercodes (media titles).'
    },
    {
        placeholder: 'Action: Kayak Customer Lookup',
        description: 'Makes a validation if the senders’ phone number is found in Kayak. Returns the subscribers’ Kayak customer number. Use a placeholder to add the customer number to eg. a static reply message.'
    }
];
export default infoData;