import React from 'react';
import { Badge, IconButton } from '@material-ui/core';
import propTypes from 'prop-types';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useSelector } from 'react-redux';

const TopBarIcon = ({ onClick }) => {
    const { unreadNotificationCount } = useSelector((state) => state.notifications);

    return (
        <IconButton
            color="inherit"
            aria-label={unreadNotificationCount > 0 ? `Show ${unreadNotificationCount} new notifications` : 'Show notifications'}
            onClick={onClick}
        >
            {unreadNotificationCount === 0 && (
                <NotificationsIcon />
            )}
            {unreadNotificationCount > 0 && (
                <Badge
                    badgeContent={unreadNotificationCount > 0 ? unreadNotificationCount : ''}
                    color="secondary"
                >
                    <NotificationsIcon />
                </Badge>
            )}

        </IconButton>
    );
};

TopBarIcon.propTypes = {
    onClick: propTypes.func.isRequired
};

export default TopBarIcon;