const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Service state',
        description: 'The state of the service, (Active or Passive).'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Dialog service',
        description: 'Use this service in Dialog.'
    },
    {
        field: 'Code service',
        description: 'Select a Code service to be able to incorporate Redeemable codes in messages.'
    },
    {
        field: 'Default sender',
        description: 'If no sender is provided in the sending request, this sender will be used. This can be the case when sending a manual reply, either via API or the user interface. Number (Long- or shortcode) or Alphanumeric string (3-11 chars, no special characters).'
    },
    {
        field: 'Start date',
        description: 'Service valid from this date.'
    },
    {
        field: 'End date',
        description: 'Service valid thru this date.'
    },
    {
        field: 'Dlr url',
        description: 'The URL to send delivery status callbacks.'
    },
    {
        field: 'Message retention days',
        description: 'Defines how long messages will be stored. Minimum value possible is 90 days. If not set, default value will be set to max value, which is 365 days.'
    },
    {
        field: 'Hidden content',
        description: 'When set, will hide the message content for all messages. To disable Hidden content please contact support.'
    },
    {
        field: 'Poll service',
        description: 'If the 2-Way messaging service is used as Poll service, then a separate Poll statistics section will be available. The Poll results are shown as a bar chart and in a poll results table with votes and percentages.'
    },
    {
        field: 'Enable folders',
        description: 'When switched on you can create folders and move messages from inbox. It´s also possible to move messages to a folder when exporting messages or recipients.'
    },
    {
        field: 'Allow webhooks',
        description: 'Allow incoming messages from Webhook API. If "Dialog service" is switched on, incoming messages will also appear in the dialog inbox.'
    },
    {
        field: 'Allow feeds',
        description: 'Allow feeds.'
    }
];
export default infoData;