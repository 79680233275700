import React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, FormHelperText, IconButton, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/styles';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { sortByProperty } from 'src/utils/arrayFunctions';
import PropTypes from 'prop-types';
import { parseDisplayContent } from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection';
import { useSelector } from 'react-redux';
import { useHttpGetRequest } from 'src/utils/httpClient';
import MessageTemplateDialog from 'src/components/MessageTemplates/MessageTemplateDialog';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 0,
        zIndex: 1000
    },
    headerRow: {
        backgroundColor: theme.palette.background.gray,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        '&& *': {
            color: theme.palette.white
        }
    },
    headerRowButtonContainer: {
        marginLeft: 'auto'
    },
    templateSelectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxHeight: 200,
        overflow: 'auto',
        zIndex: 9999,
        padding: theme.spacing(1)
    },
    templateButton: {
        '&&:not(last-child)': {
            marginBottom: theme.spacing(1)
        }
    },
    templatePreview: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&& h5': {
            fontWeight: 600
        }
    },
    placeholderTable: {
        maxHeight: 200,
        overflow: 'auto'
    }
}));

const placeholderRegex = /{{[0-9]+}}/gm;

const WhatsappTemplateSelection = ({ onClose }) => {
    const classes = useStyles();
    const { whatsappTemplates, messageTemplatesLoading } = useSelector((state) => state.messageTemplates);
    const { selectedDialogMessage, sendDiscussionMessageSettings } = useSelector((state) => state.dialog);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [placeholders, setPlaceholders] = React.useState([]);
    const [placeholderValues, setPlaceholderValues] = React.useState({});
    const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = React.useState(false);

    const { payload: serviceInputsourcePayload, loading: serviceInputsourcesLoading } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/dialog/serviceinputsources/${selectedDialogMessage?.senderAddress ?? sendDiscussionMessageSettings?.recipientAddress}`,
        params: {
            protocol: 'Whatsapp'
        }
    });

    const serviceInputsourcesByAddress = React.useMemo(() => (
        serviceInputsourcePayload?.inputSources?.reduce?.((addresses, is) => ({ ...addresses, [is?.address]: is }), {}) ?? {}
    ), [serviceInputsourcePayload]);

    const filteredWhatsappTemplates = React.useMemo(() => (
        whatsappTemplates?.filter?.((tmpl) => serviceInputsourcesByAddress?.[tmpl?.extensionParameters?.identityNumber])
    ), [serviceInputsourcesByAddress, whatsappTemplates]);

    const previewTemplateBody = React.useMemo(() => (
        placeholders?.reduce?.((result, placeholder) => {
            const placeholderValue = placeholderValues?.[placeholder] ?? '';
            if (placeholderValue !== '') {
                return result?.replace(placeholder, placeholderValue);
            }
            return result;
        }, selectedTemplate?.extensionParameters?.rawContent ?? '')
    ), [selectedTemplate, placeholders, placeholderValues]);

    const onTemplateClick = (template) => () => {
        const foundPlaceholders = template?.extensionParameters?.rawContent?.match?.(placeholderRegex);

        if (foundPlaceholders) {
            setSelectedTemplate(template);
            setPlaceholders(foundPlaceholders ?? []);
            setPlaceholderValues(foundPlaceholders?.reduce?.((values, current) => ({ ...values, [current]: '' }), {}) ?? {});
        } else {
            onClose({
                template,
                discussionServiceId: serviceInputsourcePayload?.serviceId,
                hasActiveThread: serviceInputsourcesByAddress?.[template?.extensionParameters?.identityNumber]?.hasActiveThread
            });
        }
    };

    const onCloseButtonClick = () => {
        // Go back to template selection if one with parameters is selected
        if (selectedTemplate) {
            setSelectedTemplate(null);
            setPlaceholders([]);
            setPlaceholderValues({});
        } else {
            onClose(null);
        }
    };

    const placeholderInputOnchange = (placeholder) => (event) => {
        setPlaceholderValues((values) => ({
            ...values,
            [placeholder]: event.target.value
        }));
    };

    const handlePlaceholderSubmit = () => {
        onClose({
            template: selectedTemplate,
            bodyParameters: Object.keys(placeholderValues).map((placeholder) => placeholderValues[placeholder]),
            discussionServiceId: serviceInputsourcePayload?.serviceId,
            hasActiveThread: serviceInputsourcesByAddress?.[selectedTemplate?.extensionParameters?.identityNumber]?.hasActiveThread
        });
        setSelectedTemplate(null);
        setPlaceholders([]);
        setPlaceholderValues({});
    };

    const handleMessageTemplateDialogClose = () => {

    };

    const loading = messageTemplatesLoading || serviceInputsourcesLoading;

    return (
        <>
            <Card className={classes.card}>
                <CardMedia className={classes.headerRow}>
                    <Typography variant="h5">
                        {selectedTemplate ? 'Insert placeholder values' : 'Select WhatsApp Template'}
                    </Typography>
                    <div className={classes.headerRowButtonContainer}>
                        <Tooltip title="Close">
                            <IconButton size="small" variant="contained" color="primary" onClick={onCloseButtonClick}><CloseIcon /></IconButton>
                        </Tooltip>
                    </div>
                </CardMedia>
                <CardContent>
                    {!selectedTemplate && (
                        <div className={classes.templateSelectionContainer}>
                            {loading && <div style={{ width: '100%' }}><DelayedLinearProgress /></div>}
                            {!loading && filteredWhatsappTemplates?.length === 0 && (
                                <>
                                    <Typography variant="body1">
                                        No WhatsApp templates available.
                                    </Typography>
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={() => setCreateTemplateDialogOpen(true)}
                                    >
                                        Request WhatsApp Template
                                    </Button>
                                </>
                            )}
                            {filteredWhatsappTemplates?.sort?.(sortByProperty('content'))?.map?.((tmpl) => (
                                <div key={tmpl?.id} className={classes.templateButton}>
                                    <Button
                                        variant="contained"
                                        color={serviceInputsourcesByAddress?.[tmpl?.extensionParameters?.identityNumber]?.hasActiveThread ? 'secondary' : 'primary'}
                                        size="small"
                                        onClick={onTemplateClick(tmpl)}
                                    >
                                        {parseDisplayContent(tmpl?.content)}
                                    </Button>
                                    {serviceInputsourcesByAddress?.[tmpl?.extensionParameters?.identityNumber]?.hasActiveThread && <FormHelperText>Thread already active. Choosing this template will expire the existing thread.</FormHelperText>}
                                </div>
                            ))}
                        </div>
                    )}
                    {selectedTemplate && placeholders?.length > 0 && (
                        <div className={classes.templateParameterContainer}>
                            <Card className={classes.templatePreview} elevation={3}>
                                <CardMedia>
                                    <Typography variant="h5" gutterBottom>
                                        Template Preview
                                    </Typography>
                                </CardMedia>
                                <Typography variant="body1">
                                    {previewTemplateBody}
                                </Typography>
                            </Card>
                            <div className={classes.placeholderTable}>
                                <Table xs={12} sm={6}>
                                    <colgroup>
                                        <col width="30%" />
                                        <col width="70%" />
                                    </colgroup>
                                    <TableBody>
                                        {placeholders?.map?.((placeholder) => (
                                            <TableRow key={placeholder}>
                                                <TableCell align="left">
                                                    <Typography variant="h5">{placeholder}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField
                                                        fullWidth
                                                        value={placeholderValues?.[placeholder] ?? ''}
                                                        placeholder={`Enter a value to use instead of ${placeholder}`}
                                                        onChange={placeholderInputOnchange(placeholder)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    )}
                </CardContent>
                {selectedTemplate && (
                    <CardActions>
                        <Button variant="contained" color="primary" style={{ marginLeft: 'auto' }} onClick={handlePlaceholderSubmit}>OK</Button>
                    </CardActions>
                )}
            </Card>
            <MessageTemplateDialog channelType="Whatsapp" open={createTemplateDialogOpen} setOpen={setCreateTemplateDialogOpen} handleClose={handleMessageTemplateDialogClose} />
        </>
    );
};

WhatsappTemplateSelection.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default WhatsappTemplateSelection;