import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3, 0, 3, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    }
}));

const BreadCrumbItem = ({ match, title, TitleComponent, linkable }) => {
    const _match = useRouteMatch(match.url);

    if (Boolean(linkable) && !_match.isExact) {
        return <Link to={match.url}>{TitleComponent ? <TitleComponent match={match} /> : title}</Link>;
    }
    return <span>{TitleComponent ? <TitleComponent match={match} /> : title}</span>;
};

BreadCrumbItem.propTypes = {
    match: PropTypes.object,
    title: PropTypes.string,
    linkable: PropTypes.bool,
    TitleComponent: PropTypes.func
};

const SmartDialogBreadcrumbsWrapper = ({ routes }) => {
    const classes = useStyles();

    const SmartDialogBreadcrumbs = withBreadcrumbs(routes, { excludePaths: ['/', '/:customerId'] })(({ breadcrumbs }) => {
        if (breadcrumbs.some((bc) => bc.title)) {
            return (
                <Breadcrumbs className={classes.root}>
                    {breadcrumbs.filter((item) => Boolean(item.title) || Boolean(item.TitleComponent)).map(({ match, ...rest }) => (
                        <BreadCrumbItem key={match.url} match={match} {...rest} />
                    ))}
                </Breadcrumbs>
            );
        }
        return null;
    });

    return <SmartDialogBreadcrumbs />;
};

SmartDialogBreadcrumbsWrapper.propTypes = {
    routes: PropTypes.array
};

export default SmartDialogBreadcrumbsWrapper;