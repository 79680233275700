import { useLoadOneWayServices, useLoadTwoWayServices, useLoadGroupServices, useLoadCodeServices, useLoadCarrierBillingServices } from 'src/actions/actionHooks';

const ServicesProvider = () => {
    useLoadOneWayServices();
    useLoadTwoWayServices();
    useLoadGroupServices();
    useLoadCodeServices();
    useLoadCarrierBillingServices();

    return null;
};

export default ServicesProvider;