import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

const InfoChip = ({ title, content, ...rest }) => {
    const [open, setOpen] = React.useState(false);

    const onClick = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Chip {...rest} icon={<InfoOutlined />} label="INFO" onClick={onClick} />
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={onClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

InfoChip.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};

export default InfoChip;