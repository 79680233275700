import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';

const RestrictedResourcesInfoContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="body1">
                Here you can select a single service, to which you want to give a restricted access.
                <br />
                This service can only be seen by access group and only by users which have been added to that specific access group.
            </Typography>
        </div>
    );
};

export default RestrictedResourcesInfoContent;