import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { ProductAccessProvider, ProductAccessConsumer } from 'src/auth/ProductAccess';
import SmartDialogBreadCrumbs from 'src/components/SmartDialogBreadCrumbs';
import ErrorBoundary from 'src/components/ErrorBoundary';
import NavigationGuard from 'src/components/NavigationGuard';
import { useHistory } from 'react-router';
import ScrollableContent from 'src/components/ScrollableContent';
import { InfoPopupProvider } from 'src/components/InfoPopup';
import DialogProvider from 'src/components/Dialogs/DialogProvider';
import NotificationsProvider from 'src/components/Notifications/NotificationsProvider';
import AccessGroupProvider from 'src/auth/AccessGroupProvider';
import NewsAndAlertsProvider from 'src/components/NewsAlerts/NewsAndAlertsProvider';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import DevTools from 'src/components/DevTools';
import SnackbarProvider from 'src/components/Snackbar';
import CustomerDataProvider from 'src/providers/CustomerDataProvider';
import ExternalPopupWindowProvider from 'src/components/ExternalPopupWindows/ExternalPopupWindowProvider';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        height: theme.constants.contentHeight,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: ({ desktopDrawerOpen }) => (
                desktopDrawerOpen ? 256 : 0
            )
        },
        [theme.breakpoints.up('sm')]: {
            top: 64
        },
        [theme.breakpoints.down('xs')]: {
            top: 56
        }
    }
}));

function MainLayout({ route }) {
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const [desktopDrawerOpen, setDesktopDrawerOpen] = React.useState(true);
    const classes = useStyles({ desktopDrawerOpen });
    const history = useHistory();

    return (
        <ProductAccessProvider>
            <SnackbarProvider>
                <AccessGroupProvider />
                <ProductAccessConsumer>
                    {({ loading, renderRoutes }) => (
                        <ExternalPopupWindowProvider>
                            <CustomerDataProvider />
                            <DialogProvider />
                            <NewsAndAlertsProvider />
                            <NotificationsProvider />
                            <DevTools />
                            <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                            <NavBar
                                onMobileClose={() => setOpenNavBarMobile(false)}
                                openMobile={openNavBarMobile}
                                desktopDrawerOpen={desktopDrawerOpen}
                                setDesktopDrawer={(value) => setDesktopDrawerOpen(value)}
                            />
                            <InfoPopupProvider>
                                <div className={classes.content}>
                                    <ScrollableContent style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                                        {loading && <DelayedLinearProgress />}
                                        <Suspense fallback={<LinearProgress />}>
                                            <SmartDialogBreadCrumbs routes={route.routes} />
                                            <ErrorBoundary>
                                                {!loading && renderRoutes(route.routes)}
                                            </ErrorBoundary>
                                        </Suspense>
                                    </ScrollableContent>
                                </div>
                            </InfoPopupProvider>
                            <NavigationGuard
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => true}
                            />
                        </ExternalPopupWindowProvider>
                    )}
                </ProductAccessConsumer>
            </SnackbarProvider>
        </ProductAccessProvider>
    );
}

MainLayout.propTypes = {
    route: PropTypes.object
};

export default MainLayout;