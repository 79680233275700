import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    errorLayoutContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    }
}));

function Error({ route }) {
    const classes = useStyles();

    return (
        <div className={classes.errorLayoutContainer}>
            <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
            </Suspense>
        </div>
    );
}

Error.propTypes = {
    route: PropTypes.object
};

export default Error;
