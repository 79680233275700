import React from 'react';
import PropTypes from 'prop-types';

const ThreadChatWindowContext = React.createContext();

export const ThreadChatWindowContextProvider = ({ dialogState, children }) => {
    return (
        <ThreadChatWindowContext.Provider value={dialogState}>
            {children}
        </ThreadChatWindowContext.Provider>
    );
};

ThreadChatWindowContextProvider.propTypes = {
    dialogState: PropTypes.object.isRequired,
    children: PropTypes.any
};

export const useThreadChatWindowContext = () => {
    const context = React.useContext(ThreadChatWindowContext);
    if (context === undefined) {
        throw new Error('useThreadChatWindowContext must be used within a ThreadChatWindowContextProvider');
    }
    return context;
};