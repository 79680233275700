import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const MessagingAccountTitle = ({ match }) => {
    const { messagingAccounts } = useSelector((state) => state.messagingAccounts);
    const messagingAccountName = messagingAccounts?.find((account) => account.id === match?.params?.accountId)?.name;

    return (
        <div>
            {messagingAccountName || 'Messaging Account'}
        </div>
    );
};

MessagingAccountTitle.propTypes = {
    match: PropTypes.object
};

export default MessagingAccountTitle;