import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/userAccessActions';
import { sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    usersLoading: true,
    accessGroupsLoading: true,
    customerProductsLoading: true,
    customerResourcesLoading: true,
    refreshUsers: false,
    refreshAccessGroups: false,
    refreshCustomerProducts: false,
    refreshCustomerResources: false,

    usersById: {},
    customerProductsByProductId: {},
    customerProductsById: {},
    accessGroups: [],
    newAccessGroupUsers: [],
    accessGroupsByUserId: {},
    customerResourcesById: {},
    productTypes: {},
    accessGroupGridView: true
};

const userAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_USERS: {
            return {
                ...state,
                usersLoading: false,
                usersById: action?.data?.reduce((users, user) => ({ ...users, [user.externalUserId]: user }), {})
            };
        }
        case ACTIONS.SET_USERS_LOADING: {
            return {
                ...state,
                usersLoading: true
            };
        }
        case ACTIONS.SET_ACCESSGROUPS: {
            return {
                ...state,
                refreshAccessGroups: false,
                accessGroupsLoading: false,
                accessGroups: action?.data?.sort(sortByProperty('name')) ?? [],
                accessGroupsByUserId: action?.data?.reduce((accessGroupsByUserIds, accessGroup) => (
                    accessGroup?.accessGroupUsers?.reduce((accessGroupUsers, user) => ({
                        ...accessGroupUsers ?? [],
                        [user.userId]: [
                            ...accessGroupUsers?.[user.userId] ?? [],
                            {
                                ...accessGroup
                            }
                        ]
                    }), accessGroupsByUserIds) ?? {}), {}) ?? {}
            };
        }
        case ACTIONS.SET_ACCESSGROUPS_LOADING: {
            return {
                ...state,
                accessGroupsLoading: true
            };
        }
        case ACTIONS.REFRESH_ACCESSGROUPS: {
            return {
                ...state,
                refreshAccessGroups: true
            };
        }
        case ACTIONS.SET_NEW_ACCESSGROUP_USERS: {
            return {
                ...state,
                newAccessGroupUsers: Array.isArray(action?.data) ? action.data : []
            };
        }
        case ACTIONS.SET_CUSTOMERPRODUCTS: {
            return {
                ...state,
                customerProductsLoading: false,
                customerProductsByProductId: action?.data?.reduce?.((productTypes, customerProduct) => ({ ...productTypes, [customerProduct?.product?.id]: customerProduct }), {}) ?? {},
                customerProductsById: action?.data?.filter?.((customerProduct) => customerProduct?.product?.accessGroupAssignable)?.reduce?.((customerProducts, customerProduct) => ({ ...customerProducts, [customerProduct.id]: customerProduct }), {}) ?? {},
                productTypes: action?.data?.reduce?.((productTypes, customerProduct) => ({ ...productTypes, [customerProduct?.product?.productType]: customerProduct?.product }), {}) ?? {}
            };
        }
        case ACTIONS.SET_CUSTOMERPRODUCTS_LOADING: {
            return {
                ...state,
                customerProductsLoading: true
            };
        }
        case ACTIONS.SET_CUSTOMERRESOURCES: {
            return {
                ...state,
                customerResourcesLoading: false,
                customerResourcesById: action?.data?.reduce((customerResources, customerResource) => ({ ...customerResources, [customerResource.id]: customerResource }), {}) ?? {}
            };
        }
        case ACTIONS.SET_CUSTOMERRESOURCES_LOADING: {
            return {
                ...state,
                customerResourcesLoading: true
            };
        }
        case ACTIONS.SET_ACCESSGROUP_GRID_VIEW: {
            return {
                ...state,
                accessGroupGridView: Boolean(action?.data)
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default:
            return {
                ...state
            };
    }
};

export default userAccessReducer;