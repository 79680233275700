import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/oneWayMessagingActions';

const initialState = {
    services: [],
    refreshServices: false,
    servicesLoading: true
};

const oneWayMessagingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ONEWAYSERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                services: [...action?.data ?? []]
            };
        }
        case actionTypes.SET_ONEWAYSERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case actionTypes.SET_ONEWAYSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.REFERSH_ONEWAYSERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case actionTypes.ONEWAYSERVICEUPDATED: {
            return {
                ...state,
                services: state.services.reduce((services, service) => {
                    if (service?.id === action?.data?.id) {
                        return [...services, action.data];
                    }
                    return [...services, service];
                }, [])
            };
        }
        case actionTypes.ONEWAYSERVICEDELETED: {
            return {
                ...state,
                services: state.services.filter((service) => service?.id !== action?.data)
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default oneWayMessagingReducer;
