import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import Measure from 'react-measure';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    copyContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            flex: 1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            minWidth: 0,
            overflow: 'hidden',
            fontSize: 11,
            marginRight: 2
        },
        '& > button': {
            padding: 0,
            marginLeft: 5
        }
    }
}));

const WithCopyButton = ({ children, text }) => {
    const classes = useStyles();
    const { isCopied, handleCopy } = useCopyToClipboard(5000);

    const handleCopyClick = () => {
        handleCopy(text);
    };

    return (
        <Measure
            bounds
        >
            {({ measureRef, contentRect: { bounds: { height } } }) => (
                <div ref={measureRef} className={classes.copyContainer}>
                    {children}
                    <Tooltip title={isCopied ? 'Copied' : 'Copy to Clipboard'} onClick={handleCopyClick}>
                        <IconButton>
                            <CopyIcon
                                style={{
                                    height: height ? (height - 6) : 0,
                                    width: height ? (height - 6) : 0,
                                    maxHeight: 30,
                                    maxWidth: 30
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Measure>

    );
};

WithCopyButton.propTypes = {
    children: PropTypes.any.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object, PropTypes.func]).isRequired
};

export default WithCopyButton;