import palette from '../palette';

export default {
    message: {
        whiteSpace: 'break-spaces'
    },
    standardSuccess: {
        backgroundColor: palette.success.main,
        color: palette.white,
        '& *,.MuiTypography-root': {
            color: palette.white
        }
    },
    standardError: {
        backgroundColor: palette.error.main,
        color: palette.white,
        '& *,.MuiTypography-root': {
            color: palette.white
        }
    }
};