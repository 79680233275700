import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ErrorBoundary from 'src/components/Widgets/ErrorBoundary';
import WidgetMenu from 'src/components/Widgets/WidgetMenu';
import { Card, DialogTitle, IconButton } from '@material-ui/core';
import { RefreshRounded, LinkRounded } from '@material-ui/icons';
import WidgetSkeleton from 'src/components/Skeleton';
import { WidgetComponentContextProvider } from 'src/components/Widgets/WidgetComponentContext';

const useStyles = makeStyles((theme) => ({
    card: (props) => ({
        overflow: 'hidden',
        height: '100%',
        '& .react-resizable-handle': (props?.['data-grid']?.isResizable && props?.['data-grid']?.isDraggable) ?? ({
            display: 'none'
        }),
        position: 'unset'
    }),
    widgetTitle: {
        height: '200px'
    },
    widgetCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    refreshButton: {
        padding: 0,
        marginRight: 3,
        color: theme.palette.white
    },
    dialogTitle: {
        '&& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    linkButton: {
        marginLeft: 'auto',
        color: theme.palette.white
    }
}));

const WidgetComponent = ({ componentName, settings }) => {
    const [component, setComponent] = React.useState(null);

    React.useEffect(() => {
        let mounted = true;

        const importComponent = async () => {
            const Component = await React.lazy(() => import(`src/widgets/${componentName}`).catch(() => import('src/widgets/WidgetNotFound')));
            if (mounted) {
                setComponent(<Component settings={settings} />);
            }
        };

        if (componentName && mounted) {
            importComponent();
        }

        return () => { mounted = false; };
    }, [componentName, settings]);

    return (
        <React.Suspense fallback={<WidgetSkeleton />}>
            {component}
        </React.Suspense>
    );
};

WidgetComponent.propTypes = {
    componentName: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
};

const Widget = React.forwardRef(({
    component,
    id,
    layout,
    settings,
    onRemoveWidget,
    ...rest
}, ref) => {
    const classes = useStyles({ ...rest });
    const { x, y, w, h } = layout;
    const _onRemoveWidget = React.useCallback(() => onRemoveWidget(), [onRemoveWidget]);

    return (
        <div
            {...rest}
            ref={ref}
            className={classes.card}
            data-grid={{
                i: id,
                x,
                y,
                w,
                h
            }}
        >
            <ErrorBoundary>
                <WidgetComponentContextProvider onRemoveWidget={_onRemoveWidget}>
                    {({ setShouldRefresh, setShouldNavigateToResource }) => (
                        <WidgetMenu>
                            <Card className={classes.widgetCard}>
                                {settings.title && (
                                    <DialogTitle className={classes.dialogTitle}>
                                        {settings.hasRefresh && (
                                            <IconButton className={classes.refreshButton} size="small" aria-label="Refresh Widget" onClick={() => setShouldRefresh(true)}>
                                                <RefreshRounded />
                                            </IconButton>
                                        )}
                                        {settings.title}
                                        {settings.hasLink && (
                                            <IconButton className={classes.linkButton} size="small" aria-label="Go to resource" onClick={() => setShouldNavigateToResource(true)}>
                                                <LinkRounded />
                                            </IconButton>
                                        )}
                                    </DialogTitle>
                                )}
                                <ErrorBoundary>
                                    <WidgetComponent componentName={component} settings={settings} />
                                </ErrorBoundary>
                            </Card>
                        </WidgetMenu>
                    )}
                </WidgetComponentContextProvider>
            </ErrorBoundary>
        </div>
    );
});

Widget.propTypes = {
    style: PropTypes.object,
    isResizable: PropTypes.bool,
    isDraggable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
    layout: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onRemoveWidget: PropTypes.func
};

export default Widget;