import React from 'react';
import { useHttpFetchRequest } from 'src/utils/httpClient';

const urlCreator = window.URL || window.webkitURL;

const defaultExpirationInHours = 30 * 3 * 24; // 3 Months

export const useUploadAttachment = (inputFile = null) => {
    const [attachment, setAttachment] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (inputFile === null && attachment) {
            urlCreator.revokeObjectURL(attachment.url);
            setAttachment(null);
        }
        if (inputFile && attachment === null) {
            const url = urlCreator.createObjectURL(inputFile);
            setAttachment({
                file: inputFile,
                url
            });
        }
        if (inputFile && attachment && inputFile?.name !== attachment?.file?.name) {
            urlCreator.revokeObjectURL(attachment.url);
            const url = urlCreator.createObjectURL(inputFile);
            setAttachment({
                file: inputFile,
                url
            });
        }
    }, [inputFile, attachment]);

    const { mutate: uploadMediaAttachement } = useHttpFetchRequest(({ body, expirationInHours = defaultExpirationInHours }) => ({
        method: 'POST',
        endpoint: '/utils/media',
        body,
        params: {
            expirationInHours
        }
    }));

    const uploadAttachment = React.useCallback(async () => {
        if (attachment) {
            setLoading(true);
            const body = new FormData();
            body.append('files', attachment.file, attachment.file.name);

            const response = await uploadMediaAttachement({
                body
            });
            setLoading(false);
            return response;
        }
        return { payload: null, error: false, errorMessages: null };
    }, [uploadMediaAttachement, attachment]);

    return {
        uploadAttachment,
        attachment,
        loading
    };
};
