import React from 'react'; import { Button, Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { MessageTemplateTypes } from 'src/components/Dialogs/ChatWindow/MessageTemplateSelection';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    templateSelectionButtons: {
        marginTop: theme.spacing(1),
        '&& > button:not(:first-child)': {
            marginLeft: theme.spacing(1)
        }
    }
}));

const MessageTemplateSelectionButtons = ({ enabledTemplateTypes, onTemplateTypeSelected }) => {
    const classes = useStyles();
    const { selectedDialogMessage, sendDiscussionMessageSettings } = useSelector((state) => state.dialog);

    const handleInsertTemplateClick = (templateType) => () => {
        onTemplateTypeSelected(templateType);
    };

    const quickReplyEnabled = enabledTemplateTypes.indexOf(MessageTemplateTypes.QuickReply) > -1;
    const smsEnabled = enabledTemplateTypes.indexOf(MessageTemplateTypes.SMS) > -1;
    const whatsappEnabled = enabledTemplateTypes.indexOf(MessageTemplateTypes.Whatsapp) > -1;

    const whatsappDisabled = React.useMemo(() => {
        return !(selectedDialogMessage?.senderAddress ?? sendDiscussionMessageSettings?.recipientAddress);
    }, [selectedDialogMessage, sendDiscussionMessageSettings]);

    return (
        <Collapse in={enabledTemplateTypes.length > 0}>
            <div className={classes.templateSelectionButtons}>
                {quickReplyEnabled && <Button size="small" variant="contained" color="primary" onMouseDown={handleInsertTemplateClick(MessageTemplateTypes.QuickReply)}>Quick Replies</Button>}
                {smsEnabled && <Button size="small" variant="contained" color="primary" onMouseDown={handleInsertTemplateClick(MessageTemplateTypes.SMS)}>SMS Templates</Button>}
                {whatsappEnabled && <Button size="small" disabled={whatsappDisabled} variant="contained" color="primary" onMouseDown={handleInsertTemplateClick(MessageTemplateTypes.Whatsapp)}>WhatsApp Templates</Button>}
            </div>
        </Collapse>
    );
};

MessageTemplateSelectionButtons.defaultProps = {
    enabledTemplateTypes: []
};

MessageTemplateSelectionButtons.propTypes = {
    enabledTemplateTypes: PropTypes.arrayOf(PropTypes.string),
    onTemplateTypeSelected: PropTypes.func.isRequired
};

export default MessageTemplateSelectionButtons;