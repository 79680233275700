import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { sortByDateProperty } from 'src/utils/arrayFunctions';
import { useParams } from 'react-router';
import { ClearThreadMessages, RefreshThreadMessages, SetThreadMessagesLoaded, SetThreadMessagesLoading } from 'src/actions';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { useHttpRequest } from 'src/utils/httpClient';
import { useThreadChatWindowContext } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import Measure from 'react-measure';
import Message from './Message';
import ReplyMessage from './ReplyMessage';

const useStyles = makeStyles((theme) => ({
    messagesRoot: {
        flex: 1,
        position: 'relative',
        width: '100%'
    },
    inner: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'auto'
    }
}));

function ConversationMessages({ className, ...rest }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { threadId } = useParams();
    const [scrollContentRef, setScrollContentRef] = React.useState(null);
    const [messageContainerHeight, setMessageContainerHeight] = React.useState(0);
    const { threadsById, threadMessagesById, threadMessagesLoading, refreshThreadMessages, sentDialogMessagesByThreadId, isWidget, dialogInboxHasFocus } = useThreadChatWindowContext();

    const selectedThread = React.useMemo(() => threadsById?.[threadId], [threadsById, threadId]);

    const selectedThreadServiceId = selectedThread?.serviceId ?? null;
    const selectedThreadIsTemporary = selectedThread?.isTemporary ?? null;

    const allThreadmessages = React.useMemo(() => {
        const sentDialogMessagesById = sentDialogMessagesByThreadId?.[threadId] ?? {};

        const sentDialogMessageIds = Object.keys(sentDialogMessagesById);
        const threadMessageIds = Object.keys(threadMessagesById).filter((messageId) => threadMessagesById[messageId]?.threadId === threadId);

        const distinctMessageIds = [
            ...new Set([
                ...sentDialogMessageIds,
                ...threadMessageIds
            ])];

        return distinctMessageIds.map((messageId) => (threadMessagesById?.[messageId] ?? sentDialogMessagesById?.[messageId])).sort(sortByDateProperty('sentTimestamp', 'desc'));
    }, [threadId, sentDialogMessagesByThreadId, threadMessagesById]);

    const { mutate: fetchThreadMessages } = useHttpRequest(({ threadId, serviceId }) => ({
        method: 'GET',
        endpoint: `/messaging/services/dialog/threads/${serviceId}/${threadId}`
    }));

    // Scroll to bottom when messages are loaded
    React.useEffect(() => {
        if (scrollContentRef && !threadMessagesLoading) {
            scrollContentRef.scrollTop = scrollContentRef.scrollHeight;
        }
    }, [scrollContentRef, threadMessagesLoading, allThreadmessages, messageContainerHeight]);

    const imageLoaded = React.useCallback(() => {
        if (scrollContentRef) {
            scrollContentRef.scrollTop = scrollContentRef.scrollHeight;
        }
    }, [scrollContentRef]);

    React.useEffect(() => {
        if (!isWidget && threadId) {
            dispatch(RefreshThreadMessages());
        }
    }, [isWidget, threadId, dispatch]);

    React.useEffect(() => {
        const makeRequest = async () => {
            dispatch(SetThreadMessagesLoading(true));
            const { error, payload } = await fetchThreadMessages({ threadId, serviceId: selectedThreadServiceId });
            dispatch(SetThreadMessagesLoaded(error ? [] : payload));
        };
        if (!isWidget && threadId && !selectedThreadIsTemporary && refreshThreadMessages && dialogInboxHasFocus) {
            makeRequest();
        }
    }, [isWidget, threadId, selectedThreadServiceId, selectedThreadIsTemporary, fetchThreadMessages, refreshThreadMessages, dispatch, dialogInboxHasFocus]);

    // Clear messages when selecting other thread
    React.useEffect(() => {
        if (!isWidget) {
            dispatch(ClearThreadMessages([]));
        }
    }, [dispatch, threadId, isWidget]);

    return (
        <Measure
            bounds
            onResize={(contentRect) => {
                setMessageContainerHeight(contentRect.bounds.height);
            }}
        >
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    {...rest}
                    className={classes.messagesRoot}
                >
                    <div className={classes.inner} ref={(ref) => setScrollContentRef(ref)}>
                        {/* Only show loading when first opening chatview to prevent flashing when thread is update */}
                        {threadMessagesLoading && allThreadmessages.length === 0 && <DelayedLinearProgress />}
                        {allThreadmessages?.map((message) => {
                            if (message.internalQueueId) {
                                return (
                                    <ReplyMessage
                                        key={message.internalQueueId}
                                        message={message}
                                        imageLoaded={imageLoaded}
                                    />
                                );
                            }
                            return (
                                <Message
                                    key={message?.id}
                                    message={message}
                                    imageLoaded={imageLoaded}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </Measure>
    );
}

ConversationMessages.propTypes = {
    className: PropTypes.string
};

export default ConversationMessages;
