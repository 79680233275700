import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/sendGridActions';

const initialState = {
    sendGridTemplates: [],
    sendGridVerifiedSendersLoading: true,
    sendGridVerifiedSender: [],
    sendGridTemplatesLoading: true,
    sendGridFullTemplate: null,
    sendgridFullTemplateLoading: true
};

const SendGridReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_SENDGRID_TEMPLATES: {
            return {
                ...state,
                sendGridTemplates: Array.isArray(action?.data) ? action.data : [],
                sendGridTemplatesLoading: false
            };
        }
        case ACTIONS.SET_SENDGRID_TEMPLATES_LOADING: {
            return {
                ...state,
                sendGridTemplatesLoading: true
            };
        }
        case ACTIONS.SET_SENDGRID_VERIFIED_SENDERS: {
            return {
                ...state,
                sendGridVerifiedSender: Array.isArray(action?.data) ? action.data : [],
                sendGridVerifiedSendersLoading: false
            };
        }
        case ACTIONS.SET_SENDGRID_VERIFIED_SENDERS_LOADING: {
            return {
                ...state,
                sendGridVerifiedSendersLoading: true
            };
        }
        case ACTIONS.SET_SENDGRID_FULLTEMPLATE: {
            return {
                ...state,
                sendGridFullTemplate: action.data,
                sendGridFullTemplateLoading: false
            };
        }
        case ACTIONS.SET_SENDGRID_FULLTEMPLATE_LOADING: {
            return {
                ...state,
                sendGridFullTemplateLoading: true
            };
        }
        case ACTIONS.CLEAR_SENDGRID_FULLTEMPLATE: {
            return {
                ...state,
                sendGridFullTemplate: null
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default SendGridReducer;
