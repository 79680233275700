import { useSnackbar } from 'src/components/Snackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROCESS_SNACKBAR_NOTIFICATION } from 'src/actions/notificationActions';

const useNotificationProcessor = () => {
    const dispatch = useDispatch();
    const { notification, closeAllSnackbars } = useSnackbar();
    const { newNotificationSnackbarQueue, notificationsDrawerOpen } = useSelector((state) => state.notifications);

    // Process newNotificationSnackbarQueue
    React.useEffect(() => {
        if (newNotificationSnackbarQueue?.length > 0) {
            const _notification = newNotificationSnackbarQueue[0];

            if (!notificationsDrawerOpen) {
                notification(_notification);
            }

            dispatch({ type: PROCESS_SNACKBAR_NOTIFICATION, data: _notification });
        }
    }, [notificationsDrawerOpen, newNotificationSnackbarQueue, notification, dispatch]);

    // When opening notification drawer, close all open notification snacks
    React.useEffect(() => {
        if (notificationsDrawerOpen) {
            closeAllSnackbars();
        }
    }, [notificationsDrawerOpen, closeAllSnackbars]);

    return null;
};

export default useNotificationProcessor;