import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/DoneRounded';
import MessageAttachment from 'src/components/Dialogs/ChatWindow/MessageAttachment';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    outboundMessage: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& $body': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    },
    bodyAndErrorMessageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        maxWidth: '100%'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        maxWidth: 500,
        backgroundColor: colors.grey[100],
        color: theme.palette.text.primary,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 2),
        wordBreak: 'break-all',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    },
    errorMessage: {
        wordBreak: 'break-all',
        color: theme.palette.error.main
    },
    senderText: {
        alignSelf: 'flex-start'
    },
    messageContent: {
        whiteSpace: 'pre-wrap'
    },
    loading: {
        width: '100%'
    },
    statusRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const ConversationMessage = ({ message, className, imageLoaded, ...rest }) => {
    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(
                classes.root,
                classes.outboundMessage,
                className
            )}
        >
            <div className={classes.bodyAndErrorMessageContainer}>
                <div className={classes.body}>
                    <Typography
                        color="inherit"
                        variant="h5"
                        className={classes.senderText}
                    >
                        {message?.senderName ?? 'SenderAddress'}
                    </Typography>
                    {message.attachmentUri && (
                        <div className={classes.messageAttachmentContainer}>
                            <MessageAttachment uri={message.attachmentUri} imageLoaded={imageLoaded} />
                        </div>
                    )}
                    <Typography
                        color="inherit"
                        variant="body1"
                        className={classes.messageContent}
                    >
                        {message?.content ?? ''}
                    </Typography>
                    <div className={classes.statusRow}>
                        <Typography
                            color="inherit"
                            variant="body2"
                        >
                            {moment(message?.sentTimestamp ?? new Date()).fromNow()}
                        </Typography>
                        {!message?.error && <DoneIcon fontSize="inherit" style={{ fontSize: 15 }} />}
                    </div>
                    {message?.errorMessage && (
                        <Typography
                            className={classes.errorMessage}
                            variant="body2"
                        >
                            {`Message was not sent: ${message.errorMessage}`}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};

ConversationMessage.propTypes = {
    className: PropTypes.string,
    message: PropTypes.object.isRequired,
    imageLoaded: PropTypes.func.isRequired
};

export default ConversationMessage;
