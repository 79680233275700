import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Tooltip } from 'chart.js';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    chartContainer: {
        minHeight: 0,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative'
    }
}));

Tooltip.positioners.customPosition = (items) => {
    if (items?.length) {
        const { chart } = items[0]._chart;
        const { x } = items[0]._model;

        return {
            x,
            y: chart.chartArea.bottom,
            xAlign: 'center',
            yAlign: 'bottom',
        };
    }
    return false;
};

const barThickness = 15;
const maxBarThickness = 12;
const cornerRadius = 3;
const stacked = true;

function ChargesBarChart({ data: { byTimestamp, total }, groupBy, labels }) {
    const classes = useStyles();
    const theme = useTheme();

    const getLabelCharges = (msgType) => {
        return byTimestamp?.[msgType]?.reduce((acc, current) => acc + current.y, 0) ?? 0;
    };

    const keywordDatasets = labels?.filter?.((l) => getLabelCharges(l) > 0)?.map?.((l, i) => {
        return {
            label: `${l || 'Keywordless'} - ${String(getLabelCharges(l)?.toFixed(2) ?? '').replace('.', ',')}`,
            tooltipLabel: l,
            backgroundColor: theme.palette.charges[i % 10].main,
            borderColor: 'transparent',
            data: byTimestamp[l],
            barThickness,
            maxBarThickness,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            stack: 2,
            hidden: !(total?.[l] > 0)
        };
    }) ?? [];

    const data = {
        datasets: [...keywordDatasets,
            {
                label: `Total charges - ${String(getLabelCharges('TotalCharges')?.toFixed(2) ?? '').replace('.', ',')}`,
                tooltipLabel: 'Total charges',
                backgroundColor: theme.palette.primary.main,
                borderColor: 'transparent',
                data: byTimestamp.TotalCharges,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                stack: 1
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius,
        animation: {
            duration: 500,
            easing: 'linear'
        },
        legend: {
            display: true
        },
        layout: {
            padding: 0
        },
        scales: {
            xAxes: [
                {
                    offset: true,
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: groupBy,
                        displayFormats: {
                            minute: 'mm',
                            hour: 'HH',
                            day: 'D.M.YYYY',
                            week: '[w]w',
                            month: 'MMM-YY',
                            quarter: '[Q]Q-YY',
                            year: 'YYYY',
                        }
                    },
                    bounds: 'ticks',
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        source: 'auto',
                        padding: 20,
                        fontColor: theme.palette.text.secondary
                    },
                    stacked
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    },
                    ticks: {
                        padding: 20,
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                        maxTicksLimit: 5
                    },
                    stacked
                }
            ]
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            position: 'customPosition',
            intersect: false,
            yAlign: 'bottom',
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.common.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: () => { },
                label: (tooltipItem) => {
                    const label = `${data.datasets[tooltipItem.datasetIndex].tooltipLabel || 'Keywordless'}: ${String(Number(tooltipItem.yLabel ?? 0)?.toFixed(2)).replace('.', ',')}`;
                    return tooltipItem.yLabel !== 0 && label;
                }
            }
        }
    };

    return (
        <div className={classes.chartContainer}>
            <Bar
                data={data}
                options={options}
            />
        </div>
    );
}

ChargesBarChart.propTypes = {
    data: PropTypes.object.isRequired,
    groupBy: PropTypes.string,
    labels: PropTypes.array.isRequired
};

export default ChargesBarChart;