/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Chip
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import DoughnutChart from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChart';
import DoughnutChartStats from 'src/components/OneWayMessaging/OneWayServiceStatistics/Charts/DoughnutChartStats';
import uuid from 'uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chipContainer: {
        textAlign: 'center',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chartContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        overflow: 'hidden',
        padding: theme.spacing(1, 2, 2, 2)
    },
    statsContainer: {
        textAlign: 'center',
        display: 'flex',
        flex: 0,
        justifyContent: 'center'
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid #e2e6e9'
    }
}));

const dateFormatter = (rowData) => {
    const date = moment.utc(rowData).local();
    return date.isValid() ? date.format('D.M.YYYY HH:mm') : '';
};

function CodeStatisticsDoughnut({
    data, chips, showBy, interval, isWidget
}) {
    const classes = useStyles();
    const theme = useTheme();

    const _sent = data?.filter((c) => c?.status === 'Sent' && c?.events?.find((e) => e?.type === 'Sent' && moment(e?.timestamp)?.isBetween(moment(interval.from), moment(interval.to).endOf('date')))) ?? [];
    const _redeemed = data?.filter((c) => c?.status === 'Redeemed' && c?.events?.find((e) => e?.type === 'Redeemed' && moment(e?.timestamp)?.isBetween(moment(interval.from), moment(interval.to).endOf('date')))) ?? [];
    const _voided = data?.filter((c) => c?.status === 'Voided' && c?.events?.find((e) => e?.type === 'Voided' && moment(e?.timestamp)?.isBetween(moment(interval.from), moment(interval.to).endOf('date')))) ?? [];
    const _recycled = data?.filter((c) => c?.status === 'Recycled' && c?.events?.find((e) => e?.type === 'Recycled' && moment(e?.timestamp)?.isBetween(moment(interval.from), moment(interval.to).endOf('date')))) ?? [];
    const total = _sent.length + _redeemed.length + _voided.length + _recycled.length;

    let chartData = [
        {
            id: uuid(),
            label: 'Sent',
            value: _sent.length,
            color: theme.palette.status.sent.main
        },
        {
            id: uuid(),
            label: 'Redeemed',
            value: _redeemed.length,
            color: theme.palette.status.redeemed.main
        },
        {
            id: uuid(),
            label: 'Voided',
            value: _voided.length,
            color: theme.palette.status.voided.main
        },
        {
            id: uuid(),
            label: 'Recycled',
            value: _recycled.length,
            color: theme.palette.status.recycled.main
        }
    ];

    if (showBy === 'percentage') {
        chartData = chartData.map((d) => ({ ...d, value: (d.value / total) * 100 }));
    }

    return (
        <Card
            className={classes.root}
        >
            <div className={classes.chipContainer}>
                {chips?.map((c, index) => <Chip key={index} className={classes.chip} label={c} />)}
                {isWidget && (
                    <Chip className={classes.chip} label={`Widget updated: ${dateFormatter(moment())}`} />
                )}
            </div>
            <div className={classes.chartContainer}>
                <DoughnutChart
                    data={chartData}
                    showBy={showBy}
                />
            </div>
            <div className={classes.statsContainer}>
                <DoughnutChartStats
                    data={chartData}
                    showBy={showBy}
                />
            </div>
        </Card>
    );
}

CodeStatisticsDoughnut.propTypes = {
    data: PropTypes.array.isRequired,
    chips: PropTypes.array,
    showBy: PropTypes.string,
    interval: PropTypes.any,
    isWidget: PropTypes.bool
};

export default CodeStatisticsDoughnut;