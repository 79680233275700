import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    chartAreaContainer: {
        minHeight: 0,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        padding: theme.spacing(1, 2, 2, 2)
    },
    chartContainer: {
        minHeight: 0,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative',
        height: '100%'
    }
}));

function PollStackedBarChart({ data, showBy, channel, hasIvr }) {
    const classes = useStyles();

    const options = {
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            callbacks: {
                label: (tooltipItem, data) => {
                    const type = data.datasets[tooltipItem.datasetIndex].label;
                    const amount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                    const suffix = showBy === 'percentage' ? '%' : '';
                    return `${type}: ${amount}${suffix}`;
                },
                footer: (tooltipItems) => {
                    if (channel === 'all' && hasIvr) {
                        let total = 0;
                        const suffix = showBy === 'percentage' ? '%' : '';
                        for (let i = 0; i < tooltipItems.length; i++) {
                            total += parseFloat(tooltipItems[i].yLabel, 10);
                        }
                        return `Total: ${showBy === 'percentage' ? total.toFixed(1) : total}${suffix}`;
                    }
                    return null;
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius: 2,
        scales: {
            xAxes: [{
                stacked: true,
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    suggestedMin: 0,
                    precision: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2]
                }
            }]
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.chartAreaContainer}>
                <div className={classes.chartContainer}>
                    <Bar
                        data={data}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

PollStackedBarChart.propTypes = {
    data: PropTypes.shape({
        datasets: PropTypes.array.isRequired
    }).isRequired,
    showBy: PropTypes.string,
    channel: PropTypes.string,
    hasIvr: PropTypes.bool
};

export default PollStackedBarChart;