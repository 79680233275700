import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Widget from 'src/components/FavouriteServiceWidget/FavouriteServiceWidget';

const FavouriteServiceWidget = ({ settings }) => {
    const { services: oneWayServices } = useSelector((state) => state.onewaymessaging);
    const { services: twoWayServices } = useSelector((state) => state.twowaymessaging);
    const { services: groupServices } = useSelector((state) => state.groupmessaging);
    const { services: codeServices } = useSelector((state) => state.codeservice);
    const { services: carrierBillingServices } = useSelector((state) => state.carrierbilling);
    const { services: surveyServices } = useSelector((state) => state.surveyservice);
    const { serviceType, serviceId } = settings;

    const getServiceName = () => {
        switch (serviceType) {
            case 'onewaymessaging':
                return oneWayServices?.find((s) => s.id === serviceId)?.name ?? '';
            case 'twowaymessaging':
                return twoWayServices?.find((s) => s.id === serviceId)?.name ?? '';
            case 'groupmessaging':
                return groupServices?.find((s) => s.id === serviceId)?.name ?? '';
            case 'codeservice':
                return codeServices?.find((s) => s.id === serviceId)?.name ?? '';
            case 'carrierbilling':
                return carrierBillingServices?.find((s) => s.id === serviceId)?.name ?? '';
            case 'surveyservice':
                return surveyServices?.find((s) => s.id === serviceId)?.name ?? '';
            default:
                return '';
        }
    };

    return (
        <Widget
            serviceType={serviceType}
            serviceId={serviceId}
            serviceName={getServiceName()}
        />
    );
};

export const size = {
    xl: {
        w: 1,
        h: 1
    },
    lg: {
        w: 1,
        h: 1
    },
    md: {
        w: 1,
        h: 1
    },
    xs: {
        w: 1,
        h: 1
    },
    xxs: {
        w: 1,
        h: 1
    }
};

FavouriteServiceWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export default FavouriteServiceWidget;