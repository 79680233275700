import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/folderActions';

const initialState = {
    foldersByServiceId: {},
    foldersLoading: true,
    refreshFolders: false
};

const folderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FOLDERS_BY_SERVICEID: {
            return {
                ...state,
                refreshFolders: false,
                foldersLoading: false,
                foldersByServiceId: action?.data?.reduce?.((folders, folder) => ({
                    ...folders,
                    [folder?.serviceId]: [
                        ...folders?.[folder?.serviceId] ? folders[folder?.serviceId] : [],
                        folder
                    ]
                }), {}) ?? {}
            };
        }
        case actionTypes.SET_FOLDERS_LOADING: {
            return {
                ...state,
                foldersLoading: Boolean(action.data)
            };
        }
        case actionTypes.REFERSH_FOLDERS: {
            return {
                ...state,
                refreshFolders: true
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default folderReducer;