import { SET_PROFILE_DRAWER_OPEN, SET_PROFILE_DRAWER_SETTINGS_OPEN, SET_PROFILE_DRAWER_SETTINGS_TAB } from 'src/actions';

const initialState = {
    serviceWorkerUpdated: false,
    openProfileDrawer: null,
    openSettingsDialog: null,
    openSettingsDialogTab: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'SW_UPDATE_AVAILABLE':
            return {
                ...state,
                serviceWorkerUpdated: true
            };
        case 'SW_UPDATED': {
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        }
        case SET_PROFILE_DRAWER_OPEN: {
            return {
                ...state,
                openProfileDrawer: Boolean(action?.data)
            };
        }
        case SET_PROFILE_DRAWER_SETTINGS_OPEN: {
            return {
                ...state,
                openSettingsDialog: Boolean(action?.data)
            };
        }
        case SET_PROFILE_DRAWER_SETTINGS_TAB: {
            return {
                ...state,
                openSettingsDialogTab: action?.data ?? null
            };
        }
        default:
            return state;
    }
};

export default reducer;