import { Dialog, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import ProfileIcon from '@material-ui/icons/PersonRounded';
import NotificationsIcon from '@material-ui/icons/NotificationsRounded';
import ProfileEdit from 'src/components/Profile/ProfileEdit';
import NotificationSettings from 'src/components/Profile/NotificationSettings';
import { useDispatch, useSelector } from 'react-redux';
import { OpenProfileDrawerSettingsTab } from 'src/actions';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme }) => ({
    '&': {
        backgroundColor: theme.palette.background.gray,
        color: theme.palette.white,
        minHeight: 60
    },
    '& .MuiTabs-indicator': {
        display: 'none',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)({
    color: 'rgba(255, 255, 255, 0.7)',
    minHeight: 60,
    padding: 0,
    '&.Mui-selected': {
        backgroundColor: '#373737'
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#373737'
    },
    '&& .MuiSvgIcon-root': {
        marginBottom: 0
    }
});

const TabDefinitions = {
    Profile: 'Profile',
    Notifications: 'Notifications'
};

const SettingsDialog = ({ open, setOpen, onClose }) => {
    const dispatch = useDispatch();
    const { openSettingsDialogTab } = useSelector((state) => state.app);
    const [selectedTab, setSelectedTab] = React.useState(TabDefinitions.Profile);

    React.useEffect(() => {
        if (openSettingsDialogTab && TabDefinitions?.[openSettingsDialogTab]) {
            setSelectedTab(openSettingsDialogTab);
            dispatch(OpenProfileDrawerSettingsTab(null));
        }
    }, [dispatch, openSettingsDialogTab]);

    const onExited = () => {
        setOpen(false);
        setSelectedTab(TabDefinitions.Profile);
        onClose();
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Dialog open={open} TransitionProps={{ onExited }} maxWidth="sm" fullWidth aria-label="settings-dialog">
            <StyledTabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="fullWidth"
            >
                <StyledTab icon={<ProfileIcon />} value={TabDefinitions.Profile} label={TabDefinitions.Profile} />
                <StyledTab icon={<NotificationsIcon />} value={TabDefinitions.Notifications} label={TabDefinitions.Notifications} />
            </StyledTabs>
            {selectedTab === TabDefinitions.Profile && <ProfileEdit setOpen={setOpen} selectedTab={selectedTab} />}
            {selectedTab === TabDefinitions.Notifications && <NotificationSettings setOpen={setOpen} selectedTab={selectedTab} />}
        </Dialog>
    );
};

SettingsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default SettingsDialog;