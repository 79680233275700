import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import InputIcon from '@material-ui/icons/Input';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Typography, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { OpenProfileDrawerSettings, logout } from 'src/actions';
import { useParams } from 'react-router';
import WithCopyButton from 'src/components/WithCopyButton';
import EmailIcon from '@material-ui/icons/EmailRounded';
import PhoneIcon from '@material-ui/icons/PhoneRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import SettingsDialog from 'src/components/Profile/SettingsDialog';

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    drawerPaper: {
        maxHeight: 300,
        width: 450,
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(8)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
            width: '100%'
        }
    },
    modalBackdrop: {
        backgroundColor: 'transparent'
    },
    header: {
        width: '100%',
        height: 75,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 3),
        paddingBottom: 5,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2),
            paddingBottom: 3,
        }
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2)
        }
    },
    footer: {
        width: '100%',
        height: 75,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2)
        }
    },
    companyNameContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'start',
        overflow: 'hidden'
    },
    subscriptionPlanContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'end'
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(1, 0, 1, 2),
        wordBreak: 'break-all'
    },
    userInfoText: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > svg': {
            height: 20,
            marginRight: 2
        }
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            width: 70,
            height: 70,
            fontSize: '2.25rem'
        },
        width: 90,
        height: 90,
        fontSize: '2.25rem'
    },
    logoutIcon: {
        marginRight: theme.spacing(1)
    }
}));

const ProfileDrawer = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const { selectedCustomerName, selectedCustomerRole, selectedCustomerSubscriptionPlan, claims, userInitials, isSysAdmin } = useSelector((state) => state.auth);
    const { openSettingsDialog } = useSelector((state) => state.app);
    const [SettingsDialogOpen, setSettingsDialogOpen] = React.useState(false);

    React.useEffect(() => {
        if (openSettingsDialog) {
            setSettingsDialogOpen(true);
            dispatch(OpenProfileDrawerSettings(false));
        }
    }, [dispatch, openSettingsDialog]);

    const logoutClick = () => {
        onClose();
        dispatch(logout());
    };

    return (
        <Drawer
            keepMounted
            ModalProps={{
                BackdropProps: {
                    className: classes.modalBackdrop
                }
            }}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <div className={classes.drawerContainer}>
                <Paper className={classes.header}>
                    <div className={classes.companyNameContainer}>
                        <Typography variant="h5">{selectedCustomerName}</Typography>
                        <WithCopyButton text={customerId}><Typography>{customerId}</Typography></WithCopyButton>
                    </div>
                    <div className={classes.subscriptionPlanContainer}>
                        {!isSysAdmin && <Typography variant="h5">{selectedCustomerSubscriptionPlan}</Typography>}
                        <Typography variant="h5">
                            Role:
                            {' '}
                            <b>{selectedCustomerRole}</b>
                        </Typography>
                    </div>
                </Paper>
                <div className={classes.content}>
                    <div className={classes.avatarContainer}>
                        <Avatar className={classes.avatar}>{userInitials}</Avatar>
                    </div>
                    <div className={classes.userInfoContainer}>
                        <Typography className={classes.userInfoText} variant="h4" gutterBottom>{claims?.name}</Typography>
                        <Typography className={classes.userInfoText} variant="subtitle2">
                            <PhoneIcon />
                            {claims?.mobilePhone}
                        </Typography>
                        <Typography className={classes.userInfoText} variant="subtitle2">
                            <EmailIcon />
                            {claims?.mail}
                        </Typography>
                    </div>
                </div>
                <Paper className={classes.footer}>
                    <Button
                        className={classes.logoutButton}
                        color="inherit"
                        onClick={() => setSettingsDialogOpen(true)}
                    >
                        <SettingsIcon className={classes.logoutIcon} />
                        Settings
                    </Button>
                    <Button
                        className={classes.logoutButton}
                        color="inherit"
                        onClick={logoutClick}
                    >
                        <InputIcon className={classes.logoutIcon} />
                        Sign out
                    </Button>
                </Paper>
            </div>
            <SettingsDialog open={SettingsDialogOpen} setOpen={setSettingsDialogOpen} onClose={() => { }} />
        </Drawer>
    );
};

ProfileDrawer.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired
};

export default ProfileDrawer;