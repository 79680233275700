export const Operators = {
    equals: { name: '=', label: 'equals' },
    notEqual: { name: '!=', label: 'does not equal' },
    largerThan: { name: '<', label: '<' },
    lessThan: { name: '>', label: '>' },
    largerThanOrEqual: { name: '<=', label: '<=' },
    lessThanOrEqual: { name: '>=', label: '>=' },
    contains: { name: 'Contains', label: 'contains' },
    startsWith: { name: 'StartsWith', label: 'starts with' },
    endsWith: { name: 'endsWith', label: 'ends with' },
    doesNotContain: { name: 'doesNotContain', label: 'does not contain' },
    doesNotBeginWith: { name: 'doesNotBeginWith', label: 'does not begin with' },
    doesNotEndWith: { name: 'doesNotEndWith', label: 'does not end with' },
    null: { name: 'null', label: 'is null' },
    notNull: { name: 'notNull', label: 'is not null' },
    in: { name: 'in', label: 'in' },
    notIn: { name: 'notIn', label: 'not in' },
    between: { name: 'between', label: 'between' },
    notBetween: { name: 'notBetween', label: 'not between' },
};

export const DataTypes = {
    text: 'text',
    number: 'number',
    boolean: 'boolean'
};

export const OperatorsArray = Object.keys(Operators).map((op) => Operators[op]);