import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Paper, Button, ButtonGroup } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import WidgetsIcon from '@material-ui/icons/Widgets';
import WidgetsList from 'src/components/Widgets/WidgetsList';
import { useWidgetContext, useWidgetDispatch, actions as widgetActions } from 'src/components/Widgets/WidgetContext';

const useStyles = makeStyles((theme) => ({
    menuContainer: {
        position: 'sticky',
        width: '100%',
        'z-index': 99999,
        height: '50px',
        top: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    grid: {
        padding: 10,
        height: '100%',
        width: '100%'
    }
}));

const WidgetGridMenu = () => {
    const classes = useStyles();
    const dispatch = useWidgetDispatch();
    const { editMode, widgets } = useWidgetContext();
    const [widgetsOpen, setWidgetsOpen] = React.useState(false);

    const saveClick = () => {
        dispatch({ type: widgetActions.LAYOUT_SAVE });
    };

    const cancelClick = () => {
        dispatch({ type: widgetActions.LAYOUT_CANCEL });
    };

    const editClick = () => {
        dispatch({ type: widgetActions.LAYOUT_EDIT });
    };

    const handleWidgetsOpen = () => {
        setWidgetsOpen(true);
    };

    const handleWidgetsClose = () => {
        setWidgetsOpen(false);
    };

    return (
        <Paper className={classes.menuContainer}>
            <Grid
                className={classes.grid}
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h4">Dashboard</Typography>
                    <ButtonGroup aria-label="button group">
                        {widgets?.length > 0 && editMode && (
                            <Button onClick={saveClick}><SaveIcon /></Button>
                        )}
                        {widgets?.length > 0 && editMode && (
                            <Button onClick={cancelClick}><CancelIcon /></Button>
                        )}
                        {widgets?.length > 0 && !editMode && (
                            <Button onClick={editClick}><EditIcon /></Button>
                        )}
                        <Button onClick={handleWidgetsOpen}><WidgetsIcon /></Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <WidgetsList open={widgetsOpen} handleClose={handleWidgetsClose} />
        </Paper>
    );
};

export default WidgetGridMenu;