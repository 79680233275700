/* eslint-disable no-param-reassign */
import React from 'react';

const usePreventWindowUnload = (preventUnload) => {
    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        if (preventUnload) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => { window.removeEventListener('beforeunload', handleBeforeUnload); };
    }, [preventUnload]);
};

export default usePreventWindowUnload;