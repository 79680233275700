const infoData = [
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Description',
        description: 'A description of the service.'
    },
    {
        field: 'Type',
        description: 'Choose between RKioski (Ärrä-koodi) and Generic codes.'
    },
    {
        field: 'Created date',
        description: 'The date when the Code service was created.'
    },
    {
        field: 'Out of codes message',
        description: 'This message will be sent to the end-user when there is no more unused codes.'
    },
    {
        field: 'Codes imported',
        description: 'Total amount of codes regardless code type.'
    },
    {
        field: 'Codes sent',
        description: 'The amount of codes sent.'
    },
    {
        field: 'Codes redeemed',
        description: 'The amount of codes redeemed.'
    },
    {
        field: 'Codes available now',
        description: 'The amount of unused and recycled codes taking dates into consideration.'
    },
    {
        field: 'Codes available later',
        description: 'The amount of unused codes where valid from is in the future.'
    },
    {
        field: 'Codes expired',
        description: 'The amount of unused and recycled codes that has expired.'
    },
    {
        field: 'Code generators: Priority',
        description: 'Decides in which order generators will be used when generating codes on the fly. Lower value means higher priority.'
    },
    {
        field: 'Code generators: Prefix',
        description: 'Will be used as a code prefix.'
    },
    {
        field: 'Code generators: Product',
        description: 'Product name can be put in a message with a placeholder.'
    },
    {
        field: 'Code generators: Valid days',
        description: 'How many days a code is valid.'
    },
    {
        field: 'Code generators: Algorithm',
        description: 'Alphanumeric: 16 character long code, with prefix included\n\nShort: 5 character long code, with prefix included\n\nGuid: Prefix + 36 character long guid'
    },
    {
        field: 'Code generators: Codes remaining',
        description: 'How many codes that can still be generated.'
    },
    {
        field: 'Limitations: Limitation type',
        description: 'Recipient max codes: Set max code amount for a recipient during service lifetime. Daily max codes: Set a daily max code amount for a recipient. Codes Remaining lower alert: An alert will be sent when the remaining code amount is less than the specified value.'
    },
    {
        field: 'Limitations: Value',
        description: 'The max amount of codes for a recipient.'
    },
    {
        field: 'Limitations: Error message',
        description: 'This message will be sent when the value is reached.'
    }
];

export default infoData;