const infoData = [
    {
        field: 'Service tags',
        description: 'Categorize services with Tags for future search and segmentation'
    },
    {
        field: 'Service name',
        description: 'The name of the service.'
    },
    {
        field: 'Service state',
        description: 'The state of the service, (Active or Passive).'
    },
    {
        field: 'Messaging account',
        description: 'The messaging account used for this service. The messaging account defines username, password and possible restrictions that apply to this service.'
    },
    {
        field: 'Start date',
        description: 'Service valid from this date.'
    },
    {
        field: 'End date',
        description: 'Service valid thru this date.'
    }
];

export default infoData;