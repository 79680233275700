import React from 'react';
import { Button, List, ListItem } from '@material-ui/core';
import { useSnackbar } from 'src/components/Snackbar';
import { useDispatch } from 'react-redux';
import { NEW_UNREAD_NOTIFICATION, showDesktopNotification } from 'src/actions/notificationActions';
import uuid from 'uuid';
import { clearSendMessageSettings, OpenNotificationSettings, PlayNewUnreadMessageSound, refreshAccessToken, SET_DIALOG_SENDMESSAGE_SETTINGS, THREAD_REASSIGNED } from 'src/actions';
import { NavigateTo } from 'src/actions/routerActions';
import useExportSnack from 'src/components/Notifications/useExportSnack';

const notificationId = uuid();

const DevToolContent = () => {
    const dispatch = useDispatch();
    const { success: successSnack, error: errorSnack } = useSnackbar();
    const showMessagesExportedSnack = useExportSnack();

    const simulateNotification = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: uuid(),
                header: 'Some longer content',
                created: new Date().toJSON(),
                content: 'How about some longer content? How about some longer content? How about some longer content? How about some longer content? How about some longer content?',
                read: false,
                level: 'Information'
            }
        });
    };

    const simulateNotificationWithConstantId = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: notificationId,
                header: 'This notification should only appear once',
                created: new Date().toJSON(),
                content: 'How about some longer content? How about some longer content? How about some longer content? How about some longer content? How about some longer content?',
                read: false,
                level: 'Information'
            }
        });
    };

    const simulateMessageExportEvent = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: uuid(),
                eventType: 'CustomerMessagesExported',
                header: 'Messages Exported',
                created: new Date().toJSON(),
                content: 'Messages were successfully exported and can be downloaded.',
                resources: [
                    {
                        id: 'MessageExportCsv',
                        value: '884cb5a8-1110-41d1-edca-08d756da92f3_1644235148118.csv'
                    }
                ],
                read: false,
                level: 'Information'
            }
        });
    };

    const simulateGroupContactImport = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: uuid(),
                eventType: 'IGroupContactsImported',
                header: 'Groupcontacts Imported',
                created: new Date().toJSON(),
                content: 'Group contacts imported',
                resources: [
                    {
                        id: 'ServiceId',
                        value: 'b8ebc99f-3b0b-4fde-2673-08daee359ed7'
                    }
                ],
                read: false,
                level: 'Information'
            }
        });
    };

    const simulateDiscussionThreadReassigned = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: uuid(),
                eventType: 'IDiscussionThreadReassigned',
                header: 'Dialog thread reassigned',
                created: new Date().toJSON(),
                content: 'You have been assigned a Dialog thread. Click to navigate to the thread.',
                resources: [
                    {
                        id: 'ThreadId',
                        value: '6e5b6bd7-e066-4b56-8f8d-ba14e6d6fc9f'
                    }
                ],
                read: false,
                level: 'Information'
            }
        });

        dispatch({
            type: THREAD_REASSIGNED,
            data: {
                threadId: 'e79a4cdb-8300-45d8-81bf-bd425234f673',
                ownerUserId: 'fbb96502-3756-4406-8fb8-884a11dd7628'
            }
        });
    };

    const simulateTemplateApprovedEvent = () => {
        dispatch({
            type: NEW_UNREAD_NOTIFICATION,
            data: {
                id: uuid(),
                eventType: 'MessageTemplateApproved',
                header: 'Message Template Approved',
                created: new Date().toJSON(),
                content: 'The messagetemplate {DisplayName} has been approved.',
                resources: [
                    {
                        id: 'Id',
                        value: 'robin_test'
                    }
                ],
                read: false,
                level: 'Information'
            }
        });
    };

    return (
        <List>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateGroupContactImport}>Groupcontacts imported</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={() => dispatch(refreshAccessToken(true))}>Refresh Token</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={() => successSnack('Message export process started. You will receive a notification when it is complete. Make sure notifications are enabled in user settings.', { persist: true })}>Success Snack</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={() => errorSnack('Hejhej')}>Error Snack</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={() => errorSnack(['Test', 'something a bit longer something a bit longer something a bit longer', 'test3'], { persist: true })}>Error Snack With errormessages array</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateNotification}>Notification</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateNotificationWithConstantId}>Notification with Constant ID</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateMessageExportEvent}>Messages Exported</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateGroupContactImport}>Groupcontacts imported</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={simulateDiscussionThreadReassigned}>DiscussionThread Reassigned</Button>
            </ListItem>
            <ListItem>
                <Button size="small" variant="outlined" onClick={() => dispatch(clearSendMessageSettings())}>Clear sendmessage settings</Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        dispatch(showDesktopNotification('New Dialogmessage', 'This is the body', () => {
                            dispatch(NavigateTo('/884cb5a8-1110-41d1-edca-08d756da92f3/dialogs/started/1591df3a-f10c-46ed-8ae9-d534f229d4cd'));
                        }));
                    }}
                >
                    Send desktop notification
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        dispatch(OpenNotificationSettings());
                    }}
                >
                    Open notification settings
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        dispatch(PlayNewUnreadMessageSound());
                    }}
                >
                    Play unread notification sound
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        dispatch({
                            type: SET_DIALOG_SENDMESSAGE_SETTINGS,
                            data: null
                        });
                    }}
                >
                    Clear Dialog Send New message settings
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={simulateTemplateApprovedEvent}
                >
                    MessageTemplateApproved
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={showMessagesExportedSnack}
                >
                    Messages exported infosnack
                </Button>
            </ListItem>
        </List>
    );
};

export default DevToolContent;