import React from 'react';
import PropTypes from 'prop-types';
import ThreadChatWindow from 'src/components/Dialogs/ChatWindow/ThreadChatWindow';
import { Route, StaticRouter, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ThreadChatWindowContextProvider } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import { useHttpGetRequest } from 'src/utils/httpClient';
import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';
import { makeStyles } from '@material-ui/styles';
import { useWidgetComponentContext } from 'src/components/Widgets/WidgetComponentContext';

const DialogChatWindowWidget = ({ settings }) => {
    const customerId = settings?.selectedThread?.customerId;
    const threadId = settings?.selectedThread?.id;
    const history = useHistory();

    // Need to create navigate function here because history in widgetcontent comes from StaticRouter
    const navigate = React.useCallback(() => history.push(`/${customerId}/dialogs/started/${threadId}`), [history, customerId, threadId]);

    return (
        <StaticRouter
            location={{ pathname: `/${customerId}/dialogs/started/${threadId}` }}
        >
            <Route exact path="/:customerId/dialogs/started/:threadId">
                <WidgetContent settings={settings} navigate={navigate} />
            </Route>
        </StaticRouter>
    );
};

DialogChatWindowWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

const useStyles = makeStyles({
    root: {
        padding: 15,
        display: 'flex',
        height: '100%',
        width: '100%'
    }
});

const WidgetContent = ({ settings, navigate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dialogState = useSelector((state) => state.dialog);
    const threadId = settings?.selectedThread?.id;
    const serviceId = settings?.selectedThread?.serviceId;
    const [sentDialogMessage, setSentDialogMessage] = React.useState(null);
    const { shouldNavigateToResource, setShouldNavigateToResource } = useWidgetComponentContext();

    const { payload: threadsPayload, loading: threadsLoading, query: refreshThread } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads',
        params: { $filter: `Id eq ${threadId}` }
    });

    const { payload: threadMessagesPayload, loading: threadMessagesLoading, query: refreshThreadMessages } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/dialog/threads/${serviceId}/${threadId}`
    });

    const refreshWidget = React.useCallback((eventThreadId) => {
        if (eventThreadId === threadId) {
            refreshThread();
            refreshThreadMessages();
            setSentDialogMessage(null);
        }
    }, [threadId, refreshThread, refreshThreadMessages]);

    const threadsById = React.useMemo(() => threadsPayload?.value?.reduce?.((threads, thread) => ({ ...threads, [thread?.id]: thread }), {}) ?? {}, [threadsPayload]);
    const threadMessagesById = React.useMemo(() => threadMessagesPayload?.reduce?.((messages, message) => ({
        ...messages,
        [message?.id]: message
    }), {}) ?? {}, [threadMessagesPayload]);

    const selectedThread = React.useMemo(() => threadsById?.[threadId] ?? settings?.selectedThread, [threadsById, threadId, settings.selectedThread]);

    React.useEffect(() => {
        const subscriptionId = dispatch(signalREventSubscribe('IDiscussionThreadStatusChanged', ({ data }) => {
            refreshWidget(data?.threadId);
        }));

        return () => {
            dispatch(signalREventUnsubscribe('IDiscussionThreadStatusChanged', subscriptionId));
        };
    }, [dispatch, refreshWidget]);

    React.useEffect(() => {
        if (threadId === dialogState.sentDialogMessage?.sendRequest?.threadId) {
            setSentDialogMessage(dialogState.sentDialogMessage);
        }
    }, [threadId, dialogState.sentDialogMessage]);

    React.useEffect(() => {
        if (shouldNavigateToResource) {
            setShouldNavigateToResource(false);
            navigate();
        }
    }, [navigate, shouldNavigateToResource, setShouldNavigateToResource]);

    return (
        <div className={classes.root}>
            <ThreadChatWindowContextProvider
                dialogState={{
                    ...dialogState,
                    isWidget: true,
                    selectedThread,
                    threadsById,
                    threadsLoading,
                    threadMessagesById,
                    threadMessagesLoading,
                    sentDialogMessage
                }}
            >
                <ThreadChatWindow />
            </ThreadChatWindowContextProvider>
        </div>
    );
};

WidgetContent.propTypes = {
    settings: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired
};

export const size = {
    xl: {
        w: 4,
        h: 3
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 4,
        h: 3
    },
    xs: {
        w: 4,
        h: 3
    },
    xxs: {
        w: 4,
        h: 3
    }
};

export default DialogChatWindowWidget;