import React from 'react';
import PropTypes from 'prop-types';

const WidgetComponentContext = React.createContext();

export const WidgetComponentContextProvider = ({ children, onRemoveWidget }) => {
    const [shouldRefresh, setShouldRefresh] = React.useState(false);
    const [shouldNavigateToResource, setShouldNavigateToResource] = React.useState(false);

    const context = {
        shouldRefresh,
        shouldNavigateToResource,
        setShouldRefresh,
        setShouldNavigateToResource,
        onRemoveWidget
    };

    return (
        <WidgetComponentContext.Provider
            value={context}
        >
            {children(context)}
        </WidgetComponentContext.Provider>
    );
};

WidgetComponentContextProvider.propTypes = {
    children: PropTypes.func.isRequired,
    onRemoveWidget: PropTypes.func.isRequired
};

export const useWidgetComponentContext = () => {
    const context = React.useContext(WidgetComponentContext);
    if (context === undefined) {
        throw new Error('useWidgetComponentContext must be used within a WidgetComponentContextProvider');
    }
    return context;
};