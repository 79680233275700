import React from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: '20px'
    },
    image: {
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    }
}));

const QrCodePopup = ({ open, setOpen, postfix }) => {
    const classes = useStyles();

    const handleCancelClick = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" aria-labelledby="qr-code-popup-title">
            <DialogContent className={classes.dialogContent}>
                <QRCode value={`https://qr.siirry.fi/${postfix}`} size={384} className={classes.image} />
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="close" onClick={handleCancelClick}>
                    <Tooltip title="Close">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

QrCodePopup.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    postfix: PropTypes.string.isRequired
};

export default QrCodePopup;