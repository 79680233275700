import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';
import ExportDialog from 'src/components/MessageEvents/ExportDialog';
import { useRouteMatch } from 'react-router';
import { ExportTypes } from 'src/components/MessageEvents/constants';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import moment from 'moment';
import PropTypes from 'prop-types';
import useExportSnack from 'src/components/Notifications/useExportSnack';

const ExportToolbar = ({ filterExpression, serviceId, folders }) => {
    const { error: errorSnack } = useSnackbar();
    const showExportSnack = useExportSnack();
    const [exportMenuAnchor, setExportMenuAnchor] = React.useState(null);
    const generalMessageLogUrlMatch = useRouteMatch('/:customerId/messagelog/all', { exact: true });
    const [exportTypeSelected, setExportTypeSelected] = React.useState(ExportTypes.Recipients);
    const [exportDialogOpen, setExportDialogOpen] = React.useState(false);
    const [startExportDisabled, setStartExportDisabled] = React.useState(false);

    const isGeneralMessageLog = React.useMemo(() => {
        return Boolean(generalMessageLogUrlMatch);
    }, [generalMessageLogUrlMatch]);

    const { mutate: startMessagesCsvExport } = useHttpRequest(({ filter, destinationFolderId }) => ({
        method: 'POST',
        endpoint: '/messagepersistence/export/start',
        params: {
            ...filter ? { $filter: filter } : {},
            ...destinationFolderId ? { folderId: destinationFolderId } : {},
        }
    }));

    const { mutate: startRecipientsCsvExport } = useHttpRequest(({
        fromMillis,
        toMillis,
        serviceId,
        type,
        direction,
        folderId,
        status,
        reason
    }) => ({
        method: 'POST',
        endpoint: '/messagepersistence/export/recipients/start',
        params: {
            ...Number.isNaN(fromMillis) ? {} : { fromMillis },
            ...Number.isNaN(toMillis) ? {} : { toMillis },
            ...serviceId ? { serviceId } : {},
            ...type ? { type } : {},
            ...direction ? { direction } : {},
            ...folderId ? { folderId } : {},
            ...status ? { status } : {},
            ...reason ? { reason } : {}
        }
    }));

    const { mutate: startFailedContactCsvExport } = useHttpRequest(({
        fromMillis,
        toMillis,
        minFailedCount,
        populateCustomerContactProperties
    }) => ({
        method: 'POST',
        endpoint: '/messagepersistence/export/failed-contacts/start',
        params: {
            ...Number.isNaN(fromMillis) ? {} : { fromMillis },
            ...Number.isNaN(toMillis) ? {} : { toMillis },
            minFailedCount,
            populateCustomerContactProperties
        }
    }));

    const exportMenuItemClicked = (exportType) => async () => {
        setExportMenuAnchor(null);

        switch (exportType) {
            case ExportTypes.Messages: {
                if (folders?.length < 1) {
                    const { error, errorMessages } = await startMessagesCsvExport({ filter: filterExpression, destinationFolderId: null });
                    if (!error) {
                        showExportSnack();
                    } else {
                        errorSnack(errorMessages);
                    }
                } else {
                    setExportTypeSelected(ExportTypes.Messages);
                    setExportDialogOpen(true);
                }
                break;
            }
            case ExportTypes.Recipients: {
                setExportTypeSelected(ExportTypes.Recipients);
                setExportDialogOpen(true);
                break;
            }
            case ExportTypes.FailedContacts: {
                setExportTypeSelected(ExportTypes.FailedContacts);
                setExportDialogOpen(true);
                break;
            }
            default:
        }
    };

    const onExportDialogExited = () => {
        setExportTypeSelected(null);
    };

    const handleExportClose = async (options) => {
        if (!options) {
            // Cancelled
            setExportDialogOpen(false);
            return;
        }

        setStartExportDisabled(true);
        const fromMillis = moment(options?.createdFrom).valueOf();
        const toMillis = moment(options?.createdTo).valueOf();
        let response = { error: false, errorMessages: '' };
        const destinationFolderId = options?.folderId ?? null;

        switch (exportTypeSelected) {
            case ExportTypes.Messages: {
                const { error, errorMessages } = await startMessagesCsvExport({ filter: filterExpression, destinationFolderId });
                if (!error) {
                    showExportSnack();
                } else {
                    errorSnack(errorMessages);
                }
                break;
            }
            case ExportTypes.Recipients: {
                response = await startRecipientsCsvExport({ ...options, fromMillis, toMillis });
                if (!response.error) {
                    showExportSnack('Recipients export process started. You will receive a notification when it is complete.');
                }
                break;
            }
            case ExportTypes.FailedContacts: {
                response = await startFailedContactCsvExport({ ...options, fromMillis, toMillis });
                if (!response.error) {
                    showExportSnack('Failed contact export process started. You will receive a notification when it is complete.');
                }
                break;
            }
            default:
                break;
        }
        setStartExportDisabled(false);

        if (!response.error) {
            setExportDialogOpen(false);
        } else {
            errorSnack(response.errorMessages);
        }
    };

    return (
        <>
            <Tooltip title="Export">
                <IconButton aria-controls="export-menu" aria-haspopup="true" onClick={(e) => setExportMenuAnchor(e.currentTarget)}>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="export-menu"
                anchorEl={exportMenuAnchor}
                keepMounted
                open={Boolean(exportMenuAnchor)}
                onClose={() => setExportMenuAnchor(null)}
            >
                <MenuItem onClick={exportMenuItemClicked(ExportTypes.Messages)}>Export Messages as Csv</MenuItem>
                <MenuItem onClick={exportMenuItemClicked(ExportTypes.Recipients)}>Export Message Recipients as Csv</MenuItem>
                {isGeneralMessageLog && <MenuItem onClick={exportMenuItemClicked(ExportTypes.FailedContacts)}>Export Failed Contacts as Csv</MenuItem>}
            </Menu>
            <ExportDialog
                open={exportDialogOpen}
                onClose={handleExportClose}
                onExited={onExportDialogExited}
                exportType={exportTypeSelected}
                startExportDisabled={startExportDisabled}
                serviceId={serviceId}
                folders={folders}
            />
        </>
    );
};

ExportToolbar.propTypes = {
    filterExpression: PropTypes.string,
    serviceId: PropTypes.string,
    folders: PropTypes.array
};

export default ExportToolbar;