import { RESET_REDUCER_STATE } from 'src/actions';
import * as ACTIONS from 'src/actions/notificationActions';
import { AuthenticationActions } from 'src/actions/authActions';

const PAGESIZE = 100;

const initialState = {
    top: PAGESIZE,
    newNotificationSnackbarQueue: [],

    unreadNotificationsById: {},
    unreadNotificationCount: 0,
    unreadNotificationSkipCount: 0,
    unreadNotificationsLoading: true,
    hasMoreUnreadNotifications: false,
    refreshUnreadNotifications: true,

    readNotificationsById: {},
    readNotificationCount: 0,
    readNotificationSkipCount: 0,
    readNotificationsLoading: false,
    hasMoreReadNotifications: false,
    refreshReadNotifications: false,

    notificationsDrawerOpen: false,
    notificationsToMarkAsRead: [],
    hasMoreNotifications: false,
    showReadNotifications: false,

    settingsLoaded: false,
    sendNotification: false,
    sendSms: false,
    sendEmail: false,
    sendEvent: false
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UNREAD_NOTIFICATIONS_LOADING: {
            return {
                ...state,
                unreadNotificationsLoading: true
            };
        } case ACTIONS.READ_NOTIFICATIONS_LOADING: {
            return {
                ...state,
                readNotificationsLoading: true
            };
        }
        case ACTIONS.UNREAD_NOTIFICATIONS_LOADED: {
            const items = Array.isArray(action?.data?.items) ? action.data.items : [];
            const count = action?.data?.count ?? 0;

            const notificationsById = items.reduce((all, notification) => ({
                ...all,
                [notification.id]: notification
            }), state.unreadNotificationsById);

            return {
                ...state,
                unreadNotificationsById: notificationsById,
                unreadNotificationCount: count,
                unreadNotificationsLoading: false,
                refreshUnreadNotifications: false,
                hasMoreUnreadNotifications: Object.keys(notificationsById).length < count
            };
        }
        case ACTIONS.READ_NOTIFICATIONS_LOADED: {
            const items = Array.isArray(action?.data?.items) ? action.data.items : [];
            const count = action?.data?.count;

            const notificationsById = items.reduce((all, notification) => ({
                ...all,
                [notification.id]: notification
            }), state.readNotificationsById);

            return {
                ...state,
                readNotificationsById: notificationsById,
                readNotificationCount: count,
                readNotificationsLoading: false,
                refreshReadNotifications: false,
                hasMoreReadNotifications: Object.keys(notificationsById).length < count
            };
        }
        case ACTIONS.REFRESH_UNREAD_NOTIFICATIONS: {
            return {
                ...state,
                refreshUnreadNotifications: true,
                unreadNotificationsLoading: true
            };
        }
        case ACTIONS.REFRESH_READ_NOTIFICATIONS: {
            return {
                ...state,
                refreshReadNotifications: true,
                readNotificationsLoading: true
            };
        }
        case ACTIONS.MARK_NOTIFICATIONS_AS_READ: {
            if (!Array.isArray(action?.data)) {
                return { ...state };
            }

            const notificationIdsToMarkAsRead = action.data.filter((notification) => !notification?.read).map((notification) => (notification?.id));
            const unreadNotificationsById = Object.keys(state.unreadNotificationsById).filter((id) => notificationIdsToMarkAsRead?.indexOf(id) === -1).map((id) => state.unreadNotificationsById[id]);
            const readNotificationsById = Object.keys(state.unreadNotificationsById).filter((id) => notificationIdsToMarkAsRead?.indexOf(id) > -1).reduce((readNotificationsById, id) => ({
                ...readNotificationsById,
                [id]: {
                    ...state.unreadNotificationsById[id],
                    read: true
                }
            }), state.readNotificationsById);

            return {
                ...state,
                unreadNotificationsById,
                readNotificationsById,
                readNotificationCount: state.readNotificationCount + notificationIdsToMarkAsRead.length,
                unreadNotificationCount: Math.max(0, state.unreadNotificationCount - notificationIdsToMarkAsRead.length),
                unreadNotificationSkipCount: 0,
                notificationsToMarkAsRead: notificationIdsToMarkAsRead.map((id) => ({ id }))
            };
        }
        case ACTIONS.MARK_NOTIFICATIONS_AS_READ_COMPLETED: {
            return {
                ...state,
                refreshUnreadNotifications: true,
                refreshReadNotifications: state.showReadNotifications,
                readNotificationsLoading: state.showReadNotifications,
                unreadNotificationsLoading: true,
                notificationsToMarkAsRead: []
            };
        }
        case ACTIONS.SET_NOTIFICATIONSDRAWER_OPEN: {
            return {
                ...state,
                notificationsDrawerOpen: Boolean(action?.data),
                ...action?.data ? {
                    // When opening drawer
                } : {
                    // When closing drawer
                    unreadNotificationSkipCount: 0,
                    readNotificationSkipCount: 0,
                    top: PAGESIZE,
                    readNotifications: [],
                    showReadNotifications: false,
                    hasMoreNotifications: false
                }
            };
        }
        case ACTIONS.SHOW_READ_NOTIFICATIONS: {
            return {
                ...state,
                showReadNotifications: true,
                refreshReadNotifications: true,
                readNotificationsLoading: true
            };
        }
        case ACTIONS.LOAD_MORE_NOTIFICATIONS: {
            if (state.showReadNotifications) {
                return {
                    ...state,
                    readNotificationSkipCount: state.readNotificationSkipCount + PAGESIZE,
                    refreshReadNotifications: true
                };
            }
            return {
                ...state,
                unreadNotificationSkipCount: state.unreadNotificationSkipCount + PAGESIZE,
                refreshUnreadNotifications: true
            };
        }
        case ACTIONS.NEW_UNREAD_NOTIFICATION: {
            const notificationId = action?.data?.id;

            if (state.unreadNotificationsById?.[notificationId]) {
                const unreadNotificationsById = { ...state.unreadNotificationsById };
                unreadNotificationsById[notificationId] = { ...action.data };
                return {
                    ...state,
                    unreadNotificationsById
                };
            }
            return {
                ...state,
                unreadNotificationsById: {
                    ...state.unreadNotificationsById,
                    [notificationId]: action.data
                },
                unreadNotificationCount: state.unreadNotificationCount + 1,

                // Only show notification snack when notificationDrawer is closed
                ...state.notificationsDrawerOpen ? {} : {
                    newNotificationSnackbarQueue: [
                        ...state.newNotificationSnackbarQueue,
                        action.data
                    ]
                }
            };
        }
        case ACTIONS.PROCESS_SNACKBAR_NOTIFICATION: {
            return {
                ...state,
                newNotificationSnackbarQueue: state.newNotificationSnackbarQueue.filter((item) => item?.id !== action?.data?.id)
            };
        }
        case ACTIONS.SETTINGS_LOADED: {
            return {
                ...state,
                settingsLoaded: true,
                sendNotification: Boolean(action?.data?.sendNotification),
                sendSms: Boolean(action?.data?.sendSms),
                sendEmail: Boolean(action?.data?.sendEmail),
                sendEvent: Boolean(action?.data?.sendEvent),
            };
        }
        case AuthenticationActions.CustomerSelected:
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default notificationReducer;
