import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVertRounded';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useHttpRequest } from 'src/utils/httpClient';
import { useSnackbar } from 'src/components/Snackbar';
import { layout } from 'src/widgets/defaultWidgetLayout';

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        width: '100%',
        position: 'relative'
    },
    button: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 99999
    }
}));

const SaveAsWidgetOverlay = ({ children, component, settings }) => {
    const { success: successSnack, error: errorSnack } = useSnackbar();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [widgetDisabled, setWidgetDisabled] = React.useState(settings?.disabled ?? false);
    const open = Boolean(anchorEl);

    const { mutate: createWidget } = useHttpRequest((settings) => ({
        endpoint: '/widgets',
        method: 'POST',
        body: { component, settings, layout }
    }));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveAsWidget = async () => {
        setAnchorEl(null);
        const { error } = await createWidget(settings);
        if (error) {
            errorSnack('Error creating widget');
        } else {
            successSnack('Widget created');
            if ('disabled' in settings) {
                setWidgetDisabled(true);
            }
        }
    };

    return (
        <div className={classes.container}>
            <IconButton
                className={classes.button}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={saveAsWidget} disabled={widgetDisabled}>
                    Pin to Dashboard
                </MenuItem>
            </Menu>
            {children}
        </div>
    );
};

SaveAsWidgetOverlay.propTypes = {
    children: PropTypes.object,
    component: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired
};

export default SaveAsWidgetOverlay;