import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, DialogActions, DialogContent, FormControlLabel, FormHelperText, IconButton, TextField, Tooltip } from '@material-ui/core';
import { useHttpRequest } from 'src/utils/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'src/components/Snackbar';
import SaveIcon from '@material-ui/icons/SaveRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import { profileUpdated } from 'src/actions';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3)
    },
    formControl: {
        flex: 1,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    }
}));

const ProfileEdit = ({ setOpen }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { error: errorSnack, success: successSnack } = useSnackbar();
    const [editedProfile, setEditedProfile] = React.useState({});
    const { claims } = useSelector((state) => state.auth);
    const [mfaAuthenticatorAppConfigured, setMfaAuthenticatorAppConfigured] = React.useState(Boolean(claims?.mfaAuthenticatorAppConfigured));

    const { mutate: updateProfile, loading: updateProfileLoading } = useHttpRequest((body) => ({
        method: 'PUT',
        endpoint: '/user',
        body: {
            profile: {
                ...Object.keys(body).reduce((props, prop) => ({
                    ...props,
                    [prop]: body[prop] === '' ? null : body[prop]
                }), {})
            }
        }
    }));

    const disablePasswordSigninVisible = React.useMemo(() => claims.authenticationSource === 'socialIdpAuthentication', [claims.authenticationSource]);

    React.useEffect(() => {
        setEditedProfile({
            givenName: claims?.given_name ?? '',
            surname: claims?.family_name ?? '',
            displayName: claims?.name ?? '',
            streetAddress: claims?.streetAddress ?? '',
            city: claims?.city ?? '',
            postalCode: claims?.postalCode ?? '',
            mobilePhone: claims?.mobilePhone ?? '',
            mail: claims?.mail ?? '',
            signInEmail: claims?.signInEmail ?? '',
            disablePasswordSignin: claims?.disablePasswordSignin ?? false
        });
    }, [claims]);

    const onChange = (e) => {
        setEditedProfile({
            ...editedProfile,
            [e.target.name]: e.target.value
        });
    };

    const onDisablePasswordSigninChange = ({ target: { checked } }) => {
        setEditedProfile({
            ...editedProfile,
            disablePasswordSignin: checked
        });
    };

    const mfaAuthenticatorChecked = (e) => {
        setMfaAuthenticatorAppConfigured(e.target.checked);
    };

    const handleCancelClick = () => {
        setOpen(false);
    };

    const handleSaveClick = async () => {
        const { error, errorMessages } = await updateProfile(editedProfile);
        if (!error) {
            const enableMfa = claims?.mfaAuthenticatorAppConfigured === false && mfaAuthenticatorAppConfigured;
            dispatch(profileUpdated(editedProfile, enableMfa));
            successSnack('Profile Updated');
            setOpen(false);
        } else {
            errorSnack(errorMessages);
        }
    };

    return (
        <>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    className={classes.formControl}
                    name="givenName"
                    id="givenName"
                    variant="outlined"
                    label="Firstname"
                    required
                    value={editedProfile.givenName ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="surname"
                    id="surname"
                    variant="outlined"
                    label="Lastname"
                    required
                    value={editedProfile.surname ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="displayName"
                    id="displayName"
                    variant="outlined"
                    label="Displayname"
                    required
                    value={editedProfile.displayName ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    disabled
                    name="signInEmail"
                    id="signInEmail"
                    variant="outlined"
                    label="Signin Email"
                    value={editedProfile.signInEmail ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="mail"
                    id="mail"
                    variant="outlined"
                    label="Mail"
                    required
                    value={editedProfile.mail ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="streetAddress"
                    id="streetAddress"
                    variant="outlined"
                    label="Street Address"
                    value={editedProfile.streetAddress ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="postalCode"
                    id="postalCode"
                    variant="outlined"
                    label="Postal Code"
                    value={editedProfile.postalCode ?? ''}
                    onChange={onChange}
                />
                <TextField
                    className={classes.formControl}
                    name="mobilePhone"
                    id="mobilePhone"
                    variant="outlined"
                    label="Mobile"
                    value={editedProfile.mobilePhone ?? ''}
                    onChange={onChange}
                />
                {disablePasswordSigninVisible && (
                    <FormControlLabel
                        control={<Checkbox checked={Boolean(editedProfile.disablePasswordSignin)} onChange={onDisablePasswordSigninChange} value={Boolean(editedProfile.disablePasswordSignin)} />}
                        label="Disable Password Signin"
                    />
                )}
                {claims?.mfaAuthenticatorAppConfigured ? (
                    <>
                        <FormControlLabel
                            control={<Checkbox disabled checked />}
                            label="Mfa Authenticator Configured"
                        />
                        <FormHelperText>Please contact support to disable Mfa.</FormHelperText>
                    </>
                ) : (
                    <>
                        <FormControlLabel
                            control={<Checkbox checked={mfaAuthenticatorAppConfigured} onChange={mfaAuthenticatorChecked} value={mfaAuthenticatorAppConfigured} />}
                            label="Enable Mfa Authenticator"
                        />
                        {mfaAuthenticatorAppConfigured && <FormHelperText>Disabling Mfa after it has been enabled requires contacting support.</FormHelperText>}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="cancel" onClick={handleCancelClick}>
                    <Tooltip title="Cancel">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
                <IconButton aria-label="Save" disabled={updateProfileLoading} onClick={handleSaveClick}>
                    <Tooltip title="Save">
                        <SaveIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </>
    );
};

ProfileEdit.propTypes = {
    setOpen: PropTypes.func.isRequired
};

export default ProfileEdit;