export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_USERS = 'SET_USERS';

export const SET_ACCESSGROUPS_LOADING = 'SET_ACCESSGROUPS_LOADING';
export const SET_ACCESSGROUPS = 'SET_ACCESSGROUPS';
export const REFRESH_ACCESSGROUPS = 'REFRESH_ACCESSGROUPS';
export const SET_NEW_ACCESSGROUP_USERS = 'SET_NEW_ACCESSGROUP_USERS';

export const SET_CUSTOMERPRODUCTS_LOADING = 'SET_CUSTOMERPRODUCTS_LOADING';
export const SET_CUSTOMERPRODUCTS = 'SET_CUSTOMERPRODUCTS';

export const SET_CUSTOMERRESOURCES_LOADING = 'SET_CUSTOMERRESOURCES_LOADING';
export const SET_CUSTOMERRESOURCES = 'SET_CUSTOMERRESOURCES';

export const SET_ACCESSGROUP_GRID_VIEW = 'SET_ACCESSGROUP_GRID_VIEW';