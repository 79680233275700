import React from 'react';
import useSound from 'use-sound';
import { useDispatch, useSelector } from 'react-redux';
import { StopPlayingSounds } from 'src/actions';

import newUnreadDiscussionMessage from 'src/assets/sounds/newUnreadDiscussionMessage.mp3';
import newDiscussion from 'src/assets/sounds/newDiscussion.mp3';

const useConditionallyPlaySound = (src, shouldPlay) => {
    const dispatch = useDispatch();
    const [play] = useSound(src);

    React.useEffect(() => {
        if (shouldPlay) {
            play();
            dispatch(StopPlayingSounds());
        }
    }, [dispatch, play, shouldPlay]);
};

const SoundProvider = () => {
    const { shouldPlayNewUnreadMessageSound, shouldPlayNewDialogSound } = useSelector((state) => state.sound);

    useConditionallyPlaySound(newUnreadDiscussionMessage, shouldPlayNewUnreadMessageSound);
    useConditionallyPlaySound(newDiscussion, shouldPlayNewDialogSound);

    return null;
};

export default SoundProvider;