import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/adBanActions';
import { sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    servicesLoading: true,
    services: [],
    selectedAdBanService: null,
    selectedAdBanServiceLoading: true,
    refreshSelectedAdBanService: false,
    refreshServices: false,
    totalCount: 0
};

const adBanReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ADBANSERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case actionTypes.SET_SELECTEDADBANSERVICE: {
            return {
                ...state,
                selectedAdBanService: action.data,
                selectedAdBanServiceLoading: false,
                refreshSelectedAdBanService: false
            };
        }
        case actionTypes.SET_SELECTEDADBANSERVICELOADING: {
            return {
                ...state,
                selectedAdBanServiceLoading: true
            };
        }
        case actionTypes.REFRESH_SELECTEDADBANSERVICE: {
            return {
                ...state,
                refreshSelectedAdBanService: true
            };
        }
        case actionTypes.SET_ADBANSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_ADBANSERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                services: Array.isArray(action?.data) ? action.data.sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.REFERSH_ADBANSERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case actionTypes.ADBANSERVICEUPDATED: {
            return {
                ...state,
                services: state.services.reduce((services, service) => {
                    if (service?.id === action?.data?.id) {
                        return [...services, action.data];
                    }
                    return [...services, service];
                }, [])
            };
        }
        case actionTypes.ADBANSERVICEDELETED: {
            return {
                ...state,
                services: state.services.filter((service) => service?.id !== action?.data)
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default adBanReducer;