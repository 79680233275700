import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden'
    },
    statsContainer: {
        display: 'flex',
        maxWidth: '450px',
        margin: '0 auto'
    },
    statsItem: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2, 1, 2),
        '&:not(:last-of-type)': {
            borderRight: `1px solid ${theme.palette.divider}`
        }
    },
    statsItemColor: ({ color }) => ({
        backgroundColor: color,
        height: theme.spacing(2),
        marginBottom: theme.spacing(1)
    })
}));

const DoughnutChartStats = ({ data, showBy }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.statsContainer}>
                {data?.map(({ label, value, color }) => {
                    return <StatsItem key={label} label={label} value={value} color={color} showBy={showBy} />;
                })}
            </div>
        </div>
    );
};

DoughnutChartStats.propTypes = {
    data: PropTypes.array.isRequired,
    showBy: PropTypes.string
};

const StatsItem = ({ label, value, color, showBy }) => {
    const classes = useStyles({ color });

    return (
        <div className={classes.statsItem}>
            <div className={classes.statsItemColor} />
            <Typography
                align="center"
                component="h6"
                gutterBottom
                variant="overline"
            >
                {label}
            </Typography>
            <Typography
                align="center"
                variant="h4"
            >
                {showBy?.toLowerCase() === 'percentage' ? value?.toFixed(1) ?? value : value}
                {showBy?.toLowerCase() === 'percentage' ? ('%') : (null)}
                {showBy?.toLowerCase() === 'euro' ? ('€') : (null)}
            </Typography>
        </div>
    );
};

StatsItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    showBy: PropTypes.string
};

export default DoughnutChartStats;