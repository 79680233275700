import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    serviceName: {
        display: 'block',
        marginTop: theme.spacing(2),
        fontSize: '1.1em',
        fontWeight: 'bold',
        color: '#000',
        padding: theme.spacing(0, 1),
        wordBreak: 'break-word'
    },
    image: {
        borderRadius: theme.shape.borderRadius,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 48,
        width: 48,
        backgroundColor: theme.palette.primary.main
    },
}));

function FavouriteServiceWidget({ serviceType, serviceId, serviceName }) {
    const classes = useStyles();
    const history = useHistory();
    const { customerId } = useParams();

    const handleGoToFavouriteClick = () => {
        if (serviceType === 'onewaymessaging') {
            history.push(`/${customerId}/services/onewaymessaging/${serviceId}/info`);
        }
        if (serviceType === 'twowaymessaging') {
            history.push(`/${customerId}/services/twowaymessaging/info/${serviceId}`);
        }
        if (serviceType === 'groupmessaging') {
            history.push(`/${customerId}/services/groupmessaging/${serviceId}/info`);
        }
        if (serviceType === 'codeservice') {
            history.push(`/${customerId}/services/codeservice/${serviceId}/info`);
        }
        if (serviceType === 'carrierbilling') {
            history.push(`/${customerId}/services/carrierbilling/${serviceId}/info`);
        }
        if (serviceType === 'surveyservice') {
            history.push(`/${customerId}/services/surveyservice/${serviceId}/info`);
        }
    };

    const getServiceIcon = () => {
        switch (serviceType) {
            case 'onewaymessaging':
                return (
                    <Tooltip title="1-Way Messaging Service">
                        <img
                            alt="1-Way Service"
                            className={classes.image}
                            src="/images/1way_messaging_icon_transparent.png"
                        />
                    </Tooltip>
                );
            case 'twowaymessaging':
                return (
                    <Tooltip title="2-Way Messaging Service">
                        <img
                            alt="2-Way Service"
                            className={classes.image}
                            src="/images/2way_messaging_icon_transparent.png"
                        />
                    </Tooltip>
                );
            case 'groupmessaging':
                return (
                    <Tooltip title="Group Messaging Service">
                        <img
                            alt="Group Service"
                            className={classes.image}
                            src="/images/group_messaging_icon_transparent.png"
                        />
                    </Tooltip>
                );
            case 'codeservice':
                return (
                    <Tooltip title="Code Service">
                        <img
                            alt="Code Service"
                            className={classes.image}
                            src="/images/code_service_icon_transparent.png"
                        />
                    </Tooltip>
                );
            case 'carrierbilling':
                return (
                    <Tooltip title="Carrier Billing Service">
                        <img
                            alt="Carrier Billing Service"
                            className={classes.image}
                            src="/images/carrier_billing_icon_transparent.png"
                        />
                    </Tooltip>
                );
            case 'surveyservice':
                return (
                    <Tooltip title="Survey Service">
                        <img
                            alt="Survey Service"
                            className={classes.image}
                            src="/images/survey_icon_transparent.png"
                        />
                    </Tooltip>
                );
            default:
                return '';
        }
    };

    return (
        <div onClick={handleGoToFavouriteClick} className={classes.root}>
            {getServiceIcon()}
            <span className={classes.serviceName}>
                {serviceName}
            </span>
        </div>
    );
}

FavouriteServiceWidget.propTypes = {
    serviceType: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired
};

export default FavouriteServiceWidget;