import React, { useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, Menu, MenuItem, ListItemText } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseRounded';
import ListIcon from '@material-ui/icons/ListAltRounded';
import LanguageIcon from '@material-ui/icons/LanguageRounded';
import MUIDataTable from 'mui-datatables';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { sendStatusData, deliveryStatusData, deliveryReasonData, sendStatusDataFI, deliveryStatusDataFI, deliveryReasonDataFI } from './DeliveryStatusList';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: '20px'
    },
    header: {
        '&&:not(:first-child)': {
            marginTop: theme.spacing(5)
        }
    },
    languageButton: {
        color: '#fff',
        float: 'right'
    }
}));

const statusColumns = [
    {
        name: 'status',
        label: 'Status'
    },
    {
        name: 'description',
        label: 'Description'
    }
];

const reasonColumns = [
    {
        name: 'reason',
        label: 'Reason'
    },
    {
        name: 'description',
        label: 'Description'
    }
];

const options = {
    elevation: 0,
    responsive: 'standard',
    print: false,
    search: false,
    sort: false,
    filter: false,
    download: false,
    viewColumns: false,
    pagination: false,
    selectableRowsHeader: false,
    selectableRows: 'none'
};

const DeliveryStatusButton = () => {
    const classes = useStyles();
    const moreRef = useRef(null);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [language, setLanguage] = React.useState('en');

    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleCancelClick = () => {
        setPopupOpen(false);
    };

    return (
        <>
            <Tooltip title="Delivery status descriptions">
                <IconButton aria-label="descriptions-button" onClick={() => setPopupOpen(true)}>
                    <ListIcon />
                </IconButton>
            </Tooltip>
            <Dialog onClose={handleCancelClick} open={popupOpen} maxWidth="md" fullWidth aria-labelledby="descriptions-popup">
                <DialogTitle id="descriptions-title">
                    Delivery status descriptions
                    <Tooltip title="Choose language" className={classes.languageButton}>
                        <IconButton
                            onClick={handleMenuOpen}
                            ref={moreRef}
                            size="small"
                        >
                            <LanguageIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={moreRef.current}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        elevation={1}
                        onClose={handleMenuClose}
                        open={openMenu}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <MenuItem>
                            <ListItemText primary="In English" onClick={() => { setLanguage('en'); handleMenuClose(); }} />
                        </MenuItem>
                        <MenuItem>
                            <ListItemText primary="Suomeksi" onClick={() => { setLanguage('fi'); handleMenuClose(); }} />
                        </MenuItem>
                    </Menu>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <MuiThemeProvider theme={(theme) => ({
                        ...theme,
                        overrides: {
                            ...theme.overrides,
                            MuiTableHead: {
                                root: {
                                    color: '#000 !important'
                                }
                            },
                            MUIDataTableHeadCell: {
                                root: {
                                    backgroundColor: '#fff',
                                    borderBottom: '2px solid #254a8e',
                                    fontWeight: 'bold',
                                    '&:nth-child(1)': {
                                        width: '30%'
                                    }
                                }
                            },
                            MUIDataTableBodyCell: {
                                root: {
                                    whiteSpace: 'pre-wrap'
                                }
                            }
                        }
                    })}
                    >
                        <Typography gutterBottom variant="h3" className={classes.header}>Send Status</Typography>
                        <MUIDataTable
                            data={language === 'fi' ? sendStatusDataFI : sendStatusData}
                            columns={statusColumns}
                            options={options}
                        />
                        <Typography gutterBottom variant="h3" className={classes.header}>Delivery Status</Typography>
                        <MUIDataTable
                            data={language === 'fi' ? deliveryStatusDataFI : deliveryStatusData}
                            columns={statusColumns}
                            options={options}
                        />
                        <Typography gutterBottom variant="h3" className={classes.header}>Delivery Reason</Typography>
                        <MUIDataTable
                            data={language === 'fi' ? deliveryReasonDataFI : deliveryReasonData}
                            columns={reasonColumns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </DialogContent>
                <DialogActions>
                    <IconButton aria-label="close" onClick={handleCancelClick}>
                        <Tooltip title="Close">
                            <CancelIcon />
                        </Tooltip>
                    </IconButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeliveryStatusButton;