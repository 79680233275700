import React from 'react';
import AxiosMockAdapter from 'axios-mock-adapter';
import { createClient, ClientContextProvider } from 'react-fetching-library';
import { default as FetchMock } from 'fetch-mock';
import { responseInterceptor, requestAuthHeadersInterceptor } from 'src/utils/httpClient';
import axios from './axios';

const instance = new AxiosMockAdapter(axios, { delayResponse: 0 });
export default instance;

export const fetchMock = FetchMock.sandbox();

const client = createClient({
    requestInterceptors: [requestAuthHeadersInterceptor],
    responseInterceptors: [responseInterceptor],
    fetch: fetchMock
});

export const withFetchMock = (WrappedComponent) => (props) => {
    return <ClientContextProvider client={client}><WrappedComponent {...props} /></ClientContextProvider>;
};