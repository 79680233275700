import { colors } from '@material-ui/core';
import { DataTypes, Operators } from 'src/components/QueryBuilder/constants';
import { numberValidator } from 'src/components/QueryBuilder/validators';
import { toVatPercentage, toVatPpm } from 'src/utils/vatConvert';

export const listItemPadding = 40;

export const XarrowDefaults = {
    strokeWidth: 3,
    headSize: 4,
    lineColor: colors.common.black,
    headColor: colors.common.black,
    path: 'grid'
};

export const ActionTypes = {
    HttpRequest: 'HttpRequest',
    StaticReply: 'StaticReply',
    Email: 'Email',
    KayakSubscriptionLookup: 'KayakSubscriptionLookup',
    WhatsappTemplateReply: 'WhatsappTemplateReply',
    KayakCustomerNumberLookup: 'KayakCustomerNumberLookup',
    SmartAgentLookup: 'SmartAgentLookup',
    GroupLookup: 'GroupLookup',
    ForwardToFolder: 'ForwardToFolder'
};

const staticReplyOutputDefinition = [
    {
        name: 'Charge',
        label: 'Charge',
        dataType: DataTypes.number,
        step: 0.01,
        validator: numberValidator,
        operators: [
            Operators.largerThan,
            Operators.lessThan,
            Operators.equals
        ]
    },
    {
        name: 'ValueTax',
        label: 'Value Tax',
        dataType: DataTypes.number,
        step: 0.1,
        validator: numberValidator,
        setValueFunc: (val) => toVatPpm(val),
        getDisplayValueFunc: (val) => toVatPercentage(val),
        operators: [
            Operators.largerThan,
            Operators.lessThan,
            Operators.equals
        ]
    },
    {
        name: 'ContentPreview',
        label: 'Content',
        operators: [
            Operators.contains,
            Operators.startsWith
        ]
    }
];

const httpRequestOutputDefinition = [
    {
        name: 'ResponseBody',
        label: 'Response Body',
        operators: [
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'StatusCode',
        label: 'Status Code',
        dataType: DataTypes.number,
        validator: numberValidator,
        operators: [
            Operators.equals,
            Operators.largerThan,
            Operators.lessThan
        ]
    },
    {
        name: 'ReplyMessage.SenderAddress',
        label: 'Sender Address',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.startsWith
        ]
    },
];

const kayakSubscriptionLookupOutputDefinition = [
    {
        name: 'KayakIsValidSubscriber',
        label: 'Is Subscriber',
        dataType: DataTypes.boolean,
        operators: [
            Operators.equals
        ],
        defaultValue: 'true'
    },
];

const kayakCustomerNumberLookupOutputDefinition = [
    {
        name: 'CustomerNumbers',
        label: 'Customer Numbers',
        operators: [
            Operators.contains
        ]
    },
    {
        name: 'CustomerNumbers.Count',
        label: 'Customer Number Count',
        dataType: DataTypes.number,
        operators: [
            Operators.largerThan,
            Operators.largerThanOrEqual,
            Operators.lessThan,
            Operators.lessThanOrEqual,
            Operators.equals
        ]
    }
];

const groupLookupOutputDefinition = ({ availableCustomContactProperties }) => {
    const validatedCustomContactProperties = availableCustomContactProperties?.filter?.((prop) => typeof prop === 'string') ?? [];
    return [
        {
            name: 'Phone',
            label: 'Phone',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'Email',
            label: 'Email',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'City',
            label: 'City',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'StreetAddress',
            label: 'Street Address',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'FirstName',
            label: 'Firstname',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'LastName',
            label: 'Lastname',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'ZipCode',
            label: 'Postal Code',
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'BirthYear',
            label: 'Birth Year',
            dataType: DataTypes.number,
            operators: [
                Operators.equals,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }, {
            name: 'CountryCode',
            label: 'Country Code',
            operators: [
                Operators.equals
            ]
        },
        ...validatedCustomContactProperties.map((name) => ({
            name,
            label: name,
            operators: [
                Operators.equals,
                Operators.notEqual,
                Operators.contains,
                Operators.doesNotContain,
                Operators.startsWith,
                Operators.endsWith
            ]
        }))
    ];
};

const smartAgentLookupOutputDefinition = [
    {
        name: 'City',
        label: 'City',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'FirstName',
        label: 'Firstname',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'LastName',
        label: 'Lastname',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'IsCompany',
        label: 'Is Company',
        dataType: DataTypes.boolean,
        operators: [
            Operators.equals
        ],
        defaultValue: 'true'
    },
    {
        name: 'OwnerFirstName',
        label: 'Owner Firstname',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'OwnerLastName',
        label: 'Owner Lastname',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'Sex',
        label: 'Gender',
        options: ['Male', 'Female', 'Other'],
        value: 'Male',
        operators: [
            Operators.equals
        ]
    },
    {
        name: 'StreetAddress',
        label: 'StreetAddress',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
    {
        name: 'SubsType',
        label: 'Subscription Type',
        options: [
            {
                name: 'Active free copy subscription',
                value: 'ActiveFreeCopySubscription'
            },
            {
                name: 'Active periodic subscription',
                value: 'ActivePeriodicSubscription'
            },
            {
                name: 'Active standing subscription',
                value: 'ActiveStandingSubscription'
            },
            {
                name: 'No active subscription',
                value: 'NoActiveSubscriptions'
            },
            {
                name: 'Sleeping free copy subscription',
                value: 'SleepingFreeCopySubscription'
            },
            {
                name: 'Sleeping periodic subscription',
                value: 'SleepingPeriodicSubscription'
            },
            {
                name: 'Sleeping standing subscription',
                value: 'SleepingStandingSubscription'
            },
            {
                name: 'Waiting subscription',
                value: 'WaitingSubscription'
            }
        ],
        operators: [
            Operators.equals
        ]
    },
    {
        name: 'Zip',
        label: 'Postal Code',
        operators: [
            Operators.equals,
            Operators.contains,
            Operators.doesNotContain,
            Operators.startsWith,
            Operators.endsWith
        ]
    },
];

const forwardToFolderOutputDefinition = [
    {
        name: 'IsMatch',
        label: 'Is Match',
        dataType: DataTypes.boolean,
        operators: [
            Operators.equals
        ],
        defaultValue: 'true'
    }
];

export const actionTypeOutputDefinitions = {
    [ActionTypes.StaticReply]: staticReplyOutputDefinition,
    [ActionTypes.HttpRequest]: httpRequestOutputDefinition,
    [ActionTypes.KayakSubscriptionLookup]: kayakSubscriptionLookupOutputDefinition,
    [ActionTypes.KayakCustomerNumberLookup]: kayakCustomerNumberLookupOutputDefinition,
    [ActionTypes.GroupLookup]: groupLookupOutputDefinition,
    [ActionTypes.SmartAgentLookup]: smartAgentLookupOutputDefinition,
    [ActionTypes.ForwardToFolder]: forwardToFolderOutputDefinition
};