export const SET_SENDGRID_TEMPLATES = 'SET_SENDGRID_TEMPLATES';
export const SET_SENDGRID_TEMPLATES_LOADING = 'SET_SENDGRID_TEMPLATES_LOADING';
export const SET_SENDGRID_VERIFIED_SENDERS = 'SET_SENDGRID_VERIFIED_SENDERS';
export const SET_SENDGRID_VERIFIED_SENDERS_LOADING = 'SET_SENDGRID_VERIFIED_SENDERS_LOADING';
export const SET_SENDGRID_FULLTEMPLATE = 'SET_SENDGRID_FULLTEMPLATE';
export const SET_SENDGRID_FULLTEMPLATE_LOADING = 'SET_SENDGRID_FULLTEMPLATE_LOADING';
export const CLEAR_SENDGRID_FULLTEMPLATE = 'CLEAR_SENDGRID_FULLTEMPLATE';

export const setSendGridTemplates = (data) => (dispatch) => dispatch({
    type: SET_SENDGRID_TEMPLATES,
    data
});

export const setSendGridTemplatesLoading = () => (dispatch) => dispatch({
    type: SET_SENDGRID_TEMPLATES_LOADING,
    data: true
});

export const setSendGridVerifiedSenders = (data) => (dispatch) => dispatch({
    type: SET_SENDGRID_VERIFIED_SENDERS,
    data
});

export const setSendGridVerifiedSendersLoading = () => (dispatch) => dispatch({
    type: SET_SENDGRID_VERIFIED_SENDERS_LOADING,
    data: true
});

export const setSendGridFullTemplate = (data) => (dispatch) => dispatch({
    type: SET_SENDGRID_FULLTEMPLATE,
    data
});

export const setSendGridFullTemplateLoading = () => (dispatch) => dispatch({
    type: SET_SENDGRID_FULLTEMPLATE_LOADING,
    data: true
});

export const clearSendGridFullTemplate = () => (dispatch) => dispatch({
    type: CLEAR_SENDGRID_FULLTEMPLATE
});