import React from 'react';
import PropTypes from 'prop-types';
import DialogChatWindow from 'src/components/ExternalPopupWindows/DialogChatWindow';
import { Route, StaticRouter, useParams } from 'react-router';
import PopupWindow from 'src/components/ExternalPopupWindows/PopupWindow';
import uuid from 'uuid';

const ExternalPopupWindowContext = React.createContext();
const PopupWindowContext = React.createContext();

window.popupWindows = {};

const closeAllPopups = () => {
    Object.keys(window.popupWindows).forEach((id) => {
        if (window.popupWindows[id] && typeof window.popupWindows[id].close === 'function') {
            window.popupWindows[id].close();
        }
    });
};

export const ExternalPopupWindowProvider = ({ children }) => {
    const [externalPopupWindowComponents, setExternalPopupWindowComponents] = React.useState({});
    const { customerId } = useParams();

    // Close popups when leaving app/reloading
    React.useEffect(() => {
        const handleUnload = () => closeAllPopups();
        window.addEventListener('unload', handleUnload);
        return () => {
            window.removeEventListener('unload', handleUnload);
        };
    }, []);

    React.useEffect(() => {
        closeAllPopups();
    }, [customerId]);

    const hideExternalPopupWindow = React.useCallback((id) => setExternalPopupWindowComponents((state) => {
        if (!state[id]) {
            return state;
        }
        const newState = { ...state };
        delete newState[id];

        if (window.popupWindows?.[id] && typeof window.popupWindows?.[id]?.close === 'function') {
            window.popupWindows[id].close();
        }

        return newState;
    }), []);

    const showDialogChatInNewWindow = React.useCallback((thread) => {
        const popupId = uuid.v4();
        const component = (
            <StaticRouter
                location={{ pathname: `/${thread?.customerId}/dialogs/started/${thread?.id}` }}
            >
                <Route exact path="/:customerId/dialogs/started/:threadId">
                    <DialogChatWindow thread={thread} />
                </Route>
            </StaticRouter>
        );

        setExternalPopupWindowComponents((state) => ({
            ...state,
            [popupId]: component
        }));

        return popupId;
    }, []);

    return (
        <ExternalPopupWindowContext.Provider
            value={{
                hideExternalPopupWindow,
                showDialogChatInNewWindow
            }}
        >
            {children}
            {Object.keys(externalPopupWindowComponents).map((id) => (
                <PopupWindow
                    key={id}
                    onUnload={() => hideExternalPopupWindow(id)}
                    onOpen={(newWindow) => {
                        window.popupWindows = {
                            ...window.popupWindows,
                            [id]: newWindow
                        };
                    }}
                >
                    <PopupWindowContext.Provider value={{ id }}>
                        {externalPopupWindowComponents[id]}
                    </PopupWindowContext.Provider>
                </PopupWindow>
            ))}
        </ExternalPopupWindowContext.Provider>
    );
};

ExternalPopupWindowProvider.propTypes = {
    children: PropTypes.any
};

export const useExternalPopupWindowContext = () => {
    const context = React.useContext(ExternalPopupWindowContext);
    if (context === undefined) {
        throw new Error('useExternalPopupWindowContext must be used within a ExternalPopupWindowProvider');
    }
    return context;
};

export const useGetPopupWindow = () => {
    const id = React.useContext(PopupWindowContext)?.id ?? null;
    return window?.popupWindows?.[id] ?? null;
};

export default ExternalPopupWindowProvider;