import React from 'react';
import Summary from 'src/components/SummaryWidget/SummaryWidget';

const SummaryWidget = () => {
    return (
        <Summary />
    );
};

export const size = {
    xl: {
        w: 4,
        h: 5
    },
    lg: {
        w: 4,
        h: 5
    },
    md: {
        w: 4,
        h: 5
    },
    xs: {
        w: 5,
        h: 5
    },
    xxs: {
        w: 8,
        h: 5
    }
};

export default SummaryWidget;