import * as ACTIONS from 'src/actions/sendMessageActions';
import { AuthenticationActions } from 'src/actions/authActions';
import moment from 'moment';
import { RESET_REDUCER_STATE } from 'src/actions';

const initialState = {
    sender: {
        messageType: 'SMS',
        senderSource: ''
    },
    messageContent: {
        content: '',
        messageTemplate: '',
        emailAttachment: null,
        whatsAppAttachment: null,
        whatsappTemplateBodyParameters: [],
        useSmsFallback: false,
        dynamicTemplateData: []
    },
    recipients: {
        recipients: [],
        placeholders: {},
        groupSource: 'none',
        selectedGroupIds: [],
        selectedGroupMessagingAccountId: null,
        selectedGroupServiceName: '',
        sendStartedFromGroupService: false,
        groupContactsFilter: '',
        customContactPropertyFilterString: '',
        groupContactsFilterCount: 0,
        groupContactCount: 0,
        csvFilename: null,
        csvPhoneColumn: null,
        csvPhoneColumnIndex: null,
        personalizationColumns: [],
        csvRowCount: 0,
        ephemeralSendProcessId: null,
        importContacts: false,
        discussionServiceId: null
    },
    otherSettings: {
        sendTime: null,
        sendTimeError: false,
        staggeredSend: false,
        allowedSendTimeStart: '00:00:00',
        allowedSendTimeEnd: '23:59:59',
        allowedSendDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        allowedSendDaysError: false,
        allowedSendTimeError: false,
        maxMessagesPerBatch: 17,
        batchIntervalMinutes: 0.034,
        batchMessagesPerMinute: 500,
        batchedSend: false,
        startDiscussion: false,
        forceExpiration: false,
        adMessage: false,
        codeServiceId: null,
        availableCodes: 0,
        groupServiceHasCodeId: false,
        isPriorityMessage: false,
        ignoreInvalidNumbers: false,
        parentMessageId: null,
        serviceId: null,
        parentServiceName: null,
        messageName: null
    },
    senderStepComplete: false,
    messageContentStepComplete: false,
    recipientStepComplete: false,
    otherSettingsStepComplete: false,
    allStepsComplete: false,
    clearSettingsOnSend: true
};

const placeholderPattern = /\$\(([^()]+)\)/gm;

const sendMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_SENDER: {
            return {
                ...state,
                sender: {
                    ...state.sender,
                    ...action.data
                }
            };
        }
        case ACTIONS.SET_MESSAGECONTENT: {
            return {
                ...state,
                messageContent: {
                    ...state.messageContent,
                    ...action.data
                },
                // When content changes, populate placeholders and recipient objects in recipient step
                recipients: {
                    ...state.recipients,
                    ...(action.data.content ? {
                        ...[...action.data.content?.matchAll(placeholderPattern) ?? []]?.reduce((acc, p) => ({
                            placeholders: {
                                ...acc.placeholders,
                                [p[1]]: state.recipients.placeholders?.[p[1]] ?? ''
                            },
                            recipients: acc.recipients.map((r) => ({
                                ...r,
                                personalization: {
                                    ...acc.placeholders,
                                    [p[1]]: state.recipients.placeholders?.[p[1]] ?? ''
                                }
                            })),
                        }), { placeholders: {}, recipients: state.recipients.recipients })
                    } : {})
                }
            };
        }
        case ACTIONS.SET_WHATSAPPATTACHMENT: {
            return {
                ...state,
                messageContent: {
                    ...state.messageContent,
                    ...action.data
                }
            };
        }
        case ACTIONS.SET_EMAILATTACHMENT: {
            return {
                ...state,
                messageContent: {
                    ...state.messageContent,
                    ...action.data
                }
            };
        }
        case ACTIONS.SET_RECIPIENTS: {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    ...action.data,
                    // If recipients change add placeholder values for each
                    ...(action.data.recipients ? {
                        recipients: action.data.recipients?.map((r) => ({
                            address: r,
                            personalization: {
                                ...state.recipients.placeholders
                            }
                        }))
                    } : {}),
                    // If placeholders get updated, update personalization in every recipient aswell
                    ...(action.data.placeholders ? {
                        recipients: state.recipients.recipients?.map((r) => ({
                            ...r,
                            personalization: {
                                ...action.data.placeholders
                            }
                        }))
                    } : {})
                }
            };
        }
        case ACTIONS.SET_CSVRECIPIENTS: {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    ...action.data
                }
            };
        }
        case ACTIONS.RESET_CSVRECIPIENTS: {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    csvFilename: null,
                    csvPhoneColumn: null,
                    csvPhoneColumnIndex: null,
                    personalizationColumns: [],
                    csvRowCount: 0,
                    ephemeralSendProcessId: null
                },
                otherSettings: {
                    ...state.otherSettings
                }
            };
        }
        case ACTIONS.SET_GROUPCONTACTSFILTER: {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    ...action.data
                }
            };
        }
        case ACTIONS.SET_GROUPCONTACTCOUNT: {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    ...action.data
                }
            };
        }
        case ACTIONS.SET_OTHERSETTINGS: {
            return {
                ...state,
                otherSettings: {
                    ...state.otherSettings,
                    ...Object.keys(action.data).reduce((settings, setting) => {
                        const allowedSendTimeStart = action.data.allowedSendTimeStart ?? moment(initialState.otherSettings.allowedSendTimeStart, 'HH:mm:ss');
                        const allowedSendTimeEnd = action.data.allowedSendTimeEnd ?? moment(initialState.otherSettings.allowedSendTimeEnd, 'HH:mm:ss');

                        switch (setting) {
                            case 'allowedSendTimeStart':
                                return {
                                    ...settings,
                                    allowedSendTimeStart: allowedSendTimeStart.format('HH:mm:ss'),
                                    allowedSendTimeError: allowedSendTimeStart.startOf('minute')?.isSameOrAfter(moment(state.otherSettings?.allowedSendTimeEnd, 'HH:mm:ss').startOf('minute'))
                                };
                            case 'allowedSendTimeEnd':
                                return {
                                    ...settings,
                                    allowedSendTimeEnd: allowedSendTimeEnd.format('HH:mm:ss'),
                                    allowedSendTimeError: moment(state.otherSettings?.allowedSendTimeStart, 'HH:mm:ss').startOf('minute')?.isSameOrAfter(allowedSendTimeEnd?.startOf('minute'))
                                };
                            case 'sendTime':
                                return {
                                    ...settings,
                                    sendTime: action.data.sendTime,
                                    sendTimeError: action.data.sendTime !== null && action.data.sendTime?.isBefore(moment()),
                                    ...action.data.sendTime !== null ? { staggeredSend: false } : {}
                                };
                            case 'allowedSendDays':
                                return {
                                    ...settings,
                                    allowedSendDays: action.data.allowedSendDays,
                                    allowedSendDaysError: action.data.allowedSendDays?.length === 0 ?? false
                                };
                            default:
                                return {
                                    ...settings,
                                    [setting]: action.data[setting]
                                };
                        }
                    }, {})
                }
            };
        }
        case ACTIONS.TOGGLE_STAGGEREDSEND: {
            return {
                ...state,
                otherSettings: {
                    ...state.otherSettings,
                    staggeredSend: action.data,
                    ...(action.data ? {} : {
                        allowedSendTimeStart: initialState.otherSettings.allowedSendTimeStart,
                        allowedSendTimeEnd: initialState.otherSettings.allowedSendTimeEnd,
                        allowedSendDays: initialState.otherSettings.allowedSendDays,
                        allowedSendDaysError: false
                    })
                }
            };
        }
        case ACTIONS.TOGGLE_BATCHEDSEND: {
            return {
                ...state,
                otherSettings: {
                    ...state.otherSettings,
                    batchedSend: action.data,
                    ...(action.data ? {} : {
                        batchIntervalMinutes: initialState.otherSettings.batchIntervalMinutes,
                        maxMessagesPerBatch: initialState.otherSettings.maxMessagesPerBatch,
                        batchMessagesPerMinute: initialState.otherSettings.batchMessagesPerMinute,
                    })
                }
            };
        }
        case ACTIONS.VALIDATE_STEPS: {
            return {
                ...state,
                otherSettings: {
                    ...state.otherSettings,
                    sendTimeError: state.otherSettings.sendTime !== null && state.otherSettings.sendTime?.isBefore(moment())
                }
            };
        }
        case ACTIONS.SET_CLEARSETTINGSONSEND: {
            return {
                ...state,
                clearSettingsOnSend: action.data
            };
        }
        case ACTIONS.CLEARSENDMESSAGESETTINGS: {
            return {
                ...initialState,
                sender: {
                    ...initialState.sender,
                    ...state.sender,
                    messageType: initialState.sender.messageType,
                    senderSource: initialState.sender.senderSource
                },
                recipients: {
                    ...initialState.recipients,
                    importContacts: state.recipients.importContacts
                }
            };
        }
        case AuthenticationActions.SelectedCustomerFetched: {
            return {
                ...state,
                otherSettings: {
                    ...state.otherSettings,
                    adMessage: Boolean(action?.data?.metadata?.find?.((metadata) => metadata?.key === 'SendAdMessageByDefault')?.value)
                }
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

const stepCompletionValidator = (state, action) => {
    const reducedState = sendMessageReducer(state, action);
    let whatsAppTemplateParamsSet = true;

    if (reducedState.sender.messageType === 'Whatsapp' && reducedState.messageContent.messageTemplate !== '') {
        const placeholders = reducedState.messageContent.content.match(/{{[0-9]+}}/gm);
        const amountPlaceholders = placeholders ? placeholders.length : 0;

        for (let i = 0; i < amountPlaceholders; i++) {
            if (!reducedState.messageContent.whatsappTemplateBodyParameters?.[i] || reducedState.messageContent.whatsappTemplateBodyParameters?.[i]?.value === '') {
                whatsAppTemplateParamsSet = false;
            }
        }
    }

    const senderStepComplete = reducedState.sender.messageType !== '' && reducedState.sender.senderSource !== '';
    const recipientStepComplete = senderStepComplete && (
        (reducedState.recipients.recipients.length > 0 ||
            (reducedState.recipients.csvRowCount > 0 && ((reducedState.recipients.csvPhoneColumn && reducedState.recipients.csvPhoneColumn !== '') || reducedState.recipients.csvPhoneColumnIndex !== null)) ||
            (reducedState.recipients.selectedGroupIds?.length > 0))
    );
    const messageContentStepComplete = recipientStepComplete && (reducedState.messageContent.content !== '' || (reducedState.sender.messageType === 'Email' && reducedState.messageContent.messageTemplate !== '')) && whatsAppTemplateParamsSet;
    const otherSettingsStepComplete = messageContentStepComplete && reducedState.otherSettings.sendTimeError === false && reducedState.otherSettings.allowedSendDaysError === false && reducedState.otherSettings.allowedSendTimeError === false;
    const allStepsComplete = otherSettingsStepComplete;

    return {
        ...reducedState,
        senderStepComplete,
        messageContentStepComplete,
        recipientStepComplete,
        otherSettingsStepComplete,
        allStepsComplete
    };
};

export default stepCompletionValidator;
