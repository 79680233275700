import React from 'react';
import { Router, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AuthContextProvider } from 'src/auth';
import { TelemetryProvider } from 'src/utils/appInsights';
import { theme } from 'src/theme';
import store from 'src/store';
import routes from 'src/routes';
import ScrollReset from 'src/components/ScrollReset';
import 'src/mixins/chartjs';
import 'src/mixins/moment';
import 'src/mixins/validate';
import 'src/mixins/prismjs';
import 'src/mock';
import { msalInstance } from 'src/auth/PublicClientApplication';
import ServiceWorkerProvider from 'src/ServiceWorkerProvider';
import CookiesNotification from 'src/components/CookiesNotification';
import moment from 'moment';

moment.locale('fi', {
    week: {
        dow: 1
    }
});

const history = createBrowserHistory();

function App() {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router history={history}>
                        <ServiceWorkerProvider />
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <TelemetryProvider>
                                <AuthContextProvider msalInstance={msalInstance}>
                                    <ScrollReset />
                                    <CookiesNotification />
                                    {renderRoutes(routes)}
                                </AuthContextProvider>
                            </TelemetryProvider>
                        </QueryParamProvider>
                    </Router>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StoreProvider>
    );
}

export default App;