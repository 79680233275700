import React from 'react';
import SmartAgent from 'src/components/SmartAgent/SmartAgent';

const SmartAgentWidget = () => {
    return (
        <SmartAgent />
    );
};

export const size = {
    xl: {
        w: 4,
        h: 2
    },
    lg: {
        w: 4,
        h: 2
    },
    md: {
        w: 4,
        h: 2
    },
    xs: {
        w: 4,
        h: 2
    },
    xxs: {
        w: 8,
        h: 2
    }
};

export default SmartAgentWidget;