const infoData = [
    {
        field: 'Action keywords',
        description: 'An Input Source (address and keyword) that can be used to perform actions via SMS. Input sources are protocol-specific, meaning that you will need to add one for each type of message you want to support, like SMS, WhatsApp et.c.'
    },
    {
        field: 'Response messages',
        description: 'Reply messages that will be sent, in response to taken actions or group functions. Examples are: Join group, Renewal reminder'
    },
    {
        field: 'Action: Join group',
        description: 'Join a Group service via sms, users will receive one of the following responses: Join group, Already a member, Member limit hit.'
    },
    {
        field: 'Action: Leave group',
        description: 'Leave a Group service via sms, users will receive one of the following responses: Levae group, Not a member.'
    },
    {
        field: 'Action: Renew Group membership',
        description: 'If a Group service is set to expire, a user can renew the membership with this keyword. users will receive the following responses: Membership renewed. If the group is set to send out reminders of expiration, that message will be picked from \'Renewal reminder\'.'
    },
    {
        field: 'Admins',
        description: 'Admins can send out messages to the group from their phone. Admins have to be added to the service first.'
    }
];

export default infoData;