import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/tagActions';

const initialState = {
    tagsLoading: true,
    tags: [],
    refreshtags: false
};

const codeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TAGS_LOADING: {
            return {
                ...state,
                tags: Boolean(action.data)
            };
        }
        case actionTypes.SET_TAGS: {
            return {
                ...state,
                refreshtags: false,
                tagsLoading: false,
                tags: Array.isArray(action?.data) ? action.data.sort() : []
            };
        }
        case actionTypes.REFERSH_TAGS: {
            return {
                ...state,
                refreshtags: true
            };
        }

        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default codeReducer;