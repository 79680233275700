import React from 'react';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from 'src/auth/authConfig';
import { useQueryParam } from 'use-query-params';
import { msalInstance } from 'src/auth/PublicClientApplication';

export const AuthenticationActions = {
    CustomerSelected: 'CustomerSelected',
    CustomerRightsLoaded: 'CustomerRightsLoaded',
    ProfileUpdated: 'ProfileUpdated',
    SelectedCustomerFetched: 'SelectedCustomerFetched',
    UserAccessGroupsLoaded: 'UserAccessGroupsLoaded',
    AccessTokenRefreshed: 'AccessTokenRefreshed'
};

export const useGetLoginRequest = () => {
    const [sysAdminQueryParam, setSysAdminQueryParam] = useQueryParam('sysadmin');
    const [passwordResetQueryParam, setPasswordResetQueryParam] = useQueryParam('passwordReset');
    const [sysAdmin] = React.useState(sysAdminQueryParam);
    const [passwordReset] = React.useState(passwordResetQueryParam);

    // Remove passwordReset param from Url
    React.useEffect(() => {
        if (typeof passwordReset !== 'undefined') {
            setPasswordResetQueryParam(undefined, 'replace');
        }
    }, [passwordReset]);

    // Remove sysAdmin param from Url
    React.useEffect(() => {
        if (typeof sysAdmin !== 'undefined') {
            setSysAdminQueryParam(undefined, 'replace');
        }
    }, [sysAdmin]);

    return React.useMemo(() => ({
        ...loginRequest,
        ...typeof passwordReset !== 'undefined' ? {
            prompt: 'login',
            extraQueryParameters: {
                passwordReset: true,
                ...passwordReset ? {
                    /* If passwordReset param is set to an email pass that as loginHint */
                    loginHint: passwordReset
                } : {}
            }
        } : {},
        ...typeof sysAdmin !== 'undefined' ? {
            domainHint: 'arenainteractive.fi',
            extraQueryParameters: {
                idps: 'aiaad'
            }
        } : {}
    }), [sysAdmin, passwordReset]);
};

export const refreshAccessToken = () => async (dispatch) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.[0]) {
        const request = {
            account: accounts[0],
            scopes: loginRequest.scopes
        };

        try {
            const response = await msalInstance.acquireTokenSilent(request);
            dispatch({ type: AuthenticationActions.AccessTokenRefreshed, data: response.accessToken });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request);
            }
            if (error instanceof BrowserAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request);
            }
        }
    }
    return null;
};

export const refreshAccessTokenRedirect = (enableMfa) => async () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.[0]) {
        const request = {
            account: accounts[0],
            scopes: loginRequest.scopes,
            ...enableMfa ? { prompt: 'login' } : {},
            extraQueryParameters: {
                enableMfa
            }
        };

        try {
            await msalInstance.acquireTokenRedirect(request);
        } catch (error) {
            return null;
        }
    }
    return null;
};

export const customerRightsLoaded = (customerRights, isSysAdmin) => (dispatch) => dispatch({
    type: AuthenticationActions.CustomerRightsLoaded,
    data: { customerRights, isSysAdmin }
});

export const profileUpdated = (data, enableMfa = false) => (dispatch) => {
    dispatch({ type: AuthenticationActions.ProfileUpdated, data });
    dispatch(refreshAccessTokenRedirect(enableMfa));
};

export const logout = () => () => {
    msalInstance.logoutRedirect();
};