import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { SelectThread, SetSingleDialogThreadLoaded, TEMPLATE_SELECTED } from 'src/actions';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { useHttpRequest } from 'src/utils/httpClient';
import * as InboxItemTypes from 'src/views/Dialogs/inboxItemTypes';
import { useThreadChatWindowContext } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import ConversationToolbar from './Toolbar';
import ConversationMessages from './Messages';
import ConversationForm from './ConversationForm';

const useStyles = makeStyles((theme) => ({
    chatWindowRoot: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        minWidth: 0
    }
}));

const ThreadChatWindow = ({ className, ...rest }) => {
    const [threadNotFound, setThreadNotFound] = React.useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { threadsById, threadsLoading, dialogServicesLoading, threadFilters: { service, status, search, ownerUserId } = {}, isWidget } = useThreadChatWindowContext();
    const { threadId, customerId } = useParams();

    const selectedThreadId = React.useMemo(() => threadsById[threadId]?.id ?? null, [threadsById, threadId]);

    const $filter = React.useMemo(() => {
        const statusFilterArray = Array.isArray(status) ? status : [];

        const filters = [
            { expression: `id == ${threadId}`, if: true },
            { expression: `SourceServiceIdOrServiceIdEquals == ${service}`, if: service !== '' },
            { expression: `status == ${statusFilterArray.join('|')}`, if: statusFilterArray.length > 0 },
            { expression: `ownerUserId == ${ownerUserId}`, if: ownerUserId !== '' }
        ]
            .filter((f) => f?.if).map((f) => f.expression);
        return filters.length > 0 ? filters.join(', ') : null;
    }, [threadId, service, status, ownerUserId]);

    const { mutate: fetchThreadById, loading: threadLoading } = useHttpRequest(({ $filter, search }) => ({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads',
        params: {
            filters: $filter,
            searchText: search
        }
    }));

    React.useEffect(() => {
        const fetchThread = async () => {
            const { payload, error } = await fetchThreadById({ $filter, search });
            const thread = payload?.value?.[0] ?? null;

            if (error || payload?.totalCount !== 1 || thread?.status === 'Pending' || thread?.expirationReason === 'NoResponse') {
                setThreadNotFound(true);
            } else {
                dispatch(SetSingleDialogThreadLoaded(thread));
            }
        };

        if (!isWidget && !threadsLoading && selectedThreadId === null) {
            fetchThread();
        }
    }, [isWidget, dispatch, fetchThreadById, threadsLoading, selectedThreadId, $filter, search]);

    // Set selected thread when thread exists
    React.useEffect(() => {
        if (selectedThreadId) {
            dispatch(SelectThread(selectedThreadId));
        } else {
            dispatch(SelectThread(null));
        }
    }, [selectedThreadId, dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch({
                type: TEMPLATE_SELECTED,
                data: null
            });
        };
    }, [dispatch]);

    if (!selectedThreadId && (threadsLoading || dialogServicesLoading || threadLoading || !threadNotFound)) {
        return (
            <div
                {...rest}
                className={clsx(classes.chatWindowRoot, className)}
            >
                <DelayedLinearProgress />
            </div>
        );
    }

    if (!selectedThreadId && !threadsLoading && !threadLoading && threadNotFound) {
        return <Redirect to={`/${customerId}/dialogs/${InboxItemTypes.Started}`} />;
    }

    return (
        <div
            {...rest}
            className={clsx(classes.chatWindowRoot, className)}
        >
            <ConversationToolbar conversation={{}} />
            <Divider />
            <ConversationMessages />
            <Divider />
            <ConversationForm />
        </div>
    );
};

ThreadChatWindow.propTypes = {
    className: PropTypes.string
};

export default ThreadChatWindow;