const infoData = [
    {
        field: 'Messaging account id',
        description: 'The id of the messaging account.'
    },
    {
        field: 'Account state',
        description: 'The state of the account, (Active or Passive)'
    },
    {
        field: 'Account platform',
        description: 'The source which the account was migrated from (E0, E1, Aimo, SmartDialog). For new customers the platform is Modern.'
    },
    {
        field: 'Allowed senders',
        description: 'Any sender value (technically compliant) can be used, unless they have been restricted, if so only the values listed here are allowed.'
    },
    {
        field: 'Allowed recipient countries',
        description: 'Can be used to restrict sending messages to recipients from specific countries. Some countries share callingcodes, either all with the same callingcode are selected or none.'
    },
    {
        field: 'Allow alphanumeric sender',
        description: 'If true, the sender can be freely set (technical limits apply, like length 3-11)'
    },
    {
        field: 'IP restrictions',
        description: 'If no restrictions are applied you can send from any IP address. Multiple restrictions can be entered, either as single IP addresses, address ranges or network CIDR notations.'
    },
    {
        field: 'Alert contacts',
        description: 'Used when sending e-mail alerts.'
    },
    {
        field: 'Message volume alerts > Daily upper alert limit',
        description: 'An e-mail alert will immediately be sent to the alert contact(s) when the limit is exceeded. This limit is evaluated on a daily basis.'
    },
    {
        field: 'Message volume alerts > Lower alert limit',
        description: 'An e-mail alert will be sent to the alert contact(s) for each alert mail check if the amount of messages has not exceeded the limit.'
    },
    {
        field: 'Message volume alerts > Lower alert interval',
        description: 'An interval that defines how often the alert limit check will run. Required when lower alert limit is set.'
    },
    {
        field: 'Message volume alerts > Lower alert start time',
        description: 'Alert limit check interval start time. Optional.'
    },
    {
        field: 'Message volume alerts > Lower alert end time',
        description: 'Alert limit check interval end time. Optional.'
    },
    {
        field: 'Username',
        description: 'The username of the account'
    },
    {
        field: 'Password',
        description: 'The password of the account'
    },
    {
        field: 'Created date',
        description: 'The time of the accounts creation.'
    },
    {
        field: 'Modified date',
        description: 'The time the account was last modified.'
    },
    {
        field: 'Default account',
        description: 'The Messaging Account can be set as a default account to be used in messaging services.'
    },
    {
        field: 'Description',
        description: 'A free text field for describing the account.'
    },
    {
        field: 'Message tail',
        description: 'Text that will be added to the end of the SMS message content. Note! Message tail is added to every message that is sent through this message account.'
    }
];

export default infoData;