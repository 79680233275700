import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const DialogServiceTitle = ({ match }) => {
    const { dialogServicesById } = useSelector((state) => state.dialog);
    const serviceName = dialogServicesById?.[match?.params?.serviceId]?.name;

    return (
        <div>
            {serviceName || 'Dialog Service'}
        </div>
    );
};

DialogServiceTitle.propTypes = {
    match: PropTypes.object
};

export default DialogServiceTitle;