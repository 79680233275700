import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import store from 'src/store';
import { ServiceWorkerUpdateAvailable } from 'src/actions';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register({
    onSuccess: () => { /* Not yet used */ },
    onUpdate: () => store.dispatch(ServiceWorkerUpdateAvailable())
});
