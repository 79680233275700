import React from 'react';
import UrlShortener from 'src/components/UrlShortener/UrlShortenerWidget';

const UrlShortenerWidget = () => {
    return (
        <UrlShortener />
    );
};

export const size = {
    xl: {
        w: 3,
        h: 1.5
    },
    lg: {
        w: 4,
        h: 1.5
    },
    md: {
        w: 4,
        h: 1.5
    },
    xs: {
        w: 4,
        h: 2
    },
    xxs: {
        w: 8,
        h: 2
    }
};

export default UrlShortenerWidget;