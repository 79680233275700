export const PLAY_NEW_UNREAD_MESSAGE_SOUND = 'PLAY_NEW_UNREAD_MESSAGE_SOUND';
export const PLAY_NEW_DIALOG_SOUND = 'PLAY_NEW_DIALOG_SOUND';

export const STOP_PLAYING_SOUNDS = 'STOP_PLAYING_SOUNDS';

export const PlayNewUnreadMessageSound = () => (dispatch) => dispatch({
    type: PLAY_NEW_UNREAD_MESSAGE_SOUND
});

export const PlayNewDialogSound = () => (dispatch) => dispatch({
    type: PLAY_NEW_DIALOG_SOUND
});

export const StopPlayingSounds = () => (dispatch) => dispatch({
    type: STOP_PLAYING_SOUNDS
});
