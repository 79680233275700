import { RESET_REDUCER_STATE } from 'src/actions';
import * as actionTypes from 'src/actions/groupMessagingActions';
import { sortByProperty } from 'src/utils/arrayFunctions';

const initialState = {
    servicesLoading: true,
    services: [],
    servicesFetched: false,
    selectedGroupService: null,
    selectedGroupServiceLoading: true,
    refreshSelectedGroupService: false,
    refreshServices: false,
    contacts: [],
    selectedGroupContact: null,
    refreshContacts: false,
    loadingContacts: false,
    totalCount: 0,
    pushMessages: [],
    pushMessagesLoading: false,
    refreshPushMessages: false,
    selectedPushMessage: {}
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_GROUPSERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter((s) => s.id !== action?.data?.id),
                    action.data
                ]
            };
        }
        case actionTypes.SET_SELECTEDGROUPSERVICE: {
            return {
                ...state,
                selectedGroupService: action.data,
                selectedGroupServiceLoading: false,
                refreshSelectedGroupService: false
            };
        }
        case actionTypes.SET_SELECTEDGROUPSERVICELOADING: {
            return {
                ...state,
                selectedGroupServiceLoading: true
            };
        }
        case actionTypes.REFRESH_SELECTEDGROUPSERVICE: {
            return {
                ...state,
                refreshSelectedGroupService: true
            };
        }
        case actionTypes.SET_GROUPSERVICES_LOADING: {
            return {
                ...state,
                servicesLoading: Boolean(action.data)
            };
        }
        case actionTypes.SET_GROUPSERVICES: {
            return {
                ...state,
                refreshServices: false,
                servicesLoading: false,
                servicesFetched: true,
                services: Array.isArray(action?.data) ? action.data.reduce((services, service) => ([...services, { ...service, pushBilling: service?.serviceConfiguration?.pushBilling }]), []).sort(sortByProperty('name')) : []
            };
        }
        case actionTypes.REFERSH_GROUPSERVICES: {
            return {
                ...state,
                refreshServices: true
            };
        }
        case actionTypes.SET_SELECTEDGROUPCONTACT: {
            return {
                ...state,
                selectedGroupContact: action.data
            };
        }
        case actionTypes.REFERSH_GROUPCONTACTS: {
            return {
                ...state,
                refreshContacts: Boolean(action?.data)
            };
        }
        case actionTypes.SET_GROUPCONTACTSLOADING: {
            return {
                ...state,
                loadingContacts: true
            };
        }
        case actionTypes.GROUPSERVICEUPDATED: {
            return {
                ...state,
                services: state.services.reduce((services, service) => {
                    if (service?.id === action?.data?.id) {
                        return [...services, action.data];
                    }
                    return [...services, service];
                }, [])
            };
        }
        case actionTypes.GROUPSERVICEDELETED: {
            return {
                ...state,
                services: state.services.filter((service) => service?.id !== action?.data)
            };
        }
        case actionTypes.SET_PUSHMESSAGES: {
            return {
                ...state,
                pushMessages: action.data,
                pushMessagesLoading: false,
                refreshPushMessages: false
            };
        }
        case actionTypes.SET_PUSHMESSAGES_LOADING: {
            return {
                ...state,
                pushMessagesLoading: Boolean(action.data)
            };
        }
        case actionTypes.REFERSH_PUSHMESSAGES: {
            return {
                ...state,
                refreshPushMessages: !state.refreshPushMessages
            };
        }
        case actionTypes.SET_SELECTED_PUSHMESSAGE: {
            return {
                ...state,
                selectedPushMessage: action.data
            };
        }
        case RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default groupReducer;