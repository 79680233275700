export const updateObjectInDictionary = (dictionary, key, callback) => {
    if (typeof callback !== 'function') {
        throw new Error('callback parameter in updateObjectInDictionary needs to be a function!');
    }
    const dictionaryKeys = Object.keys(dictionary);

    return dictionaryKeys.reduce((entries, entryId) => {
        if (entryId === key) {
            return {
                ...entries,
                [entryId]: callback(entries[entryId])
            };
        }
        return entries;
    }, dictionary);
};