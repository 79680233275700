import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, IconButton, LinearProgress, Input } from '@material-ui/core';
import { useSnackbar } from 'src/components/Snackbar';
import { useHttpRequest } from 'src/utils/httpClient';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchResults from 'src/components/KayakLookup/SearchResults';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        flex: 1,
        backgroundColor: '#15325e'
    },
    logoImg: {
        maxWidth: '50%'
    },
    mainContainer: {
        marginTop: theme.spacing(3)
    },
    subSelect: {
        width: '100%',
        padding: theme.spacing(1),
        border: 0,
        color: '#333',
        backgroundColor: '#fff',
        borderRadius: '10px'
    },
    button: {
        marginTop: theme.spacing(3),
        width: '100%',
        color: '#fff',
        backgroundColor: '#ed7800',
        '&:hover': {
            backgroundColor: '#894703'
        },
        fontSize: '1.4em',
        fontWeight: 'bold',
        borderRadius: '10px'
    },
    info: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        color: '#fff'
    },
    progrssbar: {
        bottom: '5%',
        position: 'absolute',
        left: '5%',
        width: '90%'
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    },
    orContainer: {
        color: '#fff',
        fontSize: '1.4em',
        fontWeight: 'bold',
        display: 'contents'
    },
    or: {
        width: '100%',
        textAlign: 'center'
    }
}));

const defaultSearchCriterias = {
    msisdn: '',
    cusno: ''
};

function KayakLookup() {
    const classes = useStyles();
    const { error: errorSnack } = useSnackbar();
    const [searchCriterias, setSearchCriterias] = React.useState(defaultSearchCriterias);
    const [resultsOpen, setResultsOpen] = React.useState(false);
    const [customerData, setCustomerData] = React.useState([]);
    const [subscriptionData, setSubscriptionData] = React.useState([]);

    const onChange = (e) => {
        const newData = {
            ...defaultSearchCriterias,
            [e.target.name]: e.target.value
        };
        setSearchCriterias(newData);
    };

    const handleResultsClose = () => {
        setResultsOpen(false);
    };

    const { mutate: _kayakCustomersRequest, loading: kayakCustomersRequestLoading } = useHttpRequest(({ cusno, msisdn }) => ({
        method: 'GET',
        ...(cusno ? {
            endpoint: `/kayaklookup/getcustomerbycusno/${cusno}`
        } : {
            endpoint: `/kayaklookup/getcustomersbymsisdn/${msisdn}`
        })
    }));

    const { mutate: _kayakSubscriptionsRequest, loading: kayakSubscriptionsRequestLoading } = useHttpRequest(({ cusno, msisdn }) => ({
        method: 'GET',
        ...(cusno ? {
            endpoint: `/kayaklookup/getsubscriptionsbycusno/${cusno}`
        } : {
            endpoint: `/kayaklookup/getsubscriptionsbymsisdn/${msisdn}`
        })
    }));

    const kayakCustomersRequest = React.useCallback(_kayakCustomersRequest, []);
    const kayakSubscriptionsRequest = React.useCallback(_kayakSubscriptionsRequest, []);

    const handleSearchClick = async () => {
        if ((searchCriterias.msisdn && searchCriterias.cusno) || (!searchCriterias.msisdn && !searchCriterias.cusno)) {
            errorSnack('Either search on customer number or phone number.');
        } else {
            const { payload: customerPayload, error: customerError, errorMessages: customerErrorMessages, status: customerStatus } = await kayakCustomersRequest({ cusno: searchCriterias.cusno, msisdn: searchCriterias.msisdn });
            const { payload: subsPayload, error: subsError, errorMessages: subsErrorMessages, status: subsStatus } = await kayakSubscriptionsRequest({ cusno: searchCriterias.cusno, msisdn: searchCriterias.msisdn });

            if (!customerError) {
                setCustomerData(customerPayload);
                setResultsOpen(true);
            }
            if (!subsError) {
                setSubscriptionData(subsPayload);
                setResultsOpen(true);
            }

            let show500ErrorMessage = false;
            if (customerError) {
                if (customerStatus.toString().startsWith('5')) {
                    show500ErrorMessage = true;
                } else {
                    errorSnack(customerErrorMessages);
                }
            }
            if (subsError) {
                if (subsStatus.toString().startsWith('5')) {
                    show500ErrorMessage = true;
                } else {
                    errorSnack(subsErrorMessages);
                }
            }
            if (show500ErrorMessage) {
                errorSnack('Error occured, please try with some other search criteria.');
            }
        }
    };

    const handleClearClick = (criteria) => {
        setSearchCriterias({ ...searchCriterias, [criteria]: '' });
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const endAdornment = (criteria) => {
        if (searchCriterias[criteria]) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => handleClearClick(criteria)}
                        onMouseDown={handleMouseDown}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            );
        }

        return '';
    };

    return (
        <Card className={classes.card} dir="ltr">
            <CardContent>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <img
                            alt="Logo"
                            src="/images/logos/kayaklookup_logo.png"
                            className={classes.logoImg}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} container direction="row" className={classes.mainContainer}>
                        <Grid item xs={5} lg={5} className={classes.flexContainer}>
                            <Input
                                name="msisdn"
                                onChange={onChange}
                                value={searchCriterias?.msisdn}
                                className={classes.subSelect}
                                placeholder="Phone number"
                                endAdornment={endAdornment('msisdn')}
                                disableUnderline
                            />
                        </Grid>
                        <Grid item xs={2} lg={2} className={classes.flexContainer}>
                            <div className={classes.orContainer}>
                                <span className={classes.or}>OR</span>
                            </div>
                        </Grid>
                        <Grid item xs={5} lg={5} className={classes.flexContainer}>
                            <Input
                                name="cusno"
                                onChange={onChange}
                                value={searchCriterias?.cusno}
                                className={classes.subSelect}
                                placeholder="Customer number"
                                endAdornment={endAdornment('cusno')}
                                disableUnderline
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Button variant="contained" className={classes.button} onClick={handleSearchClick}>
                            LOOK UP
                        </Button>
                    </Grid>
                </Grid>
                <SearchResults open={resultsOpen} setOpen={handleResultsClose} customerData={customerData ?? []} subscriptionData={subscriptionData ?? []} />
                {(kayakSubscriptionsRequestLoading || kayakCustomersRequestLoading) && <LinearProgress className={classes.progrssbar} />}
            </CardContent>
        </Card>
    );
}

export default KayakLookup;