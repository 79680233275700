/* eslint-disable react/no-multi-comp */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useParams, useHistory } from 'react-router';
import { defaultRoute } from 'src/routes';
import { AuthenticationActions } from 'src/actions';
import { useDispatch, useSelector } from 'react-redux';
import SysAdminCustomerSelect from 'src/layouts/Main/SysAdminCustomerSelect';

const useStyles = makeStyles(() => ({
    root: {
    },
    selectCustomerHeader: {
        color: '#fff !important'
    },
    selectCustomerContainer: {
        padding: '5%',
        margin: '0 0 5% 0'
    },
    formControl: {
        width: '100%'
    }
}));

function CustomerSelect({
    className,
    ...rest
}) {
    const { customerId } = useParams();
    const { isSysAdmin, customerRights, customerRightsLoading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        dispatch({ type: AuthenticationActions.CustomerSelected, data: customerId ?? null });
    }, [customerId, dispatch]);

    const handleChange = (event) => {
        history.push(`/${event.target.value}${defaultRoute}`);
        event.preventDefault();
    };

    const sortCustomers = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const nameA = a?.name.toUpperCase() ?? '';
        const nameB = b?.name.toUpperCase() ?? '';

        let comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        } else if (nameA < nameB) {
            comparison = -1;
        }
        return comparison;
    };

    if (isSysAdmin) {
        return (
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <div className={classes.selectCustomerContainer}>
                    <SysAdminCustomerSelect loading={customerRightsLoading} />
                </div>
            </div>
        );
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.selectCustomerContainer}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="customer-select-label" className={classes.selectCustomerHeader}>Choose company</InputLabel>
                    <Select
                        displayEmpty
                        id="customer-select"
                        value={customerId}
                        onChange={handleChange}
                    >
                        {customerRights.sort(sortCustomers).map((customer) => <MenuItem value={customer.customerId} key={customer.customerId}>{customer.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

CustomerSelect.propTypes = {
    className: PropTypes.string
};

export default CustomerSelect;