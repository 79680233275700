const infoData = [
    {
        placeholder: 'Add source',
        description: 'Address and keyword that can be used to send in content. Input sources are protocol-specific, meaning that you will need to add one for each type of message channel you want to receive: SMS, MMS, WhatsApp.'
    },
    {
        placeholder: 'Billable',
        description: 'The number list shows either all available numbers, or only those numbers that can be connected to free/paid services.'
    },
    {
        placeholder: 'Inputsource',
        description: 'Available numbers and possible main keywords connected to them are displayed in the list. Select a number suitable for this service from list.'
    },
    {
        placeholder: 'Keyword',
        description: 'Add a keyword. If the number has a main keyword, it has already been added to the beginning of the Keyword line.'
    },
    {
        placeholder: 'Price',
        description: 'The desired price category for this keyword is selected from the list.'
    },
    {
        placeholder: 'Vat',
        description: 'Select the correct VAT percentage from the list.'
    }
];
export default infoData;