import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import propTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Paper, Typography, List, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import NotificationListItem from 'src/components/Notifications/NotificationListItem';
import ScrollableContent from 'src/components/ScrollableContent';
import NotificationIcon from '@material-ui/icons/NotificationsNone';
import WarningIcon from '@material-ui/icons/WarningRounded';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { LOAD_MORE_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ, SHOW_READ_NOTIFICATIONS } from 'src/actions/notificationActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import DelayedLinearProgress from 'src/components/DelayedLinearProgress';
import { sortByDateProperty } from 'src/utils/arrayFunctions';

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexDirection: 'column',
        overflowY: 'hidden',
        maxHeight: theme.constants.contentHeight
    },
    drawerPaper: {
        width: 550,
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(8)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
            width: '100%'
        }
    },
    modalBackdrop: {
        backgroundColor: 'transparent'
    },
    header: {
        width: '100%',
        height: 75,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 3),
        paddingBottom: 5,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2),
            paddingBottom: 3,
        }
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden'
    },
    notificationList: {
        width: '100%'
    },
    noNotificationsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    },
    noNotificationsContent: {
        height: 200,
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    },
    footer: {
        width: '100%',
        height: 75,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2)
        }
    },
}));

export const getNotificationIcon = (notification, theme) => {
    const iconStyle = {
        color: '#fff'
    };

    let icon = <NotificationIcon />;

    switch (notification?.level) {
        case 'Information':
            iconStyle.color = theme.palette.secondary.main;
            break;
        case 'Warning':
            iconStyle.color = theme.palette.warning.light;
            icon = <WarningIcon />;
            break;
        case 'Error':
            iconStyle.color = theme.palette.error.main;
            icon = <ErrorIcon />;
            break;
        default:
            break;
    }

    return { iconStyle, icon };
};

const NotificationsDrawer = ({ open, onClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        readNotificationsById,
        unreadNotificationsById,
        readNotificationsLoading,
        unreadNotificationsLoading,
        hasMoreUnreadNotifications,
        hasMoreReadNotifications,
        showReadNotifications,
        notificationsToMarkAsRead
    } = useSelector((state) => state.notifications);

    const readNotifications = React.useMemo(() => Object.keys(readNotificationsById).map((id) => readNotificationsById[id]).sort(sortByDateProperty('created')), [readNotificationsById]);
    const unreadNotifications = React.useMemo(() => Object.keys(unreadNotificationsById).map((id) => unreadNotificationsById[id]).sort(sortByDateProperty('created')), [unreadNotificationsById]);

    const hasMoreNotifications = React.useMemo(() => (showReadNotifications ? hasMoreReadNotifications : hasMoreUnreadNotifications), [showReadNotifications, hasMoreUnreadNotifications, hasMoreReadNotifications]);
    const notifications = React.useMemo(() => (showReadNotifications ? [...unreadNotifications, ...readNotifications] : unreadNotifications), [showReadNotifications, readNotifications, unreadNotifications]);
    const notificationsLoading = React.useMemo(() => (showReadNotifications ? readNotificationsLoading : unreadNotificationsLoading), [showReadNotifications, readNotificationsLoading, unreadNotificationsLoading]);

    const endMessage = React.useMemo(() => {
        if (showReadNotifications && !readNotificationsLoading && !hasMoreReadNotifications) {
            return (
                <h3 style={{ textAlign: 'center', backgroundColor: '#fff', padding: '20px 0' }}>
                    You have seen all notifications
                </h3>
            );
        }
        if (notifications.length === 0) {
            return (
                <div className={classes.noNotificationsContainer}>
                    <div className={classes.noNotificationsContent}>
                        <Typography variant="h4">No unread notifications</Typography>
                        <Button size="small" onClick={() => dispatch({ type: SHOW_READ_NOTIFICATIONS, data: true })}>Show read</Button>
                    </div>
                </div>
            );
        }
        return null;
    }, [dispatch, notifications, showReadNotifications, hasMoreReadNotifications, readNotificationsLoading]);

    const handleMarkAsRead = () => {
        dispatch({ type: MARK_NOTIFICATIONS_AS_READ, data: [...unreadNotifications] });
    };

    return (
        <Drawer
            ModalProps={{
                BackdropProps: {
                    className: classes.modalBackdrop
                },
                keepMounted: true
            }}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <div className={classes.drawerContainer}>
                <Paper className={classes.header}>
                    <Typography variant="h4">Notifications</Typography>
                    {unreadNotifications?.length > 0 && (
                        <Button disabled={notificationsToMarkAsRead?.length > 0} onClick={handleMarkAsRead}>Mark as Read</Button>
                    )}
                </Paper>
                <ScrollableContent id="scrollBarProvider" className={classes.content} style={{ width: '100%', height: '100%' }}>
                    {notificationsLoading && <DelayedLinearProgress absolute />}
                    <List component="nav" className={classes.notificationList} aria-label="Notifications">
                        <InfiniteScroll
                            dataLength={notifications?.length ?? 0}
                            next={() => dispatch({ type: LOAD_MORE_NOTIFICATIONS })}
                            hasMore={hasMoreNotifications}
                            loader={<DelayedLinearProgress />}
                            scrollableTarget="scrollBarProvider"
                            endMessage={endMessage}
                        >
                            {notifications?.map((notification) => {
                                const { iconStyle, icon } = getNotificationIcon(notification, theme);
                                return (
                                    <NotificationListItem
                                        // Needed for infinitescroll component to work
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={notification.id}
                                        icon={icon}
                                        iconStyle={iconStyle}
                                        notification={notification}
                                    />

                                );
                            })}

                        </InfiniteScroll>
                    </List>
                </ScrollableContent>
                <Paper className={classes.footer} />
            </div>
        </Drawer>
    );
};

NotificationsDrawer.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired
};

export default NotificationsDrawer;