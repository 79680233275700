import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes as renderReactRoutes } from 'react-router-config';
import { useHttpRequest } from 'src/utils/httpClient';
import useNavConfig from 'src/navConfig';
import { useCheckIfSysAdmin } from 'src/auth';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationActions } from 'src/actions';
import { useSnackbar } from 'src/components/Snackbar';

const ProductAccessContext = React.createContext();
const ProductAccessConsumer = ProductAccessContext.Consumer;

const ProductAccessProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { customerId } = useParams();
    const isSysAdmin = useCheckIfSysAdmin();
    const { error: errorSnack } = useSnackbar();
    const [productAccess, setProductAccess] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { selectedCustomerRole } = useSelector((state) => state.auth);

    const { mutate: fetchCustomerProductAccess } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/accesscontrol/productaccess'
    }));

    const { mutate: fetchSelectedCustomer } = useHttpRequest(() => ({
        method: 'GET',
        endpoint: '/customermanagement/selectedcustomer'
    }));

    React.useEffect(() => {
        const makeRequest = async () => {
            const { error, payload: productAccessPayload } = await fetchCustomerProductAccess();

            if (!error) {
                setProductAccess(Array.isArray(productAccessPayload) ? productAccessPayload : []);

                const { error, payload } = await fetchSelectedCustomer();
                dispatch({ type: AuthenticationActions.SelectedCustomerFetched, data: error ? null : payload });
            } else {
                errorSnack('Error fetching Customer access.');
                setProductAccess([]);
            }
            setLoading(false);
        };

        if (customerId) {
            setProductAccess([]);
            setLoading(true);
            makeRequest();
        } else {
            setProductAccess([]);
        }
    }, [customerId, fetchCustomerProductAccess, fetchSelectedCustomer, errorSnack]);

    const renderRoutes = React.useCallback((routes, props) => {
        if (isSysAdmin) {
            return renderReactRoutes(routes, props);
        }

        const allProductTypes = productAccess?.map((pa) => pa.productType) ?? [];
        const allowedRoutes = routes.filter((route) => {
            let disabled = false;

            if (!Array.isArray(route.restrictedToProductTypes)) {
                return true;
            }

            const productAssignedToCustomer = allProductTypes.find((productType) => route.restrictedToProductTypes.some((p) => p === productType));
            const productEffectiveAccess = productAccess?.find?.((product) => (route.restrictedToProductTypes.some((productType) => productType === product?.productType)));

            if (!productAssignedToCustomer) {
                disabled = true;
            } else if (Array.isArray(route.restrictedToRoles) && productEffectiveAccess) {
                disabled = !route.restrictedToRoles.find((role) => role === productEffectiveAccess?.effectiveRole);
            } else if (Array.isArray(route.restrictedToRoles) && !route.restrictedToRoles.find((role) => role === selectedCustomerRole)) {
                disabled = true;
            }
            return !disabled;
        });

        return renderReactRoutes(allowedRoutes, props);
    }, [productAccess, isSysAdmin, selectedCustomerRole]);

    return (
        <ProductAccessContext.Provider
            value={{
                loading,
                productAccess,
                renderRoutes
            }}
        >
            {children}
        </ProductAccessContext.Provider>
    );
};

ProductAccessProvider.propTypes = {
    children: PropTypes.any
};

const useProductAccessContext = () => {
    const context = React.useContext(ProductAccessContext);
    if (context === undefined) {
        throw new Error('useProductAccessContext must be used within a ProductAccessProvider');
    }
    return context;
};

const filterItems = (items, customerProductAccess, selectedCustomerRole, isSysAdmin) => {
    const allProductTypes = customerProductAccess?.map((pa) => pa.productType) ?? [];

    return items
        .map((item) => {
            let disabled = false;

            if (Array.isArray(item.restrictedToProductTypes)) {
                const productAssignedToCustomer = allProductTypes?.some?.((productType) => item?.restrictedToProductTypes?.some?.((p) => p === productType));
                const productEffectiveAccess = customerProductAccess?.find?.((product) => (item?.restrictedToProductTypes?.some?.((productType) => productType === product?.productType)));

                if (!productAssignedToCustomer) {
                    disabled = true;
                } else if (Array.isArray(item.restrictedToRoles) && productEffectiveAccess) {
                    disabled = !item.restrictedToRoles.some((role) => role === productEffectiveAccess?.effectiveRole);
                } else if (Array.isArray(item.restrictedToRoles) && !item.restrictedToRoles.find((role) => role === selectedCustomerRole)) {
                    disabled = true;
                }
            }

            if (item.items) {
                return {
                    ...item,
                    disabled,
                    items: [
                        ...filterItems(item.items, customerProductAccess, selectedCustomerRole, isSysAdmin)
                    ]
                };
            }
            return {
                ...item,
                disabled
            };
        });
};
/**
 * Hook method used to get NavConfig with allowed links
 */
const useProductAccessNavConfig = () => {
    const { productAccess } = useProductAccessContext();
    const isSysAdmin = useCheckIfSysAdmin();
    const { selectedCustomerRole } = useSelector((state) => state.auth);
    const navConfig = useNavConfig();

    const config = React.useMemo(() => {
        if (productAccess?.length > 0) {
            return filterItems(navConfig, productAccess, selectedCustomerRole, isSysAdmin);
        }
        return [];
    }, [navConfig, isSysAdmin, productAccess, selectedCustomerRole]);

    return config;
};

const useAssignedProducts = () => {
    const { productAccess } = useProductAccessContext();
    return React.useMemo(() => productAccess?.reduce?.((products, ap) => ({ ...products, [ap?.productType]: true }), {}) ?? {}, [productAccess]);
};

const useProducts = () => {
    const { productAccess } = useProductAccessContext();
    return React.useMemo(() => (Array.isArray(productAccess) ? productAccess : []), [productAccess]);
};

const useProductsByProductType = () => {
    const { productAccess } = useProductAccessContext();
    return React.useMemo(() => productAccess?.reduce?.((products, ap) => ({ ...products, [ap?.productType]: ap }), {}) ?? {}, [productAccess]);
};

const useCheckProductAssignment = (product) => {
    const assignedProducts = useAssignedProducts();
    return Boolean(assignedProducts[product]);
};

export {
    ProductAccessProvider,
    ProductAccessConsumer,
    useProductAccessContext,
    useProductAccessNavConfig,
    useProducts,
    useProductsByProductType,
    useAssignedProducts,
    useCheckProductAssignment
};