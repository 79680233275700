import * as actions from 'src/actions';

const initialState = {
    pageHasChanges: false,
    confirmationRequested: false,
    confirmationResolveFunction: null,
    confirmationPromise: null
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CHANGES_UNSAVED: {
            return {
                ...state,
                pageHasChanges: true
            };
        }
        case actions.CHANGES_SAVED: {
            return {
                ...state,
                pageHasChanges: false
            };
        }
        case actions.CONFIRMATION_REQUESTED: {
            return {
                ...state,
                confirmationRequested: true,
                confirmationResolveFunction: action.data
            };
        }
        case actions.CONFIRMATION_SAVE_PROMISE: {
            return {
                ...state,
                confirmationPromise: action.data
            };
        }
        case actions.CONFIRMATION_RESPONSE_RECEIVED: {
            return {
                ...state,
                confirmationPromise: null,
                confirmationRequested: false,
                confirmationResolveFunction: null,
                pageHasChanges: action.resp
            };
        }
        case actions.RESET_REDUCER_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default sessionReducer;
