import React from 'react';
import CarrierBillingStatisticsDoughnut from 'src/components/CarrierBilling/Statistics/CarrierBillingStatisticsDoughnut';
import PropTypes from 'prop-types';
import WidgetDataProvider from 'src/components/Widgets/WidgetDataProvider';
import { getIntervalDates } from 'src/widgets/widgetUtils';

const CarrierBillingStatisticsDoughnutWidget = ({ settings }) => {
    const { chips, visualization, interval } = settings;
    const { labels } = getIntervalDates(interval);

    return (
        <WidgetDataProvider settings={{
            ...settings,
            requestSettings: {
                ...settings.requestSettings,
                params: {
                    ...settings.requestSettings.params
                }
            }
        }}
        >
            {(data) => (
                <CarrierBillingStatisticsDoughnut
                    data={data ?? []}
                    chips={chips?.concat(labels) ?? []}
                    showBy={visualization}
                    interval={interval}
                    isWidget
                />
            )}
        </WidgetDataProvider>
    );
};

CarrierBillingStatisticsDoughnutWidget.propTypes = {
    settings: PropTypes.object.isRequired
};

export const size = {
    xl: {
        w: 5,
        h: 3
    },
    lg: {
        w: 6,
        h: 3
    },
    md: {
        w: 6,
        h: 3
    },
    xs: {
        w: 3,
        h: 3
    },
    xxs: {
        w: 8,
        h: 3
    }
};

export default CarrierBillingStatisticsDoughnutWidget;