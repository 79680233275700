import { FormControl, FormHelperText, Grid, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AddRounded } from '@material-ui/icons';
import React from 'react';
import OutlinedContainer from 'src/components/FormComponents/OutlinedContainer';
import PropTypes from 'prop-types';
import WhatsappTemplateButtonCard from 'src/components/MessageTemplates/WhatsappTemplateButtonCard';
import uuid from 'uuid';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3)
    },
    formControl: {
        flex: 1,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    fieldSet: {
        width: '100%'
    },
    addButton: {
        width: 150
    },
    formHelperText: {
        marginTop: theme.spacing(2)
    }
}));

export const TemplateButtonTypes = {
    Call: 'Call',
    QuickReply: 'QuickReply',
    Url: 'Url'
};

const templateButtonDefaults = {
    label: '',
    data: ''
};

const WhatsappTemplateButtons = ({ buttons = false, disabled, onChange }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddButton = (type) => () => {
        setAnchorEl(null);
        onChange([
            ...buttons,
            { ...templateButtonDefaults, type, _key: uuid() }
        ]);
    };

    const handleButtonChanged = (index, newValue) => {
        const _buttons = [...buttons];
        _buttons.splice(index, 1, newValue);
        onChange(_buttons);
    };

    const handleButtonRemoved = (index) => () => {
        const _buttons = [...buttons];
        _buttons.splice(index, 1);
        onChange(_buttons);
    };

    return (
        <OutlinedContainer className={classes.formControl} label="Template Buttons">
            <FormControl component="fieldset" className={classes.fieldSet}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                >
                    {buttons?.map?.((button, index) => (
                        <Grid
                            item
                            key={button?._key}
                        >
                            <WhatsappTemplateButtonCard
                                disabled={disabled}
                                button={button}
                                onChange={(newValue) => handleButtonChanged(index, newValue)}
                                onRemove={handleButtonRemoved(index)}
                            />
                        </Grid>
                    ))}
                    {!disabled && (
                        <Grid item>
                            <Tooltip title="Add Template Button">
                                <IconButton size="small" aria-controls="templateButtonTypeMenu" aria-haspopup="true" onClick={handleClick}><AddRounded /></IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <FormHelperText className={classes.formHelperText}>WhatsApp buttons, can contain either "Quick Reply" or "Call" and/or "Url" </FormHelperText>
            </FormControl>
            <Menu
                id="templateButtonTypeMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAddButton(TemplateButtonTypes.Call)}>Call</MenuItem>
                <MenuItem onClick={handleAddButton(TemplateButtonTypes.QuickReply)}>Quick Reply</MenuItem>
                <MenuItem onClick={handleAddButton(TemplateButtonTypes.Url)}>Url</MenuItem>
            </Menu>
        </OutlinedContainer>
    );
};

WhatsappTemplateButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default WhatsappTemplateButtons;