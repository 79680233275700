import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';
import ThreadChatWindow from 'src/components/Dialogs/ChatWindow/ThreadChatWindow';
import { ThreadChatWindowContextProvider } from 'src/components/Dialogs/ChatWindow/ThreadChatWindow/ThreadChatWindowContext';
import { useHttpGetRequest } from 'src/utils/httpClient';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        padding: 15,
        display: 'flex',
        height: '100%',
        width: '100%'
    }
});

const DialogChatWindow = ({ thread }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dialogState = useSelector((state) => state.dialog);
    const threadId = thread?.id;
    const serviceId = thread?.serviceId;
    const [sentDialogMessage, setSentDialogMessage] = React.useState(null);

    const { payload: threadsPayload, loading: threadsLoading, query: refreshThread } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/messaging/services/dialog/threads',
        params: { filters: `id == ${threadId}` }
    });

    const { payload: threadMessagesPayload, loading: threadMessagesLoading, query: refreshThreadMessages } = useHttpGetRequest({
        method: 'GET',
        endpoint: `/messaging/services/dialog/threads/${serviceId}/${threadId}`
    });

    const refreshWidget = React.useCallback((eventThreadId) => {
        if (eventThreadId === threadId) {
            refreshThread();
            refreshThreadMessages();
            setSentDialogMessage(null);
        }
    }, [threadId, refreshThread, refreshThreadMessages]);

    const threadsById = React.useMemo(() => threadsPayload?.value?.reduce?.((threads, thread) => ({ ...threads, [thread?.id]: thread }), {}) ?? {}, [threadsPayload]);
    const threadMessagesById = React.useMemo(() => threadMessagesPayload?.reduce?.((messages, message) => ({
        ...messages,
        [message?.id]: message
    }), {}) ?? {}, [threadMessagesPayload]);

    const selectedThread = React.useMemo(() => threadsById?.[threadId] ?? thread, [threadsById, threadId, thread]);

    React.useEffect(() => {
        const subscriptionId = dispatch(signalREventSubscribe('IDiscussionThreadStatusChanged', ({ data }) => {
            refreshWidget(data?.threadId);
        }));

        return () => {
            dispatch(signalREventUnsubscribe('IDiscussionThreadStatusChanged', subscriptionId));
        };
    }, [dispatch, refreshWidget]);

    React.useEffect(() => {
        if (threadId === dialogState.sentDialogMessage?.sendRequest?.threadId) {
            setSentDialogMessage(dialogState.sentDialogMessage);
        }
    }, [threadId, dialogState.sentDialogMessage]);

    return (
        <div className={classes.root}>
            <ThreadChatWindowContextProvider
                dialogState={{
                    ...dialogState,
                    isWidget: true,
                    selectedThread,
                    threadsById,
                    threadsLoading,
                    threadMessagesById,
                    threadMessagesLoading,
                    sentDialogMessage
                }}
            >
                <ThreadChatWindow />
            </ThreadChatWindowContextProvider>
        </div>
    );
};

DialogChatWindow.propTypes = {
    thread: PropTypes.object.isRequired
};

export default DialogChatWindow;