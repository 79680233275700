/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { DIALOGSERVICE } from 'src/smartDialogProducts';

const { NODE_ENV, REACT_APP_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID } = process.env;

const useStyles = makeStyles((theme) => ({
    pageRoot: {
        padding: theme.spacing(0, 2, 2, 2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 1, 0, 1)
        },
        overflow: 'hidden',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        'body *#lg_chat_container &': {
            marginBottom: 60
        }
    }
}));

const Page = ({ title, children }) => {
    const location = useLocation();
    const classes = useStyles();
    const { unreadThreadCount } = useSelector((state) => state.dialog);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    const pageTitle = React.useMemo(() => {
        if (customerHasDialogServiceAccess && unreadThreadCount > 0) {
            return `(${unreadThreadCount}) Unread | SmartDialog`;
        }
        return `${title} | SmartDialog`;
    }, [customerHasDialogServiceAccess, unreadThreadCount, title]);

    useEffect(() => {
        if (NODE_ENV !== 'production') {
            return;
        }

        if (window.gtag) {
            window.gtag('config', GA_MEASUREMENT_ID, {
                page_path: location.pathname,
                page_name: title
            });
        }
        /* eslint-disable-next-line */
    }, []);

    return (
        <>
            <Helmet defer={false}>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={classes.pageRoot}>
                {children}
            </div>
        </>
    );
};

Page.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string
};

export default Page;
