import React from 'react';
import Guide from 'src/components/GuideWidget/GuideWidget';

const GuideWidget = () => {
    return (
        <Guide />
    );
};

export const size = {
    xl: {
        w: 4,
        h: 3
    },
    lg: {
        w: 4,
        h: 3
    },
    md: {
        w: 4,
        h: 2
    },
    xs: {
        w: 4,
        h: 3
    },
    xxs: {
        w: 8,
        h: 2
    }
};

export default GuideWidget;