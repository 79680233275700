import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { LinearProgress } from '@material-ui/core';
import Widget from 'src/components/Widgets/Widget';
import { useWidgetContext, useWidgetDispatch, actions as widgetActions } from 'src/components/Widgets/WidgetContext';

const ResponsiveGridLayout = WidthProvider(Responsive);

const WidgetGrid = () => {
    const { widgets, layout, widgetsLoading, editMode } = useWidgetContext();
    const dispatch = useWidgetDispatch();

    const onLayoutChange = (currentLayout, allLayouts) => {
        dispatch({ type: widgetActions.LAYOUT_CHANGED, data: allLayouts });
    };

    const onRemoveWidget = (widgetId) => {
        dispatch({ type: widgetActions.REMOVE_WIDGET, data: widgetId });
    };

    if (!widgetsLoading && widgets.length === 0) {
        return <img src="/images/SmartDialog.jpg" alt="dashboard" style={{ maxWidth: '100%' }} />;
    }

    if (widgetsLoading) {
        return <LinearProgress />;
    }

    return (
        <ResponsiveGridLayout
            className="layout"
            isResizable={false}
            isDraggable={editMode}
            layouts={layout}
            breakpoints={{ xl: 1920, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ xl: 12, lg: 10, md: 8, sm: 6, xs: 4, xxs: 1 }}
            onBreakpointChange={() => { }}
            onLayoutChange={onLayoutChange}
        >
            {widgets?.map((widget) => (
                <Widget
                    key={widget?.id}
                    id={widget?.id}
                    component={widget?.component}
                    layout={widget?.layout}
                    settings={widget?.settings}
                    onRemoveWidget={() => onRemoveWidget(widget?.id)}
                />
            ))}
        </ResponsiveGridLayout>
    );
};

export default WidgetGrid;