import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INFOMESSAGES_LOADED, INFOMESSAGES_LOADING } from 'src/actions/alertActions';
import { signalREventSubscribe, signalREventUnsubscribe } from 'src/actions/signalrActions';
import { useHttpGetRequest } from 'src/utils/httpClient';

const NewsAndAlertsProvider = () => {
    const { selectedCustomerId } = useSelector((state) => state.auth);
    const { shouldRefreshInfoMessages } = useSelector((state) => state.alerts);
    const dispatch = useDispatch();

    const { payload: infoMessagePayload, query: _refreshInfomessages } = useHttpGetRequest({
        method: 'GET',
        endpoint: '/newsandalerts/usermessages'
    }, false);

    const refreshInfomessages = React.useCallback(_refreshInfomessages, []);

    // Set alerts loading on load
    React.useEffect(() => {
        dispatch({ type: INFOMESSAGES_LOADING, data: true });
    }, [dispatch]);

    // Set alerts in reducer when done fetching
    React.useEffect(() => {
        if (infoMessagePayload) {
            dispatch({ type: INFOMESSAGES_LOADED, data: infoMessagePayload });
        }
    }, [dispatch, infoMessagePayload]);

    // Trigger update of alerts when called or when customer changed
    React.useEffect(() => {
        if (shouldRefreshInfoMessages) {
            refreshInfomessages();
        }
    }, [shouldRefreshInfoMessages, refreshInfomessages]);

    // Subscribe to IInfoMessageEvent event
    React.useEffect(() => {
        if (selectedCustomerId) {
            refreshInfomessages();

            dispatch(signalREventSubscribe('IInfoMessageEvent', () => {
                refreshInfomessages();
            }));
        }

        return () => {
            dispatch(signalREventUnsubscribe('IInfoMessageEvent'));
        };
    }, [dispatch, refreshInfomessages, selectedCustomerId]);

    return null;
};

export default NewsAndAlertsProvider;