import React from 'react';
import { createClient, ClientContextProvider, useMutation, useQuery } from 'react-fetching-library';
import { trackException } from 'src/utils/appInsights';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import Logger from 'src/utils/logger';
import { useSelector } from 'react-redux';
import parseErrorObject from './httpClientErrorParser';

export const requestAuthHeadersInterceptor = () => async (action) => {
    const resp = {
        ...action,
        endpoint: `${!(action?.endpoint?.startsWith('http')) ? process.env.REACT_APP_HOST : ''}${action.endpoint}`
    };

    Logger.info({ ...resp, jsonBody: JSON.stringify(resp?.body) });

    return resp;
};

export const responseInterceptor = () => async (action, interceptedResponse) => {
    const { payload, headers: _headers, status, error, ...rest } = interceptedResponse;
    const headers = {};

    const it = _headers?.entries();
    let result = it?.next();

    while (!result.done) {
        const headerName = result.value[0];
        const headerValue = result.value[1];
        headers[headerName] = headerValue;
        result = it.next();
    }

    const response = { ...interceptedResponse, headers, payload: status === 204 ? undefined : payload, status, action };

    if (error) {
        Logger.error('Error: ', response);
        const errorMessages = parseErrorObject(response);

        trackException(errorMessages);

        return { ...response, headers, error, errorMessages, ...rest };
    }

    Logger.info(response);
    return response;
};

const client = createClient({
    requestInterceptors: [requestAuthHeadersInterceptor],
    responseInterceptors: [responseInterceptor]
});

const HttpClientProvider = ({ children }) => {
    return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};

HttpClientProvider.propTypes = {
    children: PropTypes.node
};

const useHttpRequestHeaders = () => {
    const { accessToken } = useSelector((state) => state.auth);

    return React.useMemo(() => ({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }), [accessToken]);
};

const buildEndpointUrlFromParams = (endpointUrl, params) => {
    return endpointUrl + (params && Object.keys(params).length > 0 ? (`?${Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')}`) : '');
};

const useHttpRequest = (requestConfig) => {
    const { customerId } = useParams();
    const [requestInProgress, setRequestInProgress] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const headers = useHttpRequestHeaders();

    const { payload, mutate: _mutate, error, reset, abort: _abort } = useMutation((data) => {
        const _requestConfig = requestConfig(data);
        const endpoint = buildEndpointUrlFromParams(_requestConfig.endpoint, _requestConfig.params);

        return {
            ..._requestConfig,
            endpoint,
            headers: {
                ..._requestConfig.headers,
                ...headers,
                customerId
            }
        };
    });

    const mutate = React.useCallback(_mutate, [headers, customerId]);
    const abort = React.useCallback(_abort, []);

    React.useEffect(() => {
        let mounted = true;

        const makeRequest = async () => {
            const { data, resolve } = requestInProgress;
            const resp = await mutate(data);
            if (mounted) {
                setLoading(false);
                setRequestInProgress(null);
            }
            resolve(resp);
        };
        if (mounted && requestInProgress) {
            makeRequest();
        }

        return () => {
            mounted = false;
        };
    }, [requestInProgress, mutate]);

    // Abort requests on component dismount
    React.useEffect(() => () => abort(), [abort]);

    const mutateFunction = React.useCallback((data) => {
        setLoading(true);
        return new Promise((resolve) => { setRequestInProgress({ data, resolve }); });
    }, []);

    return { loading, payload, mutate: mutateFunction, error, reset, abort };
};

const useHttpGetRequest = (requestConfig, initFetch = true) => {
    const { customerId } = useParams();
    const [queryTriggered, triggerQuery] = React.useState(false);
    const [loading, setLoading] = React.useState(initFetch);
    const headers = useHttpRequestHeaders();

    const endpoint = buildEndpointUrlFromParams(requestConfig.endpoint, requestConfig.params);

    const config = {
        ...requestConfig,
        endpoint,
        method: 'GET',
        headers: {
            ...requestConfig.headers,
            ...headers,
            customerId
        }
    };

    const { query: _query, abort: _abort, ...rest } = useQuery(config, false);

    const query = React.useCallback(_query, [config]);
    const abort = React.useCallback(_abort, []);

    const runQuery = React.useCallback(() => {
        setLoading(true);
        triggerQuery(null);

        // Before triggering new request, abort previous request if its still running
        abort();
        return new Promise((resolve) => { triggerQuery({ resolve }); });
    }, [abort]);

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async (resolve) => {
            const resp = await query(config);
            if (resp.error && resp?.errorObject?.code === 20) {
                // User aborted so new request is still loading, dont set loading to false
                Logger.info('User aborted previous request');
            } else {
                // User did not abort
                setLoading(false);
            }
            resolve(resp);
        };

        if (mounted && queryTriggered) {
            const { resolve } = queryTriggered;
            triggerQuery(null);
            fetchData(resolve);
        }

        return () => {
            mounted = false;
        };
    }, [config, queryTriggered, query]);

    // Trigger query if endpoint changes eg. if parameters change when filtering
    React.useEffect(() => {
        let mounted = true;

        if (mounted && initFetch) {
            runQuery();
        }

        return () => {
            mounted = false;
        };
    }, [initFetch, endpoint, runQuery]);

    // Abort requests on component dismount
    React.useEffect(() => () => abort(), [abort]);

    return { ...rest, query: runQuery, loading, abort };
};

const useHttpFetchRequest = (requestConfig) => {
    const headers = useHttpRequestHeaders();
    const { customerId } = useParams();
    const [requestInProgress, setRequestInProgress] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const getConfig = React.useCallback(requestConfig, []);

    React.useEffect(() => {
        let mounted = true;

        const makeRequest = async () => {
            const { data, resolve } = requestInProgress;

            const _requestConfig = getConfig(data);

            const request = {
                ..._requestConfig,
                endpoint: buildEndpointUrlFromParams(`${process.env.REACT_APP_HOST}${_requestConfig.endpoint}`, _requestConfig.params),
                headers: {
                    ..._requestConfig.headers,
                    Authorization: headers.Authorization,
                    customerId
                }
            };

            if (mounted) { setLoading(true); }

            try {
                Logger.info(request);
                const resp = await fetch(request.endpoint, request);
                let payload = await resp.text();

                try {
                    payload = JSON.parse(payload);
                    // eslint-disable-next-line no-empty
                } catch (error) { }

                const error = !resp.ok;
                let response = {};

                if (error) {
                    response = { status: resp?.status, error, errorMessages: parseErrorObject({ payload, status: resp?.status }), payload };
                } else {
                    response = { status: resp?.status, error, payload };
                }

                Logger.info(response);
                resolve(response);
            } catch (error) {
                Logger.error(error);
                setError(true);
                resolve({ error: true, payload: null });
            }

            if (mounted) {
                setLoading(false);
                setRequestInProgress(null);
            }
        };
        if (requestInProgress) {
            makeRequest();
        }

        return () => { mounted = false; };
    }, [requestInProgress, getConfig, customerId, headers]);

    const mutateFunction = React.useCallback((data) => {
        return new Promise((resolve) => { setRequestInProgress({ data, resolve }); });
    }, []);

    return { loading, mutate: mutateFunction, error };
};

const useHttpGetWithContinuation = (endpoint) => {
    if (!endpoint || typeof endpoint !== 'string') {
        throw new Error('endpoint parameter needs to be set in useHttpGetWithContinuation hook');
    }

    const [continuationToken, setContinuationToken] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [initiallyFetched, setInitiallyFetched] = React.useState(false);
    const [fetchMore, setFetchMore] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const { query, loading } = useHttpGetRequest({
        method: 'GET',
        endpoint,
        headers: {
            ...((continuationToken !== null && continuationToken !== undefined) ? { 'x-continuation-token': continuationToken } : {})
        }
    });

    React.useEffect(() => {
        const makeRequest = async (concatPayload) => {
            const { payload, error, headers } = await query();

            if (!error) {
                if (concatPayload) {
                    setData((data) => data.concat(Array.isArray(payload) ? payload : []));
                } else {
                    setData(Array.isArray(payload) ? payload : []);
                }
            }
            setContinuationToken(headers?.['x-continuation-token'] ?? null);
        };

        if (!initiallyFetched || refresh) {
            setContinuationToken(null);
            setInitiallyFetched(true);
            setRefresh(false);
            makeRequest(false);
        }

        if (fetchMore) {
            setFetchMore(false);
            makeRequest(true);
        }
    }, [query, initiallyFetched, refresh, fetchMore]);

    return {
        data,
        loading,
        hasMore: React.useMemo(() => Boolean(continuationToken), [continuationToken]),
        loadMore: React.useCallback(() => setFetchMore(true), []),
        refresh: React.useCallback(() => setRefresh(true), [])
    };
};

export {
    HttpClientProvider,
    useHttpRequest,
    useHttpGetRequest,
    useHttpFetchRequest,
    useHttpGetWithContinuation
};