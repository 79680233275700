import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { ClearThreads, SET_DIALOG_INBOX_HAS_FOCUS, SubscribeDiscussionThreadCreated, SubscribeDiscussionThreadReassigned, SubscribeDiscussionThreadStatusChanges, UnsubscribeDiscussionThreadCreated, UnsubscribeDiscussionThreadReassigned, UnsubscribeDiscussionThreadStatusChanges } from 'src/actions';
import { useDialogMessageProcessor, useLoadCustomerInputSources, useLoadDialogServiceMessages, useLoadDialogServices, useLoadDialogThreads, useLoadDiscussionServices, useLoadPendingDialogThreads, useLoadPinnedDialogThreads } from 'src/actions/actionHooks';
import { useCheckProductAssignment } from 'src/auth/ProductAccess';
import { DIALOGSERVICE } from 'src/smartDialogProducts';
import useTabHasFocus from 'src/utils/useTabHasFocus';

const DialogProvider = () => {
    const dispatch = useDispatch();
    const { selectedCustomerId } = useSelector((state) => state.auth);

    const tabFocused = useTabHasFocus();

    const location = useLocation();
    const match = React.useMemo(() => matchPath(location.pathname, '/:customerId/dialogs'), [location.pathname]);

    const customerHasDialogServiceAccess = useCheckProductAssignment(DIALOGSERVICE);

    useLoadDialogServices();
    useLoadDiscussionServices();
    useLoadDialogThreads();
    useLoadPendingDialogThreads();
    useLoadPinnedDialogThreads();
    useLoadDialogServiceMessages();
    useDialogMessageProcessor();
    useLoadCustomerInputSources();

    React.useEffect(() => {
        if (selectedCustomerId && customerHasDialogServiceAccess) {
            dispatch(SubscribeDiscussionThreadCreated());
            dispatch(SubscribeDiscussionThreadStatusChanges());
            dispatch(SubscribeDiscussionThreadReassigned());
        }

        return () => {
            dispatch(ClearThreads());
            dispatch(UnsubscribeDiscussionThreadCreated());
            dispatch(UnsubscribeDiscussionThreadStatusChanges());
            dispatch(UnsubscribeDiscussionThreadReassigned());
        };
    }, [dispatch, selectedCustomerId, customerHasDialogServiceAccess]);

    React.useEffect(() => {
        dispatch({
            type: SET_DIALOG_INBOX_HAS_FOCUS,
            data: match && tabFocused && customerHasDialogServiceAccess
        });

        return () => {
            dispatch({
                type: SET_DIALOG_INBOX_HAS_FOCUS,
                data: false
            });
        };
    }, [dispatch, match, tabFocused, customerHasDialogServiceAccess]);

    return null;
};

export default DialogProvider;