import React from 'react';
import { HttpClientProvider } from 'src/utils/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationActions, useGetLoginRequest } from 'src/actions/authActions';
import AuthenticationStatusPage from 'src/auth/AuthenticationStatusPage';
import { InteractionStatus, InteractionRequiredAuthError, InteractionType, BrowserAuthError, ServerError } from '@azure/msal-browser';
import { MsalProvider, useMsal, useMsalAuthentication, useIsAuthenticated } from '@azure/msal-react';
import useAccessToken from 'src/auth/useAccessToken';
import useInterval from 'src/utils/useInterval';
import { Typography } from '@material-ui/core';
import AuthLayout from 'src/layouts/AuthLayout';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SnackbarProvider from 'src/components/Snackbar';
import PropTypes from 'prop-types';
import { trackException } from 'src/utils/appInsights';
import CustomerRightsProvider from 'src/providers/CustomerRightsProvider';
import RouterProvider from 'src/providers/RouterProvider';
import SoundProvider from 'src/providers/SoundProvider';

const checkRefreshTokenIntervalMs = 60000;

const IsAuthenticated = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const loginRequest = useGetLoginRequest();
    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const isAuthenticated = useIsAuthenticated();
    const accessToken = useAccessToken(loginRequest.scopes);
    const { accessToken: stateAccessToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (error) {
            trackException(error);
        }
        if (error && error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else if (error instanceof BrowserAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else if (error instanceof ServerError) {
            login(InteractionType.Redirect, loginRequest);
        }
    }, [error, login, loginRequest]);

    React.useEffect(() => {
        if (accessToken) {
            dispatch({ type: AuthenticationActions.AccessTokenRefreshed, data: accessToken });
        }
    }, [dispatch, accessToken]);

    // Call acquireTokenSilent every checkRefreshTokenIntervalMs to refresh accesstoken automatically in the background
    useInterval(async () => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            const request = {
                account: accounts[0],
                scopes: loginRequest.scopes
            };
            instance.acquireTokenSilent(request).then((response) => {
                if (response.accessToken !== stateAccessToken) {
                    dispatch({ type: AuthenticationActions.AccessTokenRefreshed, data: response.accessToken });
                }
            }).catch((error) => {
                trackException(error);

                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request);
                }
                if (error instanceof BrowserAuthError) {
                    return instance.acquireTokenRedirect(request);
                }

                if (error instanceof ServerError) {
                    return instance.acquireTokenRedirect(request);
                }
                return null;
            });
        }
    }, checkRefreshTokenIntervalMs);

    if (loginRequest.prompt) {
        instance.acquireTokenRedirect(loginRequest);
        return <AuthenticationStatusPage />;
    }

    if (isAuthenticated && stateAccessToken) {
        return (
            <HttpClientProvider>
                {children}
            </HttpClientProvider>
        );
    }

    if (
        error &&
        !(error instanceof InteractionRequiredAuthError) &&
        !(error instanceof BrowserAuthError) &&
        !(error instanceof ServerError)
    ) {
        return (
            <AuthenticationStatusPage>
                <Typography variant="h3">{error?.errorCode ?? ''}</Typography>
                <Typography variant="body1">{error?.errorMessage ?? ''}</Typography>
            </AuthenticationStatusPage>
        );
    }

    return <AuthenticationStatusPage />;
};

IsAuthenticated.propTypes = {
    children: PropTypes.any
};

const AuthContextProvider = ({ children, msalInstance }) => {
    return (
        <AuthLayout>
            <MsalProvider instance={msalInstance}>
                <IsAuthenticated>
                    <CustomerRightsProvider />
                    <RouterProvider />
                    <SoundProvider />
                    {/* SnackbarProvider used by ProductAccess. */}
                    <SnackbarProvider>
                        <GoogleAnalytics>
                            {children}
                        </GoogleAnalytics>
                    </SnackbarProvider>
                </IsAuthenticated>
            </MsalProvider>
        </AuthLayout>
    );
};

AuthContextProvider.propTypes = {
    children: PropTypes.any,
    msalInstance: PropTypes.object.isRequired
};

export default AuthContextProvider;