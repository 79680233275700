import React, { Suspense } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, IconButton, Tooltip, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, TextField, FormControl, Select, MenuItem, Input, InputAdornment, Button, FormHelperText } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CloseRounded';
import PropTypes from 'prop-types';
import { useSnackbar } from 'src/components/Snackbar';
import useCopyToClipboard from 'src/utils/useCopyToClipboard';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        minWidth: '40%'
    },
    dialogContent: {
        padding: '0 !important'
    },
    select: {
        width: '100%'
    },
    sliderDiv: {
        display: 'inline-block',
        width: '100%'
    },
    slider: {
        maxWidth: '90%',
        float: 'right',
        margin: '0'
    },
    textfield: {
        border: '1px solid #666;',
        borderRadius: '15px',
        width: '100%',
        padding: '2%',
        '& input.Mui-disabled': {
            color: '#666'
        }
    }
}));

const ShareLinkPopup = ({ open, handleClose, hasFoldersEnabled, folders }) => {
    const classes = useStyles();
    const location = useLocation();
    const { success: successSnack } = useSnackbar();
    const [subtractHours, setSubtractHours] = React.useState(24);
    const [folderId, setFolderId] = React.useState('_inbox_');
    const [link, setLink] = React.useState('');
    const { isCopied, handleCopy } = useCopyToClipboard(5000);

    const handleCancelClick = () => {
        handleClose();
    };

    const handleCopyLinkClick = () => {
        handleCopy(link);
    };

    const handleInputChange = (e) => {
        setSubtractHours(Number(e.target.value));
    };

    const handleBlur = () => {
        if (!Number.isNaN(Number(subtractHours))) {
            if (subtractHours < 0) {
                setSubtractHours(0);
            } else if (subtractHours > 744) {
                setSubtractHours(744);
            }
        } else {
            setSubtractHours(0);
        }
    };

    const onFolderChange = (e) => {
        setFolderId(e.target.value);
    };

    React.useEffect(() => {
        let path = location?.pathname ?? '';

        if (subtractHours > 0) {
            path = `${path}${path.includes('?') ? '&' : '?'}subtracthours=${subtractHours}`;
        }
        if (folderId !== '_inbox_') {
            path = `${path}${path.includes('?') ? '&' : '?'}folderid=${folderId}`;
        }
        setLink(`${window.location.protocol}//${window.location.host}${path}`);
    }, [folderId, subtractHours]);

    React.useEffect(() => {
        if (isCopied) {
            successSnack('Link copied to clipboard.');
        }
    }, [isCopied]);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth aria-labelledby="share-link">
            <DialogTitle id="share-dialog">Share</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Suspense fallback={<LinearProgress />}>
                    <TableContainer>
                        <Table>
                            <colgroup>
                                <col width="30%" />
                                <col width="70%" />
                            </colgroup>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">Subtract hours:</TableCell>
                                    <TableCell align="left">
                                        <Input
                                            value={subtractHours}
                                            size="small"
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 744,
                                                type: 'number'
                                            }}
                                        />
                                        <FormHelperText>Max value is 744 hours (31 days).</FormHelperText>
                                    </TableCell>
                                </TableRow>
                                {hasFoldersEnabled && (
                                    <TableRow>
                                        <TableCell align="left">Folder:</TableCell>
                                        <TableCell align="left">
                                            <FormControl className={classes.formControl}>
                                                <Select
                                                    id="folders-select"
                                                    name="folderId"
                                                    className={classes.select}
                                                    value={folderId ?? ''}
                                                    onChange={(e) => onFolderChange(e)}
                                                >
                                                    <MenuItem value="_allmessages_"><i>All messages</i></MenuItem>
                                                    <MenuItem value="_inbox_"><i>Inbox</i></MenuItem>
                                                    {folders?.map?.((f) => (
                                                        <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell align="left" colSpan={2}>
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                className={classes.textfield}
                                                value={link}
                                                disabled
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Button variant="contained" color="primary" onClick={handleCopyLinkClick} style={{ borderRadius: '15px' }}>
                                                                Copy
                                                            </Button>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Suspense>
            </DialogContent>
            <DialogActions>
                <IconButton aria-label="close" onClick={handleCancelClick}>
                    <Tooltip title="Close">
                        <CancelIcon />
                    </Tooltip>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

ShareLinkPopup.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    hasFoldersEnabled: PropTypes.bool,
    folders: PropTypes.array
};

export default ShareLinkPopup;