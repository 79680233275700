import React from 'react';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useWidgetContext } from 'src/components/Widgets/WidgetContext';
import { useWidgetComponentContext } from 'src/components/Widgets/WidgetComponentContext';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        height: '100%'
    },
    button: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 9999,
        margin: 20,
        backgroundColor: theme.palette.error.light,
        color: theme.palette.white,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        }
    },
    widgetMenuOverlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 2000,
        backgroundColor: 'rgba(80, 80, 80, 0.5)'
    }
}));

const WidgetMenu = ({ children }) => {
    const { editMode } = useWidgetContext();
    const { onRemoveWidget } = useWidgetComponentContext();
    const classes = useStyles();

    const handleClick = () => onRemoveWidget();

    return (
        <div className={classes.container}>
            {editMode && (
                <div className={classes.widgetMenuOverlay}>
                    <Fab className={classes.button} variant="extended" aria-label="remove widget" onClick={handleClick}>
                        <DeleteIcon />
                        Remove
                    </Fab>
                </div>
            )}
            {children}
        </div>
    );
};

WidgetMenu.propTypes = {
    children: PropTypes.object
};

export default WidgetMenu;