export const SET_MESSAGINGACCOUNTSLOADING = 'SET_MESSAGINGACCOUNTSLOADING';
export const SET_MESSAGINGACCOUNTS = 'SET_MESSAGINGACCOUNTS';
export const SET_MESSAGINGACCOUNT = 'SET_MESSAGINGACCOUNT';
export const SET_DEFAULTMESSAGINGACCOUNTLOADING = 'SET_DEFAULTMESSAGINGACCOUNTLOADING';
export const SET_DEFAULTMESSAGINGACCOUNT = 'SET_DEFAULTMESSAGINGACCOUNT';

export const setMessagingAccountsLoading = (data) => (dispatch) => dispatch({
    type: SET_MESSAGINGACCOUNTSLOADING,
    data
});

export const setMessagingAccounts = (data) => (dispatch) => dispatch({
    type: SET_MESSAGINGACCOUNTS,
    data
});

export const setMessagingAccount = (data) => (dispatch) => dispatch({
    type: SET_MESSAGINGACCOUNT,
    data
});

export const setDefaultMessagingAccountLoading = () => (dispatch) => dispatch({
    type: SET_DEFAULTMESSAGINGACCOUNTLOADING,
    data: true
});

export const setDefaultMessagingAccount = (data) => (dispatch) => dispatch({
    type: SET_DEFAULTMESSAGINGACCOUNT,
    data
});