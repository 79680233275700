import log from 'loglevel';

log.setLevel(3);

if (process.env.NODE_ENV === 'development') {
    log.enableAll();
}

const logWithLevel = (logLevel, message) => {
    switch (logLevel) {
        case log.levels.TRACE:
            return log.trace(message);
        case log.levels.DEBUG:
            return log.debug(message);
        case log.levels.WARN:
            return log.warn(message);
        case log.levels.ERROR:
            return log.error(message);
        default:
            return log.error(message);
    }
};

export default {
    log: logWithLevel,
    info: (message, ...otherMessages) => log.info(message, ...otherMessages),
    warn: (message, ...otherMessages) => log.warn(message, ...otherMessages),
    error: (message, ...otherMessages) => log.error(message, ...otherMessages)
};