import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: theme.spacing(3)
    }
}));

const ConfirmDialog = ({
    title,
    children,
    open,
    setOpen,
    onConfirm,
    onCancel,
    onExited,
    cancelButtonText,
    confirmButtonText,
    maxWidth,
    dialogActions
}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            maxWidth={maxWidth}
            fullWidth
            scroll="paper"
            TransitionProps={{
                onExited
            }}
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent className={classes.contentContainer}>{children}</DialogContent>

            <DialogActions>
                {dialogActions ? dialogActions({ setOpen, onConfirm, onCancel }) : (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                                if (typeof (onCancel) === 'function') {
                                    onCancel();
                                }
                            }}
                            color="secondary"
                        >
                            {cancelButtonText || 'No'}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                                onConfirm();
                            }}
                            color="default"
                        >
                            {confirmButtonText || 'Yes'}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onExited: PropTypes.func,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'false']),
    dialogActions: PropTypes.func
};

ConfirmDialog.defaultProps = {
    setOpen: () => { },
    maxWidth: 'sm',
    onExited: () => { }
};

export default ConfirmDialog;