import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const GoogleAnalytics = ({ children }) => {
    const [initialized, setInitialized] = React.useState(false);
    const { claims } = useSelector((state) => state.auth);
    const { pathname } = useLocation();

    React.useEffect(() => {
        // Track current UserId
        if (GA_MEASUREMENT_ID) {
            ReactGA.initialize([
                {
                    trackingId: GA_MEASUREMENT_ID,
                    gaOptions: {
                        userId: claims?.sub ?? null
                    }
                }
            ]);
            setInitialized(true);
        }
    }, [claims]);

    React.useEffect(() => {
        // Track current location pathname
        if (initialized && pathname) {
            ReactGA.send({ hitType: 'pageview', page: pathname });
        }
    }, [initialized, pathname]);

    return children;
};

export default GoogleAnalytics;
