import { DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import { NavigateBeforeRounded, NavigateNextRounded } from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0)
    },
    dialogTitleText: {
        flex: 1
    },
    buttons: {
        margin: theme.spacing(0, 0.5)
    }
}));

const DialogTitleWithNavigation = ({
    title = '',
    onNextClick,
    onBackClick
}) => {
    const classes = useStyles();

    return (
        <DialogTitle disableTypography className={classes.dialogTitle}>
            <IconButton disabled={!onBackClick} onClick={onBackClick} className={classes.buttons}><NavigateBeforeRounded /></IconButton>
            <Typography variant="h4" className={classes.dialogTitleText}>
                {title}
            </Typography>
            <IconButton disabled={!onNextClick} onClick={onNextClick} className={classes.buttons}><NavigateNextRounded /></IconButton>
        </DialogTitle>
    );
};

DialogTitleWithNavigation.propTypes = {
    title: PropTypes.string,
    onNextClick: PropTypes.func,
    onBackClick: PropTypes.func
};

export default DialogTitleWithNavigation;